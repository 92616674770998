.accept-all-cookies,
.mobile-suggestion {
  background: rgba(41, 41, 41, 0.8);
  margin: auto !important;
  padding: 24px;
  position: fixed;
  left: 50% !important;
  transform: translate(-50%, 0);
  bottom: 44px;
  z-index: 9999;
  box-shadow: 0 0 19px rgba(0, 0, 0, 0.5);
  border-radius: 16px;
  width: 100%;
  max-width: 615px;
  text-align: center;
  overflow-x: hidden;
  overflow-y: auto;

  &.webview {
    bottom: 88px;
  }

  span {
    color: $white !important;
  }

  @include mobile {
    padding: 16px;
    max-width: calc(100vw - 32px);
    bottom: 16px !important;
  }

  a {
    font-weight: 600;
    text-decoration: underline;
    color: $white !important;

    &:focus,
    &:hover,
    &:active {
      text-decoration: underline;
      color: $white !important;
    }
  }

  * {
    text-align: left;
  }

  .accept-cookies-button {
    display: flex;
    justify-content: flex-end;

    @include mobile {
      justify-content: center;
      margin-top: 16px;
    }

    span {
      font-size: 16px !important;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9998;
}
