.float-label {
  position: relative;
  width: 100%;

  input,
  textarea {
    min-height: 60px !important;
    font-weight: 500;
    font-size: 14px;
    padding-top: 18px !important;
    color: $default !important;

    &:disabled {
      border: none;
    }
  }

  textarea {
    padding-top: 24px !important;
  }

  .ant-input-affix-wrapper {
    padding: 0 16px 0 !important;
  }

  .ant-picker {
    padding: 0 16px !important;
    width: 100%;
  }

  .ant-select-selector {
    height: 60px !important;
    padding: 12px 16px 0 !important;
  }

  .label {
    width: 85% !important;
  }

  &--white {
    .label {
      background-color: $white;
    }
  }
}

.label {
  font-size: 12px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 16px;
  top: 20px;
  transition: 0.2s ease all;
  color: rgba($default, 0.7) !important;
  z-index: 999;
}

.label-float {
  top: 1px;
  padding-top: 5px;
  width: 100% !important;
  font-size: 12px !important;
  color: rgba($default, 0.7) !important;
}
