@import "../../styles/abstracts/variables";

$minWidth: 700px;
$maxWidth: 1024px;

.livestream-layout {
  .ant-layout-footer {
    margin-top: 0;
  }
}

.livestream {
  padding: 0 40px 40px 40px;

  &--with-chat {
    height: calc(100vh - 80px);
    padding: 0 0 0 40px;

    .chat-box__content {
      height: calc(100vh - 196px);
    }
  }

  &__action-button {
    width: 70px !important;
    height: 70px !important;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    animation-duration: 0s !important;

    svg path {
      fill: $default;
    }

    &:hover {
      background: $white;
    }

    &:active,
    &:focus {
      background: $white;
    }
  }

  &.container {
    max-width: 1400px !important;
  }

  .video-placeholder {
    .ant-typography {
      display: none !important;
    }
  }

  &__chat {
    width: 336px;
    margin-left: 12px;
  }

  .stream-player {
    > div {
      background-color: transparent !important;
    }

    border-radius: 8px;

    video {
      max-height: calc(100vh - 350px) !important;

      &.fullscreen {
        object-fit: contain !important;
        max-height: 100vh !important;
        max-width: 100vw !important;
      }
    }
    &--hidden {
      video {
        display: none !important;
      }
    }
  }

  .local-preview {
    justify-content: center;
    align-items: center;
    width: 704px;
    margin: auto;
    min-height: 70vh !important;

    &__header {
      width: 100%;
      margin: auto !important;
      margin-bottom: 40px !important;
      border-radius: 8px 8px 0 0;
      min-width: $minWidth;
      max-width: $maxWidth;
      &--time {
        background: $white;
        border-radius: 4px;
        padding: 6px 10px;
      }
      .ant-typography {
        font-weight: 500;
        font-size: 24px;

        @include mobile {
          font-size: 16px;
        }
      }

      &__dot {
        width: 10px;
        height: 10px;
        background-color: $red;
        border-radius: 100%;
      }

      &__info.ant-typography {
        font-weight: 500;
        font-size: 14px;
      }
    }

    &__container {
      justify-content: center;
      display: flex;
      align-items: center;
      background-color: $white;
      padding: 72px;
      border-radius: 16px;

      &__camera {
        width: 100%;
        margin: auto;
        position: relative;
        min-width: $minWidth;
        max-width: $maxWidth;
        border-radius: 8px;

        video {
          display: block;
          width: 100% !important;
          max-height: 60vh;
          min-height: 30vh;
          position: unset !important;
          height: 100%;
          border-radius: 8px;
          object-fit: contain !important;
          margin: auto !important;
          min-width: $minWidth;
          max-width: $maxWidth;
        }

        .btn-fullscreen {
          position: absolute;
          right: 16px;
          bottom: 24px;
          z-index: 999;
          padding: 0;
          border-radius: 100%;
          width: 32px !important;
          height: 32px !important;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 36px;
            height: 36px;

            path {
              fill: $default;
            }
          }
        }
      }
    }

    &__action-button {
      width: 100%;
      position: unset;
      max-width: $maxWidth;
      min-width: $minWidth;
      margin: auto !important;
      margin-bottom: 0 !important;
      margin-top: 40px !important;

      &.m__t--0 {
        margin-top: 0 !important;
      }

      .ant-btn {
        width: 72px !important;
        height: 72px !important;
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        animation-duration: 0s !important;

        &-text {
          background-color: transparent;
        }

        &:hover {
          background: $white;
          opacity: 0.7;
        }

        &:active,
        &:focus {
          background: $white;
        }

        svg {
          width: 36px;
          height: 36px;
        }
      }

      &__live.ant-btn {
        background-color: $red;
        width: 212px !important;
        font-size: 16px;
        color: $white;
        border-radius: 40px;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);

        &:hover,
        &:active,
        &:focus {
          background: $red;
          opacity: 1;
        }

        &:disabled {
          background-color: rgba($red, 0.3) !important;
        }
      }

      &__finish.ant-btn {
        width: 143px !important;
        background-color: transparent;
        color: $white;
        border: 1px solid $white;
        border-radius: 40px !important;
        font-weight: 500;
        font-size: 20px;

        &:hover,
        &:active,
        &:focus {
          background: $white;
          color: $black;
          opacity: 1;
        }
      }

      &__full-screen.ant-btn {
        position: absolute;
        bottom: 16px;
        right: 16px;
      }

      &__tip.ant-btn {
        width: auto !important;
        border-radius: 40px !important;
        padding: 0 32px;
      }
    }

    .video-track__video {
      height: auto !important;
    }

    .video-placeholder {
      width: 560px !important;
      height: 420px !important;

      &__container {
        height: 420px !important;
      }

      .ant-typography {
        display: none;
      }
    }

    .video-track {
      .ant-typography {
        display: none !important;
      }
    }
  }

  .btn-share-screen {
    height: auto !important;
    padding: 16px;
    background-color: transparent !important;
    border: 1px solid $white !important;
    border-radius: 8px !important;
    width: 212px;

    &:hover,
    &:active,
    &:focus {
      border: 1px solid $white !important;
    }

    svg path {
      stroke: $white;
    }

    &.ant-btn-red {
      border: 1px solid $red !important;

      &:hover,
      &:active,
      &:focus {
        border: 1px solid $red !important;
      }
    }
  }

  &__actions__button {
    width: 72px !important;
    height: 72px !important;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    animation-duration: 0s !important;
    border-radius: 100% !important;

    svg path {
      stroke: $default;
    }

    &:hover {
      background: $white;
    }

    &:active,
    &:focus {
      background: $white;
    }

    &.ant-btn-red {
      border-width: 2px;
      height: 48px !important;
    }

    &--finish {
      border-radius: 40px;
      width: 196px;
      height: 60px !important;
      background-color: transparent !important;

      &.ant-btn-red {
        width: 144px;
        border-width: 2px;
        height: 48px !important;
        border-radius: 40px;
      }
    }

    &--end-call {
      background-color: $red !important;
      border-color: $red !important;

      svg path {
        stroke: $white;
      }

      &:hover {
        background: $red;
      }

      &:active,
      &:focus {
        background: $red;
      }
    }
  }
}
