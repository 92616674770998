.data-capture-modal {
  padding: 0;

  @include mobile {
    width: 100% !important;
    max-width: unset;
    padding: 0 24px;
  }

  .ant-modal-content {
    overflow: visible;
  }

  &__scroll {
    max-height: calc(100vh - 184px);
    overflow: auto;
  }

  .ant-picker-input {
    input {
      padding-left: 0;
      height: 48px;
    }
  }

  .ant-form-item-required {
    display: block;
  }

  input,
  textarea {
    font-size: 16px;
    line-height: 24px;

    &::placeholder {
      font-size: 16px;
      line-height: 24px;
      color: #121212;
      opacity: 0.5;
    }
  }

  textarea {
    padding: 12px;
  }

  .react-tel-input {
    .phone-input {
      padding-left: 86px;
    }

    .selected-flag {
      width: 74px;

      .flag {
        // transform: scale(1.5, 2.182);
        // transform-origin: top center;
        // border-radius: 100%;
        // margin-top: -8px;

        .arrow {
          // transform: translate(0, -50%) scale(0.8335, 0.5835);
        }
      }
    }
  }

  .ant-form-item-has-error {
    :not(.ant-input-disabled).ant-input {
      border: 1px solid #ff3c45 !important;
    }

    .react-tel-input input.phone-input {
      border: 1px solid #ff3c45 !important;
    }

    .react-tel-input input.phone-input + .phone-button-select {
      border-right: 1px solid #ff3c45 !important;
    }
  }

  .ant-form-item-explain {
    min-height: 0;
    margin-top: 4px;
  }

  .ant-form-item-explain-error {
    margin-top: 0 !important;
    font-size: 12px;
    line-height: 16px;
  }

  .ant-select-selection-placeholder {
    font-size: 16px;
    line-height: 24px;
    color: #121212;
    opacity: 0.5;
    font-weight: 400;
  }

  #dateOfBirth {
    padding-left: 0;
  }

  .ant-checkbox {
    background-color: $white !important;
    font-weight: 400;

    span {
      color: $default;
    }

    + span {
      padding-left: 14px;
    }

    .ant-checkbox-inner {
      width: 20px !important;
      height: 20px !important;
    }

    &-group-item {
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 18px;
    }
  }

  .radio-form,
  .checkbox-form {
    font-weight: 400;

    .ant-form-item-label {
      padding-bottom: 0 !important;

      label {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .ant-radio {
    margin: 0 4px;

    &-wrapper {
      width: 100%;
      margin-top: 18px;
    }
  }

  .btn-actions {
    display: flex;
    flex-direction: row-reverse;
    column-gap: 24px;

    button {
      width: 160px !important;
      height: 52px !important;
    }

    @include mobile {
      flex-direction: column;

      button {
        width: 100% !important;
        height: 44px !important;

        span {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  .country-list {
    width: 294px !important;
    max-height: 336px !important;
    border: none !important;
    background: #ffffff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.18) !important;
    border-radius: 16px !important;
    margin: 8px 0 0 -1px !important;

    .country {
      padding: 16px 20px !important;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      // display: flex;

      .flag {
        // transform: scale(1.5, 2.182);
        // transform-origin: top center;
        // border-radius: 100%;
        // margin-top: 0;
      }

      &.highlight,
      &:hover {
        background: rgba(37, 127, 252, 0.05) !important;
      }

      .country-name {
        margin-left: 5px;
      }
    }
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
}

.country-dropdown {
  width: 219px !important;
  min-width: 219px !important;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.18);
  border-radius: 16px;
  padding: 0 !important;

  .rc-virtual-list-holder {
    max-height: 338px !important;
  }

  .ant-select-item-option-active {
    background: rgba(37, 127, 252, 0.05) !important;
  }
}

.data-capture-form-datepicker {
  &--modal {
    position: fixed;
    left: 0 !important;
    top: 0 !important;
    display: flex;
    align-items: center;
    justify-self: center;
    width: 100vw;
    height: 100vh;
    background: rgba(#121212, 0.6);

    .ant-picker-panel-container {
      margin: auto;
      box-shadow: 0 0 15px rgb(0 0 0 / 18%);
      border-radius: 16px;
      overflow: hidden;
      width: calc(100% - 48px);
    }
  }

  .ant-picker-year-panel,
  .ant-picker-month-panel,
  .ant-picker-decade-panel {
    .ant-picker-cell-inner {
      width: unset !important;
      height: unset !important;
      line-height: 25px !important;
    }
    .ant-picker-header-view {
      text-align: center;
    }
    .ant-picker-header-super-next-btn,
    .ant-picker-header-super-prev-btn {
      display: flex;
    }
  }

  .ant-picker-decade-panel {
    @include mobile {
      width: 100%;
    }
  }

  .ant-picker-cell-selected {
    .ant-picker-cell-inner {
      color: #ffffff;
      background: #121212;
      border-radius: 1rem;
    }
  }

  .ant-picker-panel-container {
    margin: auto;
    box-shadow: 0 0 15px rgb(0 0 0 / 18%);
    border-radius: 16px;
    overflow: hidden;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: none;
  }

  .ant-picker-year-panel,
  .ant-picker-month-panel {
    @include mobile {
      width: 100% !important;
    }
  }

  .ant-picker-date-panel {
    width: 358px;

    @include mobile {
      width: 100%;
    }

    .ant-picker-cell-inner {
      font-size: 16px;
      line-height: 32px;
      width: 32px;
      height: 32px;
      border-radius: 100%;
      // visibility: hidden;
    }

    .ant-picker-cell {
      pointer-events: none;
      margin: 8px;
    }

    .ant-picker-cell-in-view {
      pointer-events: all;

      .ant-picker-cell-inner {
        color: #121212;
        // visibility: visible;
      }
    }

    .ant-picker-cell-selected {
      .ant-picker-cell-inner {
        background: #121212;
        color: white;
      }
    }

    .ant-picker-content {
      width: 100%;
    }
  }

  .ant-picker-content {
    th {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
    }
  }

  .ant-picker-header {
    padding: 10px 16px;
    display: flex;
    align-items: center;
  }

  .ant-picker-header-super-next-btn,
  .ant-picker-header-super-prev-btn {
    display: none;
  }

  .ant-picker-header-prev-btn {
    display: flex;
    align-items: center;
    position: absolute;
    right: 56px;

    @include mobile {
      right: 80px;
    }
  }

  .ant-picker-header-next-btn {
    display: flex;
    align-items: center;
    position: absolute;
    right: 16px;

    @include mobile {
      right: 40px;
    }
  }

  .ant-picker-header-view {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #000000;
    text-align: left;
  }
}
