.file-card {
  border: 1px solid $gray5;
  padding: 16px;
  border-radius: 16px;
  display: flex;

  &__content {
    flex: 1;
    margin-left: 16px;
  }
}
