/* stylelint-disable */
@use "sass:math";

$default: #121212;
$dark: #121131;
$bg-dark: #121212;
$primary: #60e5e8;
$blue: #257ffc;
$white: #ffffff;
$black: #000000;
$black2: #121727;
$black3: #181818;
$black4: #1a1a1a;
$gray: #e6e7e9;
$gray2: #80858a;
$gray3: #fafafa;
$grey: #f4f3f3;
$grey2: #e6e6e6;
$grey3: #e0e0e0;
$grey4: #636363;
$gray5: #f5f5f5;
$gray6: #d3d3d3;
$gray7: #f4f3f3;
$red: #ff3c45;
$gray8: #414141;
$gray9: #f3f3f3;
$border: #e9e9eb;
$inputBorder: #e4e4ed;
$checked: linear-gradient(360deg, #34aa44 2.22%, #38b249 100%);
$checkedBorder: #2d9c3c;
$yellow: #ffba00;
$green: #50c32c;
$green2: #00dc93;
$violet: #ec8efc;
$lavender: #cd5ce0;
$pink: #ff07c9;
$text: #020025;
$orange: #ff8540;
$orange2: #ff8440;
$line: #e9e9eb;
$blueDodger: #5e64ff;
$bunting: #111a3d;
$anakiwa: #8feaff;
$codGray: #141414;
$woodSmoke: #101011;
$seashell: #f1f1f1;
$mineShaft: #292929;
$primary-gradient: linear-gradient(270deg, #17e391 21.47%, #58e5df 77.04%, #60e5e8 77.05%);
$codGray2: #1e1e1e;
$whisper: #e5e5e5;
$tundola: #414141;
$warning: #ffa41c;
$success: #76ba18;
$alto: #e0e0e0;
$shark: #1e1f20;
$mercury: #e6e6e6;
$apricot: #f18969;
$silver: #c0c0c0;
$turquoise: #37e4b7;
$doveGray: #636363;

$boxShadow: rgba(60, 64, 67, 0.3) 0 1px 2px 0, rgba(60, 64, 67, 0.15) 0 1px 3px 1px;

$boxShadow2: 0 0 10px rgba(0, 0, 0, 0.25);
$boxShadow3: 0 0 15px rgba(0, 0, 0, 0.15);

$x-small: 12px;
$small: 14px;
$medium1: 16px;
$medium2: 18px;
$normal: 20px;
$normal1: 22px;
$normal2: 24px;
$normal3: 28px;
$large: 32px;
$larger: 36px;
$x-large: 40px;
$xx-large: 56px;
$xxl-large: 72px;

$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;

$header: 80px;
$sidebar: 200px;
$mobile-width: 480px;
$tablet-width: 768px;
$desktop-width: 961px;
$desktop-large: 1500px;
$container: 1194px;
$request-container: 1158px;
$landing-page-container: 1288px;
$leftSpace: calc(min(calc(max(calc(100vw - 1333px) / 2, 50px)), 150px));
$fontGotham: "Gotham Pro", sans-serif;
$fontSFProDisplay: "SFProDisplay", sans-serif;
$videoCallHeight: calc((50vw - 64px) * 720 / 1280);
$vw-viewport-px: 1240px;
$vw-viewport: 1240;

@function get-vw($font) {
  $vw-context: $vw-viewport * 0.01 * 1px;
  $vw-font: math.div($font, $vw-context) * 1vw;

  @return $vw-font;
}

@mixin mobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin sm {
  @media (min-width: #{$mobile-width}) and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$mobile-width}) and (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin smallHeight {
  @media (max-height: #{$small-height}) {
    @content;
  }
}

@mixin desktopLarge {
  @media (min-width: #{$desktop-large}) {
    @content;
  }
}
@mixin safari {
  @media not all and (min-resolution: 0.001dpcm) and (max-width: #{$mobile-width - 1px}) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}
