.carousel-wrapper {
  max-width: 100vw;

  .slick-slide {
    padding: 0 12px;
    box-sizing: border-box;
    pointer-events: unset !important;

    img {
      width: 100%;
    }
  }

  .slick-slider {
    margin: 0 -12px;

    .slick-arrow {
      width: auto !important;
      height: auto !important;
      top: 40%;

      .ant-btn {
        padding: 0;
        box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
        border-radius: 100px;
        border: none;
        width: 32px;
        height: 32px;
        background: white !important;
        display: none !important;
      }
    }

    &:hover {
      .slick-arrow .ant-btn {
        display: block !important;
      }
    }
  }

  .slick-prev {
    left: -4px;
    z-index: 999;
  }

  .slick-next {
    right: -4px;
    z-index: 999;
  }

  .slick-dots {
    &.slick-dots-top {
      bottom: unset !important;
    }
  }
}
