.video-modal {
  .ant-modal-content {
    background: transparent;
    box-shadow: none;

    .ant-modal-close {
      right: -40px;
      left: auto;
      top: 32px;

      svg path {
        stroke: $white;
      }
    }

    .ant-modal-body {
      padding-top: 46px;
    }
  }
}
