.avatar-profile {
  text-align: left;

  &__profession {
    font-size: 10px;
  }

  &__info {
    //height: 20px;
    //line-height: 20px;
    //display: block;
  }

  &__text {
    background-color: $primary !important;
    color: $default !important;
    font-weight: 500;
    span {
      color: $default !important;
    }

    // .ant-avatar-string {
    //   font-weight: 500;
    //   font-size: 12px;
    //   line-height: 16px;
    // }
  }
}
