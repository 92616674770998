$container-size: 1138px;
$sm-width: 481px;

@mixin xs {
  @media (max-width: #{$sm-width - 1px}) {
    @content;
  }
}

.trial-sign-up {
  background: $default;
  width: 100%;
  min-height: 100vh;
  padding: 40px;

  @include xs {
    padding: 48px 0 0 0;
  }

  &__container {
    max-width: $container-size;
    width: 100%;
    margin: auto;

    &--logo {
      padding-bottom: 40px;

      @include xs {
        padding-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__content {
    background-color: white;
    border-radius: 16px;
    overflow: hidden;
  }
}
