.special-requests {
  max-width: 754px;
  margin: auto;

  .experience-card__date {
    margin-top: 24px;
    margin-bottom: 16px;

    @include mobile {
      margin-top: 16px;
      margin-bottom: 12px;
    }
  }

  @include mobile {
    padding: 24px 16px;
  }
}
