@import '../../../styles/abstracts/_variables.scss';

.trial-modal {
  &--title {
    margin-bottom: 0.5em !important;
  }
  &--subtitle {
    font-size: 15px;
    margin-right: 24px;
  }

  .ant-modal-body {
    padding: 24px;
    padding-bottom: 30px;
  }

  &--form {
    padding-bottom: 36px;
  }
}

.blockquote {
  background-color: #F3F3F3;
  padding: 8px 16px;
  font-size: 14px;
  width: 100%;
  border-radius: 8px;
}

.button--text {
  padding-left: 0;
  padding-right: 0;

  &.disabled {
    color: $default;
    pointer-events: none;
    cursor: default !important;
  }
}

.trial-modal--checkbox {
  &.checkbox-inline > .ant-checkbox {
    top: 0.32em !important;
  }

  .ant-checkbox-inner {
    width: 20px;
    height: 20px;

    :checked {
      background-color: #76BA18 !important;
    }

    ::after {
      height: 11px;
      width: 6px;
      left: 22.5%;
      top: 45%;
      transform: rotate(50deg) scale(1) translate(-50%, -50%);
    }
  }
}

.input-email {
  height: 100%;
  border: none;
  border-radius: 123px;
  padding: 4px 8px;

  .ant-input-suffix {
    margin-left: 0;
  }

  input {
    background: transparent;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 0 40px;

    &:active,
    &:focus,
    &:hover {
      border: none;
    }

    &::placeholder {
      color: rgba($default, 0.5);
    }
  }

  .ant-btn-circle {
    width: 32px;
    height: 32px;
    background: $primary;
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;

    &.home-page {
      background-color: $default;

      svg path {
        stroke: $white;
      }
    }

    &:disabled {
      background-color: $alto !important;
    }
  }

  &__wrapper {
    height: 52px;
    border-radius: 123px;
    padding: 2px;
    background-color: $mercury;

    &.active {
      background: linear-gradient(to left, #17e391, #58e5df, #60e5e8);
    }
  }
}
