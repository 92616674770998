.gift-card-info {
  position: relative;

  img {
    width: 100%;
    border-radius: 16px;
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
  }

  &__title {
    font-size: 40px;
    font-weight: 500;
    line-height: 60px;

    @include mobile {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__description {
    font-size: 18px;
    color: rgba($default, 0.7) !important;

    span {
      font-weight: 500;
    }

    @include mobile {
      font-size: 12px;
      line-height: 20px;
    }
  }

  &__btn-send.ant-btn {
    border-color: $default;
    background-color: $default;
    color: $white;
    height: 48px;
    padding: 0 24px;

    @include mobile {
      display: none;
    }
  }
}

.gift-card {
  position: relative;

  img {
    width: 100%;
  }

  &__code {
    position: absolute;
    top: calc(50% - 55px);
    right: 16px;
    width: 134px;
    height: 40px;
    line-height: 40px !important;
    text-align: center;
    border-radius: 8px;
    background-color: $gray5;
    opacity: 0.8;
  }

  &__logo {
    width: 76px !important;
    height: auto !important;

    @include mobile {
      width: 53px !important;
    }
  }

  &__content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 24px 24px 32px 24px;

    @include mobile {
      padding: 16px;
    }

    &__price {
      font-size: 32px !important;
      font-weight: bold;
      line-height: 48px;

      @include mobile {
        font-size: 24px !important;
        line-height: 36px;
      }
    }

    &__message {
      font-size: 18px !important;

      @include mobile {
        font-size: 12px !important;
      }
    }
  }

  &--small {
    .gift-card__logo {
      width: 53px !important;
    }

    .gift-card__content {
      padding: 16px;

      &__price {
        font-size: 24px !important;
        line-height: 36px;
      }

      &__message {
        font-size: 12px !important;
      }
    }
  }
}
