.agora-player__box {
  position: relative;
}

.agora-player__placeholder {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
}

.agora-player__box.contain video {
  left: 0;
  object-fit: contain !important;
}

.agora-player__box.cover {
  video {
    object-fit: contain !important;
    width: auto !important;
  }

  div[id^="player_"] {
    display: flex;
    justify-content: center;
  }
}

.agora-player__decorations {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 2;
  display: flex;
  white-space: nowrap;
}

.agora-player__icon {
  display: inline-flex;
  margin: 3px;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  transition: 0.3s all ease-in-out;
}

.agora-player__icon img {
  max-width: 100%;
}

.agora-player__label {
  position: absolute;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: white;
  height: 24px;
  width: 100%;
  bottom: 0;
  z-index: 2;
  right: 0;
}
