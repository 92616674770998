$total: 220;

.m--auto {
  margin: auto !important;
}

@for $i from 0 through $total {
  // Margin
  .m__t--#{$i*2} {
    margin-top: #{$i * 2}px !important;
  }
  .m__b--#{$i*2} {
    margin-bottom: #{$i * 2}px !important;
  }
  .m__r--#{$i*2} {
    margin-right: #{$i * 2}px !important;
  }
  .m__l--#{$i*2} {
    margin-left: #{$i * 2}px !important;
  }
  .m__y--#{$i*2} {
    margin-top: #{$i * 2}px !important;
    margin-bottom: #{$i * 2}px !important;
  }
  .m__x--#{$i*2} {
    margin-left: #{$i * 2}px !important;
    margin-right: #{$i * 2}px !important;
  }
  .m--#{$i*2} {
    margin: #{$i * 2}px !important;
  }

  // Padding
  .p__t--#{$i*2} {
    padding-top: #{$i * 2}px !important;
  }
  .p__b--#{$i*2} {
    padding-bottom: #{$i * 2}px !important;
  }
  .p__r--#{$i*2} {
    padding-right: #{$i * 2}px !important;
  }
  .p__l--#{$i*2} {
    padding-left: #{$i * 2}px !important;
  }
  .p__y--#{$i*2} {
    padding-top: #{$i * 2}px !important;
    padding-bottom: #{$i * 2}px !important;
  }
  .p__x--#{$i*2} {
    padding-left: #{$i * 2}px !important;
    padding-right: #{$i * 2}px !important;
  }
  .p--#{$i*2} {
    padding: #{$i * 2}px !important;
  }

  // Top
  .top--#{$i*2} {
    top: #{$i * 2}px !important;
    bottom: initial !important;
  }

  // Bottom
  .bottom--#{$i*2} {
    bottom: #{$i * 2}px !important;
    top: initial !important;
  }

  // Right
  .right--#{$i*2} {
    right: #{$i * 2}px !important;
    left: initial !important;
  }

  // Left
  .left--#{$i*2} {
    left: #{$i * 2}px !important;
    right: initial !important;
  }

  .height--#{$i*2} {
    height: #{$i * 2}px !important;
  }
  .width--#{$i*2} {
    width: #{$i * 2}px !important;
  }
  .line-height--#{$i*2} {
    line-height: #{$i * 2}px !important;
  }
}

.m {
  &__x--auto {
    margin: 0 auto !important;
  }
}

.p {
  &--0 {
    padding: 0 !important;
  }
}
.h-screen {
  height: 100vh !important;
}
.full-height {
  height: 100% !important;
}

.full-vheight {
  height: 100vh !important;
}

.height-auto {
  height: auto !important;
}
.height-vertical {
  height: 100vh;
}

.full-width {
  width: 100% !important;
}

.full-vwidth {
  width: 100vw !important;
}
.pt-3 {
  padding-top: 3px;
}
.flex {
  &--unset {
    flex: unset;
  }
  &--none {
    flex: none !important;
  }
  &--0 {
    flex: 0 !important;
  }

  &--1 {
    flex: 1 !important;
  }

  &--2 {
    flex: 2 !important;
  }

  &--3 {
    flex: 3 !important;
  }

  &--4 {
    flex: 4 !important;
  }
}
.max {
  &-w-full {
    max-width: 100%;
  }
  &-h-full {
    max-height: 100%;
  }
}
.w {
  &-full {
    width: 100% !important;
  }
}
.h {
  &-full {
    height: 100%;
  }
}
.border {
  &-radius {
    &-8 {
      border-radius: 8px;
    }
    &-16 {
      border-radius: 16px;
    }
  }
}
.leading {
  &-20 {
    line-height: 20px !important;
  }
  &-22 {
    line-height: 22px !important;
  }
  &-24 {
    line-height: 24px !important;
  }
  &-28 {
    line-height: 28px !important;
  }
}
