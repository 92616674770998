.filter-categories {
  position: relative;
  margin-bottom: 32px;

  @include mobile {
    margin-top: 16px;
    margin-bottom: 0;
    padding-right: 16px;
  }

  .swiper-container {
    width: 100%;
  }

  &__end {
    height: 40px !important;
    width: 150px !important;
  }

  &__separator {
    border: none !important;
    padding: 0 !important;
    height: 40px !important;

    span {
      width: 1px !important;
      height: 24px !important;
      background-color: $gray6 !important;
    }
  }

  .ant-btn-round {
    height: 40px;
    padding: 0 24px;

    @include mobile {
      height: 36px;
      padding: 0 16px;
    }
  }

  .swiper-slide {
    width: auto;
  }

  &__navigation {
    position: absolute;
    right: 0;
    z-index: 99;
    height: 40px;

    @include mobile {
      height: 36px;
    }

    &__shadow {
      width: 123px;
      background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    }

    .ant-btn {
      background-color: white;
      width: 32px;
      height: 40px !important;
      padding: 0 !important;
      border: none;
      outline: none;
      border-radius: 0;

      &:disabled {
        display: none;
      }

      @include mobile {
        height: 36px !important;
      }

      &::after {
        display: none;
      }
    }
  }
}
