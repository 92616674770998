.profile-picture {
  &-has-data {
    .ant-upload.ant-upload-select-picture-card {
      background-color: $shark !important;
    }
  }

  .ant-upload.ant-upload-select-picture-card {
    display: block;
    width: 64px;
    height: 64px;
    margin: 0;
    background-color: $white;
    border-radius: 100%;
    position: relative;
    border: unset;
  }

  .ant-upload--has-data {
    .ant-upload {
      border: none;
    }
  }

  &__preview-wrapper {
    position: relative;
    width: 64px;
    height: 64px;
    overflow: hidden;

    &__btn-actions {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      background-color: rgba($black, 0.4);

      > svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
.profile-picture-edit {
  .ant-upload.ant-upload-select-picture-card {
    display: block;
    width: 80px !important;
    height: 80px !important;
    margin: 0;
    background-color: $white;
    border-radius: 100%;
    position: relative;
    border: unset;
  }

  .profile-picture__preview-wrapper {
    position: relative;
    width: 80px !important;
    height: 80px !important;
    overflow: hidden;
  }
}
