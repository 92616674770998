.main-layout {
  background: $white !important;

  &__header--fixed {
    position: fixed;
    left: 0;
    right: 0;
  }

  &__header.ant-layout-header {
    background-color: $white;
    height: 84px;
    box-shadow: 0 1px 0 $border;
    margin-bottom: 1px;
    z-index: 10;
    line-height: unset;
    padding: 0 32px;

    .mobile-header--left {
      @include mobile {
        padding-left: 16px;
      }
    }

    @include mobile {
      margin-bottom: 0;
    }
  }

  .dark-mode {
    @include mobile {
      box-shadow: none;

      path {
        stroke: $white;
      }
    }
  }

  &__content {
    background-color: $white;
  }

  .ant-layout-footer {
    padding: 0;
    background: $white;

    .footer-top-area {
      height: 60px;
      border-bottom: solid 1px rgba(5, 13, 38, 0.1);
    }

    &.footer-wrapper--show-less {
      .footer-top-area {
        border-bottom: none;
      }
    }
  }

  &--dark-mode {
    background: $default !important;

    .ant-layout {
      background: $default !important;
    }

    span,
    .ant-typography {
      color: $white !important;
    }

    .main-layout__header {
      background: $default;
    }

    .main-layout__content {
      background: $default;
    }

    .ant-layout-footer {
      background: $default;

      .footer__info {
        box-shadow: 0 -1px 0 rgba($white, 0.2);
      }
    }
  }

  .mobile-sider {
    overflow: auto;
    height: calc(100vh - 100px - 64px);
    position: fixed;
    left: 0;
    z-index: 999;
    background-color: $white;
    margin-top: 64px;
    //&-footer {
    //  position: fixed;
    //  bottom: 0;
    //  left: 16px;
    //  right: 16px;
    //  z-index: 999;
    //  border-top: 1px $gray5 solid;
    //}
    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
    }

    .ant-menu {
      padding: 0 0 !important;
    }

    .ant-menu-item {
      height: auto;
      min-height: 56px;
      display: flex;
      align-items: center;
      width: 100%;
      border-bottom: 1px solid rgba($border, 0.5);
      padding: 0 16px !important;

      &.p__x--0 {
        padding: 0 0 !important;
      }

      &-disabled {
        border-bottom: none;
      }

      svg {
        width: 24px;
        height: 24px;
        margin-right: 16px;
      }
    }

    .ant-collapse {
      border-radius: 0;
      border-width: 0;
      background-color: $white;

      &-item {
        border-radius: 0;
        border-width: 0;
      }

      &-header {
        border-bottom-width: 0;
        padding: 0 16px !important;
        font-size: $medium1;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 8px;
      }

      &-content {
        border-width: 0 !important;
        &-box {
          border-width: 0 !important;
          padding: 0 !important;
          .ant-menu {
            border-width: 0 !important;
            padding: 0 !important;
            .ant-menu-item {
              height: 52px;
              min-height: 52px;
              border-bottom-width: 0 !important;
              margin-top: 0;
              margin-bottom: 0;
              padding: 0 16px !important;

              &:hover {
                background-color: rgba($primary, 0.2);
              }
              img {
                filter: brightness(0);
              }
            }
          }
        }
      }

      &.category-menu-mobile {
        svg {
          transform: rotate(0deg);
        }

        &.active {
          svg {
            transform: rotate(90deg);
          }
        }
      }

      &-arrow {
        top: 0 !important;
      }
    }

    .btn-login {
      padding: 18px 0;
      border-top: 1px $gray5 solid;
      margin-top: 18px;
    }
  }

  &--livestream {
    .main-layout__header {
      background: $default;
      margin-bottom: 0;
      box-shadow: none;
      padding: 0 48px;

      .ant-select-show-search {
        display: none;
      }

      .category-menu {
        display: none;
      }

      .btn-talent {
        display: none;
      }

      .mobile-header--left {
        @include mobile {
          display: none !important;
        }
      }

      .ant-col {
        @include mobile {
          padding-left: 24px;
        }
      }

      .mobile-header--right {
        @include mobile {
          padding-right: 8px;
        }
      }
    }

    .main-layout__content {
      margin-left: 0;
      background: $default;

      .container {
        width: 100% !important;
        max-width: 100% !important;
      }
    }

    &--waiting {
      .main-layout__header {
        background: $white;
        margin-bottom: 1px;
      }

      .main-layout__content {
        background: $white;

        .live-stream-app {
          height: calc(100vh - 84px);
        }
      }
    }
  }
}
