.call-room {
  flex: 1;
  flex-direction: column;
  display: flex;
  &__chat {
    width: 336px;
    margin-left: 24px;
  }
  &--time {
    background: $white;
    border-radius: 4px;
    padding: 6px 10px;
  }
  .agora-player__placeholder {
    border-radius: 8px;
    background-color: transparent;
  }

  video::-webkit-media-controls-overlay-play-button {
    display: none !important;
  }

  .stream-player-wrapper {
    position: relative;
  }

  .btn-fullscreen {
    position: absolute;
    right: 16px;
    bottom: 64px;
    z-index: 999;
    padding: 0;
    border-radius: 100%;
    width: 32px !important;
    height: 32px !important;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 24px;
      height: 24px;

      path {
        fill: $default;
      }
    }
  }

  .agora-player__box,
  .stream-player {
    width: 100%;
    object-fit: cover;
    height: $videoCallHeight;
    margin: auto;

    > div {
      display: flex;
      justify-content: center;
    }

    video {
      object-fit: contain !important;
      border-radius: 8px;
      width: auto !important;
    }

    &.cover {
      video {
        border-radius: 8px;
      }
    }
  }

  &__room-info {
    span {
      display: block;
    }
  }

  .btn-setting {
    &-wrapper {
      width: 212px;
    }
  }

  button.btn-share-screen {
    padding: 16px;
    border: 1px solid $white !important;
    border-radius: 8px;
    width: 212px;

    &.ant-btn-red {
      border: 1px solid $red !important;
    }
  }

  &__action-buttons {
    flex-grow: 1;
    align-items: center;

    &__button {
      width: 70px !important;
      height: 70px !important;
      background-color: $white !important;
      display: flex !important;
      align-items: center;
      justify-content: center;
      border: 0;
      animation-duration: 0s !important;
      border-radius: 100% !important;

      &:hover {
        background: $white;
        opacity: 0.7;
      }

      &:active,
      &:focus {
        background: $white;
      }

      &--end-call {
        background-color: $red !important;
        border-color: $red !important;

        &:hover {
          background: $red;
          opacity: 0.7;
        }

        &:active,
        &:focus {
          background: $red;
        }
      }
    }
  }
}
