.talent-videos {
  padding: 32px;
  background-color: $default;
  margin-top: 40px;

  .ant-tabs {
    overflow: visible;

    &-tab {
      color: $white !important;
      margin-right: 60px;
    }

    &-nav {
      margin-bottom: 24px;

      &::before {
        border: none !important;
      }
    }
  }

  &__item {
    cursor: pointer;
    margin-bottom: 12px;
    padding: 8px;

    &:hover,
    &.active {
      background: rgba(196, 196, 196, 0.05);
      border-radius: 8px;
    }

    &__icon {
      background-color: rgba($white, 0.1);
      border-radius: 100%;
      width: 24px;
      height: 24px;

      svg {
        width: 18px;
        height: 18px;

        path {
          stroke: $white;
        }
      }
    }

    &__name {
      color: $white !important;
    }

    &__type {
      color: rgba($white, 0.5) !important;
      font-size: 12px;
    }
  }
}
