.ant-select {
  &-selector {
    height: 48px !important;
    border-radius: 8px !important;

    .ant-select-selection-placeholder {
      line-height: 48px !important;
    }
  }

  &-arrow {
    width: 48px;
    height: 48px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-item {
    font-weight: 500;
  }

  &-selection-item {
    line-height: 48px !important;
  }
}
