// TODO: Refactor out sections

.landing-page-v2 {
  .landing-page-section-hero {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__background {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
      display: flex;
      align-items: center;
    }

    &__content {
      display: grid;
      grid-template-rows: repeat(3, 1fr);

      &__heading {
        width: 85vw;

        > span {
          margin: 0;
          display: block;
          height: 0px;
          overflow: hidden;
        }
      }

      &__cta {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &--mobile {
      justify-content: flex-end;

      .landing-page-section-hero {
        &__content {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-end;
          gap: 20px;
          padding-bottom: 24vh;
        }
      }
    }
  }

  .landing-page-section-empower-creators {
    height: 100%;
    width: 85vw;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20vh 0 10vh 0;

    &__heading {
      width: 100%;

      > span {
        margin: 0;
        display: block;
        height: 0px;
        overflow: hidden;
      }
    }

    &__content {
      position: relative;
      display: grid;
      grid-template-columns: 432px 1fr;
      align-items: center;
      width: 100%;
      gap: 40px;
      padding-top: 48px;
      max-width: 920px;

      &__category-selector {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }

      &__category-selector--mobile {
        height: 200px;
      }

      &__category-selector,
      &__category-selector--mobile {
        &__category {
          color: white;
          font-size: 40px;
          font-weight: 500;
          letter-spacing: -0.025em;
          font-feature-settings: "ss16" on;
          background: none;
          border: none;
          cursor: pointer;
          user-select: none;
          transition: color 0.2s ease-in-out;
          padding: 0 4px;
          line-height: 1.3;

          &--selected {
            color: #ff05d7;
          }
        }
      }

      &__category-selector--mobile {
        &__category {
          display: flex;
          align-items: center;
          opacity: 0.2;
          transform-origin: 0% 50%;
          transform: scale(0.4);

          &.swiper-slide-active {
            opacity: 1;
            transform: scale(1);
            color: #ff05d7;
          }
          &.swiper-slide-prev,
          &.swiper-slide-next {
            opacity: 0.6;
          }
          &.swiper-slide-prev {
            transform: scale(0.65) translateY(-30%);
          }
          &.swiper-slide-next {
            transform: scale(0.65) translateY(30%);
          }

          transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out, color 0.2s ease-in-out;
        }
      }

      &__category-text {
        color: #efefef;
        font-weight: 400;
        font-size: 20px;
        line-height: 100%;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding-top: 8px;
        padding-left: 4px;
      }

      &__graphic {
        max-width: 400px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        position: relative;

        &__phone-container {
          border: 2px solid #ff05d7;
          border-radius: 20px;
          padding: 4px;
          transform: matrix(0.96, 0.1, -0.3, 0.99, 0, 0) translateZ(0);
          box-shadow: 16px 16px 32px rgba(0, 0, 0, 0.2);
          overflow: hidden;
        }

        &__phone {
          width: calc(5vh + 2vw + 80px);
          max-width: 320px;
          aspect-ratio: 1/2;
          border-radius: 16px;
          position: relative;
          overflow: hidden;

          &__screenshot {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            opacity: 0;
            top: -60px;
            transition: opacity 0.2s ease-in-out, top 0.75s cubic-bezier(0.3, 0.8, 0.45, 1);

            &--visible {
              opacity: 1;
              top: 0;
            }
          }
        }
      }
    }

    @media (max-width: 768px) {
      padding: 5vh 0;

      &__content {
        grid-template-columns: 5fr 6fr;

        &__graphic {
          &__phone-container {
            transform: none;
          }

          &__phone {
            width: 32vw;
            aspect-ratio: 1/2;
            border-radius: 12px;
          }
        }
      }
    }
  }

  .landing-page-section-features {
    position: relative;
    padding: 10vh 0;

    .swiper-container {
      height: 100vh;
      max-height: 720px;
      width: 100%;
      z-index: 0;
    }

    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    }

    &__theme,
    &__everything,
    &__data-analytics {
      height: 100%;
      width: 85vw;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 3fr 2fr;
      align-items: center;
      gap: 40px;

      &__copy {
        display: flex;
        flex-direction: column;
        gap: 20px;

        &__heading {
          > span {
            margin: 0;
            display: block;
            height: 0px;
            overflow: hidden;
          }
        }

        &__description {
          color: white;
          font-size: 20px;
          line-height: 1.5;
        }
      }

      &__graphic {
        height: 80%;
        display: flex;
        align-items: center;
        position: relative;

        .atropos-inner {
          margin: 0 auto;

          &:nth-child(1) {
            max-width: 60vh;
          }
        }

        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
      }
    }

    &__everything,
    &__data-analytics {
      &__graphic {
        max-height: 65%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &__everything {
      grid-template-columns: 2fr 3fr;

      &__copy {
        &__socials {
          height: 72px;
          max-height: 88px;
          margin-right: auto;
        }
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      &::after {
        color: white;
        font-weight: 1000;
        transform: scale(0.5);
        text-stroke: 4px white;
        -webkit-text-stroke: 4px white;
      }
    }

    &__mobile {
      &__subsection {
        padding: 80px 0;

        .landing-page-section-features__theme,
        .landing-page-section-features__everything,
        .landing-page-section-features__data-analytics {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 20px;

          &__graphic {
            width: 100%;
          }

          &__copy {
            gap: 0px;

            &__heading,
            &__description {
              text-align: center;
              font-size: 16px;
              padding: 8px 0;
            }
          }
        }

        .landing-page-section-features {
          &__theme,
          &__data-analytics {
            flex-direction: column-reverse;
            gap: 0px;
          }

          &__everything {
            &__copy {
              padding-bottom: 20px;

              &__socials {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .landing-page-section-categories {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15vh 0;

    &__heading {
      width: 85vw;
      padding-top: 40px;

      > span {
        margin: 0;
        display: block;
        height: 0px;
        overflow: hidden;
      }
    }

    &__content {
      padding-top: 16px;
    }

    &--mobile {
      padding: 5vh 0 10vh 0;
    }
  }

  .landing-page-section-faq {
    position: relative;
    padding: 10vh 0;

    &__content {
      height: 100%;
      width: 85vw;
      margin: 0 auto;
      padding: 80px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 40px;

      &__faqs {
        display: flex;
        flex-direction: column;
        gap: 28px;
        max-width: 600px;

        .expandable-section__title {
          transition: color 0.1s ease-in-out;
          &:hover {
            color: #ff61ff;
          }
        }

        .expandable-section__content {
          padding: 0 40px;
          line-height: 1.5;

          > p {
            margin-bottom: 1em;
          }
        }
      }

      &__link > a {
        font-weight: 500;
        font-size: 24px;
        font-feature-settings: "ss16" on;
        color: white;
        transition: color 0.1s ease-in-out;
        text-transform: uppercase;
        &:hover {
          color: #ff61ff;
        }
      }

      &__heading {
        padding-top: 40px;
        height: min-content;
        text-align: center;

        > span {
          margin: 0;
          display: block;
          height: 0px;
          overflow: hidden;
        }

        svg {
          height: 48px;
        }
      }
    }

    &--mobile {
      padding: 5vh 0;

      .landing-page-section-faq {
        &__content {
          padding: 40px 0;
          gap: 20px;

          &__faqs {
            max-width: 100%;
          }

          .expandable-section {
            &__title {
              font-size: 16px;
              padding: 0 20px;
              text-align: left;
              line-height: 1;
            }

            &__content {
              padding: 0 20px;
            }
          }

          &__link {
            padding-top: 8px;
          }
        }
      }
    }
  }

  .landing-page-section-contact {
    position: relative;
    padding: 80px 0;

    &__content {
      height: 100%;
      width: 85vw;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &__cta {
        position: relative;
        width: 100%;
        background: rgba(0, 0, 0, 0.12);
        border: 2px solid #efefef;
        backdrop-filter: blur(15px);
        border-radius: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        padding: 100px 12px 80px 12px;

        &__heading {
          position: relative;
          width: 72%;
          max-width: 842px;

          > span {
            margin: 0;
            display: block;
            height: 0px;
            overflow: hidden;
          }

          svg {
            width: 100%;
          }
        }

        &__description {
          color: white;
          font-size: 20px;
          line-height: 42px;
          font-weight: 400;
          text-align: center;
          max-width: 80%;
        }

        &__background {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: -1;

          &::before,
          &::after {
            content: " ";
            position: absolute;
            border-radius: 100px;
            filter: blur(140px);
            transition: top 0.4s ease-in-out, left 0.4s ease-in-out;
          }

          &::before {
            left: -15%;
            top: 55%;
            background: #2e47ff;
            width: 203px;
            height: 199px;
          }
          &::after {
            left: 55%;
            top: 15%;
            background: #872fa3;
            width: 281px;
            height: 281px;
          }
        }

        &:hover {
          .landing-page-section-contact__content__cta__background {
            &::before {
              left: 0%;
              top: 50%;
            }
            &::after {
              left: 45%;
              top: 0%;
            }
          }
        }
      }
    }

    &--mobile {
      .landing-page-section-contact__content {
        width: 90vw;

        &__cta {
          padding: 40px 8px;
          gap: 20px;

          &__heading {
            width: 80%;
          }

          &__description {
            font-size: 16px;
            line-height: 150%;
            max-width: 200px;
          }
        }
      }
    }
  }
}
