.image-skeleton {
  overflow: hidden;
  position: relative;

  &__circle {
    border-radius: 100% !important;
    overflow: hidden !important;
  }

  &__image {
    width: 100%;
    height: 100%;
    opacity: 0;
    object-fit: cover;
    border-radius: inherit;
    display: inline-block;

    &.thumb {
      position: absolute;
      opacity: 1;
      filter: blur(7px);
      transition: opacity 300ms ease-in-out;
      &.isLoaded {
        opacity: 0;
      }
    }

    &.original {
      position: absolute;
      transition: opacity 300ms ease-in-out;
    }

    &.isLoaded {
      opacity: 1;
    }
  }
}
