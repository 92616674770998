.landing-page-layout {
  min-height: 100vh !important;
  width: 100%;
  overflow-x: hidden;
  background: $default;

  * {
    color: $white !important;
  }

  &.mobile-menu-visible {
    overflow: hidden;
    height: 100vh;
  }

  &__menu {
    top: 74px !important;
    position: fixed;

    @include mobile {
      position: absolute;
    }
  }

  .btn-sign-up {
    height: 40px;
  }

  &__header {
    display: flex;
    padding: 28px 0;

    .avatar-profile__text {
      font-size: 16px !important;
      font-weight: 500;
    }

    .btn-menu {
      svg path {
        stroke: #000000;
      }
    }

    @include mobile {
      padding: 16px 0;
    }

    &__wrapper {
      position: fixed;
      z-index: 999;
      width: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
      transition: background-color 500ms linear;

      &.scrolling {
        background: rgba(18, 18, 18, 0.8);
      }
    }

    &__container {
      padding: 0 88px;

      @include mobile {
        padding: 0 32px;
      }

      @include mobile {
        padding: 0 16px;
      }
    }
  }

  .landing__page-container {
    max-width: 1312px;
    padding: 0 24px;
    margin: 0 auto !important;

    &.padding {
      @include tablet {
        padding: 0 32px;
      }

      @include sm {
        padding: 0 32px;
      }

      @include mobile {
        padding: 0 24px;
      }
    }

    @include tablet {
      padding: 0;
    }

    @include sm {
      padding: 0;
    }

    @include mobile {
      padding: 0;
    }
  }

  &__footer {
    background-color: $black;

    &__content {
      padding: 32px 0;

      @include tablet {
        padding: 32px;
      }

      @include sm {
        padding: 32px;
      }

      @include mobile {
        padding: 32px 24px;
      }

      &__address {
        @include tablet {
          margin-top: 32px;
          padding-top: 32px;
          border-top: 1px solid rgba($color: $white, $alpha: 0.2);
        }

        @include sm {
          margin-top: 32px;
          padding-top: 32px;
          border-top: 1px solid rgba($color: $white, $alpha: 0.2);
        }

        @include mobile {
          padding-top: 24px;
          border-top: 1px solid rgba($color: $white, $alpha: 0.2);
        }
      }

      &--logo {
        @include tablet {
          margin-bottom: 32px;
          .item {
            width: 50%;
          }
        }

        @include sm {
          margin-bottom: 32px;
          .item {
            width: 50%;
          }
        }

        @include mobile {
          margin-bottom: 29px;
          .item {
            width: 50%;
          }
        }
      }
    }

    &__info {
      padding: 24px 0;

      @include tablet {
        padding: 16px 24px;
        border-bottom: 1px solid rgba($color: $white, $alpha: 0.2);
      }

      @include sm {
        padding: 16px 24px;
        border-bottom: 1px solid rgba($color: $white, $alpha: 0.2);
      }

      @include mobile {
        padding: 16px 24px;
        border-bottom: 1px solid rgba($color: $white, $alpha: 0.2);
      }
    }

    .p__r--19 {
      padding-right: 19px;
    }
  }

  .btn-join-waitlist,
  .btn-sign-up {
    padding: 4px 16px;
    height: 36px;
    border: none;

    &.small {
      span {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
      }
    }

    span {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
    }

    &.big {
      padding: 16px;
      width: 253px;
      height: 52px;

      span {
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
      }

      @include mobile {
        padding: 6px 20px;
        height: 40px;

        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    &:hover,
    &:focus {
      background-color: $white;

      span {
        color: $black !important;
      }
    }
  }
}

.overlay-light {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  background: rgba(175, 175, 175, 0.8);
  backdrop-filter: blur(16px);
}
