.sign-in {
  &__card {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 540px;
    border-radius: 16px !important;

    .ant-card-body {
      padding: 34px 40px 40px 40px !important;
    }

    @include mobile {
      width: 100%;
      min-width: 100%;

      .ant-card-body {
        padding: 20px !important;
      }
    }
  }

  &__wrapper {
    .ant-form-item {
      &-control-input {
        input {
          font-size: $medium1 !important;
          font-weight: $semi-bold !important;
          line-height: 24px !important;
        }
      }

      &-has-success {
        .ant-form-item-control-input-content > input {
          background-color: $gray7 !important;
        }
      }
    }
  }
}
