@import "../../styles/abstracts/variables";

.profile-selection {
  width: 100%;

  @include md {
    max-width: 100%;
    min-width: auto;
  }

  @include mobile {
    max-width: 100%;
    min-width: auto;
  }

  &__item {
    width: 50%;
    margin-left: calc(50% - 1rem);

    @include md {
      margin-left: 2rem;
      width: calc(100vw - 4rem);
    }

    @include mobile {
      margin-left: 2rem;
      width: calc(100vw - 4rem);
    }

    padding: 0;
    border-radius: 16px;
    box-shadow: $boxShadow3;
    .ant-dropdown-menu-item {
      padding: 12px 16px;
      border-bottom: 1px solid $grey3;
      &:last-child {
        border-bottom: none;
      }
    }

    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover {
      &:first-child {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      }
      &:last-child {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
      }

      background-color: rgba(37, 127, 252, 0.05);
    }
  }

  &__label {
    width: calc(100% - 24px);
  }
}
