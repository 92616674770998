.waitlist-share-modal {
  @include mobile {
    width: 100vw;
    max-width: 100vw;
    .ant-modal-content {
      bottom: 0;
      position: fixed;
      width: 100vw;
      height: calc(100vh - 40px);
      border-radius: 16px 16px 0 0 !important;
    }
    .ant-modal-body {
      padding: 1rem;
    }
  }

  &__copy--btn {
    justify-content: space-between;
    border: 1px solid $whisper;
    border-radius: 8px;

    .copy-text {
      font-size: 14px;
      line-height: 22px;
      color: $blue;
    }

    .ant-typography {
      width: calc(100% - 65px);
      color: $default;
      opacity: 0.6;
    }

    .sign-up-link {
      text-decoration: underline;
      color: $default;

      &:hover {
        color: $default;
        text-decoration: underline;
      }
    }
  }

  .close-icon {
    cursor: pointer;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;

    @include mobile {
      top: 1rem;
      right: 1rem;
    }
  }

  .ant-alert-success {
    width: 448px;
    background-color: $gray7;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    border: none;
    .ant-alert-icon {
      color: #76ba18;
      align-self: baseline;
      svg {
        width: 20px;
        height: 20px;
      }
    }

    .ant-alert-message {
      font-size: 14px;
      line-height: 22px;
      color: $default;
    }

    @include mobile {
      width: 311px;
    }
  }

  .ant-modal-footer {
    background-color: $gray7;
    border-top: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;

    @include mobile {
      height: 52px;
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }

  .confirm-btn {
    width: 100%;
    background-color: $white;
    border: 2px solid $whisper;
    box-sizing: border-box;
    border-radius: 123px;

    span {
      color: $default;
      font-weight: bold;
      font-size: 1rem;
      line-height: 1.5rem;

      @include mobile {
        font-size: 0.875rem;
        line-height: 1.25rem;
      }
    }
    &:hover,
    &:focus,
    &:active {
      border: 2px solid $whisper;
    }

    @include mobile {
      height: 44px;
    }
  }
  .social-button-group {
    justify-content: space-between;
    margin-top: 24px;
    .social-btn {
      background-color: $default;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 105px;
      &:hover,
      &:focus,
      &:active {
        border-color: transparent;
      }

      @include mobile {
        width: 73.75px;
      }
    }
  }
}
