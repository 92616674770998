.ant-modal {
  .btn-back {
    position: absolute;
    top: 0;
    left: 16px;
    height: 63px !important;
  }
  &-title {
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 28px !important;

    @include mobile {
      font-size: 16px !important;
      line-height: 24px !important;
    }
  }

  &-content {
    border-radius: 16px !important;
    overflow: hidden;

    .ant-modal-close {
      // top: 34px;
      // right: 24px;
      top: 20px;
      right: 20px;

      @include mobile {
        top: 26px;
        right: 16px;
      }
      &-x {
        width: 28px;
        height: 28px;
        line-height: 28px;

        svg {
          height: 28px;
        }
      }
    }
  }

  &-header {
    min-height: 68px;
    padding: 20px !important;
    border-radius: 16px 16px 0 0 !important;
    background-color: rgba($primary, 0.02);
    border-bottom: 1px solid $alto !important;
  }

  .gender {
    .ant-form-item-control {
      margin-top: 12px;
    }
  }

  &-mask {
    background-color: rgba(0, 0, 0, 0.8);
    // background: rgba(175, 175, 175, 0.8);
    // backdrop-filter: blur(16px);
  }
}
