$container-size: 1138px;
$lg-width: 1024px;
$sm-width: 481px;

@mixin lg {
  @media (min-width: #{$lg-width}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$sm-width}) and (max-width: #{$lg-width - 1px}) {
    @content;
  }
}

@mixin xs {
  @media (max-width: #{$sm-width - 1px}) {
    @content;
  }
}

.finish-setup {
  background: $default;
  width: 100%;
  min-height: 100vh;
  padding: 40px;

  @include xs {
    padding: 48px 0 0 0;
  }
  &__container {
    max-width: $container-size;
    width: 100%;
    margin: auto;
    &--logo {
      padding-bottom: 40px;

      @include xs {
        padding-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .input-loading {
      .loading {
        transform: translate(-50%, -40%);
      }
    }
  }

  &__content {
    background: $gray7;
    // min-height: 820px;
    border-radius: 16px;

    @include xs {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    &--left {
      flex: 1;
      padding: 32px;

      @include xs {
        padding: 0;
      }
    }
    &--right {
      flex: 1;
      display: none;
      background: url("../../../public/static/assets/images/bg-finish-setup.png");
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
      background-repeat: no-repeat;
      background-size: cover;

      @include lg() {
        display: block;
      }
    }
    &--header {
      @include xs {
        padding: 16px;
      }
    }
  }
  &__wrapper {
    background: $white;
    padding: 20px;
    border-radius: 16px;
    margin-top: 24px;

    @include xs {
      margin-top: 0;
      padding: 16px 16px 32px 16px;
      border-radius: 0;
    }
  }

  .category-item {
    border-radius: 123px;
    height: 36px;
    border: 1px solid $mercury;
    padding: 0 12px;
    cursor: pointer;
    &.active {
      background: linear-gradient(#ffffff, #ffffff) padding-box,
        linear-gradient(to left, #17e391, #58e5df, #60e5e8) border-box;
      border: 1px solid transparent;
    }
  }

  .ant-form-item-has-error {
    :not(.ant-input-disabled).ant-input,
    :not(.ant-input-disabled).ant-input-affix-wrapper {
      border: 1px solid $red !important;
      border-right-width: 1px !important;

      &:focus,
      &:hover {
        border: 1px solid $red !important;
      }
    }
    .ant-input-affix-wrapper {
      :not(.ant-input-disabled).ant-input {
        border: unset !important;
        border-right-width: 1px !important;

        &:focus,
        &:hover {
          border: unset !important;
        }
      }
    }
  }

  .ant-form {
    input {
      border-width: 1px !important;
      height: 42px;
    }

    .talent-name-input {
      height: 52px;
    }

    .ant-input-affix-wrapper {
      border-radius: 8px;
    }
    &-item-explain {
      margin-top: 4px;
      &-error {
        margin-top: 0 !important;
      }
    }
  }
  &-modal {
    @include mobile {
      left: 0;
      margin: 0 !important;
      max-width: 100vw !important;
      padding: 0 !important;
      bottom: 0;
      top: auto !important;
      position: absolute !important;

      .ant-modal-content {
        border-radius: 16px 16px 0 0 !important;
      }
    }
    .ant-modal {
      &-close {
        top: 20px;

        @include mobile {
          top: 16px;
        }
      }
      &-header {
        @include mobile {
          padding: 16px !important;
          min-height: 64px;
          .ant-modal-title {
            font-size: 20px !important;
            line-height: 32px !important;
          }
        }
      }
      &-body {
        @include xs {
          height: calc(100vh - 130px);
        }
      }
    }
    .ant-form-item-has-error :not(.ant-input-disabled).ant-input,
    .ant-form-item-has-error :not(.ant-input-disabled).ant-input-affix-wrapper {
      border-width: 1px !important;
    }
    .ant-form {
      input {
        border-width: 1px !important;
      }
      &-item-explain {
        margin-top: 4px;
        &-error {
          margin-top: 0 !important;
        }
      }
    }
  }

  .category-list {
    min-height: 300px;
  }

  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $black;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    opacity: 0.7;
  }
}
