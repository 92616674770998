// Text Color
.text {
  font-weight: $regular;
  font-size: $small;
  line-height: 20px;
  color: $text;
  &--light12.ant-typography {
    font-weight: $light !important;
    font-size: $x-small !important;
    line-height: 20px;
  }
  &--light14.ant-typography {
    font-weight: $light !important;
    font-size: $small !important;
    line-height: 24px;
  }
  &--light16.ant-typography {
    font-weight: $light !important;
    font-size: $medium1 !important;
    line-height: 24px;
  }
  &--light18.ant-typography {
    font-weight: $light !important;
    font-size: $medium2 !important;
    line-height: 28px;
  }
  &--light20.ant-typography {
    font-weight: $light !important;
    font-size: 20px !important;
    line-height: 32px;
  }
  &--light22.ant-typography {
    font-weight: $light !important;
    font-size: 22px !important;
    line-height: 32px;
  }
  &--light24.ant-typography {
    font-weight: $light !important;
    font-size: 24px !important;
    line-height: 32px;
  }
  &--regular12.ant-typography {
    font-weight: $regular !important;
    font-size: $x-small !important;
    line-height: 16px;
  }
  &--regular14.ant-typography {
    font-weight: $regular !important;
    font-size: $small !important;
    line-height: 20px;
  }
  &--regular16.ant-typography {
    font-weight: $regular !important;
    font-size: $medium1 !important;
    line-height: 24px;
  }
  &--regular18.ant-typography {
    font-weight: $regular !important;
    font-size: $medium2 !important;
    line-height: 24px;
  }
  &--regular20.ant-typography {
    font-weight: $regular !important;
    font-size: 20px !important;
    line-height: 32px;
  }
  &--regular22.ant-typography {
    font-weight: $regular !important;
    font-size: 22px !important;
    line-height: 32px;
  }
  &--regular24.ant-typography {
    font-weight: $regular !important;
    font-size: 24px !important;
    line-height: 32px;
  }
  &--regular50.ant-typography {
    font-weight: $regular !important;
    font-size: 50px !important;
    line-height: 60px;
  }
  &--regular28.ant-typography {
    font-weight: $regular !important;
    font-size: $normal !important;
    line-height: 36px;
  }
  &--medium12.ant-typography {
    font-weight: $medium !important;
    font-size: $x-small !important;
    line-height: 20px;
  }
  &--medium14.ant-typography {
    font-weight: $medium !important;
    font-size: $small !important;
    line-height: 20px;
  }
  &--medium16.ant-typography {
    font-weight: $medium !important;
    font-size: $medium1 !important;
    line-height: 24px;
  }
  &--medium18.ant-typography {
    font-weight: $medium !important;
    font-size: $medium2 !important;
    line-height: 24px;
  }
  &--medium20.ant-typography {
    font-weight: $medium !important;
    font-size: 20px !important;
    line-height: 32px;
  }
  &--medium22.ant-typography {
    font-weight: $medium !important;
    font-size: 22px !important;
    line-height: 32px;
  }
  &--medium24.ant-typography {
    font-weight: $medium !important;
    font-size: 24px !important;
    line-height: 32px;
  }
  &--medium28.ant-typography {
    font-weight: $medium !important;
    font-size: 28px !important;
    line-height: 36px;
  }
  &--medium30.ant-typography {
    font-weight: $medium !important;
    font-size: 30px !important;
    line-height: 36px;
  }
  &--medium32.ant-typography {
    font-weight: $medium !important;
    font-size: 32px !important;
    line-height: 42px;
  }
  &--semibold10.ant-typography {
    font-weight: $semi-bold !important;
    font-size: 10px !important;
    line-height: 14px;
  }
  &--semibold12.ant-typography {
    font-weight: $semi-bold !important;
    font-size: $x-small !important;
    line-height: 16px;
  }
  &--semibold14.ant-typography {
    font-weight: $semi-bold !important;
    font-size: $small !important;
    line-height: 22px;
  }
  &--semibold16.ant-typography {
    font-weight: $semi-bold !important;
    font-size: $medium1 !important;
    line-height: 24px;
  }
  &--semibold18.ant-typography {
    font-weight: $semi-bold !important;
    font-size: $medium2 !important;
    line-height: 28px;
  }
  &--semibold20.ant-typography {
    font-weight: $semi-bold !important;
    font-size: $normal !important;
    line-height: 28px;
  }
  &--semibold24.ant-typography {
    font-weight: $semi-bold !important;
    font-size: 24px !important;
    line-height: 32px;
  }
  &--semibold28.ant-typography {
    font-weight: $semi-bold !important;
    font-size: $normal3 !important;
    line-height: 36px;
  }
  &--semibold32.ant-typography {
    font-weight: $semi-bold !important;
    font-size: 32px !important;
    line-height: 42px;
  }
  &--semibold40.ant-typography {
    font-weight: $semi-bold !important;
    font-size: $x-large !important;
    line-height: 52px;
  }
  &--bold12.ant-typography {
    font-weight: $bold !important;
    font-size: 12px !important;
    line-height: 20px;
  }
  &--bold14.ant-typography {
    font-weight: $bold !important;
    font-size: 14px !important;
    line-height: 20px;
  }
  &--bold16.ant-typography {
    font-weight: $bold !important;
    font-size: $medium1 !important;
    line-height: 24px;
  }
  &--bold18.ant-typography {
    font-weight: $bold !important;
    font-size: $medium2 !important;
    line-height: 24px;
  }
  &--bold20.ant-typography {
    font-weight: $bold !important;
    font-size: 20px !important;
    line-height: 32px;
  }
  &--bold22.ant-typography {
    font-weight: $bold !important;
    font-size: 22px !important;
    line-height: 32px;
  }
  &--bold24.ant-typography {
    font-weight: $bold !important;
    font-size: $normal2 !important;
    line-height: 32px;
  }
  &--bold28.ant-typography {
    font-weight: $bold !important;
    font-size: 28px !important;
    line-height: 36px;
  }
  &--bold30.ant-typography {
    font-weight: $bold !important;
    font-size: 30px !important;
    line-height: 36px;
  }
  &--bold32.ant-typography {
    font-weight: $bold !important;
    font-size: $large !important;
    line-height: 42px;
  }
  &--bold36.ant-typography {
    font-weight: $bold !important;
    font-size: $larger !important;
    line-height: 50px;
  }
  &--bold40.ant-typography {
    font-weight: $bold !important;
    font-size: 40px !important;
    line-height: 52px;
  }
  &--bold48.ant-typography {
    font-weight: $bold !important;
    font-size: 48px !important;
    line-height: 66px;
  }
  &--bold56.ant-typography {
    font-weight: $bold !important;
    font-size: 56px !important;
    line-height: 72px;
  }
  &--bold72.ant-typography {
    font-weight: $bold !important;
    font-size: $xxl-large !important;
    line-height: 108px;
  }

  &.title {
    margin-bottom: 0 !important;
    text-transform: uppercase;
  }

  &--primary {
    color: $primary !important;

    &.ant-typography {
      color: $primary !important;
    }

    path {
      stroke: $primary !important;
    }
  }

  &--white {
    color: $white !important;

    &.ant-typography {
      color: $white !important;
    }

    path {
      stroke: $white !important;
    }
  }

  &--black {
    color: $black !important;

    &.ant-typography {
      color: $black !important;
    }

    path {
      stroke: $black !important;
    }
  }

  &--blue {
    color: $blue !important;

    &.ant-typography {
      color: $blue !important;
    }

    path {
      stroke: $blue !important;
    }
  }

  &--yellow {
    color: $yellow !important;

    &.ant-typography {
      color: $yellow !important;
    }

    path {
      stroke: $yellow !important;
    }
  }

  &--default {
    color: $default !important;

    &.ant-typography {
      color: $default !important;
    }

    path {
      stroke: $default !important;
    }
  }

  &--green {
    color: $green !important;

    &.ant-typography {
      color: $green !important;
    }

    path {
      stroke: $green !important;
    }
  }

  &--red {
    color: $red !important;

    &.ant-typography {
      color: $red !important;
    }

    path {
      stroke: $red !important;
    }
  }

  &--default50 {
    color: rgba($default, 0.5) !important;

    path {
      stroke: rgba($default, 0.5) !important;
    }
  }

  &--default70 {
    color: rgba($default, 0.7) !important;

    path {
      stroke: rgba($default, 0.7) !important;
    }
  }

  &--default80 {
    color: rgba($default, 0.8) !important;

    &.ant-typography {
      color: rgba($default, 0.8) !important;
    }

    path {
      stroke: rgba($default, 0.8) !important;
    }
  }

  &--white80 {
    color: rgba($white, 0.8) !important;

    &.ant-typography {
      color: rgba($white, 0.8) !important;
    }

    path {
      stroke: rgba($white, 0.8) !important;
    }
  }
  &--gray8 {
    color: $gray8 !important;

    &.ant-typography {
      color: $gray8 !important;
    }

    path {
      stroke: $gray8 !important;
    }
  }
  &--warning {
    color: $warning !important;

    &.ant-typography {
      color: $warning !important;
    }

    path {
      stroke: $warning !important;
    }
  }
  &--success {
    color: $success !important;

    &.ant-typography {
      color: $success !important;
    }

    path {
      stroke: $success !important;
    }
  }
}

.font__size {
  &--xsmall {
    font-size: $x-small !important;
  }

  &--small {
    font-size: $small !important;
  }

  &--medium {
    font-size: $medium1 !important;
  }

  &--medium1 {
    font-size: $medium1 !important;
  }

  &--medium2 {
    font-size: $medium2 !important;
  }

  &--normal {
    font-size: $normal !important;
  }

  &--normal2 {
    font-size: $normal2 !important;
  }

  &--large {
    font-size: $large !important;
  }

  &--xlarge {
    font-size: $x-large !important;
  }
  &--xxlarge {
    font-size: $xx-large;
  }
}

.font__family {
  &--inter {
    font-family: "Inter", sans-serif !important;
  }

  &--SFProDisplay {
    font-family: $fontSFProDisplay !important;
    -webkit-font-smoothing: antialiased;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
  }
}

// Font Weight
.font__weight {
  &--light {
    font-weight: $light !important;
  }
  &--regular {
    font-weight: $regular !important;
  }
  &--medium {
    font-weight: $medium !important;
  }
  &--semi-bold {
    font-weight: $semi-bold !important;
  }
  &--bold {
    font-weight: $bold !important;
  }
  &--extra-bold {
    font-weight: $extra-bold !important;
  }
}

.capitalize {
  text-transform: capitalize;
}

.text__align {
  &--left {
    text-align: left !important;
  }

  &--center {
    text-align: center !important;
  }

  &--right {
    text-align: right !important;
  }

  &--justify {
    text-align: justify !important;
  }
}

.cursor {
  &-pointer {
    cursor: pointer !important;
  }
}

.letter__spacing {
  &--05 {
    letter-spacing: 0.5px;
  }
  &--1 {
    letter-spacing: 1px;
  }
  &--2 {
    letter-spacing: 2px;
  }
  &--3 {
    letter-spacing: 3px;
  }
}

.leading {
  &-12 {
    line-height: 12px !important;
  }
  &-14 {
    line-height: 14px !important;
  }
  &-16 {
    line-height: 16px !important;
  }
}

.text-transform {
  &--none {
    text-transform: none !important;
  }

  &--uppercase {
    text-transform: uppercase;
  }
}

div.ant-typography,
.ant-typography p {
  margin-bottom: 0;
}

.ant-typography.ant-typography-ellipsis {
  margin-bottom: 0;
}

.line-height--28 {
  line-height: 28px !important;
}

.line-height--20 {
  line-height: 20px !important;
}

.line-height--22 {
  line-height: 22px !important;
}

.text__decoration {
  &--underline {
    text-decoration: underline !important;
  }
}
