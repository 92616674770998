.talent-detail {
  &-tab_swiper {
    .swiper-no-swiping {
      .swiper-wrapper {
        overflow: unset;
      }
    }
  }

  .empty-state {
    height: 255px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($white, 0.1);
    border-radius: 16px;
    margin: auto;
    margin-top: 24px;

    @include mobile {
      margin-top: 20px;
      width: calc(100% - 32px);
    }
    &--module {
      @include mobile {
        padding-top: 24px;
        align-items: flex-start;
      }
    }
    &--social-links {
      margin-top: 16px;
      height: 40px;
      z-index: 9;
      position: relative;

      @include mobile {
        height: 30px;
        margin-top: 10px;
      }
    }

    &--header {
      margin-top: 0;
      width: 366px;
      height: 461px;
      flex-direction: column;
      border-radius: 16px;

      @include mobile {
        width: 100%;
        height: 412px;
        border-radius: 0;
      }
    }
  }

  &__module {
    &:last-child {
      .ant-divider-horizontal {
        display: none;
      }
    }
  }

  .ant-skeleton.ant-skeleton-active {
    .ant-skeleton-avatar {
      background: rgba(#ffffff, 0.1) !important;
    }
  }

  &.darken {
    .ant-skeleton.ant-skeleton-active {
      .ant-skeleton-avatar {
        background: rgba(#121212, 0.1) !important;
      }
    }
  }

  .shadow-left {
    background: linear-gradient(270deg, rgba(18, 18, 18, 0) 0%, rgba(18, 18, 18, 0.1) 100%);
  }

  .shadow-right {
    background: linear-gradient(270deg, rgba(18, 18, 18, 0.1) 0%, rgba(18, 18, 18, 0) 100%);
  }

  .btn-swiper__wrapper {
    svg path {
      stroke: var(--ant-info-color) !important;
    }
  }

  .btn-menu {
    width: 28px !important;
    min-height: 28px !important;
    height: 28px !important;

    svg {
      width: 28px !important;
      height: 28px !important;

      path {
        stroke: var(--ant-info-color) !important;
      }
    }

    &-wrapper {
      @include mobile {
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(0deg, rgba(18, 18, 18, 0) 3.22%, rgba(#000000, 0.6) 95.91%);
        width: 100%;
        height: 110px;
        padding: 16px 0 0 16px;
        z-index: 999;
      }
    }
  }

  &__social {
    .ant-col {
      height: 32px !important;

      @include mobile {
        height: 24px !important;
      }

      svg {
        width: 32px;
        height: 32px;

        @include mobile {
          width: 24px;
          height: 24px;
        }

        path {
          fill: var(--ant-info-color) !important;
        }

        &.fill-first-path-only {
          path {
            fill: var(--ant-primary-color) !important;

            &:first-child {
              fill: var(--ant-info-color) !important;
            }
          }
        }

        &.ignore-fill-first-path {
          path {
            &:first-child {
              fill: inherit !important;
            }
          }
        }
      }
    }
  }

  &__fan-banner {
    border-radius: 16px;
    padding: 24px;

    &__empty {
      border-radius: 100%;
      background: rgba($white, 0.1);
      width: 200px;
      height: 200px;
      padding: 70px;

      @include mobile {
        width: 112px;
        height: 112px;
        padding: 36px;
      }

      &.darken {
        background: rgba($default, 0.1);
      }
    }

    &__background {
      width: 100%;
      height: 100%;

      &__gradient {
        background: radial-gradient(100.91% 50% at 50% 50%, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
        transform: rotate(180deg);
        opacity: 0.5;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        &.darken {
          background: radial-gradient(100.91% 50% at 50% 50%, #000000 0%, rgba(0, 0, 0, 0) 100%);
        }
      }

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        filter: blur(32px);
      }
    }

    &__container {
      position: relative;
      overflow: hidden;
      border-radius: 16px;
    }
  }

  &__music {
    .my-swiper {
      &.multiple-row {
        .swiper-container {
          height: 296px;

          @include mobile {
            height: 208px;
          }

          .swiper-slide {
            height: 140px;

            &:nth-child(2n) {
              margin-top: 16px !important;
            }

            @include mobile {
              height: 96px;
            }
          }
        }
      }
    }
  }

  &__links {
    .my-swiper {
      &.multiple-row {
        .swiper-container {
          height: 296px;

          @include mobile {
            height: 208px;
          }

          .swiper-slide {
            height: 140px;

            &:nth-child(2n) {
              margin-top: 16px !important;
            }

            @include mobile {
              height: 96px;
            }
          }
        }
      }
    }
  }

  &__name {
    @include mobile {
      width: 100%;
      height: 144px;
      position: absolute;
      bottom: -1px;
      left: 0;
      align-items: flex-end;
    }

    &__image {
      width: 366px;
      text-align: center;
      margin: auto;
      margin-bottom: 8px;

      .image-skeleton {
        height: 64px;

        @include mobile {
          margin-left: -16px;
        }

        .ant-skeleton-active {
          .ant-skeleton-avatar {
            height: 64px;
          }
        }

        &.loaded {
          width: auto !important;
          height: auto !important;
          margin-left: 0;

          img {
            border-radius: 0 !important;
            height: auto !important;
          }
        }
      }

      @include mobile {
        width: 312px;
        margin-bottom: 0;
        z-index: 999;
        margin-top: -70px;
        position: relative;
      }
    }

    &__text {
      width: 100%;
      display: block;

      @include mobile {
        z-index: 999;
        margin-top: -70px;
        position: relative;

        &.empty {
          font-size: 34.7494px !important;
          line-height: 45px;
          margin-top: -82px;
        }
      }
    }
  }

  &__header {
    position: relative;

    @include mobile {
      padding: 0;
    }

    &__avatar {
      @include mobile {
        width: 100% !important;
      }
    }
  }

  &-tab.ant-btn {
    height: 44px !important;
    border-radius: 8px;
    padding: 0 16px;

    &.active {
      background: linear-gradient(var(--ant-success-color), var(--ant-success-color)), var(--ant-primary-color) !important;
    }
  }

  .ant-divider {
    background: var(--ant-success-color) !important;
    height: 1px;
    border: none !important;

    @include mobile {
      width: calc(100% - 32px);
      min-width: calc(100% - 32px);
      margin: auto;
    }
  }

  .link-card {
    background: linear-gradient(var(--ant-success-color), var(--ant-success-color)), var(--ant-primary-color) !important;
    border-radius: 8px;
    overflow: hidden;

    &.bottom {
      margin-top: -8px;
    }

    &--special-offer {
      background: linear-gradient(var(--ant-success-color), var(--ant-success-color)), var(--ant-primary-color) !important;
      border-radius: 8px;

      .image-skeleton {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      .get-offer-btn {
        height: 36px;
        background: transparent;
        border-color: var(--ant-info-color);
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          border-color: var(--ant-info-color);
        }

        @include mobile {
          height: 26px;
        }
      }
    }

    &--bandsintown {
      height: 140px;
      padding: 12px;

      @include mobile {
        height: 96px;
        padding: 4px;
      }

      .link-card__date {
        position: relative;
        width: 128px;
        height: 116px;

        @include mobile {
          width: 92px;
          height: 88px;
        }

        svg {
          position: absolute;

          @include mobile {
            width: 92px;
            height: 88px;
          }

          path {
            stroke: inherit;
          }
        }

        div {
          position: absolute;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          width: 100%;
          height: 100%;
          text-align: center;
          padding-right: 20px;

          @include mobile {
            padding-right: 14px;
          }

          span {
            width: 100%;
            height: 100%;
            height: 22px;
            line-height: 22px;
            text-transform: uppercase;

            @include mobile {
              height: 16px;
              line-height: 16px;
            }

            &:nth-child(2) {
              height: 52px;
              line-height: 52px;

              @include mobile {
                height: 42px;
                line-height: 42px;
              }
            }
          }
        }
      }

      .ant-btn {
        height: 32px !important;
        background: transparent !important;
        padding: 0 12px;
        border-color: var(--ant-info-color);
        min-height: auto;

        @include mobile {
          height: 24px !important;
          min-height: 24px !important;
        }

        &:hover,
        &:focus,
        &:active {
          border-color: var(--ant-info-color);
        }
      }
    }
  }
  .border-card-inside-group {
    border-radius: 8px;
  }
  .product-card {
    .image-skeleton {
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .default-image {
      width: 140px;
      height: 140px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(#ffffff, 0.1);

      &.long {
        border-radius: 0;
      }

      @include mobile {
        width: 115px;
        height: 115px;
      }
      svg {
        width: 52px;
        height: 52px;
        path {
          fill: rgba(#ffffff, 0.3);
          stroke: none;
        }
        circle {
          fill: rgba(#ffffff, 0.3);
        }
      }
      &.darken {
        background: rgba(#121212, 0.1);
        svg {
          path {
            fill: rgba(#121212, 0.3);
          }
          circle {
            fill: rgba(#121212, 0.3);
          }
        }
      }
    }

    .text--regular12 {
      line-height: 24px !important;
    }

    &.disabled {
      cursor: not-allowed !important;
    }
  }

  .music-card {
    .music-item {
      svg {
        width: auto;
        height: 24px;

        @include mobile {
          height: 20px;
        }

        path {
          fill: var(--ant-info-color) !important;
        }
      }

      .anticon-loading {
        svg {
          height: auto;
        }
      }
    }

    .ant-divider {
      @include mobile {
        width: 100% !important;
      }
    }

    .ant-btn.music-item--single {
      border-color: var(--ant-info-color);
      background-color: transparent;
      height: 30px;
      min-height: 30px;

      @include mobile {
        height: 24px;
        min-height: 24px;
        padding: 3px 12px;
      }

      .ant-typography {
        line-height: 22px;

        @include mobile {
          line-height: 16px;
        }
      }

      &:hover,
      &:focus,
      &:active {
        border-color: var(--ant-info-color);
      }

      &:disabled {
        border: 1px solid var(--ant-info-color) !important;
        background-color: transparent !important;

        .ant-typography {
          color: var(--ant-info-color) !important;
          text-transform: none;
        }
      }
    }

    .ant-btn.btn-play-full {
      border-radius: 100px;
      background: transparent;
      display: flex;
      align-items: center;
      height: 36px;
      border-color: var(--ant-info-color);

      &:hover {
        border-color: var(--ant-info-color);
      }

      @include mobile {
        height: 26px;
        min-height: 26px;
      }
    }

    &__left {
      position: relative;

      .ant-btn {
        height: 32px !important;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        svg {
          width: 32px;
          height: 32px;
        }
      }
    }

    &__right {
      flex: 1;
    }

    &.big {
      .music-card {
        &__left {
          .ant-btn {
            width: 40px !important;
            height: 40px !important;

            svg {
              width: 40px !important;
              height: 40px !important;
            }

            @include mobile {
              width: 38.25px !important;
              height: 38.25px !important;

              svg {
                width: 38.25px !important;
                height: 38.25px !important;
              }
            }
          }
        }

        &__provider {
          height: 56px;
          padding: 12px 0;
          border-radius: 123px;

          &__divider {
            width: 1px;
            height: 32px;
            border-radius: 123px;
            background: var(--ant-info-color) !important;

            @include mobile {
              height: 20px;
            }
          }

          svg {
            height: 100%;
            width: 100%;
            max-width: 147px;
            max-height: 24px;

            path {
              fill: var(--ant-info-color);
            }
          }

          @include mobile {
            height: 40px;
            padding: 10px 0;

            svg {
              max-width: 74px;
              max-height: 20px;
            }
          }
        }

        &__singer {
          @include mobile {
            line-height: 14px;
          }
        }
      }

      &.single {
        background: linear-gradient(var(--ant-success-color), var(--ant-success-color)), var(--ant-primary-color) !important;

        .ant-skeleton.ant-skeleton-active {
          .ant-skeleton-avatar {
            background: linear-gradient(rgba(#ffffff, 0.2), rgba(#ffffff, 0.2)), var(--ant-primary-color) !important;
          }
        }

        &.darken {
          .ant-skeleton.ant-skeleton-active {
            .ant-skeleton-avatar {
              background: linear-gradient(rgba(#121212, 0.2), rgba(#121212, 0.2)), var(--ant-primary-color) !important;
            }
          }
        }

        &.pre-release {
          border-radius: 8px !important;
        }

        @include mobile {
          border-radius: 8px !important;
        }

        .music-card__left {
          margin-top: -56px;

          @include mobile {
            margin-top: -38px;
          }

          .ant-btn {
            width: 52px !important;
            height: 52px !important;

            svg {
              width: 52px !important;
              height: 52px !important;
            }

            @include mobile {
              width: 32px !important;
              height: 32px !important;

              svg {
                width: 32px !important;
                height: 32px !important;
              }
            }
          }
        }
      }
    }
  }

  &__experience-card {
    position: relative;
    width: 140px;

    @include mobile {
      width: 115px;
    }

    .image-skeleton {
      overflow: hidden;
    }

    .experience-type-tag {
      position: absolute;
      top: 12px;
      left: 12px;
    }

    &.long {
      .experience-type-tag {
        position: relative;
        top: 0;
        left: 0;
      }
    }
  }

  &__on-demand-card {
    position: relative;
    border-radius: 8px;
    overflow: hidden;

    &__icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &__name {
      width: 100%;
      height: 60px;
      padding: 16px 12px 12px;
      text-align: center;
      position: absolute;
      bottom: 0;
      background: linear-gradient(180deg, rgba(18, 18, 18, 0) 0%, rgba(#121212, 0.4) 100%);
      color: $white !important;

      @include mobile {
        height: 78px;
        padding: 42px 16px 16px;
      }
    }
  }

  .youtube-player {
    position: relative;
    background: linear-gradient(var(--ant-success-color), var(--ant-success-color)), var(--ant-primary-color) !important;
    border-radius: 8px;

    &__overlay {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      overflow: hidden;

      &__name {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 50px;

        @include mobile {
          height: 72px;
        }

        &__background {
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 9;
          background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
          opacity: 0.4;
        }

        .ant-typography {
          position: absolute;
          z-index: 10;
          padding: 12px;
          width: 100%;
          display: block;
          color: $white !important;

          @include mobile {
            padding: 8px 12px 10px;
          }
        }
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      button {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 10;
        transform: translate(-50%, -50%);
        width: 48px !important;
        height: 48px !important;

        svg {
          width: 48px !important;
          height: 48px !important;
        }

        @include mobile {
          width: 54px !important;
          height: 54px !important;

          svg {
            width: 54px !important;
            height: 54px !important;
          }
        }
      }
    }

    &--full-width {
      .youtube-player__overlay__name {
        height: 64px;

        .ant-typography {
          padding: 16px;
        }

        @include mobile {
          height: 72px;

          .ant-typography {
            padding: 12px;
          }
        }
      }

      button {
        width: 80px !important;
        height: 80px !important;

        svg {
          width: 80px !important;
          height: 80px !important;
        }

        @include mobile {
          width: 54px !important;
          height: 54px !important;

          svg {
            width: 54px !important;
            height: 54px !important;
          }
        }
      }
    }
  }
}
