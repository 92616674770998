@mixin primaryButton {
  padding: 0 24px;
  background: $default;
  border: none;
  border-radius: 123px;

  span,
  .ant-typography {
    font-size: 16px;
    line-height: 24px;
    color: $white !important;
    font-weight: 700 !important;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  svg {
    * {
      stroke: $white;
    }
  }

  &:focus,
  &:hover {
    border: none;
    background: $default;
  }
}

@mixin backButton {
  padding: 0 24px;
  border-radius: 124px;
  border: 2px solid $gray7;

  span,
  .ant-typography {
    color: $bg-dark !important;
    font-weight: 700 !important;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  svg {
    * {
      stroke: $bg-dark;
    }
  }

  &:focus,
  &:hover {
    border: 2px solid $gray7;
  }
}

button.ant-btn {
  min-height: 32px;
  box-shadow: none !important;
  text-shadow: none;
  height: 48px;
  border-radius: 123px;

  &-link {
    height: 24px;
    min-height: 24px;
    outline: none;
  }

  &:focus {
    border-color: $border;
  }

  &:hover {
    border-color: $primary;
  }

  &--border {
    border: 1px solid $default;
    font-size: 16px;
    height: 40px;

    @include mobile {
      font-size: 14px;
    }

    &:hover,
    &:focus,
    &:active {
      border: 1px solid $default;
    }
  }

  &:hover,
  &:focus {
    color: $default;
  }

  &[disabled] {
    color: rgba($white, 0.4) !important;
    background-color: $default !important;
    border: none;
    text-transform: uppercase;
    span {
      color: rgba($white, 0.4) !important;
      text-transform: uppercase;
    }
    &:hover,
    &:focus {
      color: rgba($default, 0.5);
    }
  }

  &.ant-btn-md {
    height: 52px !important;
  }

  &.ant-btn-sm {
    height: 36px;
    padding: 0 15px;

    &.ant-btn-circle {
      min-width: 36px !important;
    }
  }

  &.ant-btn-lg {
    height: 56px;
  }

  &-primary {
    @include primaryButton();

    &[disabled] {
      @include primaryButton();

      background: $alto !important;

      span,
      .ant-typography {
        color: $default !important;
      }
    }
  }
  &-ghost {
    border: 2px solid $alto;
    span,
    .ant-typography {
      color: $default !important;
      font-weight: $bold;
      text-transform: uppercase;
      font-size: $medium1;
    }

    &:hover,
    &:focus {
      border: 2px solid $primary;
    }
  }

  &-default {
    @include primaryButton();

    background: $default;
    border: 2px solid rgba(255, 255, 255, 0.2);

    span,
    .ant-typography {
      color: $white !important;
    }

    &:hover,
    &:focus {
      background: $default;
      border: 2px solid rgba(255, 255, 255, 0.2);
    }

    &[disabled] {
      @include primaryButton();

      background: $default;
      opacity: 0.5;
    }
  }

  &-back {
    @include backButton();
  }

  &-dangerous {
    border: none !important;
    color: $red !important;
    background-color: $red !important;
  }

  &-text {
    height: auto;
    min-height: 0 !important;
    border: none !important;
    border-radius: 0;

    &:hover,
    &:active,
    &:focus,
    &:disabled {
      background: none !important;
      border: none !important;
    }
  }

  &-red {
    border-color: $red !important;

    * {
      color: $red !important;
      stroke: $red !important;
    }
  }

  &-white {
    background-color: $white;

    span,
    .ant-typography {
      color: $default !important;
    }
  }

  &-grey {
    border: none;
    border-radius: 123px;
    background-color: rgba($white, 0.2);

    * {
      stroke: $white;
    }

    &:focus,
    &:hover {
      background-color: rgba($white, 0.2);
    }
  }

  &.btn--default {
    color: $default;

    &:hover,
    &:active {
      color: $default;
    }
  }

  &.btn--no-border {
    border: none;
    box-shadow: none;
  }

  &.btn--square {
    width: 48px;
    height: 48px;
    padding: 0;
  }

  &.btn--circle {
    border-radius: 100%;
  }

  &.ant-btn-round {
    border-radius: 123px;
  }

  &.ant-btn-circle {
    border-radius: 100%;
  }
}

.ant-switch {
  height: 24px;
  background-color: $gray;

  &-handle {
    width: 20px;
    height: 20px;

    &::before {
      background-color: rgba($default, 0.4);
      box-shadow: none;
    }
  }

  &-checked {
    background-color: $primary;

    .ant-switch-handle {
      left: calc(100% - 20px - 2px);

      &::before {
        background-color: $white;
      }
    }
  }
}
