.music-modal {
  width: 540px !important;

  * {
    color: var(--ant-info-color) !important;
  }

  .ant-skeleton.ant-skeleton-active {
    .ant-skeleton-avatar {
      background: linear-gradient(rgba(#ffffff, 0.2), rgba(#ffffff, 0.2)), var(--ant-primary-color);
    }
  }

  &.darken {
    .ant-skeleton.ant-skeleton-active {
      .ant-skeleton-avatar {
        background: linear-gradient(rgba(#121212, 0.2), rgba(#121212, 0.2)), var(--ant-primary-color);
      }
    }
  }

  .ant-modal-content {
    overflow: visible;
    border-radius: 16px !important;
    background-color: transparent;
  }

  .ant-modal-body {
    padding: 0;
  }

  &__header {
    background: linear-gradient(var(--ant-success-color), var(--ant-success-color)), var(--ant-primary-color);
    border-bottom: 1px solid rgba($white, 0.1);
    border-radius: 16px 16px 0 0;

    .image-skeleton {
      border-radius: 8px;
      overflow: hidden;
    }

    &__left {
      position: relative;
      margin-top: -48px;

      .ant-btn {
        height: 52px !important;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        svg {
          width: 52px;
          height: 52px;
        }

        @include mobile {
          height: 32px !important;

          svg {
            width: 32px;
            height: 32px;
          }
        }
      }
    }
  }

  &__sub-header {
    background-color: rgba($white, 0.05);

    &__date {
      color: $turquoise !important;
    }
  }

  &__content {
    background-color: $white;
    max-height: calc(100vh - 160px - 160px);
    overflow: auto;
    border-radius: 0 0 16px 16px;

    &__wrapper {
      border-radius: 0 0 16px 16px;
      overflow: hidden;
    }

    @include mobile {
      max-height: calc(100vh - 160px - 200px);
    }

    * {
      color: $black !important;
    }

    .music-item {
      border-bottom: 1px solid rgba($black, 0.1);

      img {
        height: 24px;

        @include mobile {
          height: 22px;
        }
      }
    }

    .ant-btn {
      border-color: $black;
      height: 30px;
      min-height: 30px;

      .ant-typography {
        line-height: 22px !important;

        @include mobile {
          line-height: 16px !important;
        }
      }

      @include mobile {
        height: 24px;
        min-height: 24px;
        padding: 3px 12px;
      }

      &:hover,
      &:focus,
      &:active {
        border-color: $black;
      }
    }
  }

  &--pre-release {
    background-color: $black3;
    width: 664px !important;
    border-radius: 16px;

    .music-modal__sub-header--custom {
      border-radius: 0 0 16px 16px;
    }

    .music-modal__header {
      background-color: $black3;
    }

    .music-modal__sub-header {
      &__date {
        color: var(--ant-info-color) !important;
      }
    }

    .ant-modal-close {
      top: 24px;
    }

    .music-modal__content {
      .music-item {
        .ant-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 106px;
          height: 36px;
          border: 1px solid $default !important;
          background-color: $white !important;
          text-transform: none !important;
          border-radius: 100px;

          .ant-typography {
            color: $default !important;
            text-transform: none !important;

            @include mobile {
              line-height: 20px;
            }
          }

          @include mobile {
            padding: 0;
            height: 32px;
            width: 88px;
          }
        }
      }

      img {
        height: 32px;

        &.spotify {
          width: 108px;
        }

        @include mobile {
          height: 20px;

          &.spotify {
            width: 80px;
            height: 24px;
          }
        }
      }
    }
  }

  &__title {
    background-color: $black3;
  }

  .arrow-down {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 9px solid $black3;
    margin: auto;
  }
}

.btn-presave--custom {
  margin: 16px auto 0;
  padding: 6px 12px;
  background: transparent;
  border-color: var(--ant-info-color) !important;
  color: var(--ant-info-color) !important;

  @include mobile {
    padding: 4px 12px;
  }

  .ant-typography {
    line-height: 22px !important;
  }

  @include mobile {
    padding: 4px 16px;
  }

  &:hover,
  &:focus,
  &:active {
    background: transparent;
    border-color: var(--ant-info-color) !important;
    color: var(--ant-info-color) !important;
  }
}
