// Typography
> * {
  font-size: rem-calc(14);
  font-weight: bold;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h {
  font-family: $headings-font-family;
  font-weight: 700;
  margin: 0 0 0.5em;
  color: $headings-color;
}

h1,
.h1 {
  font-size: $h1-font-size;

  @include media(">=tablet") {
    font-size: rem-calc(36);
  }
}

h2,
.h2 {
  font-size: $h2-font-size;

  @include media(">=tablet") {
    font-size: rem-calc(28);
  }
}

h3,
.h3 {
  font-size: $h3-font-size;

  @include media(">=tablet") {
    font-size: rem-calc(22);
  }
}

h4,
.h4 {
  font-size: $h4-font-size;

  @include media(">=tablet") {
    font-size: rem-calc(18);
  }
}

h5,
.h5 {
  font-size: $h5-font-size;

  @include media(">=tablet") {
    font-size: rem-calc(16);
  }
}

h6,
.h6 {
  font-size: $h6-font-size;

  @include media(">=tablet") {
    font-size: rem-calc(14);
  }
}

p {
  margin: rem-calc(0 0 16);
}

a {
  color: $base-link-color;
  @include animate(color background-color background opacity);
  text-decoration: underline;

  &:hover,
  &:focus {
    color: darken($base-link-color, 10%);
    outline: none;
    text-decoration: none;
  }
}
