.terms {
  @import url("https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98");
  ul.lst-kix_list_1-0 {
    list-style-type: none;
  }
  ul.lst-kix_mbd6e0ulpvsm-6 {
    list-style-type: none;
  }
  ul.lst-kix_mbd6e0ulpvsm-7 {
    list-style-type: none;
  }
  ul.lst-kix_mbd6e0ulpvsm-4 {
    list-style-type: none;
  }
  ul.lst-kix_mbd6e0ulpvsm-5 {
    list-style-type: none;
  }
  ul.lst-kix_mbd6e0ulpvsm-2 {
    list-style-type: none;
  }
  ul.lst-kix_mbd6e0ulpvsm-3 {
    list-style-type: none;
  }
  ul.lst-kix_mbd6e0ulpvsm-0 {
    list-style-type: none;
  }
  ul.lst-kix_mbd6e0ulpvsm-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-5 {
    list-style-type: none;
  }
  ul.lst-kix_mbd6e0ulpvsm-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-6 {
    list-style-type: none;
  }
  .lst-kix_list_5-0 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_mbd6e0ulpvsm-7 > li:before {
    content: "-  ";
  }
  .lst-kix_list_5-3 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_mbd6e0ulpvsm-8 > li:before {
    content: "-  ";
  }
  .lst-kix_list_5-2 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_5-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_5-7 > li:before {
    content: "o  ";
  }
  ul.lst-kix_list_8-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-5 {
    list-style-type: none;
  }
  .lst-kix_list_5-6 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_5-8 > li:before {
    content: "\0025aa  ";
  }
  ul.lst-kix_list_8-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-7 {
    list-style-type: none;
  }
  .lst-kix_list_5-4 > li:before {
    content: "o  ";
  }
  .lst-kix_list_5-5 > li:before {
    content: "\0025aa  ";
  }
  ul.lst-kix_list_8-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-1 {
    list-style-type: none;
  }
  .lst-kix_list_6-1 > li:before {
    content: "\0025cb  ";
  }
  .lst-kix_list_6-3 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_6-0 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_6-4 > li:before {
    content: "\0025cb  ";
  }
  li.li-bullet-2:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }
  .lst-kix_list_6-2 > li:before {
    content: "\0025a0  ";
  }
  .lst-kix_list_6-8 > li:before {
    content: "\0025a0  ";
  }
  .lst-kix_list_6-5 > li:before {
    content: "\0025a0  ";
  }
  .lst-kix_list_6-7 > li:before {
    content: "\0025cb  ";
  }
  .lst-kix_list_6-6 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_2-7 > li:before {
    content: "o  ";
  }
  .lst-kix_list_7-4 > li:before {
    content: "\0025cb  ";
  }
  .lst-kix_list_7-6 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_2-5 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_7-2 > li:before {
    content: "\0025a0  ";
  }
  ul.lst-kix_list_3-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-8 {
    list-style-type: none;
  }
  .lst-kix_list_10-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_13-7 > li:before {
    content: "\0025aa  ";
  }
  ul.lst-kix_list_3-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-2 {
    list-style-type: none;
  }
  .lst-kix_list_7-8 > li:before {
    content: "\0025a0  ";
  }
  ul.lst-kix_list_3-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-4 {
    list-style-type: none;
  }
  .lst-kix_list_10-7 > li:before {
    content: "o  ";
  }
  .lst-kix_list_10-5 > li:before {
    content: "\0025aa  ";
  }
  li.li-bullet-1:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }
  .lst-kix_list_10-3 > li:before {
    content: "\0025cf  ";
  }
  ul.lst-kix_list_11-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_11-6 {
    list-style-type: none;
  }
  .lst-kix_list_4-1 > li:before {
    content: "o  ";
  }
  ul.lst-kix_list_11-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_11-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_11-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_11-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_11-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_11-0 {
    list-style-type: none;
  }
  .lst-kix_list_9-2 > li:before {
    content: "\0025a0  ";
  }
  .lst-kix_list_4-3 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_4-5 > li:before {
    content: "\0025aa  ";
  }
  ul.lst-kix_list_11-8 {
    list-style-type: none;
  }
  .lst-kix_list_9-0 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_mbd6e0ulpvsm-5 > li:before {
    content: "-  ";
  }
  .lst-kix_mbd6e0ulpvsm-3 > li:before {
    content: "-  ";
  }
  .lst-kix_list_9-6 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_mbd6e0ulpvsm-1 > li:before {
    content: "-  ";
  }
  .lst-kix_list_9-4 > li:before {
    content: "\0025cb  ";
  }
  .lst-kix_list_11-3 > li:before {
    content: "\0025cf  ";
  }
  ul.lst-kix_list_2-8 {
    list-style-type: none;
  }
  .lst-kix_list_12-3 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_11-5 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_12-1 > li:before {
    content: "o  ";
  }
  ul.lst-kix_list_2-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-1 {
    list-style-type: none;
  }
  .lst-kix_list_9-8 > li:before {
    content: "\0025a0  ";
  }
  ul.lst-kix_list_2-6 {
    list-style-type: none;
  }
  .lst-kix_list_1-1 > li:before {
    content: "o  ";
  }
  ul.lst-kix_list_2-7 {
    list-style-type: none;
  }
  .lst-kix_list_11-7 > li:before {
    content: "o  ";
  }
  ul.lst-kix_list_2-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-0 {
    list-style-type: none;
  }
  .lst-kix_list_1-3 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_13-3 > li:before {
    content: "\0025aa  ";
  }
  ul.lst-kix_list_10-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-7 {
    list-style-type: none;
  }
  .lst-kix_list_1-7 > li:before {
    content: "o  ";
  }
  ul.lst-kix_list_10-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-3 {
    list-style-type: none;
  }
  .lst-kix_list_1-5 > li:before {
    content: "\0025aa  ";
  }
  ul.lst-kix_list_10-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-1 {
    list-style-type: none;
  }
  .lst-kix_list_13-5 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_12-5 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_12-7 > li:before {
    content: "o  ";
  }
  .lst-kix_list_2-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_2-3 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_13-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_3-0 > li:before {
    content: "\0025cf  ";
  }
  ul.lst-kix_list_5-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-8 {
    list-style-type: none;
  }
  .lst-kix_list_3-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_3-2 > li:before {
    content: "\0025aa  ";
  }
  ul.lst-kix_list_5-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-6 {
    list-style-type: none;
  }
  .lst-kix_list_8-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_8-2 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_3-5 > li:before {
    content: "\0025aa  ";
  }
  ul.lst-kix_list_5-0 {
    list-style-type: none;
  }
  .lst-kix_list_3-4 > li:before {
    content: "o  ";
  }
  ul.lst-kix_list_5-3 {
    list-style-type: none;
  }
  .lst-kix_list_3-3 > li:before {
    content: "\0025cf  ";
  }
  ul.lst-kix_list_5-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-1 {
    list-style-type: none;
  }
  .lst-kix_list_8-0 > li:before {
    content: "\0025cf  ";
  }
  ul.lst-kix_list_5-2 {
    list-style-type: none;
  }
  .lst-kix_list_8-7 > li:before {
    content: "o  ";
  }
  .lst-kix_list_3-8 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_8-5 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_8-6 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_8-3 > li:before {
    content: "\0025cf  ";
  }
  ul.lst-kix_list_13-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_13-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_13-3 {
    list-style-type: none;
  }
  .lst-kix_list_3-6 > li:before {
    content: "\0025cf  ";
  }
  ul.lst-kix_list_13-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_13-1 {
    list-style-type: none;
  }
  .lst-kix_list_3-7 > li:before {
    content: "o  ";
  }
  ul.lst-kix_list_13-0 {
    list-style-type: none;
  }
  .lst-kix_list_8-4 > li:before {
    content: "o  ";
  }
  .lst-kix_list_11-2 > li:before {
    content: "\0025aa  ";
  }
  ul.lst-kix_list_13-8 {
    list-style-type: none;
  }
  .lst-kix_list_11-1 > li:before {
    content: "o  ";
  }
  ul.lst-kix_list_13-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_13-6 {
    list-style-type: none;
  }
  .lst-kix_list_11-0 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_8-8 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_4-8 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_4-7 > li:before {
    content: "o  ";
  }
  ul.lst-kix_list_4-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_12-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_12-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_12-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_12-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_12-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_12-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_12-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_12-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_12-7 {
    list-style-type: none;
  }
  .lst-kix_list_7-0 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_2-6 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_2-4 > li:before {
    content: "o  ";
  }
  .lst-kix_list_2-8 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_7-1 > li:before {
    content: "\0025cb  ";
  }
  .lst-kix_list_7-5 > li:before {
    content: "\0025a0  ";
  }
  .lst-kix_list_7-3 > li:before {
    content: "\0025cf  ";
  }
  ul.lst-kix_list_7-5 {
    list-style-type: none;
  }
  .lst-kix_list_10-0 > li:before {
    content: "-  ";
  }
  ul.lst-kix_list_7-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-4 {
    list-style-type: none;
  }
  .lst-kix_list_13-6 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_13-8 > li:before {
    content: "\0025aa  ";
  }
  ul.lst-kix_list_7-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-0 {
    list-style-type: none;
  }
  .lst-kix_list_7-7 > li:before {
    content: "\0025cb  ";
  }
  .lst-kix_list_10-4 > li:before {
    content: "o  ";
  }
  .lst-kix_list_10-8 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_4-0 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_10-2 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_4-4 > li:before {
    content: "o  ";
  }
  .lst-kix_list_4-2 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_4-6 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_9-3 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_mbd6e0ulpvsm-6 > li:before {
    content: "-  ";
  }
  .lst-kix_list_10-6 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_9-1 > li:before {
    content: "\0025cb  ";
  }
  .lst-kix_mbd6e0ulpvsm-4 > li:before {
    content: "-  ";
  }
  .lst-kix_list_9-7 > li:before {
    content: "\0025cb  ";
  }
  .lst-kix_mbd6e0ulpvsm-0 > li:before {
    content: "-  ";
  }
  .lst-kix_list_11-4 > li:before {
    content: "o  ";
  }
  .lst-kix_mbd6e0ulpvsm-2 > li:before {
    content: "-  ";
  }
  .lst-kix_list_12-4 > li:before {
    content: "o  ";
  }
  .lst-kix_list_9-5 > li:before {
    content: "\0025a0  ";
  }
  ul.lst-kix_list_6-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-8 {
    list-style-type: none;
  }
  .lst-kix_list_12-2 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_11-6 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_1-0 > li:before {
    content: "\0025cf  ";
  }
  ul.lst-kix_list_6-2 {
    list-style-type: none;
  }
  .lst-kix_list_11-8 > li:before {
    content: "\0025aa  ";
  }
  ul.lst-kix_list_6-3 {
    list-style-type: none;
  }
  .lst-kix_list_1-2 > li:before {
    content: "\0025aa  ";
  }
  ul.lst-kix_list_6-0 {
    list-style-type: none;
  }
  .lst-kix_list_12-0 > li:before {
    content: "\0025cf  ";
  }
  ul.lst-kix_list_6-1 {
    list-style-type: none;
  }
  .lst-kix_list_1-4 > li:before {
    content: "o  ";
  }
  .lst-kix_list_13-0 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_13-4 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_1-6 > li:before {
    content: "\0025cf  ";
  }
  li.li-bullet-0:before {
    margin-left: -21.3pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 21.3pt;
  }
  .lst-kix_list_2-0 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_12-6 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_1-8 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_2-2 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_13-2 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_12-8 > li:before {
    content: "\0025aa  ";
  }
  ol {
    margin: 0;
    padding: 0;
  }
  table td,
  table th {
    padding: 0;
  }
  .c7 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .c12 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt;
  }
  .c6 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: center;
    height: 11pt;
  }
  .c1 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Verdana";
    font-style: normal;
  }
  .c0 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Calibri";
    font-style: normal;
  }
  .c10 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .c25 {
    padding-top: 18pt;
    padding-bottom: 18pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: center;
  }
  .c14 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: center;
  }
  .c27 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c13 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c4 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .c9 {
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-style: normal;
  }
  .c20 {
    -webkit-text-decoration-skip: none;
    color: #1155cc;
    text-decoration: underline;
    text-decoration-skip-ink: none;
  }
  .c21 {
    background-color: #ffffff;
    max-width: 451.3pt;
    padding: 72pt 72pt 72pt 72pt;
  }
  .c22 {
    text-decoration: none;
    vertical-align: baseline;
    font-style: normal;
  }
  .c8 {
    margin-left: 18pt;
    padding-left: 3.3pt;
  }
  .c19 {
    padding: 0;
    margin: 0;
  }
  .c15 {
    margin-left: 36pt;
    padding-left: 0pt;
  }
  .c23 {
    color: inherit;
    text-decoration: inherit;
  }
  .c2 {
    font-weight: 400;
    font-family: "Verdana";
  }
  .c11 {
    font-weight: 400;
    font-family: "Calibri";
  }
  .c3 {
    font-weight: 700;
    font-family: "Verdana";
  }
  .c16 {
    font-style: italic;
  }
  .c17 {
    font-size: 12pt;
  }
  .c5 {
    color: #000000;
  }
  .c24 {
    page-break-after: avoid;
  }
  .c26 {
    height: 11pt;
  }
  .c18 {
    font-size: 14pt;
  }
  .title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  li {
    color: #000000;
    font-size: 11pt;
    font-family: "Calibri";
  }
  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Calibri";
  }
  h1 {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 6pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h2 {
    padding-top: 18pt;
    color: #000000;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 4pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
}
