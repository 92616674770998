.wait-list-modal {
  padding-top: 24px;

  @include mobile {
    left: 0;
    margin: 0 !important;
    max-width: 100vw !important;
    padding: 0 !important;
    bottom: 0;
    top: auto !important;
    position: absolute !important;

    .ant-modal-content {
      border-radius: 16px 16px 0 0 !important;
    }
  }
  .ant-modal {
    &-content {
      @include mobile {
        overflow: hidden;
      }
    }
    &-header {
      padding: 24px 24px 0 24px !important;
      background: $white;
      border-bottom: none !important;

      @include mobile {
        padding: 16px !important;
      }
    }
    &-close {
      top: 24px;

      @include mobile {
        top: 16px;
        right: 16px;
        &-x {
          width: 24px;
          height: 24px;
          line-height: 24px;
        }
      }
    }
    &-body {
      padding: 16px 24px 24px 24px;

      @include mobile {
        padding: 8px 16px 16px 16px;
        max-height: calc(100vh - 130px);
        overflow-y: auto;
      }
    }
    &-footer {
      padding: 16px !important;
      border-top: none !important;
      background: $gray7;
    }
  }
  &__title {
    @include mobile {
      max-width: 299px;
    }
  }
  &__sub-title {
    max-width: 440px;

    @include mobile {
      max-width: 299px;
    }
  }
  .ant-form {
    &-item {
      input {
        border-width: 1px !important;
      }
      &-explain {
        margin-top: 4px;
        &-error {
          margin-top: 0 !important;
        }
      }
    }
  }
  .ant-form-item-has-error {
    :not(.ant-input-disabled).ant-input,
    :not(.ant-input-disabled).ant-input-affix-wrapper {
      border: 1px solid $red !important;
      border-right-width: 1px !important;

      &:focus,
      &:hover {
        border: 1px solid $primary !important;
      }
    }

    .react-tel-input {
      input.phone-input {
        border: 1px solid $red !important;

        + .phone-button-select {
          border-right: 1px solid $red !important;
        }
      }
    }

    .ant-input-affix-wrapper {
      :not(.ant-input-disabled).ant-input {
        border: unset !important;
        border-right-width: 1px !important;

        &:focus,
        &:hover {
          border: unset !important;
        }
      }
    }
  }

  .react-tel-input {
    .selected-flag {
      &:hover,
      &:focus,
      &:active {
        background-color: transparent !important;
      }
    }

    .flag-dropdown.open .selected-flag {
      background-color: transparent !important;
    }

    input.phone-input {
      border: 1px solid $mercury !important;

      &:focus,
      &:hover {
        border: 1px solid $primary !important;

        + .phone-button-select {
          border-right: 1px solid $primary !important;
        }
      }
    }
  }
}
