.d {
  &--flex {
    display: flex !important;

    &--row {
      display: flex !important;
      flex-direction: row;
    }

    &--column {
      display: flex !important;
      flex-direction: column;
    }

    &--wrap {
      flex-wrap: wrap;
    }
  }

  &--inline-grid {
    display: inline-grid;
  }

  &--block {
    display: block !important;
  }

  &--inline-block {
    display: inline-block !important;
  }

  &--none {
    display: none !important;
  }
}

.align__items {
  &--center {
    align-items: center;
  }

  &--stretch {
    align-items: stretch;
  }

  &--start {
    align-items: flex-start;
  }

  &--end {
    align-items: flex-end;
  }
}

.justify__content {
  &--center {
    justify-content: center;
  }

  &--start {
    justify-content: flex-start;
  }

  &--end {
    justify-content: flex-end;
  }

  &--space-between {
    justify-content: space-between;
  }

  &--space-around {
    justify-content: space-around;
  }
}

.flex__direction {
  &--row {
    flex-direction: row !important;
  }

  &--column {
    flex-direction: column !important;
  }
}

.position {
  &--relative {
    position: relative;
  }
  &--fixed {
    position: fixed;
  }
}

.line-height--unset {
  line-height: unset !important;
}
.borderless {
  border: 0 !important;
}
.opacity--05 {
  opacity: 0.5;
}
.opacity--03 {
  opacity: 0.3;
}
.opacity--1 {
  opacity: 1 !important;
}
.opacity--08 {
  opacity: 0.8;
}
.opacity--09 {
  opacity: 0.9;
}
.opacity--07 {
  opacity: 0.7;
}
.opacity--06 {
  opacity: 0.6;
}

.width--100 {
  width: 100%;
}
.underline {
  text-decoration-line: underline;
}

.visibility--hidden {
  visibility: hidden;
}

.overflow {
  &--visible {
    overflow: visible !important;
  }

  &--scroll {
    overflow: scroll !important;
  }

  &--hidden {
    overflow: hidden !important;
  }
}
