.tip-message {
  margin: 16px 0;

  &__info {
    background-color: $primary;
    border-radius: 16px 16px 0 0;
    padding: 16px 12px 12px;
    margin: 0 !important;

    .ant-typography {
      &:first-child {
        line-height: 16px;
      }

      &:last-child {
        line-height: 20px;
      }
    }
  }

  &__notes {
    background-color: $anakiwa;
    border-radius: 0 0 16px 16px;
    padding: 6px 16px;
  }

  &--non-price {
    .tip-message {
      &__info {
        border-radius: 16px;
      }

      &__notes {
        display: none;
      }
    }
  }
}
