// Background Color
.bg {
  &--primary {
    background-color: $primary;
  }
  &--default {
    background-color: $default;
  }

  &--red {
    background-color: $red;
  }

  &--blue {
    background-color: $blue;
  }

  &--white {
    background-color: $white;
  }
  &--gray7 {
    background-color: $gray7;
  }
}
