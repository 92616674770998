.upcoming-live-classes {
  padding: 32px;
  background-color: $default;
  margin-top: 70px;

  .ant-tabs {
    overflow: visible;

    &-tab {
      color: $white;
      margin-right: 60px;
    }

    &-nav {
      margin-bottom: 24px;

      &::before {
        border: none;
      }
    }
  }
}
