.available-slots {
  padding: 0;

  &__calendar {
    .ant-picker-calendar {
      background-color: $default !important;
    }

    .ant-picker-cell {
      padding: 0 !important;
      height: 32px;
      visibility: hidden;
      pointer-events: none;

      &:hover {
        .ant-picker-cell-inner {
          background-color: transparent !important;
        }
      }

      &.ant-picker-cell-in-view {
        visibility: visible;
        pointer-events: visible;

        .ant-picker-calendar-date-content {
          width: 4px;
          height: 4px;
          background: $blue;
          margin: auto;
          border-radius: 100%;
        }
      }
    }

    .btn-arrow {
      width: 24px !important;
      height: 24px !important;
      min-height: 24px !important;
      line-height: 24px;
      border: none;
    }

    .ant-picker-panel {
      border-top: none !important;
      background-color: $default !important;
      margin: 0 calc((100% - 80px) / 7 / 2 * -1);

      @include mobile {
        margin: 0 calc((100% - 158px) / 7 / 2 * -1);
      }

      tr:last-child {
        .ant-picker-cell {
          display: none;
        }

        .ant-picker-cell-in-view {
          display: table-cell;
        }
      }

      .ant-picker-content {
        border-collapse: separate;
        border-spacing: 0 24px;

        th {
          width: 40px;
          height: 22px;
          line-height: 22px;
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 500;
          color: $white;
        }
      }

      .ant-picker-body {
        padding: 0 !important;

        .ant-picker-cell-inner {
          color: $white;
          height: 32px;
          width: 32px;
          border-radius: 100%;
          padding: 4px 0;
          line-height: 24px;
          font-weight: 600;
          font-size: 16px;

          &::before {
            border: none !important;
          }
        }

        .ant-picker-cell-selected {
          .ant-picker-cell-inner {
            background-color: $blue;
          }

          &:hover {
            .ant-picker-cell-inner {
              background-color: $blue !important;
            }
          }

          &.ant-picker-cell-disabled {
            &:hover {
              .ant-picker-cell-inner {
                background-color: transparent !important;
              }
            }
          }
        }

        .ant-picker-calendar-date-today::before {
          border: none !important;
        }
      }
    }
  }

  &__time {
    .ant-skeleton-button {
      width: 183px !important;
      height: 40px !important;
      margin: 8px;

      @include mobile {
        width: 163px !important;
        height: 38px !important;
        margin: 8px;
      }
    }
    .ant-radio-group {
      margin: 0 -8px !important;
      border-radius: 124px;

      @include mobile {
        margin: 0 -8px !important;
        width: calc(100% + 16px);
      }

      .ant-radio-button-wrapper {
        width: 183px;
        text-align: center;
        margin: 8px;
        font-size: 16px !important;
        border-radius: 124px !important;
        color: $white !important;
        border: none !important;
        background: rgba($white, 0.1);
        z-index: 1 !important;
        padding: 0 !important;

        .ant-radio-button {
          border-radius: 124px;
          background-color: $default !important;
          width: calc(100% - 4px) !important;
          height: calc(100% - 4px) !important;
          top: 2px !important;
          left: 2px !important;

          span {
            line-height: 24px;
          }

          + span {
            margin-top: 2px;
            display: inline-block;

            @include mobile {
              margin-top: 0;
            }
          }
        }

        &::before {
          display: none;
        }

        @include mobile {
          height: 38px !important;
          width: 163px !important;
          padding: 0 8px !important;
          margin: 8px;
          font-size: 14px !important;
        }

        &-checked {
          background: linear-gradient(to left, #17e391, #58e5df, #60e5e8);
        }
      }
    }
  }

  .loading {
    width: 100%;
  }

  .ant-picker-cell-disabled {
    color: $white !important;

    .ant-picker-cell-inner {
      color: $white !important;
      background-color: transparent !important;
    }

    &::before {
      background: none;
    }

    .ant-picker-calendar-date-value {
      color: rgba($white, 0.3);
      font-weight: 500;
    }

    .ant-picker-calendar-date-content {
      display: none;
    }
  }

  .slots-remaining {
    background: rgba($white, 0.05);
    border-radius: 8px;
  }
}

.available-slots__timezone {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 124px;
  overflow: hidden;

  .ant-dropdown-trigger {
    height: 40px !important;
    background-color: transparent !important;
    border-radius: 124px !important;
    border: 1px solid rgba($white, 0.1) !important;
    display: flex;
    align-items: center;
    justify-content: center;

    span.dropdown {
      color: $white;
    }
  }

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 302px;
    height: 40px;
    padding: 8px 16px;
    border: solid 1px $border;
    border-radius: 124px;

    &-dropdown {
      user-select: none;
      font-size: 16px;
      font-weight: 500;
    }
  }

  &__overlay {
    z-index: 10002;
    min-width: 350px !important;
    background-color: $white;
    border-radius: 16px;
    box-shadow: $boxShadow2;
    overflow: hidden;

    .menu-timezone {
      height: 300px;
      overflow-y: auto;

      &__search {
        background-color: $white;
        position: sticky;
        top: 0;
        padding: 10px 12px;
        z-index: 10003;
      }

      &__parent {
        max-height: 300px;
      }

      &__item {
        padding: 0 16px;
        margin-bottom: 0 !important;

        &:last-child {
          margin-bottom: 24px;
        }
      }
    }
  }
}
