.live-event-card {
  width: 100%;
  cursor: pointer;
  border-radius: 12px;
  filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.1));
  background: $white;
  margin-bottom: 40px;
  position: relative;

  @include mobile {
    margin-bottom: 20px;
  }

  &__img-wrapper {
    > div {
      width: 100%;
    }
  }

  &__name {
    display: block;
    margin: 0 0 8px !important;
    text-align: center;

    @include mobile {
      margin: 8px 0;
    }
  }

  &__icon-play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 44px;
    height: 44px;
  }

  &__background-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__video-icon {
    position: absolute;
    top: calc(50% - 22px);
    left: calc(50% - 22px);
    width: 44px;
    height: 44px;
    border-radius: 22px;
    background: #000000;
    opacity: 0.6;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__tag {
    position: absolute;
    top: 12px;
    left: 12px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: $white;
    background-color: $red;
    border-radius: 4px;
    padding: 1px 6px;

    &.live_class {
      background-color: $orange;
    }
    &.interactive_live_class {
      background-color: $red;
    }

    &.exclusive_content {
      background-color: $green;
    }

    &.one_to_one {
      background-color: $blueDodger;
    }

    &.course {
      background-color: $lavender;
    }

    &.bundle {
      background-color: $pink;
    }
  }

  &__info {
    padding: 12px;
    &__price {
      color: rgba($white, 0.7) !important;
    }
  }

  &__name.title {
    margin: 12px 0 8px 0 !important;
  }

  &__owner {
    min-width: 0;

    &-name {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: rgba($default, 0.7) !important;

      @include mobile {
        font-size: 12px;
      }
    }
  }

  &__price {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    @include mobile {
      font-size: 14px;
    }
  }
  &__duration {
    position: absolute;
    right: 12px;
    bottom: 12px;
    background: rgba(36, 36, 36, 0.5);
    border-radius: 4px;
    padding: 1px 8px;
  }

  &__footer {
    position: absolute;
    bottom: 0;
    background: $border;
    width: 100%;
    height: 52px;

    &__name {
      line-height: 52px;
    }

    .ant-avatar {
      position: absolute;
      bottom: 36px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 36px !important;
      height: 36px !important;
      border: 2px solid white;
    }
  }

  &--experience {
    .live-event-card__background-img {
      border-radius: 0 0 20px 20px;
    }

    .live-event-card__background-gradient {
      bottom: 52px;
      height: 128px;
      top: auto;
    }

    .live-event-card__info {
      bottom: 78px;
    }
  }
}
