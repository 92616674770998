.privacy {
  @import url("https://themes.googleusercontent.com/fonts/css?kit=MSSLfUayeNh9PW3ng9UWrqo0P1CSBNc3gBWclSzSx0c");
  .lst-kix_list_4-1 > li {
    counter-increment: lst-ctn-kix_list_4-1;
  }
  ul.lst-kix_list_1-0 {
    list-style-type: none;
  }
  .lst-kix_list_3-0 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_3-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_3-2 > li:before {
    content: "\0025aa  ";
  }
  ol.lst-kix_list_1-8.start {
    counter-reset: lst-ctn-kix_list_1-8 0;
  }
  .lst-kix_list_4-0 > li {
    counter-increment: lst-ctn-kix_list_4-0;
  }
  ul.lst-kix_list_1-3 {
    list-style-type: none;
  }
  .lst-kix_list_3-5 > li:before {
    content: "\0025aa  ";
  }
  ul.lst-kix_list_1-1 {
    list-style-type: none;
  }
  .lst-kix_list_3-4 > li:before {
    content: "o  ";
  }
  ul.lst-kix_list_1-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-5.start {
    counter-reset: lst-ctn-kix_list_1-5 0;
  }
  .lst-kix_list_3-3 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_3-8 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_3-6 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_4-3 > li {
    counter-increment: lst-ctn-kix_list_4-3;
  }
  .lst-kix_list_3-7 > li:before {
    content: "o  ";
  }
  ol.lst-kix_list_4-5.start {
    counter-reset: lst-ctn-kix_list_4-5 0;
  }
  ol.lst-kix_list_4-2.start {
    counter-reset: lst-ctn-kix_list_4-2 0;
  }
  .lst-kix_list_1-4 > li {
    counter-increment: lst-ctn-kix_list_1-4;
  }
  .lst-kix_list_4-4 > li {
    counter-increment: lst-ctn-kix_list_4-4;
  }
  ol.lst-kix_list_1-6.start {
    counter-reset: lst-ctn-kix_list_1-6 0;
  }
  .lst-kix_list_4-8 > li:before {
    content: " ";
  }
  .lst-kix_list_4-7 > li:before {
    content: " ";
  }
  ol.lst-kix_list_4-1.start {
    counter-reset: lst-ctn-kix_list_4-1 0;
  }
  ol.lst-kix_list_4-8.start {
    counter-reset: lst-ctn-kix_list_4-8 0;
  }
  ol.lst-kix_list_4-0.start {
    counter-reset: lst-ctn-kix_list_4-0 0;
  }
  li.li-bullet-2:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }
  ol.lst-kix_list_4-7.start {
    counter-reset: lst-ctn-kix_list_4-7 0;
  }
  ol.lst-kix_list_1-4 {
    list-style-type: none;
  }
  .lst-kix_list_2-6 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_2-7 > li:before {
    content: "o  ";
  }
  ol.lst-kix_list_1-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-6 {
    list-style-type: none;
  }
  .lst-kix_list_2-4 > li:before {
    content: "o  ";
  }
  .lst-kix_list_2-5 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_2-8 > li:before {
    content: "\0025aa  ";
  }
  ul.lst-kix_list_3-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_4-6.start {
    counter-reset: lst-ctn-kix_list_4-6 0;
  }
  ul.lst-kix_list_3-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_4-3.start {
    counter-reset: lst-ctn-kix_list_4-3 0;
  }
  ol.lst-kix_list_1-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-5 {
    list-style-type: none;
  }
  .lst-kix_list_4-7 > li {
    counter-increment: lst-ctn-kix_list_4-7;
  }
  .lst-kix_list_1-7 > li {
    counter-increment: lst-ctn-kix_list_1-7;
  }
  ol.lst-kix_list_1-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-4 {
    list-style-type: none;
  }
  li.li-bullet-1:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }
  .lst-kix_list_4-0 > li:before {
    content: "" counter(lst-ctn-kix_list_4-0, decimal) ". ";
  }
  .lst-kix_list_4-1 > li:before {
    content: "" counter(lst-ctn-kix_list_4-0, decimal) "." counter(lst-ctn-kix_list_4-1, decimal) " ";
  }
  li.li-bullet-3:before {
    margin-left: -28.4pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 28.4pt;
  }
  .lst-kix_list_4-6 > li {
    counter-increment: lst-ctn-kix_list_4-6;
  }
  ol.lst-kix_list_1-7.start {
    counter-reset: lst-ctn-kix_list_1-7 0;
  }
  .lst-kix_list_4-4 > li:before {
    content: "(" counter(lst-ctn-kix_list_4-4, lower-roman) ") ";
  }
  .lst-kix_list_1-5 > li {
    counter-increment: lst-ctn-kix_list_1-5;
  }
  .lst-kix_list_4-3 > li:before {
    content: "(" counter(lst-ctn-kix_list_4-3, lower-latin) ") ";
  }
  .lst-kix_list_4-5 > li:before {
    content: " ";
  }
  .lst-kix_list_4-2 > li:before {
    content: "" counter(lst-ctn-kix_list_4-0, decimal) "." counter(lst-ctn-kix_list_4-1, decimal) "."
      counter(lst-ctn-kix_list_4-2, decimal) " ";
  }
  .lst-kix_list_4-6 > li:before {
    content: " ";
  }
  .lst-kix_list_1-8 > li {
    counter-increment: lst-ctn-kix_list_1-8;
  }
  ol.lst-kix_list_1-4.start {
    counter-reset: lst-ctn-kix_list_1-4 0;
  }
  ol.lst-kix_list_4-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_4-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_4-4.start {
    counter-reset: lst-ctn-kix_list_4-4 0;
  }
  ol.lst-kix_list_4-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_4-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_4-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-2 {
    list-style-type: none;
  }
  .lst-kix_list_1-0 > li:before {
    content: "\0025cf  ";
  }
  ul.lst-kix_list_2-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_4-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_4-5 {
    list-style-type: none;
  }
  .lst-kix_list_1-1 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_1-2 > li:before {
    content: "\0025cf  ";
  }
  ul.lst-kix_list_2-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_4-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_4-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-5 {
    list-style-type: none;
  }
  .lst-kix_list_1-3 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_1-4 > li:before {
    content: " ";
  }
  .lst-kix_list_4-8 > li {
    counter-increment: lst-ctn-kix_list_4-8;
  }
  .lst-kix_list_1-6 > li {
    counter-increment: lst-ctn-kix_list_1-6;
  }
  .lst-kix_list_1-7 > li:before {
    content: " ";
  }
  .lst-kix_list_1-5 > li:before {
    content: " ";
  }
  .lst-kix_list_1-6 > li:before {
    content: " ";
  }
  li.li-bullet-0:before {
    margin-left: -42.5pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 42.5pt;
  }
  li.li-bullet-4:before {
    margin-left: -28.4pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 28.4pt;
  }
  .lst-kix_list_2-0 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_2-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_4-5 > li {
    counter-increment: lst-ctn-kix_list_4-5;
  }
  .lst-kix_list_1-8 > li:before {
    content: " ";
  }
  .lst-kix_list_2-2 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_2-3 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_4-2 > li {
    counter-increment: lst-ctn-kix_list_4-2;
  }
  ol {
    margin: 0;
    padding: 0;
  }
  table td,
  table th {
    padding: 0;
  }
  .c25 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 169.8pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c23 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 170.2pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c20 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 127.8pt;
    border-top-color: #f0f0f0;
    border-bottom-style: solid;
  }
  .c16 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 118.8pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c36 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 88.6pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c32 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 258.7pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c34 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 14pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c3 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 7pt;
    font-family: "Verdana";
    font-style: normal;
  }
  .c45 {
    background-color: #ffffff;
    padding-top: 14pt;
    padding-bottom: 0pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c1 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Verdana";
    font-style: normal;
  }
  .c21 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c26 {
    -webkit-text-decoration-skip: none;
    font-weight: 400;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-family: "Verdana";
    font-style: normal;
  }
  .c35 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 14pt;
    font-family: "Verdana";
    font-style: normal;
  }
  .c37 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: center;
  }
  .c48 {
    padding-top: 0pt;
    padding-bottom: 10pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: center;
  }
  .c28 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .c0 {
    padding-top: 18pt;
    padding-bottom: 18pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: center;
  }
  .c46 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .c2 {
    padding-top: 0pt;
    padding-bottom: 10pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .c42 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c10 {
    text-decoration-skip-ink: none;
    font-size: 11pt;
    -webkit-text-decoration-skip: none;
    color: #0000ff;
    text-decoration: underline;
  }
  .c39 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-family: "Verdana";
    font-style: normal;
  }
  .c41 {
    margin-left: -5.8pt;
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto;
  }
  .c8 {
    margin-left: auto;
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto;
  }
  .c49 {
    text-decoration: none;
    vertical-align: baseline;
    font-family: "Verdana";
    font-style: normal;
  }
  .c31 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    text-decoration: underline;
  }
  .c43 {
    background-color: #ffffff;
    max-width: 453.5pt;
    padding: 70.9pt 70.9pt 70.9pt 70.9pt;
  }
  .c14 {
    color: inherit;
    text-decoration: inherit;
  }
  .c12 {
    margin-left: 60.5pt;
    padding-left: 24.5pt;
  }
  .c24 {
    font-size: 12pt;
    font-style: italic;
  }
  .c5 {
    font-size: 14pt;
    font-weight: 700;
  }
  .c17 {
    font-size: 11pt;
    font-style: italic;
  }
  .c13 {
    margin-left: 18pt;
    padding-left: 0pt;
  }
  .c6 {
    margin-left: 18pt;
    padding-left: 24.5pt;
  }
  .c15 {
    margin-left: 60.5pt;
    padding-left: 10.4pt;
  }
  .c4 {
    color: #000000;
    font-size: 11pt;
  }
  .c9 {
    padding: 0;
    margin: 0;
  }
  .c44 {
    margin-left: 1.1pt;
    text-indent: -1.1pt;
  }
  .c33 {
    font-size: 12pt;
  }
  .c7 {
    height: 16.9pt;
  }
  .c27 {
    height: 16.6pt;
  }
  .c30 {
    font-size: 11pt;
  }
  .c38 {
    height: 0pt;
  }
  .c50 {
    height: 68.5pt;
  }
  .c29 {
    height: 10pt;
  }
  .c18 {
    page-break-after: avoid;
  }
  .c51 {
    font-size: 7pt;
  }
  .c19 {
    margin-left: 0.1pt;
  }
  .c22 {
    color: #000000;
  }
  .c47 {
    color: #1155cc;
  }
  .c11 {
    font-weight: 700;
  }
  .c40 {
    background-color: #d9d9d9;
  }
  .title {
    padding-top: 0pt;
    color: #000000;
    font-size: 28pt;
    padding-bottom: 0pt;
    font-family: "Cambria";
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .subtitle {
    padding-top: 0pt;
    color: #5a5a5a;
    font-size: 11pt;
    padding-bottom: 8pt;
    font-family: "Calibri";
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  li {
    color: #000000;
    font-size: 10pt;
    font-family: "Verdana";
  }
  p {
    margin: 0;
    color: #000000;
    font-size: 10pt;
    font-family: "Verdana";
  }
  h1 {
    padding-top: 12pt;
    color: #366091;
    font-size: 16pt;
    padding-bottom: 0pt;
    font-family: "Cambria";
    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  h2 {
    padding-top: 2pt;
    color: #366091;
    font-size: 13pt;
    padding-bottom: 0pt;
    font-family: "Cambria";
    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  h3 {
    padding-top: 2pt;
    color: #243f61;
    font-size: 12pt;
    padding-bottom: 0pt;
    font-family: "Cambria";
    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;
    font-family: "Verdana";
    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;
    font-family: "Verdana";
    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    font-family: "Verdana";
    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
}
.talent-privacy {
  .lst-kix_list_2-1 > li {
    counter-increment: lst-ctn-kix_list_2-1;
  }
  ol.lst-kix_list_38-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_38-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_38-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_38-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_38-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_38-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_38-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_38-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_38-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_13-4.start {
    counter-reset: lst-ctn-kix_list_13-4 0;
  }
  ul.lst-kix_list_9-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_40-3.start {
    counter-reset: lst-ctn-kix_list_40-3 0;
  }
  .lst-kix_list_42-8 > li:before {
    content: "" counter(lst-ctn-kix_list_42-8, lower-roman) ". ";
  }
  ul.lst-kix_list_9-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-2 {
    list-style-type: none;
  }
  .lst-kix_list_13-0 > li {
    counter-increment: lst-ctn-kix_list_13-0;
  }
  ul.lst-kix_list_9-5 {
    list-style-type: none;
  }
  .lst-kix_list_5-0 > li {
    counter-increment: lst-ctn-kix_list_5-0;
  }
  ol.lst-kix_list_2-3.start {
    counter-reset: lst-ctn-kix_list_2-3 0;
  }
  ol.lst-kix_list_37-2.start {
    counter-reset: lst-ctn-kix_list_37-2 0;
  }
  ol.lst-kix_list_7-6 {
    list-style-type: none;
  }
  .lst-kix_list_45-2 > li {
    counter-increment: lst-ctn-kix_list_45-2;
  }
  ol.lst-kix_list_7-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_7-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-0 {
    list-style-type: none;
  }
  .lst-kix_list_42-0 > li:before {
    content: "" counter(lst-ctn-kix_list_42-0, decimal) ") ";
  }
  .lst-kix_list_42-1 > li:before {
    content: "" counter(lst-ctn-kix_list_42-1, lower-latin) ". ";
  }
  .lst-kix_list_42-2 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_40-1 > li {
    counter-increment: lst-ctn-kix_list_40-1;
  }
  ol.lst-kix_list_46-8.start {
    counter-reset: lst-ctn-kix_list_46-8 0;
  }
  ol.lst-kix_list_5-3.start {
    counter-reset: lst-ctn-kix_list_5-3 0;
  }
  .lst-kix_list_38-6 > li {
    counter-increment: lst-ctn-kix_list_38-6;
  }
  .lst-kix_list_4-3 > li {
    counter-increment: lst-ctn-kix_list_4-3;
  }
  .lst-kix_list_42-7 > li:before {
    content: "" counter(lst-ctn-kix_list_42-7, lower-latin) ". ";
  }
  ol.lst-kix_list_17-1.start {
    counter-reset: lst-ctn-kix_list_17-1 0;
  }
  .lst-kix_list_42-6 > li:before {
    content: "" counter(lst-ctn-kix_list_42-6, decimal) ". ";
  }
  .lst-kix_list_42-4 > li:before {
    content: "" counter(lst-ctn-kix_list_42-4, lower-latin) ". ";
  }
  .lst-kix_list_42-5 > li:before {
    content: "" counter(lst-ctn-kix_list_42-5, lower-roman) ". ";
  }
  ol.lst-kix_list_8-8.start {
    counter-reset: lst-ctn-kix_list_8-8 0;
  }
  .lst-kix_list_42-3 > li:before {
    content: "" counter(lst-ctn-kix_list_42-3, decimal) ". ";
  }
  ol.lst-kix_list_36-5.start {
    counter-reset: lst-ctn-kix_list_36-5 0;
  }
  .lst-kix_list_24-7 > li:before {
    content: "o  ";
  }
  ol.lst-kix_list_16-5 {
    list-style-type: none;
  }
  .lst-kix_list_1-4 > li {
    counter-increment: lst-ctn-kix_list_1-4;
  }
  ul.lst-kix_list_27-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_16-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_27-1 {
    list-style-type: none;
  }
  .lst-kix_list_24-8 > li:before {
    content: "\0025aa  ";
  }
  ol.lst-kix_list_16-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-6.start {
    counter-reset: lst-ctn-kix_list_1-6 0;
  }
  ul.lst-kix_list_27-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_16-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_16-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_40-8.start {
    counter-reset: lst-ctn-kix_list_40-8 0;
  }
  ol.lst-kix_list_16-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_16-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_16-4 {
    list-style-type: none;
  }
  .lst-kix_list_24-2 > li:before {
    content: "\0025aa  ";
  }
  ol.lst-kix_list_16-0 {
    list-style-type: none;
  }
  .lst-kix_list_24-3 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_24-4 > li:before {
    content: "o  ";
  }
  ul.lst-kix_list_27-3 {
    list-style-type: none;
  }
  .lst-kix_list_24-5 > li:before {
    content: "\0025aa  ";
  }
  ul.lst-kix_list_27-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_27-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_27-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_27-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_27-8 {
    list-style-type: none;
  }
  .lst-kix_list_24-6 > li:before {
    content: "\0025cf  ";
  }
  ol.lst-kix_list_16-4.start {
    counter-reset: lst-ctn-kix_list_16-4 0;
  }
  ol.lst-kix_list_14-1.start {
    counter-reset: lst-ctn-kix_list_14-1 0;
  }
  .lst-kix_list_23-6 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_23-3 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_23-7 > li:before {
    content: "o  ";
  }
  .lst-kix_list_23-2 > li:before {
    content: "\0025aa  ";
  }
  ol.lst-kix_list_46-3.start {
    counter-reset: lst-ctn-kix_list_46-3 0;
  }
  .lst-kix_list_23-0 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_23-8 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_3-6 > li {
    counter-increment: lst-ctn-kix_list_3-6;
  }
  ol.lst-kix_list_44-0.start {
    counter-reset: lst-ctn-kix_list_44-0 0;
  }
  .lst-kix_list_23-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_24-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_2-8 > li {
    counter-increment: lst-ctn-kix_list_2-8;
  }
  .lst-kix_list_24-0 > li:before {
    content: "o  ";
  }
  .lst-kix_list_23-4 > li:before {
    content: "o  ";
  }
  .lst-kix_list_23-5 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_43-3 > li:before {
    content: "\0025cf  ";
  }
  ol.lst-kix_list_31-2.start {
    counter-reset: lst-ctn-kix_list_31-2 0;
  }
  .lst-kix_list_22-2 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_22-6 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_43-7 > li:before {
    content: "o  ";
  }
  ol.lst-kix_list_36-0.start {
    counter-reset: lst-ctn-kix_list_36-0 0;
  }
  .lst-kix_list_43-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_8-6 > li {
    counter-increment: lst-ctn-kix_list_8-6;
  }
  .lst-kix_list_22-0 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_22-8 > li:before {
    content: "\0025aa  ";
  }
  ol.lst-kix_list_4-6.start {
    counter-reset: lst-ctn-kix_list_4-6 0;
  }
  ol.lst-kix_list_9-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_9-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_39-5.start {
    counter-reset: lst-ctn-kix_list_39-5 0;
  }
  ol.lst-kix_list_3-0.start {
    counter-reset: lst-ctn-kix_list_3-0 0;
  }
  .lst-kix_list_5-7 > li {
    counter-increment: lst-ctn-kix_list_5-7;
  }
  ol.lst-kix_list_9-6 {
    list-style-type: none;
  }
  .lst-kix_list_43-5 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_22-4 > li:before {
    content: "o  ";
  }
  .lst-kix_list_41-7 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_25-5 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_25-7 > li:before {
    content: "o  ";
  }
  .lst-kix_list_3-5 > li {
    counter-increment: lst-ctn-kix_list_3-5;
  }
  ol.lst-kix_list_1-1.start {
    counter-reset: lst-ctn-kix_list_1-1 0;
  }
  ol.lst-kix_list_45-2.start {
    counter-reset: lst-ctn-kix_list_45-2 0;
  }
  .lst-kix_list_41-1 > li:before {
    content: "" counter(lst-ctn-kix_list_41-0, decimal) "." counter(lst-ctn-kix_list_41-1, decimal) " ";
  }
  ul.lst-kix_list_29-0 {
    list-style-type: none;
  }
  .lst-kix_list_40-7 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_40-5 > li:before {
    content: "Not Defined ";
  }
  ol.lst-kix_list_15-2.start {
    counter-reset: lst-ctn-kix_list_15-2 0;
  }
  ol.lst-kix_list_42-1.start {
    counter-reset: lst-ctn-kix_list_42-1 0;
  }
  .lst-kix_list_41-5 > li:before {
    content: "Not Defined ";
  }
  ol.lst-kix_list_37-7.start {
    counter-reset: lst-ctn-kix_list_37-7 0;
  }
  ol.lst-kix_list_2-8.start {
    counter-reset: lst-ctn-kix_list_2-8 0;
  }
  ol.lst-kix_list_7-6.start {
    counter-reset: lst-ctn-kix_list_7-6 0;
  }
  ul.lst-kix_list_29-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_29-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_29-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_29-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_29-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_29-6 {
    list-style-type: none;
  }
  .lst-kix_list_41-3 > li:before {
    content: "(" counter(lst-ctn-kix_list_41-3, lower-latin) ") ";
  }
  ul.lst-kix_list_29-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_29-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_15-3.start {
    counter-reset: lst-ctn-kix_list_15-3 0;
  }
  ul.lst-kix_list_18-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_5-8.start {
    counter-reset: lst-ctn-kix_list_5-8 0;
  }
  .lst-kix_list_1-3 > li {
    counter-increment: lst-ctn-kix_list_1-3;
  }
  .lst-kix_list_40-3 > li:before {
    content: "(" counter(lst-ctn-kix_list_40-3, lower-latin) ") ";
  }
  ul.lst-kix_list_18-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_18-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_18-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_12-2.start {
    counter-reset: lst-ctn-kix_list_12-2 0;
  }
  ul.lst-kix_list_18-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_18-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_18-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_18-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_18-1 {
    list-style-type: none;
  }
  .lst-kix_list_40-1 > li:before {
    content: "" counter(lst-ctn-kix_list_40-0, decimal) "." counter(lst-ctn-kix_list_40-1, decimal) " ";
  }
  .lst-kix_list_4-2 > li {
    counter-increment: lst-ctn-kix_list_4-2;
  }
  ol.lst-kix_list_3-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_38-3.start {
    counter-reset: lst-ctn-kix_list_38-3 0;
  }
  ol.lst-kix_list_3-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-4.start {
    counter-reset: lst-ctn-kix_list_3-4 0;
  }
  ul.lst-kix_list_47-0 {
    list-style-type: none;
  }
  .lst-kix_list_5-1 > li {
    counter-increment: lst-ctn-kix_list_5-1;
  }
  ol.lst-kix_list_3-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_47-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_47-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_47-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_47-3 {
    list-style-type: none;
  }
  .lst-kix_list_38-5 > li {
    counter-increment: lst-ctn-kix_list_38-5;
  }
  .lst-kix_list_21-8 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_26-5 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_16-0 > li {
    counter-increment: lst-ctn-kix_list_16-0;
  }
  ul.lst-kix_list_47-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_47-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_47-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_47-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-6 {
    list-style-type: none;
  }
  .lst-kix_list_26-8 > li:before {
    content: "\0025aa  ";
  }
  ol.lst-kix_list_3-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-8 {
    list-style-type: none;
  }
  .lst-kix_list_21-0 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_26-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_13-1 > li {
    counter-increment: lst-ctn-kix_list_13-1;
  }
  .lst-kix_list_21-1 > li:before {
    content: "o  ";
  }
  ol.lst-kix_list_15-8.start {
    counter-reset: lst-ctn-kix_list_15-8 0;
  }
  ol.lst-kix_list_45-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_45-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_45-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_45-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_45-3 {
    list-style-type: none;
  }
  .lst-kix_list_45-3 > li {
    counter-increment: lst-ctn-kix_list_45-3;
  }
  .lst-kix_list_26-4 > li:before {
    content: "o  ";
  }
  ol.lst-kix_list_45-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_45-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_45-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_45-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_36-1.start {
    counter-reset: lst-ctn-kix_list_36-1 0;
  }
  .lst-kix_list_42-4 > li {
    counter-increment: lst-ctn-kix_list_42-4;
  }
  ol.lst-kix_list_45-7.start {
    counter-reset: lst-ctn-kix_list_45-7 0;
  }
  .lst-kix_list_21-5 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_21-4 > li:before {
    content: "o  ";
  }
  .lst-kix_list_26-0 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_31-4 > li {
    counter-increment: lst-ctn-kix_list_31-4;
  }
  ul.lst-kix_list_23-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_23-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_23-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_23-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_23-4 {
    list-style-type: none;
  }
  .lst-kix_list_31-2 > li {
    counter-increment: lst-ctn-kix_list_31-2;
  }
  ol.lst-kix_list_38-8.start {
    counter-reset: lst-ctn-kix_list_38-8 0;
  }
  ul.lst-kix_list_23-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_23-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_12-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_12-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_12-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_12-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_12-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_12-2 {
    list-style-type: none;
  }
  .lst-kix_list_45-2 > li:before {
    content: "" counter(lst-ctn-kix_list_45-2, lower-roman) ". ";
  }
  ol.lst-kix_list_12-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_39-6.start {
    counter-reset: lst-ctn-kix_list_39-6 0;
  }
  ol.lst-kix_list_12-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_45-6.start {
    counter-reset: lst-ctn-kix_list_45-6 0;
  }
  ol.lst-kix_list_12-0 {
    list-style-type: none;
  }
  .lst-kix_list_25-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_45-5 > li:before {
    content: "" counter(lst-ctn-kix_list_45-5, lower-roman) ". ";
  }
  ul.lst-kix_list_23-7 {
    list-style-type: none;
  }
  .lst-kix_list_25-0 > li:before {
    content: "\0025cf  ";
  }
  ul.lst-kix_list_23-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_10-8.start {
    counter-reset: lst-ctn-kix_list_10-8 0;
  }
  ol.lst-kix_list_31-1.start {
    counter-reset: lst-ctn-kix_list_31-1 0;
  }
  .lst-kix_list_45-6 > li:before {
    content: "" counter(lst-ctn-kix_list_45-6, decimal) ". ";
  }
  .lst-kix_list_44-6 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_39-0 > li:before {
    content: "" counter(lst-ctn-kix_list_39-0, decimal) ". ";
  }
  .lst-kix_list_44-2 > li:before {
    content: "" counter(lst-ctn-kix_list_44-0, decimal) "." counter(lst-ctn-kix_list_44-1, decimal) "."
      counter(lst-ctn-kix_list_44-2, decimal) " ";
  }
  .lst-kix_list_39-1 > li:before {
    content: "" counter(lst-ctn-kix_list_39-0, decimal) "." counter(lst-ctn-kix_list_39-1, decimal) " ";
  }
  ol.lst-kix_list_40-7.start {
    counter-reset: lst-ctn-kix_list_40-7 0;
  }
  .lst-kix_list_37-0 > li {
    counter-increment: lst-ctn-kix_list_37-0;
  }
  .lst-kix_list_16-7 > li {
    counter-increment: lst-ctn-kix_list_16-7;
  }
  .lst-kix_list_44-1 > li:before {
    content: "" counter(lst-ctn-kix_list_44-0, decimal) "." counter(lst-ctn-kix_list_44-1, decimal) " ";
  }
  .lst-kix_list_45-1 > li:before {
    content: "" counter(lst-ctn-kix_list_45-1, lower-latin) ". ";
  }
  .lst-kix_list_45-1 > li {
    counter-increment: lst-ctn-kix_list_45-1;
  }
  .lst-kix_list_38-7 > li {
    counter-increment: lst-ctn-kix_list_38-7;
  }
  .lst-kix_list_13-8 > li {
    counter-increment: lst-ctn-kix_list_13-8;
  }
  .lst-kix_list_2-2 > li {
    counter-increment: lst-ctn-kix_list_2-2;
  }
  .lst-kix_list_44-5 > li:before {
    content: "Not Defined ";
  }
  ol.lst-kix_list_4-7.start {
    counter-reset: lst-ctn-kix_list_4-7 0;
  }
  .lst-kix_list_16-5 > li {
    counter-increment: lst-ctn-kix_list_16-5;
  }
  ol.lst-kix_list_5-0 {
    list-style-type: none;
  }
  .lst-kix_list_3-7 > li {
    counter-increment: lst-ctn-kix_list_3-7;
  }
  ol.lst-kix_list_5-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_5-2 {
    list-style-type: none;
  }
  .lst-kix_list_40-8 > li {
    counter-increment: lst-ctn-kix_list_40-8;
  }
  .lst-kix_list_22-5 > li:before {
    content: "\0025aa  ";
  }
  ol.lst-kix_list_36-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_36-7 {
    list-style-type: none;
  }
  .lst-kix_list_43-2 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_43-6 > li:before {
    content: "\0025cf  ";
  }
  ol.lst-kix_list_36-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_36-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_36-6 {
    list-style-type: none;
  }
  .lst-kix_list_45-8 > li {
    counter-increment: lst-ctn-kix_list_45-8;
  }
  ol.lst-kix_list_36-5 {
    list-style-type: none;
  }
  .lst-kix_list_22-1 > li:before {
    content: "o  ";
  }
  ol.lst-kix_list_36-0 {
    list-style-type: none;
  }
  .lst-kix_list_27-4 > li:before {
    content: "o  ";
  }
  .lst-kix_list_6-6 > li {
    counter-increment: lst-ctn-kix_list_6-6;
  }
  ol.lst-kix_list_36-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_15-7.start {
    counter-reset: lst-ctn-kix_list_15-7 0;
  }
  ol.lst-kix_list_36-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-4 {
    list-style-type: none;
  }
  .lst-kix_list_13-6 > li {
    counter-increment: lst-ctn-kix_list_13-6;
  }
  ol.lst-kix_list_14-6.start {
    counter-reset: lst-ctn-kix_list_14-6 0;
  }
  .lst-kix_list_39-4 > li:before {
    content: "(" counter(lst-ctn-kix_list_39-4, lower-roman) ") ";
  }
  .lst-kix_list_39-8 > li:before {
    content: "Not Defined ";
  }
  ol.lst-kix_list_5-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_5-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_5-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-1 {
    list-style-type: none;
  }
  .lst-kix_list_8-7 > li {
    counter-increment: lst-ctn-kix_list_8-7;
  }
  ol.lst-kix_list_5-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-2 {
    list-style-type: none;
  }
  .lst-kix_list_27-0 > li:before {
    content: "\0025cf  ";
  }
  ol.lst-kix_list_5-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_5-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-0 {
    list-style-type: none;
  }
  .lst-kix_list_5-8 > li {
    counter-increment: lst-ctn-kix_list_5-8;
  }
  ol.lst-kix_list_44-5.start {
    counter-reset: lst-ctn-kix_list_44-5 0;
  }
  .lst-kix_list_41-6 > li {
    counter-increment: lst-ctn-kix_list_41-6;
  }
  .lst-kix_list_38-0 > li {
    counter-increment: lst-ctn-kix_list_38-0;
  }
  ol.lst-kix_list_2-2.start {
    counter-reset: lst-ctn-kix_list_2-2 0;
  }
  .lst-kix_list_25-4 > li:before {
    content: "o  ";
  }
  .lst-kix_list_46-5 > li:before {
    content: "" counter(lst-ctn-kix_list_46-5, lower-roman) ". ";
  }
  .lst-kix_list_25-8 > li:before {
    content: "\0025aa  ";
  }
  ol.lst-kix_list_38-4.start {
    counter-reset: lst-ctn-kix_list_38-4 0;
  }
  ul.lst-kix_list_25-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_14-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_25-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_14-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_25-2 {
    list-style-type: none;
  }
  .lst-kix_list_44-5 > li {
    counter-increment: lst-ctn-kix_list_44-5;
  }
  ul.lst-kix_list_25-3 {
    list-style-type: none;
  }
  .lst-kix_list_37-2 > li {
    counter-increment: lst-ctn-kix_list_37-2;
  }
  ul.lst-kix_list_25-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_14-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_14-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_14-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_14-6 {
    list-style-type: none;
  }
  .lst-kix_list_15-2 > li {
    counter-increment: lst-ctn-kix_list_15-2;
  }
  ol.lst-kix_list_14-0 {
    list-style-type: none;
  }
  .lst-kix_list_40-6 > li:before {
    content: "Not Defined ";
  }
  ol.lst-kix_list_44-4.start {
    counter-reset: lst-ctn-kix_list_44-4 0;
  }
  ol.lst-kix_list_14-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_14-2 {
    list-style-type: none;
  }
  .lst-kix_list_41-6 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_20-5 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_28-8 > li:before {
    content: "\0025aa  ";
  }
  ul.lst-kix_list_25-5 {
    list-style-type: none;
  }
  .lst-kix_list_41-2 > li:before {
    content: "" counter(lst-ctn-kix_list_41-0, decimal) "." counter(lst-ctn-kix_list_41-1, decimal) "."
      counter(lst-ctn-kix_list_41-2, decimal) " ";
  }
  ul.lst-kix_list_25-6 {
    list-style-type: none;
  }
  .lst-kix_list_20-1 > li:before {
    content: "o  ";
  }
  ul.lst-kix_list_25-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_25-8 {
    list-style-type: none;
  }
  .lst-kix_list_12-3 > li {
    counter-increment: lst-ctn-kix_list_12-3;
  }
  .lst-kix_list_17-3 > li {
    counter-increment: lst-ctn-kix_list_17-3;
  }
  .lst-kix_list_28-4 > li:before {
    content: "o  ";
  }
  .lst-kix_list_46-6 > li {
    counter-increment: lst-ctn-kix_list_46-6;
  }
  .lst-kix_list_39-3 > li {
    counter-increment: lst-ctn-kix_list_39-3;
  }
  ol.lst-kix_list_3-5.start {
    counter-reset: lst-ctn-kix_list_3-5 0;
  }
  ol.lst-kix_list_13-0.start {
    counter-reset: lst-ctn-kix_list_13-0 0;
  }
  .lst-kix_list_14-4 > li {
    counter-increment: lst-ctn-kix_list_14-4;
  }
  .lst-kix_list_10-7 > li {
    counter-increment: lst-ctn-kix_list_10-7;
  }
  .lst-kix_list_28-0 > li:before {
    content: "\0025cf  ";
  }
  ol.lst-kix_list_14-5.start {
    counter-reset: lst-ctn-kix_list_14-5 0;
  }
  .lst-kix_list_27-8 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_40-2 > li:before {
    content: "" counter(lst-ctn-kix_list_40-0, decimal) "." counter(lst-ctn-kix_list_40-1, decimal) "."
      counter(lst-ctn-kix_list_40-2, decimal) " ";
  }
  ol.lst-kix_list_37-1.start {
    counter-reset: lst-ctn-kix_list_37-1 0;
  }
  .lst-kix_list_36-4 > li {
    counter-increment: lst-ctn-kix_list_36-4;
  }
  .lst-kix_list_4-1 > li {
    counter-increment: lst-ctn-kix_list_4-1;
  }
  ul.lst-kix_list_43-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_43-1 {
    list-style-type: none;
  }
  .lst-kix_list_19-1 > li:before {
    content: "o  ";
  }
  ul.lst-kix_list_43-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_46-2.start {
    counter-reset: lst-ctn-kix_list_46-2 0;
  }
  ul.lst-kix_list_43-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_43-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_43-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_43-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_43-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_41-1.start {
    counter-reset: lst-ctn-kix_list_41-1 0;
  }
  .lst-kix_list_19-4 > li:before {
    content: "o  ";
  }
  ol.lst-kix_list_37-8.start {
    counter-reset: lst-ctn-kix_list_37-8 0;
  }
  .lst-kix_list_31-5 > li {
    counter-increment: lst-ctn-kix_list_31-5;
  }
  .lst-kix_list_19-3 > li:before {
    content: "\0025cf  ";
  }
  ul.lst-kix_list_43-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_38-0.start {
    counter-reset: lst-ctn-kix_list_38-0 0;
  }
  .lst-kix_list_15-0 > li {
    counter-increment: lst-ctn-kix_list_15-0;
  }
  ol.lst-kix_list_6-6.start {
    counter-reset: lst-ctn-kix_list_6-6 0;
  }
  .lst-kix_list_47-5 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_39-5 > li {
    counter-increment: lst-ctn-kix_list_39-5;
  }
  .lst-kix_list_47-6 > li:before {
    content: "\0025cf  ";
  }
  ol.lst-kix_list_1-5.start {
    counter-reset: lst-ctn-kix_list_1-5 0;
  }
  .lst-kix_list_47-8 > li:before {
    content: "\0025aa  ";
  }
  ul.lst-kix_list_32-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_32-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_32-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_32-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_9-6.start {
    counter-reset: lst-ctn-kix_list_9-6 0;
  }
  ul.lst-kix_list_32-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_16-3.start {
    counter-reset: lst-ctn-kix_list_16-3 0;
  }
  ul.lst-kix_list_32-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_32-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_32-8 {
    list-style-type: none;
  }
  .lst-kix_list_47-3 > li:before {
    content: "\0025cf  ";
  }
  ol.lst-kix_list_4-5.start {
    counter-reset: lst-ctn-kix_list_4-5 0;
  }
  ul.lst-kix_list_32-0 {
    list-style-type: none;
  }
  .lst-kix_list_37-0 > li:before {
    content: "" counter(lst-ctn-kix_list_37-0, decimal) ". ";
  }
  .lst-kix_list_41-0 > li {
    counter-increment: lst-ctn-kix_list_41-0;
  }
  .lst-kix_list_42-1 > li {
    counter-increment: lst-ctn-kix_list_42-1;
  }
  .lst-kix_list_5-2 > li {
    counter-increment: lst-ctn-kix_list_5-2;
  }
  .lst-kix_list_37-7 > li {
    counter-increment: lst-ctn-kix_list_37-7;
  }
  .lst-kix_list_19-6 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_47-0 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_37-8 > li:before {
    content: "Not Defined ";
  }
  ol.lst-kix_list_8-7.start {
    counter-reset: lst-ctn-kix_list_8-7 0;
  }
  .lst-kix_list_17-2 > li {
    counter-increment: lst-ctn-kix_list_17-2;
  }
  ol.lst-kix_list_41-6.start {
    counter-reset: lst-ctn-kix_list_41-6 0;
  }
  .lst-kix_list_37-6 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_42-5 > li {
    counter-increment: lst-ctn-kix_list_42-5;
  }
  .lst-kix_list_36-2 > li {
    counter-increment: lst-ctn-kix_list_36-2;
  }
  ol.lst-kix_list_31-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_31-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_31-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_31-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_31-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_37-3.start {
    counter-reset: lst-ctn-kix_list_37-3 0;
  }
  ol.lst-kix_list_31-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_31-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_31-3 {
    list-style-type: none;
  }
  .lst-kix_list_37-1 > li:before {
    content: "" counter(lst-ctn-kix_list_37-0, decimal) "." counter(lst-ctn-kix_list_37-1, decimal) " ";
  }
  ol.lst-kix_list_31-2 {
    list-style-type: none;
  }
  .lst-kix_list_46-2 > li:before {
    content: "" counter(lst-ctn-kix_list_46-2, lower-roman) ". ";
  }
  ol.lst-kix_list_44-1.start {
    counter-reset: lst-ctn-kix_list_44-1 0;
  }
  .lst-kix_list_46-1 > li:before {
    content: "" counter(lst-ctn-kix_list_46-1, lower-latin) ". ";
  }
  .lst-kix_list_37-3 > li:before {
    content: "(" counter(lst-ctn-kix_list_37-3, lower-latin) ") ";
  }
  ol.lst-kix_list_1-0.start {
    counter-reset: lst-ctn-kix_list_1-0 0;
  }
  .lst-kix_list_37-3 > li {
    counter-increment: lst-ctn-kix_list_37-3;
  }
  .lst-kix_list_18-0 > li:before {
    content: "\0025cf  ";
  }
  ol.lst-kix_list_13-3.start {
    counter-reset: lst-ctn-kix_list_13-3 0;
  }
  .lst-kix_list_38-7 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_3-0 > li {
    counter-increment: lst-ctn-kix_list_3-0;
  }
  .lst-kix_list_18-2 > li:before {
    content: "\0025aa  ";
  }
  ol.lst-kix_list_42-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_4-0.start {
    counter-reset: lst-ctn-kix_list_4-0 0;
  }
  ol.lst-kix_list_42-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_42-7 {
    list-style-type: none;
  }
  li.li-bullet-2:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }
  ol.lst-kix_list_42-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_42-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_42-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_42-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_42-1 {
    list-style-type: none;
  }
  .lst-kix_list_38-2 > li:before {
    content: "" counter(lst-ctn-kix_list_38-0, decimal) "." counter(lst-ctn-kix_list_38-1, decimal) "."
      counter(lst-ctn-kix_list_38-2, decimal) " ";
  }
  .lst-kix_list_38-4 > li:before {
    content: "(" counter(lst-ctn-kix_list_38-4, lower-roman) ") ";
  }
  ol.lst-kix_list_11-7.start {
    counter-reset: lst-ctn-kix_list_11-7 0;
  }
  ol.lst-kix_list_14-2.start {
    counter-reset: lst-ctn-kix_list_14-2 0;
  }
  .lst-kix_list_16-1 > li {
    counter-increment: lst-ctn-kix_list_16-1;
  }
  .lst-kix_list_41-4 > li {
    counter-increment: lst-ctn-kix_list_41-4;
  }
  .lst-kix_list_38-4 > li {
    counter-increment: lst-ctn-kix_list_38-4;
  }
  .lst-kix_list_38-5 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_27-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_48-2 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_27-3 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_17-5 > li {
    counter-increment: lst-ctn-kix_list_17-5;
  }
  .lst-kix_list_48-4 > li:before {
    content: "o  ";
  }
  ol.lst-kix_list_41-8.start {
    counter-reset: lst-ctn-kix_list_41-8 0;
  }
  .lst-kix_list_18-8 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_17-6 > li {
    counter-increment: lst-ctn-kix_list_17-6;
  }
  ol.lst-kix_list_4-3.start {
    counter-reset: lst-ctn-kix_list_4-3 0;
  }
  .lst-kix_list_7-8 > li {
    counter-increment: lst-ctn-kix_list_7-8;
  }
  .lst-kix_list_10-7 > li:before {
    content: "Not Defined ";
  }
  ul.lst-kix_list_34-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_34-1 {
    list-style-type: none;
  }
  .lst-kix_list_36-4 > li:before {
    content: "(" counter(lst-ctn-kix_list_36-4, lower-roman) ") ";
  }
  ul.lst-kix_list_34-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_34-3 {
    list-style-type: none;
  }
  .lst-kix_list_10-5 > li:before {
    content: "\0025cf  ";
  }
  ol.lst-kix_list_13-5.start {
    counter-reset: lst-ctn-kix_list_13-5 0;
  }
  ul.lst-kix_list_34-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_34-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_34-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_13-8.start {
    counter-reset: lst-ctn-kix_list_13-8 0;
  }
  .lst-kix_list_11-7 > li {
    counter-increment: lst-ctn-kix_list_11-7;
  }
  .lst-kix_list_9-2 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_46-4 > li:before {
    content: "" counter(lst-ctn-kix_list_46-4, lower-latin) ". ";
  }
  ol.lst-kix_list_37-6.start {
    counter-reset: lst-ctn-kix_list_37-6 0;
  }
  ol.lst-kix_list_14-0.start {
    counter-reset: lst-ctn-kix_list_14-0 0;
  }
  .lst-kix_list_12-5 > li {
    counter-increment: lst-ctn-kix_list_12-5;
  }
  ul.lst-kix_list_34-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_34-8 {
    list-style-type: none;
  }
  .lst-kix_list_5-5 > li {
    counter-increment: lst-ctn-kix_list_5-5;
  }
  .lst-kix_list_36-2 > li:before {
    content: "" counter(lst-ctn-kix_list_36-0, decimal) "." counter(lst-ctn-kix_list_36-1, decimal) "."
      counter(lst-ctn-kix_list_36-2, decimal) " ";
  }
  .lst-kix_list_31-1 > li {
    counter-increment: lst-ctn-kix_list_31-1;
  }
  .lst-kix_list_9-0 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_44-7 > li {
    counter-increment: lst-ctn-kix_list_44-7;
  }
  ul.lst-kix_list_21-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_21-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_21-3 {
    list-style-type: none;
  }
  .lst-kix_list_16-8 > li {
    counter-increment: lst-ctn-kix_list_16-8;
  }
  ul.lst-kix_list_21-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_21-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_21-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_21-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_21-8 {
    list-style-type: none;
  }
  .lst-kix_list_11-3 > li:before {
    content: "\0025cf  ";
  }
  ol.lst-kix_list_46-7.start {
    counter-reset: lst-ctn-kix_list_46-7 0;
  }
  .lst-kix_list_38-8 > li {
    counter-increment: lst-ctn-kix_list_38-8;
  }
  ul.lst-kix_list_21-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-3.start {
    counter-reset: lst-ctn-kix_list_1-3 0;
  }
  ol.lst-kix_list_1-2.start {
    counter-reset: lst-ctn-kix_list_1-2 0;
  }
  .lst-kix_list_29-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_20-4 > li:before {
    content: "o  ";
  }
  .lst-kix_list_29-3 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_20-2 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_9-8 > li:before {
    content: "Not Defined ";
  }
  ul.lst-kix_list_10-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_16-8.start {
    counter-reset: lst-ctn-kix_list_16-8 0;
  }
  .lst-kix_list_28-7 > li:before {
    content: "o  ";
  }
  ol.lst-kix_list_44-8 {
    list-style-type: none;
  }
  .lst-kix_list_4-8 > li {
    counter-increment: lst-ctn-kix_list_4-8;
  }
  .lst-kix_list_1-7 > li:before {
    content: "Not Defined ";
  }
  ol.lst-kix_list_44-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_44-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_44-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-4 {
    list-style-type: none;
  }
  .lst-kix_list_1-5 > li:before {
    content: "Not Defined ";
  }
  ol.lst-kix_list_44-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-3 {
    list-style-type: none;
  }
  .lst-kix_list_28-5 > li:before {
    content: "\0025aa  ";
  }
  ol.lst-kix_list_44-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_44-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-1 {
    list-style-type: none;
  }
  .lst-kix_list_5-6 > li {
    counter-increment: lst-ctn-kix_list_5-6;
  }
  ol.lst-kix_list_44-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_44-1 {
    list-style-type: none;
  }
  .lst-kix_list_2-1 > li:before {
    content: "" counter(lst-ctn-kix_list_2-0, decimal) "." counter(lst-ctn-kix_list_2-1, decimal) " ";
  }
  .lst-kix_list_2-3 > li:before {
    content: "(" counter(lst-ctn-kix_list_2-3, lower-latin) ") ";
  }
  .lst-kix_list_11-8 > li {
    counter-increment: lst-ctn-kix_list_11-8;
  }
  .lst-kix_list_35-3 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_30-4 > li:before {
    content: "o  ";
  }
  .lst-kix_list_35-6 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_36-5 > li {
    counter-increment: lst-ctn-kix_list_36-5;
  }
  .lst-kix_list_3-2 > li:before {
    content: "" counter(lst-ctn-kix_list_3-0, decimal) "." counter(lst-ctn-kix_list_3-1, decimal) "."
      counter(lst-ctn-kix_list_3-2, decimal) " ";
  }
  .lst-kix_list_26-7 > li:before {
    content: "o  ";
  }
  .lst-kix_list_8-1 > li:before {
    content: "\0025cf  ";
  }
  ol.lst-kix_list_1-8.start {
    counter-reset: lst-ctn-kix_list_1-8 0;
  }
  .lst-kix_list_3-5 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_30-7 > li:before {
    content: "o  ";
  }
  .lst-kix_list_40-2 > li {
    counter-increment: lst-ctn-kix_list_40-2;
  }
  .lst-kix_list_8-6 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_26-2 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_21-6 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_41-3 > li {
    counter-increment: lst-ctn-kix_list_41-3;
  }
  ol.lst-kix_list_16-6.start {
    counter-reset: lst-ctn-kix_list_16-6 0;
  }
  .lst-kix_list_44-4 > li {
    counter-increment: lst-ctn-kix_list_44-4;
  }
  ol.lst-kix_list_41-4.start {
    counter-reset: lst-ctn-kix_list_41-4 0;
  }
  ol.lst-kix_list_4-2.start {
    counter-reset: lst-ctn-kix_list_4-2 0;
  }
  ol.lst-kix_list_16-0.start {
    counter-reset: lst-ctn-kix_list_16-0 0;
  }
  ol.lst-kix_list_46-5.start {
    counter-reset: lst-ctn-kix_list_46-5 0;
  }
  .lst-kix_list_21-3 > li:before {
    content: "\0025cf  ";
  }
  ol.lst-kix_list_11-6.start {
    counter-reset: lst-ctn-kix_list_11-6 0;
  }
  .lst-kix_list_4-4 > li {
    counter-increment: lst-ctn-kix_list_4-4;
  }
  .lst-kix_list_45-8 > li:before {
    content: "" counter(lst-ctn-kix_list_45-8, lower-roman) ". ";
  }
  .lst-kix_list_45-3 > li:before {
    content: "" counter(lst-ctn-kix_list_45-3, decimal) ". ";
  }
  .lst-kix_list_17-1 > li:before {
    content: "Appendix " counter(lst-ctn-kix_list_17-1, decimal) " ";
  }
  .lst-kix_list_31-8 > li {
    counter-increment: lst-ctn-kix_list_31-8;
  }
  .lst-kix_list_25-3 > li:before {
    content: "\0025cf  ";
  }
  ol.lst-kix_list_4-1.start {
    counter-reset: lst-ctn-kix_list_4-1 0;
  }
  .lst-kix_list_39-2 > li {
    counter-increment: lst-ctn-kix_list_39-2;
  }
  .lst-kix_list_16-2 > li:before {
    content: " ";
  }
  ol.lst-kix_list_46-4.start {
    counter-reset: lst-ctn-kix_list_46-4 0;
  }
  .lst-kix_list_16-5 > li:before {
    content: " ";
  }
  .lst-kix_list_15-3 > li {
    counter-increment: lst-ctn-kix_list_15-3;
  }
  .lst-kix_list_44-4 > li:before {
    content: "(" counter(lst-ctn-kix_list_44-4, lower-roman) ") ";
  }
  ol.lst-kix_list_41-5.start {
    counter-reset: lst-ctn-kix_list_41-5 0;
  }
  .lst-kix_list_44-7 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_37-6 > li {
    counter-increment: lst-ctn-kix_list_37-6;
  }
  .lst-kix_list_3-3 > li {
    counter-increment: lst-ctn-kix_list_3-3;
  }
  .lst-kix_list_16-4 > li {
    counter-increment: lst-ctn-kix_list_16-4;
  }
  .lst-kix_list_39-3 > li:before {
    content: "(" counter(lst-ctn-kix_list_39-3, lower-latin) ") ";
  }
  .lst-kix_list_38-1 > li {
    counter-increment: lst-ctn-kix_list_38-1;
  }
  .lst-kix_list_45-0 > li:before {
    content: "" counter(lst-ctn-kix_list_45-0, decimal) ". ";
  }
  ol.lst-kix_list_16-5.start {
    counter-reset: lst-ctn-kix_list_16-5 0;
  }
  .lst-kix_list_17-6 > li:before {
    content: " ";
  }
  .lst-kix_list_41-7 > li {
    counter-increment: lst-ctn-kix_list_41-7;
  }
  .lst-kix_list_2-6 > li:before {
    content: "Not Defined ";
  }
  ol.lst-kix_list_16-2.start {
    counter-reset: lst-ctn-kix_list_16-2 0;
  }
  .lst-kix_list_42-8 > li {
    counter-increment: lst-ctn-kix_list_42-8;
  }
  .lst-kix_list_14-5 > li {
    counter-increment: lst-ctn-kix_list_14-5;
  }
  .lst-kix_list_7-5 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_27-6 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_43-0 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_43-8 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_22-7 > li:before {
    content: "o  ";
  }
  .lst-kix_list_34-7 > li:before {
    content: "o  ";
  }
  .lst-kix_list_18-5 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_13-6 > li:before {
    content: " ";
  }
  .lst-kix_list_6-7 > li {
    counter-increment: lst-ctn-kix_list_6-7;
  }
  .lst-kix_list_10-6 > li {
    counter-increment: lst-ctn-kix_list_10-6;
  }
  .lst-kix_list_1-7 > li {
    counter-increment: lst-ctn-kix_list_1-7;
  }
  ol.lst-kix_list_41-0.start {
    counter-reset: lst-ctn-kix_list_41-0 0;
  }
  .lst-kix_list_39-6 > li:before {
    content: "Not Defined ";
  }
  ul.lst-kix_list_30-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_30-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_30-5 {
    list-style-type: none;
  }
  .lst-kix_list_15-6 > li:before {
    content: " ";
  }
  ul.lst-kix_list_30-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_30-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_30-8 {
    list-style-type: none;
  }
  .lst-kix_list_31-3 > li:before {
    content: "" counter(lst-ctn-kix_list_31-3, decimal) ". ";
  }
  .lst-kix_list_36-7 > li:before {
    content: "Not Defined ";
  }
  ul.lst-kix_list_30-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_6-8.start {
    counter-reset: lst-ctn-kix_list_6-8 0;
  }
  .lst-kix_list_10-2 > li:before {
    content: "\0025cf  ";
  }
  ul.lst-kix_list_30-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_30-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-7.start {
    counter-reset: lst-ctn-kix_list_1-7 0;
  }
  .lst-kix_list_13-7 > li {
    counter-increment: lst-ctn-kix_list_13-7;
  }
  .lst-kix_list_20-7 > li:before {
    content: "o  ";
  }
  .lst-kix_list_4-6 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_41-8 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_25-6 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_46-7 > li {
    counter-increment: lst-ctn-kix_list_46-7;
  }
  .lst-kix_list_46-7 > li:before {
    content: "" counter(lst-ctn-kix_list_46-7, lower-latin) ". ";
  }
  .lst-kix_list_41-0 > li:before {
    content: "" counter(lst-ctn-kix_list_41-0, decimal) ". ";
  }
  ol.lst-kix_list_6-7.start {
    counter-reset: lst-ctn-kix_list_6-7 0;
  }
  .lst-kix_list_12-2 > li {
    counter-increment: lst-ctn-kix_list_12-2;
  }
  .lst-kix_list_9-5 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_29-6 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_33-3 > li:before {
    content: "\0025cf  ";
  }
  ol.lst-kix_list_41-3.start {
    counter-reset: lst-ctn-kix_list_41-3 0;
  }
  .lst-kix_list_12-2 > li:before {
    content: "Part " counter(lst-ctn-kix_list_12-2, decimal) " ";
  }
  .lst-kix_list_11-6 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_32-7 > li:before {
    content: "o  ";
  }
  ol.lst-kix_list_46-1.start {
    counter-reset: lst-ctn-kix_list_46-1 0;
  }
  .lst-kix_list_1-2 > li:before {
    content: "" counter(lst-ctn-kix_list_1-0, decimal) "." counter(lst-ctn-kix_list_1-1, decimal) "."
      counter(lst-ctn-kix_list_1-2, decimal) " ";
  }
  .lst-kix_list_1-0 > li {
    counter-increment: lst-ctn-kix_list_1-0;
  }
  .lst-kix_list_46-0 > li {
    counter-increment: lst-ctn-kix_list_46-0;
  }
  ol.lst-kix_list_16-1.start {
    counter-reset: lst-ctn-kix_list_16-1 0;
  }
  ol.lst-kix_list_40-8 {
    list-style-type: none;
  }
  li.li-bullet-0:before {
    margin-left: -42.5pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 42.5pt;
  }
  ol.lst-kix_list_40-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_40-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_41-2.start {
    counter-reset: lst-ctn-kix_list_41-2 0;
  }
  ol.lst-kix_list_40-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_40-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_40-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_40-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_40-0 {
    list-style-type: none;
  }
  .lst-kix_list_48-7 > li:before {
    content: "o  ";
  }
  ol.lst-kix_list_40-1 {
    list-style-type: none;
  }
  .lst-kix_list_40-4 > li:before {
    content: "(" counter(lst-ctn-kix_list_40-4, lower-roman) ") ";
  }
  .lst-kix_list_28-2 > li:before {
    content: "\0025aa  ";
  }
  ol.lst-kix_list_46-0.start {
    counter-reset: lst-ctn-kix_list_46-0 0;
  }
  .lst-kix_list_41-2 > li {
    counter-increment: lst-ctn-kix_list_41-2;
  }
  .lst-kix_list_14-1 > li:before {
    content: "Appendix " counter(lst-ctn-kix_list_14-1, decimal) " ";
  }
  .lst-kix_list_14-3 > li:before {
    content: "Exhibit " counter(lst-ctn-kix_list_14-3, decimal) " ";
  }
  ol.lst-kix_list_15-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_15-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_28-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_15-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_28-1 {
    list-style-type: none;
  }
  .lst-kix_list_14-0 > li:before {
    content: "Schedule " counter(lst-ctn-kix_list_14-0, decimal) " ";
  }
  .lst-kix_list_14-4 > li:before {
    content: " ";
  }
  ol.lst-kix_list_15-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_15-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_15-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_45-4.start {
    counter-reset: lst-ctn-kix_list_45-4 0;
  }
  .lst-kix_list_37-5 > li {
    counter-increment: lst-ctn-kix_list_37-5;
  }
  .lst-kix_list_14-5 > li:before {
    content: " ";
  }
  .lst-kix_list_14-7 > li:before {
    content: " ";
  }
  ol.lst-kix_list_15-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_15-0 {
    list-style-type: none;
  }
  .lst-kix_list_14-6 > li:before {
    content: " ";
  }
  ol.lst-kix_list_15-1 {
    list-style-type: none;
  }
  .lst-kix_list_17-0 > li {
    counter-increment: lst-ctn-kix_list_17-0;
  }
  ul.lst-kix_list_28-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_28-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_28-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_28-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_28-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_28-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_11-8.start {
    counter-reset: lst-ctn-kix_list_11-8 0;
  }
  ul.lst-kix_list_28-8 {
    list-style-type: none;
  }
  .lst-kix_list_14-2 > li:before {
    content: "Part " counter(lst-ctn-kix_list_14-2, decimal) " ";
  }
  ol.lst-kix_list_12-0.start {
    counter-reset: lst-ctn-kix_list_12-0 0;
  }
  ol.lst-kix_list_41-7.start {
    counter-reset: lst-ctn-kix_list_41-7 0;
  }
  .lst-kix_list_32-2 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_44-1 > li {
    counter-increment: lst-ctn-kix_list_44-1;
  }
  .lst-kix_list_32-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_32-3 > li:before {
    content: "\0025cf  ";
  }
  ol.lst-kix_list_3-7.start {
    counter-reset: lst-ctn-kix_list_3-7 0;
  }
  .lst-kix_list_14-8 > li:before {
    content: " ";
  }
  .lst-kix_list_31-7 > li {
    counter-increment: lst-ctn-kix_list_31-7;
  }
  .lst-kix_list_39-7 > li {
    counter-increment: lst-ctn-kix_list_39-7;
  }
  .lst-kix_list_32-0 > li:before {
    content: "-  ";
  }
  .lst-kix_list_3-2 > li {
    counter-increment: lst-ctn-kix_list_3-2;
  }
  ol.lst-kix_list_15-5.start {
    counter-reset: lst-ctn-kix_list_15-5 0;
  }
  ol.lst-kix_list_39-8 {
    list-style-type: none;
  }
  .lst-kix_list_5-0 > li:before {
    content: "" counter(lst-ctn-kix_list_5-0, decimal) ". ";
  }
  .lst-kix_list_14-8 > li {
    counter-increment: lst-ctn-kix_list_14-8;
  }
  .lst-kix_list_5-4 > li {
    counter-increment: lst-ctn-kix_list_5-4;
  }
  ol.lst-kix_list_39-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_39-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_39-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_39-6 {
    list-style-type: none;
  }
  .lst-kix_list_5-3 > li:before {
    content: "(" counter(lst-ctn-kix_list_5-3, lower-latin) ") ";
  }
  .lst-kix_list_36-8 > li {
    counter-increment: lst-ctn-kix_list_36-8;
  }
  ol.lst-kix_list_39-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_39-0 {
    list-style-type: none;
  }
  .lst-kix_list_5-2 > li:before {
    content: "" counter(lst-ctn-kix_list_5-0, decimal) "." counter(lst-ctn-kix_list_5-1, decimal) "."
      counter(lst-ctn-kix_list_5-2, decimal) " ";
  }
  ol.lst-kix_list_39-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_39-2 {
    list-style-type: none;
  }
  .lst-kix_list_5-1 > li:before {
    content: "" counter(lst-ctn-kix_list_5-0, decimal) "." counter(lst-ctn-kix_list_5-1, decimal) " ";
  }
  .lst-kix_list_5-7 > li:before {
    content: "Not Defined ";
  }
  ul.lst-kix_list_8-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-5 {
    list-style-type: none;
  }
  .lst-kix_list_5-6 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_5-8 > li:before {
    content: "Not Defined ";
  }
  ul.lst-kix_list_8-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_6-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_6-7 {
    list-style-type: none;
  }
  .lst-kix_list_5-4 > li:before {
    content: "(" counter(lst-ctn-kix_list_5-4, lower-roman) ") ";
  }
  ol.lst-kix_list_6-8 {
    list-style-type: none;
  }
  .lst-kix_list_5-5 > li:before {
    content: "Not Defined ";
  }
  ul.lst-kix_list_8-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_12-5.start {
    counter-reset: lst-ctn-kix_list_12-5 0;
  }
  .lst-kix_list_6-1 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_6-3 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_6-8 > li {
    counter-increment: lst-ctn-kix_list_6-8;
  }
  .lst-kix_list_6-0 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_6-4 > li:before {
    content: "\0025cf  ";
  }
  ol.lst-kix_list_14-8.start {
    counter-reset: lst-ctn-kix_list_14-8 0;
  }
  .lst-kix_list_6-2 > li:before {
    content: "\0025cf  ";
  }
  ol.lst-kix_list_15-0.start {
    counter-reset: lst-ctn-kix_list_15-0 0;
  }
  ol.lst-kix_list_44-7.start {
    counter-reset: lst-ctn-kix_list_44-7 0;
  }
  .lst-kix_list_2-5 > li {
    counter-increment: lst-ctn-kix_list_2-5;
  }
  ol.lst-kix_list_3-2.start {
    counter-reset: lst-ctn-kix_list_3-2 0;
  }
  .lst-kix_list_6-8 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_6-5 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_6-7 > li:before {
    content: "Not Defined ";
  }
  ol.lst-kix_list_42-4.start {
    counter-reset: lst-ctn-kix_list_42-4 0;
  }
  .lst-kix_list_6-6 > li:before {
    content: "Not Defined ";
  }
  ol.lst-kix_list_17-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_10-6.start {
    counter-reset: lst-ctn-kix_list_10-6 0;
  }
  .lst-kix_list_7-4 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_7-6 > li:before {
    content: "Not Defined ";
  }
  ol.lst-kix_list_17-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_17-5 {
    list-style-type: none;
  }
  .lst-kix_list_15-5 > li {
    counter-increment: lst-ctn-kix_list_15-5;
  }
  ol.lst-kix_list_17-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_17-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_17-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_36-3.start {
    counter-reset: lst-ctn-kix_list_36-3 0;
  }
  ol.lst-kix_list_17-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_17-2 {
    list-style-type: none;
  }
  .lst-kix_list_7-2 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_44-8 > li {
    counter-increment: lst-ctn-kix_list_44-8;
  }
  ol.lst-kix_list_17-3 {
    list-style-type: none;
  }
  .lst-kix_list_7-6 > li {
    counter-increment: lst-ctn-kix_list_7-6;
  }
  .lst-kix_list_31-0 > li {
    counter-increment: lst-ctn-kix_list_31-0;
  }
  .lst-kix_list_34-8 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_31-0 > li:before {
    content: "" counter(lst-ctn-kix_list_31-0, decimal) ") ";
  }
  .lst-kix_list_12-6 > li {
    counter-increment: lst-ctn-kix_list_12-6;
  }
  ol.lst-kix_list_46-6.start {
    counter-reset: lst-ctn-kix_list_46-6 0;
  }
  .lst-kix_list_13-7 > li:before {
    content: " ";
  }
  .lst-kix_list_7-8 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_15-6 > li {
    counter-increment: lst-ctn-kix_list_15-6;
  }
  .lst-kix_list_4-7 > li {
    counter-increment: lst-ctn-kix_list_4-7;
  }
  ol.lst-kix_list_2-5.start {
    counter-reset: lst-ctn-kix_list_2-5 0;
  }
  .lst-kix_list_15-5 > li:before {
    content: " ";
  }
  .lst-kix_list_31-6 > li:before {
    content: "" counter(lst-ctn-kix_list_31-6, decimal) ". ";
  }
  .lst-kix_list_31-8 > li:before {
    content: "" counter(lst-ctn-kix_list_31-8, lower-roman) ". ";
  }
  .lst-kix_list_45-6 > li {
    counter-increment: lst-ctn-kix_list_45-6;
  }
  .lst-kix_list_9-8 > li {
    counter-increment: lst-ctn-kix_list_9-8;
  }
  .lst-kix_list_13-4 > li {
    counter-increment: lst-ctn-kix_list_13-4;
  }
  .lst-kix_list_4-1 > li:before {
    content: "" counter(lst-ctn-kix_list_4-0, decimal) "." counter(lst-ctn-kix_list_4-1, decimal) " ";
  }
  .lst-kix_list_31-2 > li:before {
    content: "" counter(lst-ctn-kix_list_31-2, lower-roman) ". ";
  }
  .lst-kix_list_31-4 > li:before {
    content: "" counter(lst-ctn-kix_list_31-4, lower-latin) ". ";
  }
  .lst-kix_list_15-7 > li:before {
    content: " ";
  }
  .lst-kix_list_36-0 > li {
    counter-increment: lst-ctn-kix_list_36-0;
  }
  .lst-kix_list_17-7 > li {
    counter-increment: lst-ctn-kix_list_17-7;
  }
  ul.lst-kix_list_19-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_19-6 {
    list-style-type: none;
  }
  .lst-kix_list_4-3 > li:before {
    content: "(" counter(lst-ctn-kix_list_4-3, lower-latin) ") ";
  }
  .lst-kix_list_4-5 > li:before {
    content: "Not Defined ";
  }
  ul.lst-kix_list_19-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_19-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_19-3 {
    list-style-type: none;
  }
  .lst-kix_list_1-8 > li {
    counter-increment: lst-ctn-kix_list_1-8;
  }
  ul.lst-kix_list_19-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_38-1.start {
    counter-reset: lst-ctn-kix_list_38-1 0;
  }
  ul.lst-kix_list_19-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_19-0 {
    list-style-type: none;
  }
  .lst-kix_list_15-1 > li:before {
    content: " ";
  }
  ol.lst-kix_list_1-4.start {
    counter-reset: lst-ctn-kix_list_1-4 0;
  }
  .lst-kix_list_15-3 > li:before {
    content: " ";
  }
  .lst-kix_list_42-7 > li {
    counter-increment: lst-ctn-kix_list_42-7;
  }
  ul.lst-kix_list_19-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_4-4.start {
    counter-reset: lst-ctn-kix_list_4-4 0;
  }
  .lst-kix_list_16-2 > li {
    counter-increment: lst-ctn-kix_list_16-2;
  }
  ol.lst-kix_list_39-3.start {
    counter-reset: lst-ctn-kix_list_39-3 0;
  }
  .lst-kix_list_40-5 > li {
    counter-increment: lst-ctn-kix_list_40-5;
  }
  ol.lst-kix_list_16-7.start {
    counter-reset: lst-ctn-kix_list_16-7 0;
  }
  .lst-kix_list_32-4 > li:before {
    content: "o  ";
  }
  .lst-kix_list_33-4 > li:before {
    content: "o  ";
  }
  ol.lst-kix_list_8-8 {
    list-style-type: none;
  }
  .lst-kix_list_12-3 > li:before {
    content: "Exhibit " counter(lst-ctn-kix_list_12-3, decimal) " ";
  }
  ol.lst-kix_list_31-4.start {
    counter-reset: lst-ctn-kix_list_31-4 0;
  }
  .lst-kix_list_38-2 > li {
    counter-increment: lst-ctn-kix_list_38-2;
  }
  .lst-kix_list_32-6 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_45-5 > li {
    counter-increment: lst-ctn-kix_list_45-5;
  }
  .lst-kix_list_12-1 > li:before {
    content: "Appendix " counter(lst-ctn-kix_list_12-1, decimal) " ";
  }
  .lst-kix_list_33-0 > li:before {
    content: "-  ";
  }
  .lst-kix_list_33-2 > li:before {
    content: "\0025aa  ";
  }
  ol.lst-kix_list_8-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_8-7 {
    list-style-type: none;
  }
  .lst-kix_list_32-8 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_16-3 > li {
    counter-increment: lst-ctn-kix_list_16-3;
  }
  .lst-kix_list_13-3 > li {
    counter-increment: lst-ctn-kix_list_13-3;
  }
  ol.lst-kix_list_13-6.start {
    counter-reset: lst-ctn-kix_list_13-6 0;
  }
  ol.lst-kix_list_40-5.start {
    counter-reset: lst-ctn-kix_list_40-5 0;
  }
  .lst-kix_list_39-0 > li {
    counter-increment: lst-ctn-kix_list_39-0;
  }
  .lst-kix_list_34-0 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_46-3 > li {
    counter-increment: lst-ctn-kix_list_46-3;
  }
  .lst-kix_list_14-1 > li {
    counter-increment: lst-ctn-kix_list_14-1;
  }
  .lst-kix_list_39-6 > li {
    counter-increment: lst-ctn-kix_list_39-6;
  }
  .lst-kix_list_13-3 > li:before {
    content: "Exhibit " counter(lst-ctn-kix_list_13-3, decimal) " ";
  }
  .lst-kix_list_34-4 > li:before {
    content: "o  ";
  }
  .lst-kix_list_42-6 > li {
    counter-increment: lst-ctn-kix_list_42-6;
  }
  .lst-kix_list_34-6 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_13-5 > li:before {
    content: " ";
  }
  .lst-kix_list_12-5 > li:before {
    content: " ";
  }
  ol.lst-kix_list_13-7.start {
    counter-reset: lst-ctn-kix_list_13-7 0;
  }
  .lst-kix_list_36-1 > li {
    counter-increment: lst-ctn-kix_list_36-1;
  }
  .lst-kix_list_36-7 > li {
    counter-increment: lst-ctn-kix_list_36-7;
  }
  .lst-kix_list_42-0 > li {
    counter-increment: lst-ctn-kix_list_42-0;
  }
  .lst-kix_list_12-7 > li:before {
    content: " ";
  }
  .lst-kix_list_33-6 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_33-8 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_34-2 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_13-1 > li:before {
    content: "Appendix " counter(lst-ctn-kix_list_13-1, decimal) " ";
  }
  ul.lst-kix_list_24-0 {
    list-style-type: none;
  }
  .lst-kix_list_30-5 > li:before {
    content: "\0025aa  ";
  }
  ul.lst-kix_list_24-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_24-2 {
    list-style-type: none;
  }
  .lst-kix_list_35-0 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_35-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_35-4 > li:before {
    content: "o  ";
  }
  .lst-kix_list_35-5 > li:before {
    content: "\0025aa  ";
  }
  ul.lst-kix_list_24-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_24-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_40-6.start {
    counter-reset: lst-ctn-kix_list_40-6 0;
  }
  ul.lst-kix_list_24-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_11-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_11-7 {
    list-style-type: none;
  }
  .lst-kix_list_1-1 > li {
    counter-increment: lst-ctn-kix_list_1-1;
  }
  ol.lst-kix_list_11-8 {
    list-style-type: none;
  }
  .lst-kix_list_30-1 > li:before {
    content: "o  ";
  }
  ol.lst-kix_list_2-6.start {
    counter-reset: lst-ctn-kix_list_2-6 0;
  }
  .lst-kix_list_3-0 > li:before {
    content: "" counter(lst-ctn-kix_list_3-0, decimal) ". ";
  }
  .lst-kix_list_30-2 > li:before {
    content: "\0025aa  ";
  }
  ol.lst-kix_list_13-1.start {
    counter-reset: lst-ctn-kix_list_13-1 0;
  }
  .lst-kix_list_4-0 > li {
    counter-increment: lst-ctn-kix_list_4-0;
  }
  .lst-kix_list_31-6 > li {
    counter-increment: lst-ctn-kix_list_31-6;
  }
  ul.lst-kix_list_24-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_37-5.start {
    counter-reset: lst-ctn-kix_list_37-5 0;
  }
  ul.lst-kix_list_24-7 {
    list-style-type: none;
  }
  .lst-kix_list_3-4 > li:before {
    content: "(" counter(lst-ctn-kix_list_3-4, lower-roman) ") ";
  }
  ul.lst-kix_list_24-8 {
    list-style-type: none;
  }
  .lst-kix_list_3-3 > li:before {
    content: "(" counter(lst-ctn-kix_list_3-3, lower-latin) ") ";
  }
  .lst-kix_list_8-0 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_30-6 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_8-7 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_38-3 > li {
    counter-increment: lst-ctn-kix_list_38-3;
  }
  .lst-kix_list_3-8 > li:before {
    content: "Not Defined ";
  }
  ol.lst-kix_list_10-7.start {
    counter-reset: lst-ctn-kix_list_10-7 0;
  }
  .lst-kix_list_8-3 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_3-7 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_8-4 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_46-4 > li {
    counter-increment: lst-ctn-kix_list_46-4;
  }
  .lst-kix_list_35-8 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_17-1 > li {
    counter-increment: lst-ctn-kix_list_17-1;
  }
  .lst-kix_list_11-1 > li:before {
    content: "\0025cf  ";
  }
  ol.lst-kix_list_31-0.start {
    counter-reset: lst-ctn-kix_list_31-0 0;
  }
  .lst-kix_list_11-0 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_8-8 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_37-4 > li {
    counter-increment: lst-ctn-kix_list_37-4;
  }
  ol.lst-kix_list_2-2 {
    list-style-type: none;
  }
  .lst-kix_list_16-8 > li:before {
    content: " ";
  }
  ol.lst-kix_list_2-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-4 {
    list-style-type: none;
  }
  .lst-kix_list_16-7 > li:before {
    content: " ";
  }
  ol.lst-kix_list_2-5 {
    list-style-type: none;
  }
  .lst-kix_list_17-8 > li {
    counter-increment: lst-ctn-kix_list_17-8;
  }
  ol.lst-kix_list_2-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-1 {
    list-style-type: none;
  }
  .lst-kix_list_4-8 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_4-7 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_14-2 > li {
    counter-increment: lst-ctn-kix_list_14-2;
  }
  .lst-kix_list_17-0 > li:before {
    content: "Schedule " counter(lst-ctn-kix_list_17-0, decimal) " ";
  }
  .lst-kix_list_39-8 > li {
    counter-increment: lst-ctn-kix_list_39-8;
  }
  .lst-kix_list_16-0 > li:before {
    content: "(" counter(lst-ctn-kix_list_16-0, upper-latin) ") ";
  }
  .lst-kix_list_40-6 > li {
    counter-increment: lst-ctn-kix_list_40-6;
  }
  ol.lst-kix_list_4-8.start {
    counter-reset: lst-ctn-kix_list_4-8 0;
  }
  .lst-kix_list_16-4 > li:before {
    content: " ";
  }
  ol.lst-kix_list_3-3.start {
    counter-reset: lst-ctn-kix_list_3-3 0;
  }
  .lst-kix_list_16-3 > li:before {
    content: " ";
  }
  ol.lst-kix_list_2-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_35-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_35-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_35-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_35-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_35-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_46-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_37-4.start {
    counter-reset: lst-ctn-kix_list_37-4 0;
  }
  ul.lst-kix_list_35-5 {
    list-style-type: none;
  }
  .lst-kix_list_41-1 > li {
    counter-increment: lst-ctn-kix_list_41-1;
  }
  ol.lst-kix_list_46-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_39-7.start {
    counter-reset: lst-ctn-kix_list_39-7 0;
  }
  ol.lst-kix_list_8-6.start {
    counter-reset: lst-ctn-kix_list_8-6 0;
  }
  .lst-kix_list_44-0 > li {
    counter-increment: lst-ctn-kix_list_44-0;
  }
  ol.lst-kix_list_46-7 {
    list-style-type: none;
  }
  .lst-kix_list_17-7 > li:before {
    content: " ";
  }
  ol.lst-kix_list_46-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_46-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_46-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_46-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_38-2.start {
    counter-reset: lst-ctn-kix_list_38-2 0;
  }
  .lst-kix_list_17-8 > li:before {
    content: " ";
  }
  ol.lst-kix_list_46-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_46-1 {
    list-style-type: none;
  }
  .lst-kix_list_17-3 > li:before {
    content: "Exhibit " counter(lst-ctn-kix_list_17-3, decimal) " ";
  }
  .lst-kix_list_17-4 > li:before {
    content: " ";
  }
  ul.lst-kix_list_35-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_35-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_35-8 {
    list-style-type: none;
  }
  .lst-kix_list_7-0 > li:before {
    content: "\0025cf  ";
  }
  ol.lst-kix_list_9-7.start {
    counter-reset: lst-ctn-kix_list_9-7 0;
  }
  ul.lst-kix_list_26-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_13-8 {
    list-style-type: none;
  }
  .lst-kix_list_2-4 > li:before {
    content: "(" counter(lst-ctn-kix_list_2-4, lower-roman) ") ";
  }
  .lst-kix_list_2-8 > li:before {
    content: "Not Defined ";
  }
  ul.lst-kix_list_26-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_26-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_26-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_13-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_13-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_13-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_13-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_13-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_13-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_13-2 {
    list-style-type: none;
  }
  .lst-kix_list_7-3 > li:before {
    content: "\0025cf  ";
  }
  ol.lst-kix_list_13-3 {
    list-style-type: none;
  }
  .lst-kix_list_48-5 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_10-0 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_9-7 > li {
    counter-increment: lst-ctn-kix_list_9-7;
  }
  .lst-kix_list_13-8 > li:before {
    content: " ";
  }
  .lst-kix_list_31-1 > li:before {
    content: "" counter(lst-ctn-kix_list_31-1, lower-latin) ". ";
  }
  .lst-kix_list_18-3 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_18-7 > li:before {
    content: "o  ";
  }
  ul.lst-kix_list_26-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_26-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_26-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_26-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_26-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-8.start {
    counter-reset: lst-ctn-kix_list_3-8 0;
  }
  ol.lst-kix_list_39-8.start {
    counter-reset: lst-ctn-kix_list_39-8 0;
  }
  .lst-kix_list_7-7 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_48-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_36-5 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_15-4 > li:before {
    content: " ";
  }
  .lst-kix_list_31-5 > li:before {
    content: "" counter(lst-ctn-kix_list_31-5, lower-roman) ". ";
  }
  .lst-kix_list_10-4 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_10-8 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_4-0 > li:before {
    content: "" counter(lst-ctn-kix_list_4-0, decimal) ". ";
  }
  .lst-kix_list_36-1 > li:before {
    content: "" counter(lst-ctn-kix_list_36-0, decimal) "." counter(lst-ctn-kix_list_36-1, decimal) " ";
  }
  .lst-kix_list_15-0 > li:before {
    content: "(" counter(lst-ctn-kix_list_15-0, decimal) ") ";
  }
  .lst-kix_list_15-8 > li:before {
    content: " ";
  }
  ol.lst-kix_list_38-7.start {
    counter-reset: lst-ctn-kix_list_38-7 0;
  }
  ol.lst-kix_list_14-3.start {
    counter-reset: lst-ctn-kix_list_14-3 0;
  }
  .lst-kix_list_15-7 > li {
    counter-increment: lst-ctn-kix_list_15-7;
  }
  .lst-kix_list_4-4 > li:before {
    content: "(" counter(lst-ctn-kix_list_4-4, lower-roman) ") ";
  }
  .lst-kix_list_9-3 > li:before {
    content: "\0025cf  ";
  }
  ol.lst-kix_list_44-2.start {
    counter-reset: lst-ctn-kix_list_44-2 0;
  }
  .lst-kix_list_12-8 > li {
    counter-increment: lst-ctn-kix_list_12-8;
  }
  ol.lst-kix_list_13-2.start {
    counter-reset: lst-ctn-kix_list_13-2 0;
  }
  ol.lst-kix_list_4-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_4-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_4-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_4-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_37-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_48-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_37-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_14-4.start {
    counter-reset: lst-ctn-kix_list_14-4 0;
  }
  ul.lst-kix_list_48-0 {
    list-style-type: none;
  }
  .lst-kix_list_9-7 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_2-4 > li {
    counter-increment: lst-ctn-kix_list_2-4;
  }
  .lst-kix_list_29-4 > li:before {
    content: "o  ";
  }
  .lst-kix_list_29-8 > li:before {
    content: "\0025aa  ";
  }
  ul.lst-kix_list_48-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_37-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_48-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-6.start {
    counter-reset: lst-ctn-kix_list_3-6 0;
  }
  ol.lst-kix_list_37-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_37-2 {
    list-style-type: none;
  }
  .lst-kix_list_32-5 > li:before {
    content: "\0025aa  ";
  }
  ol.lst-kix_list_37-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_37-4 {
    list-style-type: none;
  }
  .lst-kix_list_11-4 > li:before {
    content: "\0025cf  ";
  }
  ol.lst-kix_list_37-1 {
    list-style-type: none;
  }
  .lst-kix_list_12-4 > li:before {
    content: " ";
  }
  ol.lst-kix_list_37-0 {
    list-style-type: none;
  }
  .lst-kix_list_5-3 > li {
    counter-increment: lst-ctn-kix_list_5-3;
  }
  ul.lst-kix_list_6-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_37-0.start {
    counter-reset: lst-ctn-kix_list_37-0 0;
  }
  .lst-kix_list_29-0 > li:before {
    content: "\0025cf  ";
  }
  ol.lst-kix_list_4-8 {
    list-style-type: none;
  }
  .lst-kix_list_33-1 > li:before {
    content: "o  ";
  }
  ul.lst-kix_list_48-5 {
    list-style-type: none;
  }
  .lst-kix_list_1-0 > li:before {
    content: "" counter(lst-ctn-kix_list_1-0, decimal) ". ";
  }
  ul.lst-kix_list_48-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_38-5.start {
    counter-reset: lst-ctn-kix_list_38-5 0;
  }
  ul.lst-kix_list_48-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_48-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_4-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-2 {
    list-style-type: none;
  }
  .lst-kix_list_11-8 > li:before {
    content: "Not Defined ";
  }
  ol.lst-kix_list_4-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-0.start {
    counter-reset: lst-ctn-kix_list_2-0 0;
  }
  ul.lst-kix_list_48-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_4-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-0 {
    list-style-type: none;
  }
  .lst-kix_list_12-0 > li:before {
    content: "Schedule " counter(lst-ctn-kix_list_12-0, decimal) " ";
  }
  ol.lst-kix_list_4-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-1 {
    list-style-type: none;
  }
  .lst-kix_list_1-4 > li:before {
    content: "(" counter(lst-ctn-kix_list_1-4, lower-roman) ") ";
  }
  .lst-kix_list_13-0 > li:before {
    content: "Schedule " counter(lst-ctn-kix_list_13-0, decimal) " ";
  }
  ol.lst-kix_list_44-3.start {
    counter-reset: lst-ctn-kix_list_44-3 0;
  }
  .lst-kix_list_1-6 > li {
    counter-increment: lst-ctn-kix_list_1-6;
  }
  .lst-kix_list_13-4 > li:before {
    content: " ";
  }
  .lst-kix_list_34-5 > li:before {
    content: "\0025aa  ";
  }
  ol.lst-kix_list_45-8.start {
    counter-reset: lst-ctn-kix_list_45-8 0;
  }
  ol.lst-kix_list_38-6.start {
    counter-reset: lst-ctn-kix_list_38-6 0;
  }
  .lst-kix_list_33-5 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_2-0 > li:before {
    content: "" counter(lst-ctn-kix_list_2-0, decimal) ". ";
  }
  ol.lst-kix_list_2-1.start {
    counter-reset: lst-ctn-kix_list_2-1 0;
  }
  .lst-kix_list_4-5 > li {
    counter-increment: lst-ctn-kix_list_4-5;
  }
  ol.lst-kix_list_9-8.start {
    counter-reset: lst-ctn-kix_list_9-8 0;
  }
  .lst-kix_list_1-8 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_34-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_12-8 > li:before {
    content: " ";
  }
  ul.lst-kix_list_20-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_20-3 {
    list-style-type: none;
  }
  .lst-kix_list_19-0 > li:before {
    content: "\0025cf  ";
  }
  ul.lst-kix_list_20-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_20-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_17-7.start {
    counter-reset: lst-ctn-kix_list_17-7 0;
  }
  ul.lst-kix_list_20-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_20-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_12-6.start {
    counter-reset: lst-ctn-kix_list_12-6 0;
  }
  ul.lst-kix_list_20-8 {
    list-style-type: none;
  }
  .lst-kix_list_19-2 > li:before {
    content: "\0025aa  ";
  }
  ol.lst-kix_list_3-1.start {
    counter-reset: lst-ctn-kix_list_3-1 0;
  }
  ul.lst-kix_list_20-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_20-1 {
    list-style-type: none;
  }
  .lst-kix_list_47-7 > li:before {
    content: "o  ";
  }
  ol.lst-kix_list_44-6.start {
    counter-reset: lst-ctn-kix_list_44-6 0;
  }
  ol.lst-kix_list_42-5.start {
    counter-reset: lst-ctn-kix_list_42-5 0;
  }
  .lst-kix_list_45-0 > li {
    counter-increment: lst-ctn-kix_list_45-0;
  }
  .lst-kix_list_36-6 > li {
    counter-increment: lst-ctn-kix_list_36-6;
  }
  .lst-kix_list_47-2 > li:before {
    content: "\0025aa  ";
  }
  ol.lst-kix_list_31-3.start {
    counter-reset: lst-ctn-kix_list_31-3 0;
  }
  .lst-kix_list_2-3 > li {
    counter-increment: lst-ctn-kix_list_2-3;
  }
  ol.lst-kix_list_41-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_41-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_36-4.start {
    counter-reset: lst-ctn-kix_list_36-4 0;
  }
  ol.lst-kix_list_41-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_41-6 {
    list-style-type: none;
  }
  .lst-kix_list_47-4 > li:before {
    content: "o  ";
  }
  .lst-kix_list_1-2 > li {
    counter-increment: lst-ctn-kix_list_1-2;
  }
  ol.lst-kix_list_41-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_41-4 {
    list-style-type: none;
  }
  .lst-kix_list_19-8 > li:before {
    content: "\0025aa  ";
  }
  ol.lst-kix_list_41-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_14-7.start {
    counter-reset: lst-ctn-kix_list_14-7 0;
  }
  ol.lst-kix_list_41-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_41-0 {
    list-style-type: none;
  }
  .lst-kix_list_46-1 > li {
    counter-increment: lst-ctn-kix_list_46-1;
  }
  .lst-kix_list_19-5 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_47-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_19-7 > li:before {
    content: "o  ";
  }
  ul.lst-kix_list_42-2 {
    list-style-type: none;
  }
  .lst-kix_list_37-7 > li:before {
    content: "Not Defined ";
  }
  ol.lst-kix_list_17-2.start {
    counter-reset: lst-ctn-kix_list_17-2 0;
  }
  .lst-kix_list_13-2 > li {
    counter-increment: lst-ctn-kix_list_13-2;
  }
  .lst-kix_list_38-0 > li:before {
    content: "" counter(lst-ctn-kix_list_38-0, decimal) ". ";
  }
  .lst-kix_list_46-3 > li:before {
    content: "" counter(lst-ctn-kix_list_46-3, decimal) ". ";
  }
  .lst-kix_list_38-1 > li:before {
    content: "" counter(lst-ctn-kix_list_38-0, decimal) "." counter(lst-ctn-kix_list_38-1, decimal) " ";
  }
  .lst-kix_list_14-3 > li {
    counter-increment: lst-ctn-kix_list_14-3;
  }
  ol.lst-kix_list_15-6.start {
    counter-reset: lst-ctn-kix_list_15-6 0;
  }
  .lst-kix_list_37-2 > li:before {
    content: "" counter(lst-ctn-kix_list_37-0, decimal) "." counter(lst-ctn-kix_list_37-1, decimal) "."
      counter(lst-ctn-kix_list_37-2, decimal) " ";
  }
  .lst-kix_list_46-5 > li {
    counter-increment: lst-ctn-kix_list_46-5;
  }
  .lst-kix_list_37-4 > li:before {
    content: "(" counter(lst-ctn-kix_list_37-4, lower-roman) ") ";
  }
  .lst-kix_list_37-5 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_46-0 > li:before {
    content: "" counter(lst-ctn-kix_list_46-0, decimal) ") ";
  }
  .lst-kix_list_12-1 > li {
    counter-increment: lst-ctn-kix_list_12-1;
  }
  ol.lst-kix_list_31-8.start {
    counter-reset: lst-ctn-kix_list_31-8 0;
  }
  .lst-kix_list_18-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_38-8 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_40-3 > li {
    counter-increment: lst-ctn-kix_list_40-3;
  }
  .lst-kix_list_44-3 > li {
    counter-increment: lst-ctn-kix_list_44-3;
  }
  .lst-kix_list_45-4 > li {
    counter-increment: lst-ctn-kix_list_45-4;
  }
  .lst-kix_list_38-3 > li:before {
    content: "(" counter(lst-ctn-kix_list_38-3, lower-latin) ") ";
  }
  ol.lst-kix_list_45-5.start {
    counter-reset: lst-ctn-kix_list_45-5 0;
  }
  .lst-kix_list_38-6 > li:before {
    content: "Not Defined ";
  }
  ol.lst-kix_list_2-4.start {
    counter-reset: lst-ctn-kix_list_2-4 0;
  }
  ol.lst-kix_list_1-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_22-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-4 {
    list-style-type: none;
  }
  .lst-kix_list_2-7 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_2-7 > li {
    counter-increment: lst-ctn-kix_list_2-7;
  }
  ul.lst-kix_list_22-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_22-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-6 {
    list-style-type: none;
  }
  .lst-kix_list_41-8 > li {
    counter-increment: lst-ctn-kix_list_41-8;
  }
  .lst-kix_list_48-0 > li:before {
    content: "\0025cf  ";
  }
  ul.lst-kix_list_22-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_22-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-0 {
    list-style-type: none;
  }
  .lst-kix_list_2-5 > li:before {
    content: "Not Defined ";
  }
  ul.lst-kix_list_22-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_22-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_22-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_17-0.start {
    counter-reset: lst-ctn-kix_list_17-0 0;
  }
  .lst-kix_list_27-5 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_48-6 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_18-6 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_39-5 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_39-7 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_14-6 > li {
    counter-increment: lst-ctn-kix_list_14-6;
  }
  .lst-kix_list_46-8 > li {
    counter-increment: lst-ctn-kix_list_46-8;
  }
  .lst-kix_list_10-1 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_18-4 > li:before {
    content: "o  ";
  }
  ul.lst-kix_list_22-8 {
    list-style-type: none;
  }
  .lst-kix_list_7-7 > li {
    counter-increment: lst-ctn-kix_list_7-7;
  }
  ol.lst-kix_list_42-3.start {
    counter-reset: lst-ctn-kix_list_42-3 0;
  }
  ol.lst-kix_list_15-1.start {
    counter-reset: lst-ctn-kix_list_15-1 0;
  }
  ol.lst-kix_list_15-4.start {
    counter-reset: lst-ctn-kix_list_15-4 0;
  }
  ol.lst-kix_list_1-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_45-3.start {
    counter-reset: lst-ctn-kix_list_45-3 0;
  }
  .lst-kix_list_36-6 > li:before {
    content: "Not Defined ";
  }
  ol.lst-kix_list_39-2.start {
    counter-reset: lst-ctn-kix_list_39-2 0;
  }
  li.li-bullet-1:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }
  .lst-kix_list_10-3 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_15-4 > li {
    counter-increment: lst-ctn-kix_list_15-4;
  }
  .lst-kix_list_2-6 > li {
    counter-increment: lst-ctn-kix_list_2-6;
  }
  .lst-kix_list_36-0 > li:before {
    content: "" counter(lst-ctn-kix_list_36-0, decimal) ". ";
  }
  .lst-kix_list_36-8 > li:before {
    content: "Not Defined ";
  }
  ol.lst-kix_list_40-2.start {
    counter-reset: lst-ctn-kix_list_40-2 0;
  }
  ul.lst-kix_list_11-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_44-8.start {
    counter-reset: lst-ctn-kix_list_44-8 0;
  }
  ul.lst-kix_list_11-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_11-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_11-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_11-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_11-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_45-0.start {
    counter-reset: lst-ctn-kix_list_45-0 0;
  }
  ol.lst-kix_list_5-7.start {
    counter-reset: lst-ctn-kix_list_5-7 0;
  }
  .lst-kix_list_20-8 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_39-1 > li {
    counter-increment: lst-ctn-kix_list_39-1;
  }
  .lst-kix_list_46-6 > li:before {
    content: "" counter(lst-ctn-kix_list_46-6, decimal) ". ";
  }
  .lst-kix_list_46-8 > li:before {
    content: "" counter(lst-ctn-kix_list_46-8, lower-roman) ". ";
  }
  .lst-kix_list_3-4 > li {
    counter-increment: lst-ctn-kix_list_3-4;
  }
  .lst-kix_list_29-7 > li:before {
    content: "o  ";
  }
  .lst-kix_list_29-5 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_20-0 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_9-6 > li:before {
    content: "Not Defined ";
  }
  ol.lst-kix_list_10-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_10-8 {
    list-style-type: none;
  }
  .lst-kix_list_9-4 > li:before {
    content: "\0025cf  ";
  }
  ol.lst-kix_list_10-6 {
    list-style-type: none;
  }
  .lst-kix_list_20-6 > li:before {
    content: "\0025cf  ";
  }
  ol.lst-kix_list_12-1.start {
    counter-reset: lst-ctn-kix_list_12-1 0;
  }
  .lst-kix_list_11-5 > li:before {
    content: "\0025cf  ";
  }
  ol.lst-kix_list_36-2.start {
    counter-reset: lst-ctn-kix_list_36-2 0;
  }
  .lst-kix_list_1-1 > li:before {
    content: "" counter(lst-ctn-kix_list_1-0, decimal) "." counter(lst-ctn-kix_list_1-1, decimal) " ";
  }
  .lst-kix_list_11-7 > li:before {
    content: "Not Defined ";
  }
  ul.lst-kix_list_33-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_33-1 {
    list-style-type: none;
  }
  .lst-kix_list_1-3 > li:before {
    content: "(" counter(lst-ctn-kix_list_1-3, lower-latin) ") ";
  }
  ul.lst-kix_list_33-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_33-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_33-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_33-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_33-6 {
    list-style-type: none;
  }
  .lst-kix_list_28-3 > li:before {
    content: "\0025cf  ";
  }
  ol.lst-kix_list_42-0.start {
    counter-reset: lst-ctn-kix_list_42-0 0;
  }
  ul.lst-kix_list_33-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-7.start {
    counter-reset: lst-ctn-kix_list_2-7 0;
  }
  .lst-kix_list_14-7 > li {
    counter-increment: lst-ctn-kix_list_14-7;
  }
  ol.lst-kix_list_31-5.start {
    counter-reset: lst-ctn-kix_list_31-5 0;
  }
  .lst-kix_list_48-8 > li:before {
    content: "\0025aa  ";
  }
  ol.lst-kix_list_40-4.start {
    counter-reset: lst-ctn-kix_list_40-4 0;
  }
  .lst-kix_list_27-7 > li:before {
    content: "o  ";
  }
  ul.lst-kix_list_33-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_39-4.start {
    counter-reset: lst-ctn-kix_list_39-4 0;
  }
  .lst-kix_list_28-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_35-2 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_3-1 > li {
    counter-increment: lst-ctn-kix_list_3-1;
  }
  .lst-kix_list_30-3 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_30-8 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_35-7 > li:before {
    content: "o  ";
  }
  ol.lst-kix_list_39-1.start {
    counter-reset: lst-ctn-kix_list_39-1 0;
  }
  ol.lst-kix_list_7-7.start {
    counter-reset: lst-ctn-kix_list_7-7 0;
  }
  .lst-kix_list_3-1 > li:before {
    content: "" counter(lst-ctn-kix_list_3-0, decimal) "." counter(lst-ctn-kix_list_3-1, decimal) " ";
  }
  .lst-kix_list_26-6 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_14-0 > li {
    counter-increment: lst-ctn-kix_list_14-0;
  }
  .lst-kix_list_44-0 > li:before {
    content: "" counter(lst-ctn-kix_list_44-0, decimal) ". ";
  }
  ol.lst-kix_list_17-4.start {
    counter-reset: lst-ctn-kix_list_17-4 0;
  }
  .lst-kix_list_8-2 > li:before {
    content: "\0025cf  ";
  }
  ol.lst-kix_list_36-7.start {
    counter-reset: lst-ctn-kix_list_36-7 0;
  }
  ol.lst-kix_list_31-6.start {
    counter-reset: lst-ctn-kix_list_31-6 0;
  }
  .lst-kix_list_46-2 > li {
    counter-increment: lst-ctn-kix_list_46-2;
  }
  .lst-kix_list_12-0 > li {
    counter-increment: lst-ctn-kix_list_12-0;
  }
  ol.lst-kix_list_12-3.start {
    counter-reset: lst-ctn-kix_list_12-3 0;
  }
  .lst-kix_list_44-2 > li {
    counter-increment: lst-ctn-kix_list_44-2;
  }
  ol.lst-kix_list_45-1.start {
    counter-reset: lst-ctn-kix_list_45-1 0;
  }
  ol.lst-kix_list_40-0.start {
    counter-reset: lst-ctn-kix_list_40-0 0;
  }
  .lst-kix_list_21-2 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_8-5 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_2-0 > li {
    counter-increment: lst-ctn-kix_list_2-0;
  }
  .lst-kix_list_15-1 > li {
    counter-increment: lst-ctn-kix_list_15-1;
  }
  .lst-kix_list_26-3 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_3-6 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_36-3 > li {
    counter-increment: lst-ctn-kix_list_36-3;
  }
  .lst-kix_list_21-7 > li:before {
    content: "o  ";
  }
  ol.lst-kix_list_5-0.start {
    counter-reset: lst-ctn-kix_list_5-0 0;
  }
  .lst-kix_list_11-2 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_39-4 > li {
    counter-increment: lst-ctn-kix_list_39-4;
  }
  .lst-kix_list_40-4 > li {
    counter-increment: lst-ctn-kix_list_40-4;
  }
  ol.lst-kix_list_31-7.start {
    counter-reset: lst-ctn-kix_list_31-7 0;
  }
  ol.lst-kix_list_12-4.start {
    counter-reset: lst-ctn-kix_list_12-4 0;
  }
  .lst-kix_list_16-6 > li:before {
    content: " ";
  }
  .lst-kix_list_25-2 > li:before {
    content: "\0025aa  ";
  }
  ol.lst-kix_list_5-6.start {
    counter-reset: lst-ctn-kix_list_5-6 0;
  }
  .lst-kix_list_16-1 > li:before {
    content: " ";
  }
  ol.lst-kix_list_39-0.start {
    counter-reset: lst-ctn-kix_list_39-0 0;
  }
  .lst-kix_list_45-4 > li:before {
    content: "" counter(lst-ctn-kix_list_45-4, lower-latin) ". ";
  }
  .lst-kix_list_45-7 > li:before {
    content: "" counter(lst-ctn-kix_list_45-7, lower-latin) ". ";
  }
  ol.lst-kix_list_7-8.start {
    counter-reset: lst-ctn-kix_list_7-8 0;
  }
  .lst-kix_list_12-4 > li {
    counter-increment: lst-ctn-kix_list_12-4;
  }
  .lst-kix_list_44-8 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_44-3 > li:before {
    content: "(" counter(lst-ctn-kix_list_44-3, lower-latin) ") ";
  }
  .lst-kix_list_44-6 > li {
    counter-increment: lst-ctn-kix_list_44-6;
  }
  .lst-kix_list_39-2 > li:before {
    content: "" counter(lst-ctn-kix_list_39-0, decimal) "." counter(lst-ctn-kix_list_39-1, decimal) "."
      counter(lst-ctn-kix_list_39-2, decimal) " ";
  }
  .lst-kix_list_12-7 > li {
    counter-increment: lst-ctn-kix_list_12-7;
  }
  .lst-kix_list_40-0 > li {
    counter-increment: lst-ctn-kix_list_40-0;
  }
  .lst-kix_list_17-2 > li:before {
    content: "Part " counter(lst-ctn-kix_list_17-2, decimal) " ";
  }
  ol.lst-kix_list_5-5.start {
    counter-reset: lst-ctn-kix_list_5-5 0;
  }
  .lst-kix_list_30-0 > li:before {
    content: "-  ";
  }
  ol.lst-kix_list_17-3.start {
    counter-reset: lst-ctn-kix_list_17-3 0;
  }
  ol.lst-kix_list_40-1.start {
    counter-reset: lst-ctn-kix_list_40-1 0;
  }
  .lst-kix_list_45-7 > li {
    counter-increment: lst-ctn-kix_list_45-7;
  }
  .lst-kix_list_17-5 > li:before {
    content: " ";
  }
  ol.lst-kix_list_36-6.start {
    counter-reset: lst-ctn-kix_list_36-6 0;
  }
  .lst-kix_list_27-2 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_43-4 > li:before {
    content: "o  ";
  }
  .lst-kix_list_22-3 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_7-1 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_13-5 > li {
    counter-increment: lst-ctn-kix_list_13-5;
  }
  .lst-kix_list_48-3 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_9-6 > li {
    counter-increment: lst-ctn-kix_list_9-6;
  }
  ol.lst-kix_list_42-6.start {
    counter-reset: lst-ctn-kix_list_42-6 0;
  }
  ol.lst-kix_list_5-4.start {
    counter-reset: lst-ctn-kix_list_5-4 0;
  }
  ol.lst-kix_list_5-1.start {
    counter-reset: lst-ctn-kix_list_5-1 0;
  }
  .lst-kix_list_16-6 > li {
    counter-increment: lst-ctn-kix_list_16-6;
  }
  .lst-kix_list_11-6 > li {
    counter-increment: lst-ctn-kix_list_11-6;
  }
  .lst-kix_list_31-7 > li:before {
    content: "" counter(lst-ctn-kix_list_31-7, lower-latin) ". ";
  }
  ol.lst-kix_list_17-8.start {
    counter-reset: lst-ctn-kix_list_17-8 0;
  }
  .lst-kix_list_3-8 > li {
    counter-increment: lst-ctn-kix_list_3-8;
  }
  .lst-kix_list_4-6 > li {
    counter-increment: lst-ctn-kix_list_4-6;
  }
  .lst-kix_list_1-5 > li {
    counter-increment: lst-ctn-kix_list_1-5;
  }
  .lst-kix_list_37-1 > li {
    counter-increment: lst-ctn-kix_list_37-1;
  }
  ol.lst-kix_list_17-5.start {
    counter-reset: lst-ctn-kix_list_17-5 0;
  }
  .lst-kix_list_4-2 > li:before {
    content: "" counter(lst-ctn-kix_list_4-0, decimal) "." counter(lst-ctn-kix_list_4-1, decimal) "."
      counter(lst-ctn-kix_list_4-2, decimal) " ";
  }
  .lst-kix_list_17-4 > li {
    counter-increment: lst-ctn-kix_list_17-4;
  }
  .lst-kix_list_15-2 > li:before {
    content: " ";
  }
  .lst-kix_list_36-3 > li:before {
    content: "(" counter(lst-ctn-kix_list_36-3, lower-latin) ") ";
  }
  .lst-kix_list_10-8 > li {
    counter-increment: lst-ctn-kix_list_10-8;
  }
  .lst-kix_list_9-1 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_10-6 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_40-7 > li {
    counter-increment: lst-ctn-kix_list_40-7;
  }
  ol.lst-kix_list_12-7.start {
    counter-reset: lst-ctn-kix_list_12-7 0;
  }
  .lst-kix_list_15-8 > li {
    counter-increment: lst-ctn-kix_list_15-8;
  }
  .lst-kix_list_40-8 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_41-5 > li {
    counter-increment: lst-ctn-kix_list_41-5;
  }
  ol.lst-kix_list_36-8.start {
    counter-reset: lst-ctn-kix_list_36-8 0;
  }
  .lst-kix_list_37-8 > li {
    counter-increment: lst-ctn-kix_list_37-8;
  }
  .lst-kix_list_31-3 > li {
    counter-increment: lst-ctn-kix_list_31-3;
  }
  ol.lst-kix_list_42-8.start {
    counter-reset: lst-ctn-kix_list_42-8 0;
  }
  .lst-kix_list_41-4 > li:before {
    content: "(" counter(lst-ctn-kix_list_41-4, lower-roman) ") ";
  }
  ol.lst-kix_list_12-8.start {
    counter-reset: lst-ctn-kix_list_12-8 0;
  }
  .lst-kix_list_20-3 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_29-2 > li:before {
    content: "\0025aa  ";
  }
  .lst-kix_list_8-8 > li {
    counter-increment: lst-ctn-kix_list_8-8;
  }
  ol.lst-kix_list_17-6.start {
    counter-reset: lst-ctn-kix_list_17-6 0;
  }
  .lst-kix_list_28-6 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_1-6 > li:before {
    content: "Not Defined ";
  }
  .lst-kix_list_42-3 > li {
    counter-increment: lst-ctn-kix_list_42-3;
  }
  .lst-kix_list_33-7 > li:before {
    content: "o  ";
  }
  ol.lst-kix_list_42-7.start {
    counter-reset: lst-ctn-kix_list_42-7 0;
  }
  .lst-kix_list_40-0 > li:before {
    content: "" counter(lst-ctn-kix_list_40-0, decimal) ". ";
  }
  .lst-kix_list_12-6 > li:before {
    content: " ";
  }
  .lst-kix_list_34-3 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_list_2-2 > li:before {
    content: "" counter(lst-ctn-kix_list_2-0, decimal) "." counter(lst-ctn-kix_list_2-1, decimal) "."
      counter(lst-ctn-kix_list_2-2, decimal) " ";
  }
  .lst-kix_list_13-2 > li:before {
    content: "Part " counter(lst-ctn-kix_list_13-2, decimal) " ";
  }
  ol.lst-kix_list_5-2.start {
    counter-reset: lst-ctn-kix_list_5-2 0;
  }
  ol {
    margin: 0;
    padding: 0;
  }
  table td,
  table th {
    padding: 0;
  }
  .c21 {
    border-right-style: solid;
    padding-top: 1pt;
    border-top-width: 0.5pt;
    border-bottom-color: #000000;
    border-right-width: 0.5pt;
    padding-left: 4pt;
    border-left-color: #000000;
    padding-bottom: 0pt;
    line-height: 1;
    border-right-color: #000000;
    border-left-width: 0.5pt;
    border-top-style: solid;
    background-color: #dbe5f1;
    border-left-style: solid;
    border-bottom-width: 0.5pt;
    border-top-color: #000000;
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: justify;
    padding-right: 4pt;
  }
  .c14 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 100%;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c8 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 100%;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c29 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 100%;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c26 {
    -webkit-text-decoration-skip: none;
    color: #1155cc;
    font-weight: 400;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-size: 10pt;
    font-family: "Verdana";
    font-style: normal;
  }
  .c5 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 10pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c34 {
    -webkit-text-decoration-skip: none;
    color: #000000;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-size: 10pt;
    font-family: "Verdana";
    font-style: normal;
  }
  .c1 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 10pt;
    font-family: "Verdana";
    font-style: normal;
  }
  .c4 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: justify;
    height: 10pt;
  }
  .c12 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 7pt;
    font-family: "Verdana";
    font-style: normal;
  }
  .c2 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 10pt;
    font-family: "Verdana";
    font-style: normal;
  }
  .c15 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 7.9pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .c22 {
    padding-top: 0pt;
    padding-bottom: 10pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .c25 {
    padding-top: 6pt;
    padding-bottom: 6pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: center;
  }
  .c30 {
    padding-top: 12pt;
    padding-bottom: 10pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .c23 {
    padding-top: 0pt;
    padding-bottom: 7.5pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .c0 {
    padding-top: 0pt;
    padding-bottom: 10pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .c9 {
    padding-top: 12pt;
    padding-bottom: 10pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: center;
  }
  .c19 {
    padding-top: 0pt;
    padding-bottom: 10pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: center;
  }
  .c18 {
    padding-top: 0pt;
    padding-bottom: 10pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: center;
  }
  .c32 {
    padding-top: 0pt;
    padding-bottom: 10pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c31 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .c35 {
    margin-left: 36.9pt;
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto;
  }
  .c27 {
    margin-left: 42.2pt;
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto;
    width: -webkit-fill-available;
  }
  .c10 {
    margin-left: 60.5pt;
    padding-left: 24.5pt;
    page-break-after: avoid;
  }
  .c36 {
    background-color: #ffffff;
    max-width: 453.5pt;
    padding: 70.9pt 70.9pt 70.9pt 70.9pt;
  }
  .c7 {
    padding: 0;
    margin: 0;
  }
  .c11 {
    margin-left: 42.5pt;
    text-indent: -42.5pt;
  }
  .c13 {
    margin-left: 60.5pt;
    padding-left: 24.5pt;
  }
  .c6 {
    margin-left: 18pt;
    padding-left: 24.5pt;
  }
  .c37 {
    color: inherit;
    text-decoration: inherit;
  }
  .c24 {
    height: 0pt;
  }
  .c33 {
    height: 312.1pt;
  }
  .c16 {
    font-weight: 700;
  }
  .c3 {
    height: 92.8pt;
  }
  .c38 {
    height: 70.1pt;
  }
  .c39 {
    font-size: 7pt;
  }
  .c17 {
    page-break-after: avoid;
  }
  .c28 {
    height: 38.9pt;
  }
  .c20 {
    height: 10pt;
  }
  .title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: "Verdana";
    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  li {
    color: #000000;
    font-size: 10pt;
    font-family: "Verdana";
  }
  p {
    margin: 0;
    color: #000000;
    font-size: 10pt;
    font-family: "Verdana";
  }
  h1 {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 6pt;
    font-family: "Verdana";
    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  h2 {
    padding-top: 18pt;
    color: #000000;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 4pt;
    font-family: "Verdana";
    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Verdana";
    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;
    font-family: "Verdana";
    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;
    font-family: "Verdana";
    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    font-family: "Verdana";
    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
}