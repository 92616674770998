@import "./LiveStreamApp.scss";

.live-stream-app {
  @import "./BrowserUnsupported.scss";
  @import "./Call.scss";
  @import "./CallMessage.scss";
  @import "./StartButton.scss";
  @import "./Tile.scss";
  @import "./Tray.scss";
  @import "./TrayButton.scss";
}

.call {
  &__header {
    &__item {
      background-color: $white;
      border-radius: 4px;
      border-radius: 8px;

      &__timer {
        text-align: center;
      }

      &--divider {
        margin: 0 12px;
        width: 1px;
        height: 24px;
        background-color: $gray6;
      }

      * {
        color: $default !important;
        line-height: 24px;
      }
    }
  }
}
