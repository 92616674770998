.landing-page {
  @include desktopLarge {
    padding-top: calc(92px + 19px) !important;
    background-image: url(/static/assets/images/background-top.png);
    background-repeat: no-repeat;
    background-size: 100% 840px;
  }

  @media (min-width: #{$desktop-width}) and (max-width: #{$desktop-large - 1px}) {
    padding-top: calc(92px + 19px) !important;
    background-image: url(/static/assets/images/background-top.png);
    background-repeat: no-repeat;
    background-size: 100% 812px;
  }

  @include tablet {
    padding-top: calc(41px + 70px) !important;
    background-image: url(/static/assets/images/background-top-mb.png);
    background-size: contain;
    display: block;
  }

  @include sm {
    padding-top: calc(41px + 70px) !important;
    background-image: url(/static/assets/images/background-top-mb.png);
    background-size: contain;
    display: block;
  }

  @include mobile {
    padding-top: calc(41px + 70px) !important;
    background-image: url(/static/assets/images/background-top-mb.png);
    background-size: contain;
    display: block;
  }

  .ReactTypeformEmbed {
    display: none;
  }

  &__container {
    padding: 0 88px;

    @include tablet {
      padding: 0 32px;
    }

    @include sm {
      padding: 0 32px;
    }

    @include mobile {
      padding: 0 24px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding-top: 80px;
    min-height: 700px;

    @include tablet {
      display: block;
      padding-top: 0;
    }

    @include sm {
      display: block;
      padding-top: 0;
    }

    @include mobile {
      display: block;
      padding-top: 0;
    }

    .ant-col {
      &:first-child {
        max-width: 616px;
        flex: calc(616 / 552);
        padding-top: 10rem;

        @include mobile {
          flex: auto;
          padding-top: 0;
        }
      }

      &:last-child {
        flex: 1;
        max-width: 552px;

        @include tablet {
          flex: auto;
        }

        @include sm {
          flex: auto;
        }

        @include mobile {
          flex: auto;
        }
      }
    }

    .join-waitlist__btn__wrapper {
      text-align: left;

      @include tablet {
        text-align: center;
      }

      @include sm {
        text-align: center;
      }

      @include mobile {
        text-align: center;
      }
    }

    &--left {
      width: 40%;
      margin-top: 86px;

      @include tablet {
        width: 100%;
        padding: 0 32px;
        margin-top: 32px;
      }

      @include sm {
        width: 100%;
        padding: 0 32px;
        margin-top: 32px;
      }

      @include mobile {
        width: 100%;
        padding: 0 16px;
        margin-top: 24px;
      }
    }

    .lf-player-container {
      width: get-vw(409px);

      @media (min-width: $vw-viewport-px) {
        width: 409px;
      }
    }

    &__title {
      div {
        font-weight: bold !important;
        font-size: 72px;
        line-height: 86px;
        color: $alto !important;
        mix-blend-mode: color-dodge;
        opacity: 0.8;

        @include tablet {
          font-size: 56px;
          line-height: 67px;
          text-align: center;
        }

        @include sm {
          font-size: 56px;
          line-height: 67px;
          text-align: center;
        }

        @include mobile {
          font-size: 40px;
          line-height: 48px;
          text-align: center;
        }
      }
    }

    &__secondary__title {
      font-size: 20px;
      line-height: 40px;

      @include tablet {
        text-align: center;
      }

      @include sm {
        text-align: center;
      }

      @include mobile {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
      }
    }

    &--image {
      width: 552px;
      height: 608px;
      margin-right: 88px;

      @include tablet {
        width: unset;
        height: unset;
        background-image: url(/static/assets/images/background-top-2.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: bottom;
        margin-right: 0;
      }

      @include sm {
        width: unset;
        height: unset;
        background-image: url(/static/assets/images/background-top-2.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: bottom;
        margin-right: 0;
      }

      @include mobile {
        width: unset;
        height: unset;
        background-image: url(/static/assets/images/background-top-2.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: bottom;
        margin-right: 0;
      }
    }

    img {
      width: 100%;
      transform: translate(23px, 0);

      @include tablet {
        margin-right: 0;
        margin-top: 32px;
        width: 100%;
        transform: translate(0, 0);
      }

      @include sm {
        margin-right: 0;
        margin-top: 32px;
        width: 100%;
        transform: translate(0, 0);
      }

      @include mobile {
        margin-right: 0;
        margin-top: 32px;
        width: 100%;
        transform: translate(0, 0);
      }
    }
  }

  &__partners {
    height: 600px;
    position: relative;
    background-image: url(/static/assets/images/background-partner.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: right;
    margin-top: 106px;

    @include tablet {
      margin-top: 0;
      padding-top: 40px;
      background-image: url(/static/assets/images/background-partner-tablet.png);
      min-height: 700px;
    }

    @include sm {
      margin-top: 40px;
      background-image: url(/static/assets/images/background-partner-tablet.png);
      min-height: 700px;
    }

    @include mobile {
      margin-top: 0;
      background-image: url(/static/assets/images/background-partner-mobile.png);
      height: 516px;
    }

    &--wrapper {
      padding-left: calc((100vw - 1264px) / 2);

      @media (min-width: #{$desktop-width}) and (max-width: 1312px) {
        padding-left: 24px;
      }

      @include tablet {
        padding-left: 32px;
      }

      @include sm {
        padding-left: 32px;
      }

      @include mobile {
        padding-left: 0;
      }
    }

    .position--inherit {
      position: inherit;
    }

    &--title {
      padding-right: 107px;

      @include tablet {
        padding-right: 0;
      }

      @include sm {
        padding-right: 0;
      }

      @include mobile {
        padding-right: 0;
      }
      span {
        line-height: 54px !important;

        @include mobile {
          line-height: 42px !important;
        }
      }
    }
  }

  .join-waitlist-section {
    margin-top: -190px;
    padding-top: 190px;
    background-image: url(/static/assets/images/background-waitlist.png);
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: cover;

    @include tablet {
      padding: 190px 64px 0 64px;
    }

    @include sm {
      padding: 190px 64px 0 64px;
    }

    @include mobile {
      padding: 190px 0 0 0;
    }

    &-wrapper {
      padding-top: 92px !important;
      padding-bottom: 92px !important;

      @include tablet {
        padding-top: 64px !important;
        padding-bottom: 64px !important;
      }

      @include sm {
        padding-top: 64px !important;
        padding-bottom: 64px !important;
      }

      @include mobile {
        padding-top: 48px !important;
        padding-bottom: 48px !important;
      }
    }
  }

  &__customize-link {
    background-image: url(/static/assets/images/background-customize-link.png);
    background-repeat: no-repeat;
    background-size: cover;

    @include tablet {
      background-image: url(/static/assets/images/background-customize-link.png);
    }

    @include sm {
      background-image: url(/static/assets/images/background-customize-link.png);
    }

    @include mobile {
      background-image: url(/static/assets/images/background-customize-link.png);
    }

    .ant-typography {
      opacity: 0.8;

      &.active {
        font-weight: 600 !important;
        font-size: 20px;
        line-height: 28px;
        opacity: 1;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 12px;
      }
    }

    &__images {
      @include mobile {
        padding-right: 24px;
      }
    }

    &__items {
      margin-top: 24px;

      /* FireFox */
      scrollbar-width: none;

      /* IE10+ */
      -ms-overflow-style: -ms-autohiding-scrollbar;

      &::-webkit-scrollbar {
        /* Chrome, Safari, Edge */
        -webkit-appearance: none;
        display: none;
      }

      > div {
        width: 422px;

        @include tablet {
          flex: none;
          width: auto;
        }

        @include sm {
          flex: none;
          width: auto;
        }

        @include mobile {
          flex: none;
          width: auto;
        }
      }

      @include tablet {
        margin-top: 16px;
        display: flex;
        overflow-x: auto;
        align-items: center;
        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 0;
          height: 0;
        }
        span {
          min-width: max-content;
          margin-right: 16px;
        }
      }

      @include sm {
        margin-top: 16px;
        display: flex;
        overflow-x: auto;
        align-items: center;

        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 0;
          height: 0;
        }

        span {
          min-width: max-content;
          margin-right: 16px;
        }
      }

      @include mobile {
        margin-top: 0;
        display: flex;
        overflow-x: auto;
        align-items: center;

        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 0;
          height: 0;
        }

        span {
          min-width: max-content;
          margin-right: 8px;
        }
      }
    }

    &__wrapper {
      display: flex;
      justify-content: center;

      .lf-player-container {
        transform: translateY(5px);

        @include mobile {
          height: 336px;
        }

        @include tablet {
          transform: translateY(15px);
          height: 522px;
        }
      }

      .fade-in-animation {
        width: 0;

        &.active {
          width: 470px;
          -webkit-animation: fadein 0.3s;
          -moz-animation: fadein 0.3s;
          -ms-animation: fadein 0.3s;
          -o-animation: fadein 0.3s;
          animation: fadein 0.3s;

          @include tablet {
            width: 470px;
          }

          @include sm {
            width: 470px;
          }

          @include mobile {
            width: 311px;
          }
        }
      }
      .customize-link-content {
        padding: 121px 0 154px 70px;

        @include tablet {
          padding: 40px 0 0 0;
        }

        @include sm {
          padding: 40px 0 0 0;
        }

        @include mobile {
          padding: 40px 0 0 0;
        }
      }

      @include tablet {
        flex-direction: column-reverse;
        .fade-in-animation {
          width: 0;
          margin-top: 16px;
        }
      }

      @include sm {
        flex-direction: column-reverse;
        .fade-in-animation {
          width: 0;
          margin-top: 16px;
        }
      }

      @include mobile {
        flex-direction: column-reverse;
        .fade-in-animation {
          width: 0;
        }
      }
    }
  }

  &__benefits {
    background-image: url(/static/assets/images/background-analytic.png);
    background-repeat: no-repeat;
    background-size: cover;
    &--text {
      margin-top: 70px;

      @include tablet {
        margin-top: 16px;
      }

      @include mobile {
        margin-top: 16px;
        padding: 0 24px;
      }
    }

    &--content {
      .analytic-image {
        width: 100%;

        @include tablet {
          margin-top: 40px;
          max-width: 680px;
        }

        @include mobile {
          margin-top: 16px;
        }
      }

      .analytic-content {
        padding-left: 70px;

        @include tablet {
          padding-left: 32px;
        }

        @include mobile {
          padding-left: 24px;
        }
      }

      @include tablet {
        padding: 0;
        flex-direction: column-reverse;
      }

      @include mobile {
        flex-direction: column-reverse;
        padding: 0 24px !important;
      }
    }
    &-mobile-content {
      position: relative;
      height: 500px;
    }

    .benefit_items {
      padding: 8px;

      @include tablet {
        padding: 0 16px;
      }

      @include mobile {
        padding: 0;
      }
    }
    .ant-collapse {
      background: transparent;
      border: none;
      &-item {
        border-bottom: none;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.06)), #121212;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
        margin-bottom: 16px;
        border-radius: 16px !important;
      }
      &-header {
        padding: 16px !important;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.06)), #121212;
        border-radius: 16px !important;
      }
      &-content {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.06)), #121212;
        border-bottom-left-radius: 16px !important;
        border-bottom-right-radius: 16px !important;
        border-top: none !important;
        &-box {
          padding: 0 16px 16px 16px;
        }
      }
      &-item-active {
        .ant-collapse-header {
          padding: 16px 16px 8px 16px !important;
          border-bottom-left-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }
      }
    }
  }

  &__content {
    &__setup {
      @include tablet {
        .lf-player-container {
          padding-bottom: 24px;
        }
      }

      @include sm {
        .lf-player-container {
          padding-bottom: 24px;
        }
      }

      @include mobile {
        .lf-player-container {
          padding-bottom: 24px;
        }
      }
    }

    &__title {
      font-size: get-vw(50px);
      line-height: get-vw(60px);

      @media (min-width: $vw-viewport-px) {
        font-size: 50px;
        line-height: 60px;
      }

      &.fan {
        font-weight: normal;
        max-width: 923px;
      }

      @include tablet {
        font-weight: 500;
        font-size: 32px;
        line-height: 38px;
      }

      @include sm {
        font-weight: 500;
        font-size: 32px;
        line-height: 38px;
      }

      @include mobile {
        font-weight: 500;
        font-size: 32px;
        line-height: 38px;
      }
    }

    &__label {
      color: $apricot !important;
      text-decoration-line: underline;
    }

    &__fan-item {
      .ant-typography {
        line-height: 28px;
        letter-spacing: 0.2px !important;
      }

      @include tablet {
        padding-bottom: 24px;
      }

      @include sm {
        padding-bottom: 24px;
      }

      @include mobile {
        padding-bottom: 24px;
      }
    }

    &__talent {
      &__wrapper {
        text-align: right;
        margin-top: get-vw(-180px);

        @media (min-width: $vw-viewport-px) {
          margin-top: -180px;
        }

        img {
          width: get-vw(665px);

          @media (min-width: $vw-viewport-px) {
            width: 665px;
          }
        }

        @include tablet {
          margin-top: 32px;

          img {
            width: 100%;
          }
        }

        @include sm {
          margin-top: 32px;

          img {
            width: 100%;
          }
        }

        @include mobile {
          margin-top: 32px;

          img {
            width: 100%;
          }
        }
      }
    }
  }

  .text--regular22 {
    font-size: get-vw(22px) !important;

    @media (min-width: $vw-viewport-px) {
      font-size: 22px !important;
    }

    @include mobile {
      font-size: get-vw(22px) !important;
    }
  }

  .text--regular20 {
    line-height: 28px !important;
  }

  .text--regular32 {
    font-size: 32px;
    line-height: 38px;
  }

  .text--bold32 {
    line-height: 36px;
  }

  .text--medium22 {
    font-size: get-vw(22px) !important;
    line-height: get-vw(28px) !important;

    @media (min-width: $vw-viewport-px) {
      font-size: 22px !important;
      line-height: 28px !important;
    }

    @include mobile {
      font-size: 22px !important;
      line-height: 28px !important;
    }
  }

  .text--medium32 {
    font-size: get-vw(32px) !important;
    line-height: get-vw(42px) !important;

    @media (min-width: $vw-viewport-px) {
      font-size: 32px !important;
      line-height: 42px !important;
    }

    @include mobile {
      font-size: 32px !important;
      line-height: 42px !important;
    }
  }

  .m__t--32 {
    margin-top: get-vw(32px) !important;

    @media (min-width: $vw-viewport-px) {
      margin-top: 32px !important;
    }

    @include mobile {
      margin-top: 32px !important;
    }
  }

  .m__t--72 {
    margin-top: get-vw(72px) !important;

    @media (min-width: $vw-viewport-px) {
      margin-top: 72px !important;
    }

    @include mobile {
      margin-top: 72px !important;
    }
  }

  .m__t--80 {
    margin-top: get-vw(80px) !important;

    @media (min-width: $vw-viewport-px) {
      margin-top: 80px !important;
    }

    @include mobile {
      margin-top: 80px !important;
    }
  }

  .m__t--100 {
    margin-top: get-vw(100px) !important;

    @media (min-width: $vw-viewport-px) {
      margin-top: 100px !important;
    }

    @include mobile {
      margin-top: 100px !important;
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
