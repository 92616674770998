.request-password-success {
  text-align: center;

  &__icon__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__icon {
    width: 84px;
    height: 84px;
    border-radius: 100%;
    background: $primary-gradient;
  }
}
