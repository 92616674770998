.user-wrapper {
  .account-setting__card {
    border-radius: 16px;

    .ant-card-body {
      padding: 20px 20px 32px;

      @include mobile {
        padding: 16px 16px 20px;
      }
    }
  }

  .section-header__title {
    flex: 2 !important;
  }

  .btn-back {
    height: 24px;

    a {
      height: 24px;
    }

    @include mobile {
      margin-bottom: 16px;
    }
  }

  .ant-tabs {
    &-nav {
      justify-content: center;
      height: 56px;
      //margin: 0 0 24px 0 !important;
      position: absolute !important;
      left: 0;
      width: 100%;
      padding-left: 16px;

      &::before {
        border-bottom: 1px solid rgba($white, 0.2) !important;
      }

      .ant-tabs-nav-wrap {
        max-width: $container;
        margin: 0 !important;
        width: 100%;

        &::before,
        &::after {
          display: none;
        }
      }

      .ant-tabs-tab {
        margin: 0 64px 0 0;
        color: $white !important;

        &:hover {
          color: $white;
        }

        &-active {
          .ant-tabs-tab-btn {
            color: $white !important;
          }
        }

        .card-tab {
          text-align: center !important;
        }
      }
    }

    &-content {
      margin-top: 80px;

      @include mobile {
        margin-top: 24px;
        padding-top: 8px;
      }
    }

    &-content-holder {
      max-width: $container;
      margin: auto;
      width: 100%;
    }

    &.user-tabs > .ant-tabs-nav {
      .ant-tabs-tab:first-child {
        margin-left: 64px;
      }
    }

    .experience-tabs {
      .ant-tabs-tab {
        .card-tab {
          width: 72px;
        }
      }
    }

    .account-tabs {
      .ant-tabs-tab {
        .card-tab {
          width: 120px;
        }
      }
    }

    @include mobile {
      margin-top: 24px;

      &-nav {
        height: 40px;

        &::before {
          display: none;
        }

        &-list {
          z-index: 999;
        }
      }

      &-ink-bar {
        display: none;
      }

      &-tab-btn {
        padding: 6px 16px;
        font-size: 14px;
        line-height: 24px;
        height: 36px;
        font-weight: 500;
      }

      &-tab {
        padding: 0;
        margin-right: 12px !important;

        .ant-tabs-tab-btn {
          border: 1px solid $border;
          border-radius: 8px;
          height: 40px;
        }

        &:hover {
          .ant-tabs-tab-btn {
            color: $default !important;
          }
        }

        &-active {
          .ant-tabs-tab-btn {
            font-weight: 500 !important;
            border: 2px solid $primary;
          }
        }
      }
    }
  }
}

.otp-input-mobile {
  width: 48px !important;
  height: 48px;
  border: 1px solid $border;
  border-radius: 16px;
  outline: none;

  &--separator {
    width: 8px;
    display: block;
  }

  &--focus {
    border: 2px solid $primary;
  }
}

.otp-input {
  width: 56px !important;
  height: 56px;
  border: 1px solid $border;
  border-radius: 16px;
  outline: none;

  &--separator {
    width: 20px;
    display: block;
  }

  &--focus {
    border: 2px solid $primary;
  }
}

button.btn-signin {
  height: 48px !important;
}

button.btn-reset-password {
  height: 52px !important;
}

button.btn-login-social {
  height: 52px;
  padding: 0;
  margin-top: 16px;
  border-width: 2px;
  border-radius: 124px;

  .ant-col {
    display: flex;
    align-items: center;
  }

  .ant-typography {
    text-align: left;
  }
}
button.btn-email-exclusive {
  height: 48px;
  margin-top: 30px;
  margin-bottom: 12px;
  background-color: $gray7;
  border: none;
  &:hover {
    background-color: $gray6;
  }

  @include mobile {
    margin-top: 24px;
    margin-bottom: 16px;
    background-color: $seashell !important;
    border: none;
  }
  span {
    @include mobile {
      font-size: 12px !important;
    }
  }
}

.signin-divider {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0;

  @include mobile {
    margin: 16px 0;
  }

  .divider {
    height: 1px;
    display: block;
    background-color: #e9e9eb;
    flex: 1;

    @include mobile {
      width: calc(50% - 88px);
    }
  }

  span:nth-child(2) {
    font-size: 16px;
    height: 24px;
    background-color: white;
    padding: 0 16px;
    display: block;
    text-align: center;
  }
}

.react-tel-input {
  color: $default !important;
  border-radius: 8px;

  .selected-flag {
    padding: 0 12px 0 12px;
    border-radius: 8px 0 0 8px;
    width: 66px;

    .arrow {
      background-image: url("/static/assets/icons/sDown.svg");
      width: 24px;
      height: 24px;
      border: none;
      top: 50%;
      left: 24px;
      transform: translate(0, -50%);
      margin-top: 0;

      &.up {
        background-image: url("/static/assets/icons/up.svg");
        border: none;
      }
    }
  }

  .flag-dropdown.open {
    z-index: 9999 !important;
  }

  .phone-button-select {
    height: 52px !important;
    border: none !important;
    background: none !important;
    border-right: 1px solid $mercury !important;
  }

  .form-control {
    padding-left: 78px;
    height: 52px !important;
    width: 100% !important;
    border: none !important;
    border-radius: 8px !important;
  }

  .phone-input {
    color: $default !important;
  }

  &:hover,
  &:focus {
    color: $default;
  }
}

.phone-input {
  border: none;
  background-color: transparent;
}

.create-account-modal,
.login-modal {
  width: 540px !important;
}

.create-account-modal,
.login-modal,
.sign-up-modal,
.request-talent-profile-modal {
  &.collaborator-invitation {
    margin-top: 66px;

    @include mobile {
      height: calc(100% - 93px);
    }

    .ant-modal-content {
      @include mobile {
        height: 100%;
      }
    }

    .ant-modal-body {
      max-height: 60vh;

      @include mobile {
        max-height: calc(100vh - 209px);
      }
    }

    &::before {
      content: "";
      background-image: url(/static/assets/images/logo-landing-page.svg);
      width: 80px;
      height: 26px;
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -66px);

      @include mobile {
        width: 68px;
        height: 21px;
        background-image: url(/static/assets/images/logo-landing-page-mobile.svg);
        transform: translate(-50%, -45px);
      }
    }
  }

  &.home-page {
    .ant-modal-body {
      padding-bottom: 32px;
    }

    .ant-modal-footer {
      background-color: $gray7;
      border-top: none;

      img,
      svg {
        margin: 16px 0 !important;
      }
    }
  }

  &.forgot-password {
    .ant-modal-body {
      padding-top: 0;
      padding-bottom: 8px;
    }
  }

  &.compact {
    .ant-modal-header {
      padding-bottom: 0 !important;
      min-height: auto;
    }
  }

  .error-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 10px;
    background: #ffeaee;
    gap: 8px;
    border-radius: 8px;
  }

  .ant-btn-primary {
    &.home-page {
      background: $default;

      span {
        color: $white !important;
      }
    }
  }

  .ant-input-disabled {
    opacity: 0.6;
  }

  .input-email {
    height: 100%;
    border: none;
    border-radius: 123px;
    padding: 4px 8px;

    .ant-input-suffix {
      margin-left: 0;
    }

    input {
      background: transparent;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      padding: 0 40px;

      &:active,
      &:focus,
      &:hover {
        border: none;
      }

      &::placeholder {
        color: rgba($default, 0.5);
      }
    }

    .ant-btn-circle {
      width: 32px;
      height: 32px;
      background: $primary;
      position: absolute;
      top: 8px;
      right: 8px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;

      &.home-page {
        background-color: $default;

        svg path {
          stroke: $white;
        }
      }

      &:disabled {
        background-color: $alto !important;
      }
    }

    &__wrapper {
      height: 52px;
      border-radius: 123px;
      padding: 2px;
      background-color: $mercury;

      &.active {
        background: linear-gradient(to left, #17e391, #58e5df, #60e5e8);
      }
    }
  }

  @include mobile {
    left: 0;
    margin: 0 !important;
    max-width: 100vw !important;
    padding: 0 !important;
    bottom: 0;
    top: auto !important;
    position: absolute !important;

    .ant-modal-content {
      border-radius: 16px 16px 0 0 !important;
    }
  }

  .ant-modal-header {
    padding: 24px !important;
    border-bottom: none !important;

    @include mobile {
      padding: 16px !important;
    }
  }

  .ant-modal-close {
    top: 24px !important;

    @include mobile {
      top: 16px !important;
    }
  }

  .ant-modal-body {
    padding: 8px 24px 22px;
    max-height: 70vh;
    overflow: auto;

    @include mobile {
      padding: 8px 16px 24px;
      max-height: calc(100vh - 380px);
    }
  }

  .ant-modal-footer {
    padding: 0;
    text-align: center !important;
    border-top: none;

    .divider {
      height: 2px;
      background: linear-gradient(270deg, #17e391 21.47%, #58e5df 77.04%, #60e5e8 77.05%);
    }

    img {
      margin: 12px 0;
    }
  }

  .ant-form-item {
    margin-bottom: 16px !important;

    &.m__b--0 {
      margin-bottom: 0 !important;
    }

    .ant-input-group {
      display: flex;

      .ant-select {
        flex: 1;
        &-selection-placeholder {
          color: $default;
          font-size: $medium1;
          font-weight: $regular;
          padding-left: 3px;
        }
        &:nth-child(2) {
          margin: 0 14px;
        }
      }
    }
  }

  .ant-form-item-explain {
    margin: 10px 0 0 !important;
  }

  .ant-radio-group {
    display: flex;
    justify-content: space-between;

    .ant-radio-button-wrapper {
      width: 100%;
      border-radius: 8px;
      text-align: center;
      background: $white !important;
      border: 1px solid $border;

      &::before {
        display: none;
      }

      &-checked {
        &:hover {
          color: $default !important;
        }
      }
    }
  }

  .ant-select-selection-placeholder {
    color: $default;
  }
}

.sign-up-modal-entry {
  min-width: min(90%, 910px);

  .ant-modal-body {
    padding: 0;
    overflow: hidden;
  }

  &__content {
    &--left {
      padding: 0 24px 24px;
    }
    &--right {
      .profile-examples {
        width: calc(100% + 1px);
        height: 100%;
        background-image: url(/static/assets/images/signup/background-with-profiles.jpg);
        background-size: cover;
        background-position-x: center;
        overflow: hidden;
      }
    }
  }
}

.create-account-modal {
  @include mobile {
    padding-top: 24px;
  }
  .term-checkbox {
    background-color: $white;

    @include mobile {
      margin-top: 14px;
      border: none;
    }

    .ant-checkbox-group-item {
      width: 100%;
      padding: 12px;
      border: 2px solid $mercury;
      border-bottom-width: 1px;
      border-radius: 8px 8px 0 0;

      .ant-checkbox + span {
        padding: 0 0 0 8px;
      }

      .ant-checkbox-inner {
        border-color: $mercury;
        width: 20px;
        height: 20px;
      }

      @include mobile {
        padding: 0 0 16px 0;
        border: none;
      }

      &:nth-child(2) {
        border-radius: 0 0 8px 8px;
        border-top: none;
        border-bottom-width: 2px;

        @include mobile {
          padding-top: 16px;
          padding-bottom: 0;
          border-top: 1px solid $mercury;
        }
      }
    }

    &--single {
      .ant-checkbox-group-item {
        border-radius: 8px;
        border-bottom-width: 2px;

        @include mobile {
          padding-bottom: 0;
        }
      }
    }

    span {
      color: $default;
    }
  }

  .ant-form-item-has-error {
    .term-checkbox {
      .ant-checkbox-inner {
        border-color: $red;
      }

      .ant-checkbox-group-item {
        border: 2px solid $red;

        @include mobile {
          border: none;
        }

        &:nth-child(2) {
          border-color: $mercury;
          border-top: none;

          .ant-checkbox-inner {
            border-color: $mercury;
          }

          @include mobile {
            border-top: 1px solid $mercury;
          }
        }
      }
    }
  }

  .ant-btn-primary {
    &:disabled {
      background-color: $alto !important;

      span {
        color: $white !important;
      }
    }
  }

  &-title {
    font-weight: $medium !important;
    font-size: $normal1 !important;
    line-height: 32px;

    @include mobile {
      font-size: $medium2 !important;
      line-height: 24px;
    }
  }
  &-sub-title {
    font-weight: $regular !important;
    font-size: $medium2 !important;
    line-height: 32px;
    padding-right: 60px;

    &.user-existed {
      padding-right: 0;
    }

    @include mobile {
      font-size: $small !important;
      line-height: 24px;
      padding-right: 40px;
    }
  }
  .terms-service {
    &-link {
      font-weight: $medium;
      font-size: $small !important;

      @include mobile {
        font-size: $x-small !important;
      }
    }

    @include mobile {
      font-size: $x-small !important;
    }
  }
}

.password-hint {
  &__icon {
    width: 20px;
    height: 20px;
    margin-top: 4px;
  }
}

svg.password-hint__icon path {
  stroke: $default !important;
}
