.anchor {
  &__item {
    display: block;
    min-height: 40px;
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 8px;
    margin-bottom: 24px;

    &--active.ant-typography {
      background: $primary-gradient;
      color: $default !important;
    }
  }
}
