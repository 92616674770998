.tray {
  background-color: $white;
  padding: 10px 24px;

  .livestream__actions {
    .ant-typography {
      color: $default;
    }

    &__button {
      border: 2px solid $gray5;
      width: 54px !important;
      height: 54px !important;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 28px !important;
        height: 28px !important;
      }

      &.active {
        background-color: $primary;
      }

      &--tip {
        border: 2px solid $gray5;
        height: 48px !important;

        .ant-typography {
          color: $default !important;
        }
      }
    }

    &__tag {
      height: 22px;
      border-radius: 4px;
      display: flex;
      border: none;
      align-items: flex-end;

      span {
        line-height: 20px;
        height: 20px;
        display: block;
      }
    }
  }

  .ant-btn.btn-share-screen {
    width: auto !important;
    border: none !important;
    height: 100% !important;

    .ant-typography {
      color: $default !important;
    }

    &:hover,
    &:active,
    &:focus {
      border: none !important;
    }

    svg * {
      stroke: $default;
    }

    &.ant-btn-red {
      border: none !important;

      &:hover,
      &:active,
      &:focus {
        border: none !important;
      }

      svg * {
        stroke: $red;
      }
    }
  }
}
