.user-layout {
  aside.ant-layout-sider.mobile-sider {
    background: $shark;
    height: calc(100vh - 81px);
    top: 81px;
    left: 0;

    .ant-menu {
      border: none;
      font-size: 14px;
      background-color: transparent;

      &-item {
        width: auto;
        height: 48px;
        display: flex;
        align-items: center;
        padding: 12px 16px !important;
        margin: 16px 0 0 !important;
        opacity: 0.8;

        @include mobile {
          padding: 8px !important;
        }

        &:first-child {
          margin: 0 !important;
        }

        svg path {
          stroke: $white;
        }

        a {
          font-weight: $semi-bold;

          .ant-typography {
            color: $white;
          }
        }

        &-selected {
          background-color: rgba(96, 229, 232, 0.1) !important;

          a {
            font-weight: $semi-bold;
          }
        }

        &-active,
        &-selected {
          opacity: 1;
          border-radius: 50px;

          svg path {
            stroke: $primary !important;
          }

          .ant-typography {
            color: $primary !important;
          }
        }

        &-active {
          font-weight: $semi-bold;

          a {
            font-weight: $semi-bold;
            .ant-typography {
              color: $white;
            }
          }
        }

        &::after {
          border: none !important;
        }

        &:hover {
          svg path {
            stroke: $primary;
          }

          .ant-typography {
            color: $primary !important;
          }
        }
      }

      &-submenu {
        margin-top: 16px;
        padding: 0;

        &-icon {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(0, -50%);
          transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
            -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }

        &-arrow {
          display: none;
        }

        &-active,
        &-selected {
          color: $default;

          svg path {
            stroke: $default;
          }

          a {
            color: $default;
          }

          i::before,
          i::after {
            background: $default !important;
          }
        }

        &-title {
          width: 200px;
          padding: 0 0 0 12px !important;
          margin: 0;

          &:hover {
            color: $default;
          }
        }

        .ant-menu-submenu-title {
          font-weight: $semi-bold;
        }

        &-open,
        &-active {
          .ant-menu-submenu-title {
            font-weight: $semi-bold;
          }
        }

        &-open {
          .ant-menu-submenu-icon {
            transform: rotate(90deg) translateX(-50%);
          }
        }
      }

      .ant-menu-sub {
        .ant-menu-item {
          padding-left: 20px !important;

          &:first-child {
            margin-top: 18px !important;
          }
        }
      }
    }

    .sidebar-wrapper {
      min-height: 100%;
      flex-direction: column;
      justify-content: space-between;
      flex-wrap: nowrap;
      padding: 24px 16px;

      @include mobile {
        padding: 24px;
      }

      &__icon-number {
        border: 1px solid $default;
        border-radius: 100%;
        width: 20px;
        height: 20px;
        line-height: 19px;
        text-align: center;
        margin: 0 10px 0 2px;
      }
    }
  }
}
