.request-successful {
  text-align: center;
  max-width: 537px;
  margin: auto;

  .firework-image {
    height: 230px !important;

    @include mobile {
      height: 172px !important;
    }
  }

  @include mobile {
    padding: 0 16px;
  }

  .divider {
    width: 80px !important;
  }

  .full-width {
    width: 100% !important;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 84px;
    height: 84px;
    background: linear-gradient(270deg, #17e391 21.47%, #58e5df 77.04%, #60e5e8 77.05%);
    border-radius: 100%;
    margin: auto;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 16px;

    &__wrapper {
      position: relative;

      img {
        width: 100%;
      }
    }

    svg {
      width: 56px;
      height: 56px;
      text-align: center;

      path {
        stroke: $white;
      }
    }

    @include mobile {
      width: 64px;
      height: 64px;
    }

    &--non-background {
      background-color: transparent;
      width: auto;
      height: auto;

      svg {
        width: auto;
        height: auto;

        path {
          stroke: auto;
        }
      }
    }
  }

  &__title {
    display: inline-block;
  }

  &__title-green {
    display: inline-block;
    color: $green;
  }

  &__desc {
    font-size: 16px;
    margin-top: 8px;
    display: inline-block;
  }

  &__input-copy:focus {
    border-color: $gray5 !important;
  }
  &__input-copy {
    width: 75% !important;
    height: 56px;
    background-color: $gray5 !important;

    .ant-input {
      text-align: center !important;
      background-color: $gray5 !important;
      font-size: 18px !important;
      color: $default !important;
    }

    .ant-input-suffix {
      border-left: 1px solid $line;
      padding-left: 16px;

      span {
        &:hover {
          cursor: pointer !important;
        }
      }
    }

    span {
      color: $blue;
      font-size: 14px;
    }
  }

  &-wrapper {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 104px);

    @include mobile {
      margin-top: 93px;
      align-items: flex-start;
      height: auto;
    }
  }

  .btn-access-now {
    background: $primary-gradient;

    span,
    .ant-typography {
      color: $default !important;
      letter-spacing: 0 !important;
    }

    &:hover,
    &:focus,
    &:active {
      background: $primary-gradient;
    }
  }
}

.send-tip-request-successful {
  width: 459px !important;

  @include mobile {
    width: calc(100% - 32px) !important;
  }
}
