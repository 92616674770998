@font-face {
  font-family: "ABC Repro Variable";
  font-display: swap;
  src: url("ABCReproVariable.woff2") format("woff2 supports variations"),
    url("ABCReproVariable.woff2") format("woff2-variations"),
    url("ABCReproVariable.woff") format("woff supports variations"),
    url("ABCReproVariable.woff") format("woff-variations"),
    url("ABCReproVariable.ttf") format("truetype supports variations"),
    url("ABCReproVariable.ttf") format("truetype-variations");
  font-weight: 100 900;
}
