@import "~video.js/dist/video-js.css";

.video-player {
  .video-js {
    //width: 100%;
    //max-height: 70vh;
    flex: 1;
    border-radius: 16px;

    video {
      border-radius: 16px;
      top: auto;
      bottom: 0.5px;
    }

    .vjs-big-play-button {
      display: none !important;
    }

    .vjs-control-bar {
      display: none;
    }
  }

  &--show-controls {
    .video-js {
      .vjs-control-bar {
        display: flex;
        border-radius: 0 0 16px 16px;
      }
    }

    .btn-sound {
      display: none;
    }
  }

  &.video-square {
    .video-js {
      border-radius: 5px;
    }
  }

  .vjs-paused .vjs-big-play-button,
  .vjs-paused.vjs-has-started .vjs-big-play-button {
    display: block;
  }

  &__overlay {
    //position: absolute;
    //top: 0;
    //left: 0;
    //z-index: 9;
    //width: 100%;
    //height: 100%;
  }

  &__controls {
    padding: 0 16px;
    z-index: 99;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
    -moz-transition: opacity 0.4s ease-in-out;
    -webkit-transition: opacity 0.4s ease-in-out;

    button {
      padding: 0;
      position: absolute;
      right: 24px;
      bottom: 24px;
      z-index: 99;
      cursor: pointer;

      &.btn-play,
      &.btn-pause {
        bottom: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80px;
        height: 80px !important;
        border-radius: 100%;
        background-color: rgba($color: $default, $alpha: 0.6) !important;
      }
    }
  }

  &:hover {
    .video-player__controls {
      opacity: 1;
      transition: opacity 0.1s ease-in-out;
      -moz-transition: opacity 0.1s ease-in-out;
      -webkit-transition: opacity 0.1s ease-in-out;
    }
  }

  &--paused {
    .video-js {
      .vjs-control-bar {
        display: none;
      }
    }

    .video-player__controls {
      opacity: 1;

      .btn-sound {
        display: none;
      }
    }
  }
}
