.live-stream-app {
  padding: 0 !important;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;

  .livestream__chat {
    margin-left: 28px;
    margin-bottom: 16px;
  }

  .chat-box {
    border-radius: 16px;

    &__content {
      height: calc(100vh - 289px);
    }
  }
}
