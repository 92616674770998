.experience-card {
  padding: 24px;
  border-radius: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  background-color: $white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .divider {
    background-color: $gray5;
    height: 1px;
    width: calc(100% - 16px);
    display: none;
  }

  &__header {
    .avatar-profile {
      margin-top: 8px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    &__purchaseId {
      margin-top: 8px;
    }
  }

  &__date {
    display: block;
    margin-top: 16px;
    margin-bottom: 16px;

    &:first-child {
      margin-top: 38px;
    }

    @include mobile {
      margin-top: 24px;
      margin-bottom: 16px;

      &:first-child {
        margin-top: 24px;
      }
    }
  }
}

@media (max-width: #{$tablet-width}) {
  .experience-card {
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;

    .divider {
      display: block;
    }

    &__header {
      .avatar-profile {
        margin-top: 12px;
      }
    }

    &__content {
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;

      &__purchaseId {
        margin-top: unset;
      }
    }
  }
}
