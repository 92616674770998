.audio-analyzer {
  height: 100px;
  &__button {
    background: #257ffc;
    border-radius: 123px;
    width: 124px;
    height: 30px;
  }
  &__column {
    width: 12px;
    height: 12px;
    background: white;
    border-radius: 123px;
  }
}
