.gift-experience {
  &__title.ant-typography {
    font-size: 20px;
    padding: 0 calc((100% - 80px) / 7 / 2);
  }

  .btn-back {
    padding: 0;
    height: 24px;
    margin-bottom: 32px;
  }

  .btn-next {
    width: 100%;
  }

  &__info-receiver {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
  }

  &__info-name {
    font-size: 16px;
  }

  &__info-email {
    font-size: 14px;
    opacity: 0.7;
  }

  &__payment {
    &__title {
      font-size: 20px;
    }
    &__subTitle {
      font-size: 16px;
      margin-bottom: 28px;
    }
  }

  &__info {
    .talent-info-request {
      width: 244px;
    }
  }

  &__review-item {
    border: 1px solid $border;
    border-radius: 8px;
    padding: 10px 16px;

    &__time {
      color: rgba($default, 0.7) !important;
    }
  }

  &__seperator-note {
    padding: 32px 0 0 0 !important;
    border-top: 1px solid $gray5;
    margin-top: 24px !important;
  }

  &__form {
    padding: 0 0 8px 0 !important;
  }

  .ant-select {
    width: 100%;

    &-arrow {
      width: 24px;
      height: 24px;
      margin-top: 0;
      transform: translate(0, -50%);
    }

    &-selector {
      height: 62px !important;

      img {
        width: 54px;
        height: 32px;

        &.apple-pay {
          width: 44px;
        }
      }

      .ant-select-selection-item {
        height: 62px !important;
        background: none !important;
        color: $default !important;
        line-height: 62px !important;
      }
    }
  }

  .available-slots__timezone {
    .ant-select-selector {
      height: 28px !important;

      .ant-select-selection-item {
        height: 28px !important;
        background: none !important;
        color: $default !important;
        line-height: 28px !important;
      }
    }
  }
}

.payment-methods-dropdown {
  img {
    width: 44px;
    height: 32px;
  }
}
