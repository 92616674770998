.tile {
  .react-player-wrapper {
    width: 100%;
    height: 100%;

    > div {
      display: flex;
      justify-content: center;
      width: auto !important;
    }
  }

  video {
    object-fit: cover;
    border-radius: 24px;
    width: 100%;
    transform: scaleX(-1);
  }

  &.is-sharing-screen {
    video {
      transform: scaleX(1);
    }
  }

  .background {
    padding-top: 56.25%;
    border-radius: 16px !important;

    .ant-row {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 16px;
      justify-content: center;
    }
  }

  .loading {
    position: absolute;
    color: #ffffff;
    top: 50%;
    left: 50%;
    margin: 0;
    transform: translate(-50%, -50%);
    font-size: 14px;
    line-height: 17px;
  }
}

.tile.small {
  width: 88px;
  height: 88px;
  position: relative;

  .background {
    border-radius: 4px;
  }

  .mic-off {
    position: absolute;
    height: 24px;
    right: 6px;
    bottom: 6px;
    z-index: 999;
  }

  .avatar-profile img {
    margin-left: 1px;
    border-radius: 16px;
  }

  .avatar-profile__text {
    margin-left: 1px;
    border-radius: 16px;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding: 2px;
    border-radius: 16px;
  }

  .ant-row.background__container {
    padding: 2px;
  }

  &.is-owner {
    video {
      border: 1px solid $green;
    }

    .ant-row.background__container {
      border: 1px solid $green;
    }
  }
}

.tile.large {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;

  video {
    width: auto !important;
  }

  &.portrait {
    .react-player-wrapper {
      width: auto !important;
    }
  }

  .react-player-wrapper {
    height: 100% !important;

    > div {
      border-radius: 24px;
      overflow: hidden;
    }
  }
}
