.waitlist-confirmation-modal {
  @include mobile {
    width: 100vw;
    max-width: 100vw;
    .ant-modal-content {
      bottom: 0;
      position: fixed;
      width: 100vw;
      border-radius: 16px 16px 0 0 !important;
    }
    .ant-modal-body {
      padding: 1rem;
    }
  }

  .close-icon {
    cursor: pointer;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;

    @include mobile {
      top: 1rem;
      right: 1rem;
    }
  }

  .ant-modal-footer {
    background-color: #f4f3f3;
    border-top: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;

    @include mobile {
      height: 52px;
    }
  }

  .confirm-btn {
    width: 100%;
    background-color: $default;
    margin-bottom: 0.5rem;

    span {
      color: $white;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 700;

      @include mobile {
        font-size: 0.875rem;
        line-height: 1.25rem;
      }
    }
    &:hover,
    &:focus,
    &:active {
      border-color: transparent !important;
    }

    @include mobile {
      height: 44px;
    }
  }
}
