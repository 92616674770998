.take-screen-shoot {
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @include mobile {
    max-width: calc(100vw - 32px) !important;
    margin: 16px !important;
    padding: 0 !important;
    bottom: 0;
    top: auto !important;
    position: absolute !important;
  }

  .ant-modal-body {
    @include mobile {
      padding: 16px !important;
    }
  }

  .ant-btn-lg {
    height: 52px;
  }
}
