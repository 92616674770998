.video-call-setting-modal {
  .ant-modal-body {
    padding: 20px 20px 40px 20px;

    video {
      border-radius: 16px;
      object-fit: cover;
      transform: scaleX(-1);
    }

    svg path {
      stroke: $default;
    }

    .ant-select {
      &-selector {
        height: 52px !important;
        border: 1px solid $alto !important;
        padding: 0 16px !important;
      }

      &-arrow {
        width: 24px;
        height: 24px;
        right: 16px;
        margin-top: 0;
        transform: translate(0, -50%);
      }
    }
  }

  &__dropdown {
    svg path {
      stroke: $default;
    }
  }
}
