.benefit__card {
  border-radius: 1.5rem;
  height: 100%;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.06)), #121212;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);

  &--title {
    span {
      line-height: 28px !important;
    }

    @include mobile {
      svg > path {
        stroke-width: 1px;
      }
    }
  }

  .description {
    opacity: 0.6 !important;
  }
  &--content {
    max-height: 0;
    transition: max-height 0.35s ease-out;
    overflow: hidden;
    position: relative;
    &.active {
      max-height: 1000px;
      transition: max-height 1s ease;
      overflow: hidden;
      position: relative;
    }
  }
}
