.search {
  display: flex;
  flex-direction: column;
  height: 100%;
  &__header {
    padding: 12px 16px;
    border-bottom: 1px $gray5 solid;
    .divider {
      height: 32px;
      width: 1px;
      background: $gray5;
    }
    input {
      font-weight: $medium;
      font-size: $medium1;
      &::placeholder {
        color: rgba($black, 0.65) !important;
      }
    }
  }
  &__empty {
    flex: 1;
  }
}

@media not all and (min-resolution: 0.001dpcm) and (max-width: #{$mobile-width - 1px}) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .search {
      height: -webkit-fill-available;
    }
  }
}
