#header {
  z-index: 9;
  position: relative;
  border-bottom: 1px solid #e9e9eb;
  padding: rem-calc(15 0 9);

  @include media(">=tablet") {
    padding: rem-calc(27 0 23);
  }

  a {
    text-decoration: none;
  }

  ul {
    @extend %listreset;
  }

  .btn {
    padding: rem-calc(13 32);
  }
}

.logo {
  width: rem-calc(136);
  margin-right: rem-calc(25);
  margin-top: rem-calc(-2);

  @include media("<tablet") {
    margin-top: rem-calc(2);
  }
}

.nav-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include media(">=tablet") {
    align-items: center;
    justify-content: flex-start;
  }

  .menu {
    position: relative;
    text-align: center;

    @include media(">=tablet") {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      text-align: left;
    }

    li {
      margin-bottom: rem-calc(18);

      @include media(">=tablet") {
        margin-left: rem-calc(41);
        margin-bottom: 0;
      }

      a {
        color: $light-black;

        &:hover {
          color: $dark-blue;
        }
      }
    }
  }
}

/* style for NAV */
#nav {
  width: auto;

  @include media(">=tablet") {
    flex: 1;
  }
}

.drop {
  @include animate(left);
  position: absolute;
  top: rem-calc(88);
  left: 100%;
  width: 100%;
  z-index: 999;

  .resize-active & {
    transition: none;
  }

  @include media("<tablet") {
    overflow-y: auto;
    overflow-x: hidden;
    background-color: $white;
    padding: rem-calc(30 15 35);
  }

  @include media(">=tablet") {
    position: static;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .nav-active & {
    left: 0;
  }
}

.opener {
  width: rem-calc(72);
  height: rem-calc(62);
  position: relative;
  border-radius: rem-calc(0);
  text-indent: -9999px;
  overflow: hidden;
  display: none;

  @include media("<tablet") {
    display: block;
  }

  &:before,
  &:after,
  span {
    background: $light-black;
    border-radius: 0;
    position: absolute;
    top: rem-calc(28);
    left: 30%;
    right: 30%;
    height: rem-calc(3);
    margin-top: rem-calc(-2);
    transition: all 0.2s linear;

    .resize-active & {
      transition: none;
    }
  }

  &:before,
  &:after {
    content: "";
    top: rem-calc(19);
  }

  &:after {
    top: rem-calc(37);
  }
}

.nav-active {
  .opener {
    span {
      opacity: 0;
    }

    &:before {
      transform: rotate(45deg);
      top: rem-calc(26);
      left: 28%;
      right: 28%;
    }

    &:after {
      transform: rotate(-45deg);
      top: rem-calc(26);
      left: 28%;
      right: 28%;
    }
  }
}
