.home {
  &__banner {
    margin-top: 24px;
    background: linear-gradient(0deg, rgba($black, 0.7), rgba($black, 0.7)),
      url("/static/assets/images/home-banner.jpg");
    background-size: cover;
    background-color: rgba($black, 0.7);
    border-radius: 16px;
    text-align: center;
    width: 100%;
    height: 416px;

    @include mobile {
      background: linear-gradient(0deg, rgba($black, 0.7), rgba($black, 0.7)),
        url("/static/assets/images/home-banner-mobile.png") no-repeat;
      background-size: contain;
      border-radius: 0;
      margin-top: 0;
      height: calc(100vw * 0.784);
      background-attachment: fixed;
      background-position: top;
      position: relative;
    }

    &--search {
      position: absolute;
      left: 0;
      top: 12px;
      right: 0;
      padding: 0 24px;
    }
    &--title {
      margin-bottom: 0 !important;

      @include mobile {
        margin-bottom: 94px !important;
      }
    }
  }
}
