.ondemand-video-card {
  border: 1px solid $border;
  border-radius: 16px;
  cursor: pointer;
  background-color: $white;
  margin-top: 24px;
  padding: 24px;

  @include mobile {
    margin-top: 16px;
  }

  .play-video-button {
    min-width: 227px !important;
    > span {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      text-transform: uppercase;
    }
    &:disabled {
      .ant-typography,
      span {
        color: $white !important;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .ondemand-video-card {
    padding: 16px;

    .play-video-button {
      width: 100% !important;
    }
  }
}
