.course-detail {
  .video-player {
    .video-js {
      width: 100%;
      height: 395px;
      flex: 1;

      @include mobile {
        height: 215px;
      }

      video {
        //object-fit: cover;
      }
    }
  }
}

.course-attachment {
  width: 100%;
  border: 1px solid #f5f5f5;
  border-radius: 16px;
  padding: 8px;

  .attachment-extension {
    cursor: pointer;
    width: 56px;
    height: 56px;
    border-radius: 16px;
    background: rgba(#0085ff, 0.1);

    .text {
      color: #0085ff;
    }
  }
}

.course-lesson {
  width: 100%;
  border: 1px solid #f5f5f5;
  border-radius: 16px;
  padding: 16px;
  cursor: pointer;

  &.selected {
    background: rgba($primary, 0.2);
  }
}
