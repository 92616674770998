.hero-talent-background {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  aspect-ratio: 16/9;

  opacity: 0;
  transition: opacity 0.5s ease-out;
  animation: fade-in 0.5s ease-out forwards;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &__talent {
    position: absolute;
    width: 11%;

    animation: blur-and-scale 20s infinite;

    @media (prefers-reduced-motion: reduce) {
      animation-play-state: paused;
    }

    @keyframes blur-and-scale {
      10% {
        transform: scale(1);
        filter: blur(0px);
      }
      20% {
        filter: blur(0px);
      }
      40% {
        transform: scale(0.5);
        filter: blur(6px);
      }
      60% {
        transform: scale(0.5);
        filter: blur(6px);
      }
      70% {
        filter: blur(0px);
      }
      90% {
        transform: scale(1);
        filter: blur(0px);
      }
    }

    @keyframes blur-and-scale-mobile {
      10% {
        transform: scale(1);
        filter: blur(0px);
      }
      40% {
        transform: scale(0.75);
        filter: blur(4px);
      }
      60% {
        transform: scale(0.75);
        filter: blur(4px);
      }
      90% {
        transform: scale(1);
        filter: blur(0px);
      }
    }

    .marketing-talent-card__content {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &--mobile {
    aspect-ratio: 4/5;
    margin-bottom: auto;
    margin-top: 30px;

    .hero-talent-background {
      &__talent {
        width: 30%;
        animation: blur-and-scale-mobile 20s infinite;
      }
    }
  }
}
