.special-request-banner {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  padding: 20px 32px;
  cursor: pointer;

  @include mobile {
    padding: 16px;
    border-radius: 16px;
  }

  &__icon {
    svg {
      width: 80px;
      height: 80px;

      @include mobile {
        width: 52px;
        height: 52px;
      }
    }
  }
}
