.request-layout {
  .container {
    max-width: $request-container;
  }

  .ant-layout-content {
    padding: 24px 0 0 0;
    background-color: $white;
    display: flex;
    flex-direction: column;

    @include mobile {
      padding: 0;
    }
  }

  &.center {
    .ant-layout-content {
      overflow: auto;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }

  &__header {
    background-color: $white !important;
    height: 84px !important;
    box-shadow: 0 1px 0 $border;
    margin-bottom: 1px;

    img {
      height: 40px;
    }

    @include mobile {
      height: 64px !important;

      img {
        height: 32px;
      }
    }
  }

  &--dark-mode {
    height: 100vh;
    overflow: auto;
    background-color: $default !important;

    .ant-divider {
      background-color: rgba($white, 0.1) !important;
    }

    .ant-layout-content {
      background-color: $default;
    }

    .ant-typography {
      color: $white;
    }

    svg path {
      stroke: $white;
    }
  }

  .request-layout__header.ant-layout-header {
    max-width: $request-container;
    background-color: $default !important;
    box-shadow: none;
    width: 100%;
    margin: auto;
    margin-bottom: 0;
    padding: 0 24px;
  }
}
