.tile {
  position: relative;

  .react-player-wrapper {
    width: auto !important;
    height: 100%;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto !important;
    }

    img,
    video {
      object-fit: cover;
      width: auto !important;
      transform: scaleX(-1);
    }
  }

  &.back-camera {
    .react-player-wrapper {
      img,
      video {
        transform: scaleX(1);
      }
    }
  }

  &.landscape {
    .react-player-wrapper {
      width: 100% !important;

      img,
      video {
        width: 100% !important;
      }
    }
  }

  &.is-sharing-screen {
    img,
    video {
      transform: scaleX(1);
    }
  }

  .background {
    padding-top: 56.25%;
    border-radius: 16px !important;

    .ant-row {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 16px;
      justify-content: center;
      background: $tundola;
    }
  }

  .loading {
    position: absolute;
    color: #ffffff;
    top: 50%;
    left: 50%;
    margin: 0;
    transform: translate(-50%, -50%);
    font-size: 14px;
    line-height: 17px;
  }

  &__name {
    align-items: flex-end;
    height: 100%;
    justify-content: flex-start;
    padding-bottom: 8px;
    border-radius: 0 0 16px 16px;

    &__wrapper {
      position: absolute;
      z-index: 99;
      bottom: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(#000000, 0.5) 100%);
      width: 100%;
      height: 139px;
      padding-left: 16px;
    }
  }
}

.tile-wrapper {
  width: 100%;
  height: 100%;

  @include mobile {
    .react-player-wrapper {
      img,
      video {
        height: auto !important;
      }
    }
  }

  &.local {
    position: absolute;
    z-index: 99;
    width: 235px;
    height: 134px;
    bottom: 24px;
    right: 24px;
    border-radius: 8px !important;
    overflow: hidden;

    .background {
      border-radius: 8px !important;

      .ant-row {
        border-radius: 8px;
      }
    }

    .react-player-wrapper > div {
      border-radius: 8px !important;
    }

    .tile__name {
      border-radius: 0 0 8px 8px !important;

      &__wrapper {
        height: 44px !important;
      }
    }

    @include mobile {
      width: 93px;
      height: 162px;
      bottom: 16px;
      right: 16px;

      .react-player-wrapper {
        img,
        video {
          height: 100% !important;
        }
      }
    }
  }
}

.tile.large {
  position: relative;
  width: 100% !important;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  border-radius: 16px !important;
  background-color: $black;

  @include mobile {
    display: flex;
    align-items: center;
  }

  .mic-off {
    width: 32px;
    height: 32px;
    position: absolute;
    z-index: 99;
    right: 8px;
    top: 8px;
  }

  // video {
  //   width: auto !important;
  // }

  &.portrait {
    width: auto !important;

    .react-player-wrapper {
      width: auto !important;

      img,
      video {
        width: auto !important;
        height: 100% !important;
      }

      @include mobile {
        width: 100% !important;
        height: 100% !important;

        img,
        video {
          width: 100% !important;
          height: 100% !important;
          border-radius: 16px;
        }
      }
    }
  }

  .react-player-wrapper {
    @include mobile {
      height: auto;
    }

    > div {
      overflow: hidden;
    }
  }

  &.local {
    border-radius: 8px !important;
    background-color: $tundola;

    .react-player-wrapper {
      border-radius: 8px !important;
      height: 100%;

      > div {
        border-radius: 8px !important;
        overflow: hidden;
      }

      img,
      video {
        object-fit: cover;
        border-radius: 8px !important;
      }
    }
  }
}
