.my-swiper {
  .swiper {
    overflow: hidden;
  }
  .btn-swiper {
    min-height: 24px !important;
    height: 24px !important;

    &__wrapper {
      height: 24px;
    }
  }

  .swiper-no-swiping {
    .swiper-wrapper {
      transition-timing-function: unset;
      width: 100% !important;

      @include mobile {
        overflow: auto hidden;
      }

      &::-webkit-scrollbar {
        appearance: none !important;
        display: none !important;
      }
    }
  }

  .swiper-button-disabled {
    visibility: hidden;
  }

  .swiper-wrapper {
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
  }

  .swiper-slide {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);

    iframe {
      border-radius: 8px;
    }
  }

  &.single:not(.fix-width) {
    .swiper-slide {
      width: auto !important;
    }
  }

  &__content {
    position: relative;

    .shadow-left {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9;
      width: 24px;
      height: 100%;
      background: linear-gradient(90deg, #121212 0%, rgba(18, 18, 18, 0) 100%);
    }

    .shadow-right {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 9;
      width: 24px;
      height: 100%;
      background: linear-gradient(270deg, #121212 0%, rgba(18, 18, 18, 0) 100%);
    }
  }
}
