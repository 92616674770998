.theme-feature {
  width: 100%;
  max-height: 50vh;
  aspect-ratio: 1/1;
  position: relative;

  &__graphic {
    &__img {
      width: 100%;
      height: 100%;
      // TODO: Opacity transition no longer working: being overridden by the Hover3DImageStack transition
      transition: opacity 0.4s ease-in-out;
      opacity: 0;
      &--selected {
        opacity: 1;

        &.hide {
          opacity: 0;
        }
      }
    }
  }

  .theme-colour-selector {
    position: absolute;
    z-index: 5;
    right: 10%;
    bottom: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background: #110f16;
    padding: 8px;
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);

    &__option {
      background: #1e1e20;
      border: 2px solid transparent;
      padding: 10px;
      margin: 0;
      border-radius: 8px;
      transition: border-color 0.1s ease-out;
      cursor: pointer;

      &:hover:not(.theme-colour-selector__option--selected) {
        border-color: #9ba6f8 !important;
      }

      &--selected {
        border-color: #ff05d7;
      }
      > span {
        display: block;
        width: 28px;
        height: 28px;
        border-radius: 99px;
      }

      &--light > span {
        background: #fff;
        border: 1px solid #e5e5e5;
      }
      &--dark > span {
        background: #000;
        border: 1px solid #e5e5e5;
      }
      &--color > span {
        position: relative;
        background: #ff05d7;
        background: conic-gradient(
          from 270deg at 50% 50%,
          rgb(255, 0, 0) 0deg,
          rgb(255, 255, 0) 60deg,
          rgb(0, 255, 0) 120deg,
          rgb(0, 255, 255) 180deg,
          rgb(0, 0, 255) 240deg,
          rgb(255, 0, 255) 300deg,
          rgb(255, 0, 0) 360deg
        );

        transition: transform 0.2s ease;
        &:hover {
          transform: rotate(10deg);
        }

        &::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          border-radius: 99px;
          background: radial-gradient(
            circle,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0) 60%
          );
        }
      }
    }
  }

  &--mobile {
    .theme-colour-selector {
      top: auto;
      bottom: 0;
      left: auto;
      right: 0;
    }
  }
}
