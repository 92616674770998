.join-fan-club-modal {
  @include mobile {
    left: 0;
    margin: 0 !important;
    max-width: 100vw !important;
    padding: 0 !important;
    bottom: 0;
    top: auto !important;
    position: absolute !important;

    .ant-modal-content {
      border-radius: 16px 16px 0 0 !important;
    }
  }

  .ant-modal-close {
    top: 24px;

    @include mobile {
      top: 16px;
    }
  }

  .ant-modal-header {
    padding: 24px !important;
    border-bottom: none !important;

    @include mobile {
      padding: 16px !important;
    }

    .avatar-items {
      &__item {
        position: relative;
        width: 80px;
        height: 80px;
        border-radius: 100%;
        overflow: hidden;

        @include mobile {
          width: 70px;
          height: 70px;
        }

        &__background {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: linear-gradient(270deg, #17e391 21.47%, #58e5df 77.04%, #60e5e8 77.05%);
          opacity: 0.2;
        }

        .image-skeleton,
        .ant-avatar {
          position: absolute;
          top: 9px;
          left: 9px;

          @include mobile {
            top: 8px;
            left: 8px;
          }
        }

        &:nth-child(2) {
          margin-left: -9px;

          @include mobile {
            margin-left: -5px;
          }
        }
      }
    }
  }

  .desc {
    color: $default !important;
    opacity: 0.8;
    margin-top: 4px;

    @include mobile {
      margin-top: 0;
    }
  }

  .ant-modal-body {
    padding: 8px 24px 24px !important;

    @include mobile {
      padding: 8px 16px 24px !important;
    }

    .ant-divider {
      border-top-color: $mercury;
    }

    .term-checkbox {
      background-color: $white !important;
      border: 2px solid $mercury;
      margin-top: 24px;

      @include mobile {
        margin-top: 0;
        border: none;
      }

      span {
        color: $default;
      }

      .ant-checkbox-inner {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }

  .ant-modal-footer {
    padding: 0;
    text-align: center !important;

    .divider {
      height: 2px;
      background: linear-gradient(270deg, #17e391 21.47%, #58e5df 77.04%, #60e5e8 77.05%);
    }

    img {
      margin: 12px 0;
    }
  }
}
