.special-request {
  input,
  textarea {
    &::placeholder {
      font-weight: 400;
      color: rgba($default, 0.5);
    }

    &:disabled {
      color: $default;
      background: linear-gradient(0deg, rgba(233, 233, 235, 0.5), rgba(233, 233, 235, 0.5)), #ffffff;
    }
  }
  .react-tel-input {
    border-radius: 0 !important;

    .phone-button-select {
      display: none;
    }

    .phone-input {
      padding-left: 16px;
      border-radius: 0 !important;
      background: linear-gradient(0deg, rgba(233, 233, 235, 0.5), rgba(233, 233, 235, 0.5)), #ffffff;
      font-weight: 500;
      font-family: $fontSFProDisplay;
    }
  }

  .ant-input-number {
    width: 100%;
  }

  &__talent {
    height: 133px;
    position: relative;
    border: 1px solid $border;
    margin-top: 54px;

    .image-skeleton {
      position: absolute;
      top: -54px;

      img {
        border: 2px solid white;
      }

      @include mobile {
        position: relative;
        top: 0;
      }
    }

    @include mobile {
      border: none;
      margin-top: 0;
      height: auto;
    }

    &__name {
      bottom: 24px;
      position: absolute;

      @include mobile {
        position: relative;
        margin-top: 16px;
        bottom: 0;
      }
    }
  }
}
