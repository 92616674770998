.confirm-end-video-call-modal {
  width: 540px !important;

  @include mobile {
    max-width: calc(100vw - 32px) !important;
    margin: 16px !important;
    padding: 0 !important;
    bottom: 0;
    top: auto !important;
    position: absolute !important;
  }

  .ant-modal-content {
    border-radius: 24px !important;
  }

  .ant-modal-body {
    @include mobile {
      padding: 16px !important;
    }
  }
}
