.detail-layout {
  display: flex;
  justify-content: center;
  background-size: cover;
  padding-bottom: 105px;
  background-color: $black;
  min-height: 100vh;

  @include mobile {
    padding-bottom: 0;
  }

  * {
    color: $white !important;
  }

  .footer {
    padding: 0 !important;
  }

  &__background {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    object-fit: cover;
    object-position: top;
    opacity: 0.8;
    filter: blur(104px);
  }

  &__content {
    margin-top: 80px;
    padding: 28px 74px 32px;
    width: 100%;
    border-radius: 12px;
    background: $bg-dark;
    max-width: 735px;
    overflow-x: hidden;
    position: relative;
    height: fit-content;

    &--preview {
      margin-top: 48px;
    }

    @include mobile {
      margin-top: 0;
      padding: 0;
      border-radius: 0;
    }
  }

  &.full-layout {
    padding-bottom: 0;

    .detail-layout {
      &__content {
        max-width: 100%;
        border-radius: 0;
        margin-top: 0;
        height: 100vh;
        padding: 0;
      }
    }
  }

  .ant-divider {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }

  &--preview-empty {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #121212;
  }

  &--loading {
    padding-bottom: 0;
    height: 100vh;
    padding-top: 80px;

    @include mobile {
      padding-top: 0;
    }

    .snippet {
      display: flex;
      align-items: center;
      background-color: #121212;
      width: 100%;
      justify-content: center;
      max-width: 735px;
      border-radius: 12.8104px 12.8104px 0 0;

      @include mobile {
        border-radius: 0;
        padding-top: 0;
      }
    }
  }

  &--loading-module {
    padding-bottom: 0;

    .detail-layout__content {
      height: 100vh;
      overflow: hidden;
      border-radius: 12px 12px 0 0;

      @include mobile {
        border-radius: 0;
      }
    }
  }

  &--talent-profile {
    background-color: var(--ant-primary-color);

    * {
      color: var(--ant-info-color) !important;
    }

    .detail-layout__content {
      background: var(--ant-primary-color);
    }

    .footer {
      &__info {
        box-shadow: none;
        border-top: 1px solid var(--ant-success-color) !important;

        svg {
          path {
            fill: var(--ant-info-color);
          }
        }

        @include mobile {
          padding: 8px 16px 24px !important;

          .ant-col {
            &:first-child {
              border-bottom: 1px solid var(--ant-success-color);
            }
          }
        }
      }
    }
  }
}
