.fixed-width-carousel {
  @include mobile {
    padding-right: 0;
    padding-left: 0;
  }
  .ant-btn-text {
    &:disabled {
      visibility: hidden;
    }
  }

  .btn-swiper {
    min-height: 24px !important;
    height: 24px !important;
  }
}
