.local-preview {
  justify-content: center;
  align-items: center;
  width: 704px;
  margin: auto;

  &__container {
    justify-content: center;
    display: flex;
    align-items: center;
    background-color: $white;
    padding: 72px;
    border-radius: 8px;

    &__camera {
      width: 100%;
      margin: auto;

      video {
        width: 560px;
        border-radius: 16px;
      }
    }
  }

  &__action-button {
    position: absolute;
    bottom: 0;
    margin-bottom: 30px;
    width: "100%";

    .ant-btn {
      width: 48px !important;
      height: 48px !important;
      background-color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      animation-duration: 0s !important;
      border-radius: 100% !important;

      &:hover {
        background: $white;
        opacity: 0.7;
      }

      &:active,
      &:focus {
        background: $white;
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  .video-track__video {
    height: auto !important;
  }

  .video-placeholder {
    width: 560px !important;
    height: 315px !important;

    &__container {
      height: 315px !important;
    }

    .ant-typography {
      display: none;
    }
  }

  .video-track {
    .ant-typography {
      display: none !important;
    }
  }
}
