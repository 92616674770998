.talents {
  &__wrapper {
    .talent-card__background-img-container {
      width: 100% !important;
    }

    .ant-pagination-item-active {
      border-color: $default;

      a {
        color: $default;
      }
    }
  }

  &__banner {
    position: relative;

    .image-skeleton {
      width: 100% !important;
      .ant-skeleton {
        width: 100% !important;
        .ant-skeleton-avatar {
          width: 100% !important;
        }
      }
    }

    &__photo {
      width: 100%;
    }

    &__name {
      &.ant-row.container {
        margin-bottom: 0 !important;
      }

      span {
        display: block;
        color: rgba($white, 0.8);

        &:first-child {
          color: $white;
        }
      }

      &__wrapper {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.1) 100%);
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9;
        display: flex;
        align-items: flex-end;
        padding-bottom: 48px;

        @include mobile {
          padding-bottom: 16px;
        }
      }
    }
  }
}
