.ant-tooltip-arrow {
  width: 16px !important;
  height: 20px !important;

  &-content {
    width: 16px !important;
    height: 16px !important;
    background-color: $default !important;
    border-radius: 3px !important;
  }
}

.ant-tooltip-inner {
  padding: 12px !important;
  background-color: $default !important;
  box-shadow: none !important;
}

.ant-tooltip.ant-tooltip--bg-grey {
  .ant-tooltip-inner {
    min-height: 20px;
    padding: 2px 8px !important;
    font-size: 12px;
    line-height: 16px;
    background: $tundola !important;
    border-radius: 4px;
  }

  .ant-tooltip-arrow {
    display: none;
  }
}
