.video-container {
  width: 100%;
  min-height: 100vh !important;
  margin: auto;
  margin-top: 0;
  display: flex;

  &__header {
    background: $white !important;
    height: 88px !important;
    line-height: unset !important;

    @include mobile {
      height: 64px !important;
    }

    .ant-layout-header {
      padding: 0 60px;
      height: 88px;
      box-shadow: 0 1px 0 $border;
      background-color: $white !important;

      @include mobile {
        height: 64px;
      }
    }

    &.dark-mode.ant-layout-header {
      background-color: $black !important;
    }
  }

  &__content {
    padding: 10px 48px;
    background-color: $gray;

    &__container {
      display: flex;
    }

    &--right {
      width: 258px;
    }

    .dark-mode,
    &.dark-mode {
      background-color: $black !important;
    }
  }
}

.modal-end-call-confirm {
  .ant-modal-confirm-btns {
    .ant-btn {
      height: 40px !important;
      min-width: 80px;
    }
  }
}
