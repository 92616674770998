.booking1to1-talent {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px $default inset;
    -webkit-text-fill-color: $white !important;
    transition: background-color 5000s ease-in-out 0s;
  }

  .react-tel-input {
    border: none;
    color: $default !important;
    border-radius: 8px;

    .flag-dropdown.open {
      z-index: 9999 !important;
    }

    .phone-button-select {
      width: 48px !important;
      height: 60px !important;
      margin-top: 0 !important;
      background: $gray5 !important;
      border: 1px solid $border !important;
      border-radius: 8px;
      display: flex;
      justify-content: center;
    }

    .special-label {
      color: rgba($default, 0.7);
      font-size: 12px;
      display: block;
      background: none;
      left: 78px;
      top: 8px;
      font-family: $fontSFProDisplay;
      font-weight: 400;
    }

    .form-control {
      height: 42px !important;
      width: 100% !important;
      border: none !important;
      border-radius: 8px !important;
    }

    .phone-input {
      color: $default !important;
      height: 60px !important;
      border: 1px solid $border !important;
      padding: 32px 18px 18px;
      margin-left: 60px;
      width: calc(100% - 60px) !important;
      font-weight: 500;
    }

    &:hover,
    &:focus {
      color: $default;
    }
  }

  @include mobile {
    padding: 0 16px !important;
  }

  &--special-request.container.ant-row {
    margin-top: 32px !important;

    @include mobile {
      margin-top: 24px !important;
    }
  }

  &__title.ant-typography.title {
    margin-bottom: 32px !important;
    padding: 0;
    display: block;
  }

  .btn-back {
    padding: 0;
    height: 24px;
    margin-bottom: 32px;
  }

  .btn-next {
    width: 100%;
    background: $primary-gradient;

    span,
    .ant-typography {
      color: $default !important;
      letter-spacing: 0 !important;
    }

    &:hover,
    &:focus,
    &:active {
      background: $primary-gradient;
    }
  }

  &__payment {
    &__title {
      margin-bottom: 16px;
    }
  }

  &__info {
    .talent-info-request {
      width: 244px;
    }
  }

  &__review-item {
    border: 1px solid $border;
    padding: 10px 16px;

    &__time {
      color: rgba($default, 0.7) !important;
    }
  }

  .ant-form-item-label label {
    font-size: 16px !important;
    font-weight: 400 !important;
  }

  &__form {
    border-bottom: 1px solid $gray5;

    .ant-form-item {
      margin-bottom: 32px;
    }
  }

  .ant-select {
    width: 100%;

    &-arrow {
      width: 24px;
      height: 24px;
      margin-top: 0;
      transform: translate(0, -50%);
    }

    &-selector {
      height: 52px !important;
      padding: 0 16px !important;
      background-color: transparent !important;
      border: 1px solid rgba($white, 0.1) !important;

      img {
        width: 54px;
        height: 32px;

        &.apple-pay {
          width: 44px;
        }
      }

      .ant-select-selection-item {
        height: 52px !important;
        line-height: 52px !important;
        font-size: 16px;
        background: none !important;
        color: $white !important;
      }
    }
  }
}

.gift-input {
  input {
    font-size: 16px;
    font-weight: 500;
  }
}

.active-gift-input {
  color: $default !important;

  .ant-input-affix-wrapper {
    background-color: rgba(80, 195, 44, 0.1) !important;
    border: 1px solid rgba(80, 195, 44, 0.2) !important;
    border-radius: 8px;
    height: 52px;
    padding: 0 16px !important;

    input {
      height: 60px;
    }
  }

  label {
    font-size: 18px !important;
  }

  .ant-input {
    background-color: transparent !important;
  }

  cursor: pointer !important;

  .ant-input-suffix {
    cursor: pointer !important;
  }
  span {
    cursor: pointer !important;
  }
}
.unactive-gift-input {
  cursor: pointer !important;

  label {
    font-size: 18px !important;
  }

  .ant-input-affix-wrapper {
    padding: 0 16px !important;
    border-radius: 8px;
    height: 52px;

    input {
      height: 60px;
    }
  }

  .ant-input-suffix {
    cursor: pointer !important;
  }
  span {
    cursor: pointer !important;
  }
}

.payment-methods-wrapper {
  .payment-method-item {
    height: calc(100% - 2px);
    width: calc(100% - 2px);
    position: absolute;
    top: 1px;
    left: 1px;
    background-color: #121212;
    padding: 14px 16px;
    border-radius: 6px;

    &.new-card {
      padding: 16px;
    }

    @include mobile {
      padding: 10px 12px;

      &.new-card {
        padding: 12px;
      }
    }

    > .ant-col {
      &:first-child {
        height: 24px;
      }

      .ant-typography {
        height: 28px;
        display: inline-block;

        @include mobile {
          height: 24px;
        }
      }
    }

    &-wrapper {
      position: relative;
      width: 100%;
      align-items: center;
      border: 1px solid $tundola;
      border-radius: 8px;
      height: 60px;
      margin-right: 0;
      animation: none !important;
      padding: 16px;

      @include mobile {
        height: 52px;
        padding: 12px;
      }

      &.checked {
        background: linear-gradient(to left, #17e391, #58e5df, #60e5e8);
      }
    }
  }

  .ant-space-item {
    &:last-child {
      .payment-method-item-wrapper.checked {
        border-bottom: none;
        height: auto;

        form {
          margin-top: 40px;
        }
      }
    }
  }
}

.term-checkbox {
  background: $codGray2;
  border-radius: 8px;

  @include mobile {
    background: none;
  }

  .ant-checkbox-group-item {
    padding: 16px;
    margin-right: 0;
    align-items: flex-start;
    justify-content: flex-start;

    @include mobile {
      padding: 0;
    }

    .ant-checkbox {
      margin: 2px;
    }

    .ant-checkbox + span {
      padding: 0 0 0 16px;

      @include mobile {
        padding: 0 0 0 8px;
      }
    }
  }

  span {
    color: $white;
  }
}
