.profile-item {
  padding: 20px 0;
  border-bottom: 1px solid $gray5;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: unset;
  }

  &__text {
    &:first-child {
      flex: 1;
    }

    &__label {
      font-size: 16px;
      font-weight: 500;
    }

    &__data {
      opacity: 0.7;
      margin-top: 4px;
      line-height: 24px;

      @include mobile {
        font-size: $medium1 !important;
      }
    }
  }

  &__btn-toggle {
    background-color: $white;
    border: none;
    font-weight: normal !important;

    &.edit {
      color: $blue !important;
    }
  }

  &__accomplishments {
    .btn-dangerous {
      svg {
        path {
          stroke: $red;
        }
      }
    }
  }
  .ant-picker {
    width: 100%;
    height: 52px;
  }
  .ant-select-selector {
    height: 52px !important;

    .ant-select-selection-item {
      height: 62px !important;
      background: none !important;
      color: $default !important;
    }
  }
  .ant-input-password {
    input {
      height: 42px;
    }
  }
}
