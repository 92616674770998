.all-categories {
  &__title {
    max-width: 558px;
    margin: auto;
  }

  .category-card {
    width: 100%;

    .image-skeleton,
    .ant-skeleton,
    .ant-skeleton-avatar {
      width: 100% !important;
    }
  }
}
