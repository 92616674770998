.search-box {
  @include mobile {
    width: 100% !important;
  }
  .ant-select-selection-search {
    display: flex;
    align-items: center;
  }

  .ant-select-selection-search-input {
    width: 300px !important;
    height: 40px;
    border-radius: 123px;
    background-color: #f5f5f5;
    border: none;

    @include mobile {
      height: 44px;
      width: 100% !important;
    }

    .ant-input {
      background-color: transparent;
      font-weight: $medium;
      font-size: $medium1;

      &::placeholder,
      &::-webkit-input-placeholder {
        font-weight: 500;
        color: rgba($default, 0.5);
        font-size: 14px;
      }
    }
  }

  .ant-input-search {
    margin-left: 24px !important;
    max-width: 345px;
    border: 1px solid $border;
    padding: 8px 0 8px 16px;

    &-icon {
      padding-right: 16px;

      &::before {
        display: none;
      }
    }
  }
  .input-search-bar {
    margin-left: 24px;

    @include mobile {
      margin-left: 0;
    }
  }
}
