.product-card {
  display: flex;
  flex-direction: column;

  &__image {
    overflow: hidden;
    border-radius: 16px;
    width: 100%;
    height: 100%;

    .image-skeleton {
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__no-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 140px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: rgba(255, 255, 255, 0.1);
    svg {
      width: 52px !important;
      height: 52px !important;
      path {
        stroke: unset !important;
        fill: #ffffff !important;
      }
    }
    &--dark {
      background-color: rgba(18, 18, 18, 0.1);
      svg {
        path {
          fill: #121212 !important;
        }
      }
    }
    &--vertical {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  .divider {
    background-color: $gray5;
    height: 1px;
    width: 60px;
  }

  svg {
    width: 20px;
    height: 20px;

    path {
      stroke: $blue;
    }
  }

  &__copy {
    background: $gray5;
    border-radius: 8px;
    padding: 8px;

    .ant-btn {
      min-height: 24px;
      height: 24px !important;
    }

    @include mobile {
      padding: 4px;
    }

    svg {
      width: 24px;
      height: 24px;

      path {
        stroke: $default;
      }
    }
  }
}
