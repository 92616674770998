.form-item-text {
  &__label {
    font-size: 12px;
    line-height: 20px;
    color: rgba($default, 0.5);
  }

  &__data {
    line-height: 24px;
    font-weight: 500;
    margin-top: 4px;
  }
}
