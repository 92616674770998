@mixin activeItem {
  animation: scaleIn 0.3s ease-in;
  animation-fill-mode: forwards !important;
  -webkit-animation-fill-mode: forwards !important;
  transform-origin: top;
  margin: auto;

  @include mobile {
    animation: scaleInTablet 0.3s ease-in;
    animation-fill-mode: forwards !important;
    -webkit-animation-fill-mode: forwards !important;
  }

  // @include mobile {
  //   animation: scaleInMobile 0.3s ease-in;
  //   animation-fill-mode: forwards !important;
  //   -webkit-animation-fill-mode: forwards !important;
  // }

  .name {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;

    @include tablet {
      font-size: 18px;
      line-height: 28px;
    }

    @include mobile {
      font-size: 18px;
      line-height: 28px;
    }
  }

  .followers {
    font-size: 16px;
    line-height: 24px;

    @include tablet {
      font-size: 14px;
      line-height: 22px;
    }

    @include mobile {
      font-size: 14px;
      line-height: 22px;
    }
  }
}

.talent-card-landing-page {
  width: 222px;
  height: 309px;
  animation: scaleOut 0.3s ease-out;
  animation-fill-mode: forwards !important;
  -webkit-animation-fill-mode: forwards !important;

  @include tablet {
    animation: scaleOutTablet 0.3s ease-out;
    animation-fill-mode: forwards !important;
    -webkit-animation-fill-mode: forwards !important;
    width: 180px;
    height: 250px;
  }

  @include mobile {
    // animation: scaleOutMobile 0.1s ease-out;
    // animation-fill-mode: forwards !important;
    // -webkit-animation-fill-mode: forwards !important;
    width: 196px;
    height: 269px;
  }

  filter: drop-shadow(0 4px 25px rgba(0, 0, 0, 0.4));
  position: relative;

  img {
    border-radius: 32px;
    width: 100%;
    height: 100%;
  }

  .wrapper-info {
    position: absolute;
    text-align: center;
    bottom: 0;
    width: 100%;
    padding: 1rem;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;

    .name {
      font-size: 18px;
      line-height: 28px;
      font-weight: 600;

      @include tablet {
        font-size: 16px;
        line-height: 24px;
      }

      @include mobile {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .followers {
      font-size: 14px;
      line-height: 22px;

      @include tablet {
        font-size: 12px;
        line-height: 16px;
      }

      @include mobile {
        font-size: 12px;
        line-height: 20px;
      }
    }
  }

  &.active {
    @include activeItem;
  }
}

.landing-page__swiper.my-swiper.single {
  .swiper-no-swiping {
    .swiper-wrapper {
      overflow: visible;

      @include mobile {
        overflow: auto;
        transform: unset !important;

        .swiper-slide:first-child {
          margin-left: 24px;
        }
      }
    }
  }

  .swiper-slide {
    &.swiper-slide {
      &-next {
        @include desktop {
          width: 268px !important;
          height: 373px;

          .talent-card-landing-page {
            @include activeItem;
          }
        }

        @include tablet {
          width: 240px !important;
          height: 334px;

          .talent-card-landing-page {
            @include activeItem;
          }
        }
      }

      &-active {
        @include mobile {
          // width: 222px !important;
          // height: 309px;
          // .talent-card-landing-page {
          //   @include activeItem;
          // }
        }
      }
    }
  }
}

@keyframes scaleIn {
  0% {
    transform: scaleX(1) scaleY(1);
    -webkit-transform: scaleX(1) scaleY(1);
  }
  100% {
    transform: scaleX(calc(268 / 222)) scaleY(calc(373 / 309));
    -webkit-transform: scaleX(calc(268 / 222)) scaleY(calc(373 / 309));
  }
}

@keyframes scaleOut {
  0% {
    transform: scaleX(calc(268 / 222)) scaleY(calc(373 / 309));
    -webkit-transform: scaleX(calc(268 / 222)) scaleY(calc(373 / 309));
  }

  100% {
    transform: scaleX(1) scaleY(1);
    -webkit-transform: scaleX(1) scaleY(1);
  }
}

@keyframes scaleInTablet {
  0% {
    transform: scaleX(1) scaleY(1);
    -webkit-transform: scaleX(1) scaleY(1);
  }
  100% {
    transform: scaleX(calc(240 / 180)) scaleY(calc(334 / 250));
    -webkit-transform: scaleX(calc(240 / 180)) scaleY(calc(334 / 250));
  }
}

@keyframes scaleOutTablet {
  0% {
    transform: scaleX(calc(240 / 180)) scaleY(calc(334 / 250));
    -webkit-transform: scaleX(calc(240 / 180)) scaleY(calc(334 / 250));
  }

  100% {
    transform: scaleX(1) scaleY(1);
    -webkit-transform: scaleX(1) scaleY(1);
  }
}

@keyframes scaleInMobile {
  0% {
    transform: scaleX(1) scaleY(1);
    -webkit-transform: scaleX(1) scaleY(1);
  }
  100% {
    transform: scaleX(calc(222 / 196)) scaleY(calc(309 / 269));
    -webkit-transform: scaleX(calc(222 / 196)) scaleY(calc(309 / 269));
  }
}

@keyframes scaleOutMobile {
  0% {
    transform: scaleX(calc(222 / 196)) scaleY(calc(309 / 269));
    -webkit-transform: scaleX(calc(222 / 196)) scaleY(calc(309 / 269));
  }

  100% {
    transform: scaleX(1) scaleY(1);
    -webkit-transform: scaleX(1) scaleY(1);
  }
}
