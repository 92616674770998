:root {
  --ant-typography-color: #ffffff;
  --ant-background-color: #ffffff;
  --ant-overlay-color: #ffffff;
  --ant-overlay-opacity: #ffffff;
}
.detail-podcast-layout {
  display: flex;
  justify-content: center;
  background-size: cover;
  padding-bottom: 15px;
  background-color: var(--ant-background-color);
  color: var(--ant-typography-color);
  border-radius: 16px !important;

  * {
    color: var(--ant-typography-color) !important;
  }
  .music-item {
    svg * {
      fill: var(--ant-typography-color) !important;
    }
  }

  .talent-podcast-detail .music-card.big.single {
    background: linear-gradient(var(--ant-overlay-color), var(--ant-overlay-color)), var(--ant-background-color) !important;
  }
  .talent-podcast-detail .link-card {
    background: linear-gradient(var(--ant-overlay-color), var(--ant-overlay-color)), var(--ant-background-color) !important;
    border-radius: 8px;
    overflow: hidden;
  }

  .detail-layout__content {
    background: var(--ant-background-color);
  }
}
