.request-history__card {
  border: 1px solid $border;
  border-radius: 16px;
  margin-top: 24px;

  &__header {
    padding: 24px;
    border-bottom: 1px solid rgba($default, 0.1);

    .avatar-profile__profession {
      color: rgba($default, 0.5);
    }

    .request-status {
      color: $white;
      border-radius: 4px;
      padding: 0 8px;
      display: block;
      height: 28px;
      line-height: 28px;

      &--pending,
      &--video_transcode_processing,
      &--video_transcode_failed {
        background-color: $yellow;
      }

      &--accepted,
      &--completed,
      &--video_transcode_finished {
        background-color: $green;
      }

      &--expired,
      &--declined {
        background-color: $red;
      }
    }
  }

  &__content {
    padding: 24px 40px;

    .btn-expand {
      margin-top: 24px;
    }

    &__payment {
      .ant-typography {
        text-transform: capitalize;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    padding: 24px;
    background: rgba($color: $default, $alpha: 0.02);
    border-top: 1px solid rgba($default, 0.1);

    button {
      width: 140px !important;
    }
  }
}
