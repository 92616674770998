.experience-detail {
  .btn-booking {
    background: $primary-gradient;

    .ant-typography {
      color: $default !important;
      text-transform: none !important;
      letter-spacing: 0 !important;
    }

    &:hover,
    &:focus,
    &:active {
      background: $primary-gradient;
    }
  }

  &__products {
    .swiper-container {
      margin-top: 16px !important;
    }
  }

  &__divider {
    background-color: rgba($white, 0.1) !important;
    margin: 24px 0 !important;
  }

  &__requirements {
    ul {
      margin: 0;
      list-style-type: none;
      padding-left: 0;

      > li::before {
        content: "-";
        text-indent: -5px;
        padding-right: 5px;
      }
    }
  }

  &__intro {
    &__header {
      position: relative;

      &__image {
        position: relative;

        .icon-play {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 80px;
          height: 80px;

          @include mobile {
            width: 56px;
            height: 56px;
          }
        }
      }

      .btn-menu {
        &-wrapper {
          height: 32px;

          @include mobile {
            position: absolute;
            z-index: 99;
            top: 0;
            left: 0;
            background: linear-gradient(0deg, rgba(18, 18, 18, 0) 3.22%, rgba(#000000, 0.6) 95.91%);
            width: 100%;
            height: 110px;
            padding: 16px 0 0 16px;
          }
        }
      }

      &__shadow {
        @include mobile {
          background: linear-gradient(180deg, rgba(18, 18, 18, 0) 0%, rgba(18, 18, 18, 0.78) 51.04%, #121212 100%);
          width: 100%;
          height: 144px;
          position: absolute;
          bottom: 0;
          left: 0;
          align-items: flex-end;
        }
      }
    }

    &__content {
      @include mobile {
        z-index: 999;
        position: relative;
        margin-top: -42px;
      }
    }
  }

  .avatar-profile {
    &__info {
      .ant-typography {
        font-size: 16px;
        font-weight: 500;
      }
    }

    &__profession.ant-typography {
      font-size: 14px;
      color: rgba($default, 0.8);
      font-weight: 400;
    }
  }

  &__talent {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__info {
    &__tag {
      display: inline-block;
      color: $white !important;
      background-color: rgba($default, 0.5);
      height: 24px;
      line-height: 24px;
      border-radius: 4px;
      padding: 0 6px;
      margin-right: 7px;
    }
  }

  .btn-action {
    height: 32px;
    padding: 0;
  }

  &__booking {
    margin-top: 32px;
    padding: 24px;
    box-shadow: 0 0 17px rgba(0, 0, 0, 0.05);
    border-radius: 16px;

    &__duration {
      .ant-col {
        &:first-child {
          height: 24px;
        }
      }

      svg {
        width: 24px;
        height: 24px;

        g {
          opacity: 1;
        }
      }

      .icon-col {
        width: 32px;
        height: 32px;
      }
    }

    .btn-book {
      width: 232px;
      height: 52px;
    }
  }

  .btn-save {
    &--inactive {
      svg {
        path {
          fill: none;
          stroke: $default;
        }
      }
    }
  }

  .icon-lesson {
    width: 18px;
    height: 18px;
  }

  .btn-show-more {
    &.active {
      position: relative;
      height: 98px;
      margin-top: -70px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.83) 48.44%, #ffffff 100%);
    }
  }
}
