.bundle-intro {
  &__info {
    border-radius: 24px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    position: relative;
    margin-left: -20px;
    margin-top: -40px;
    background-color: white;

    &__behind {
      position: relative;
      border-radius: 24px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
      width: 100%;
      max-width: 460px;
      padding: 20px 20px 20px 0;
    }

    &__available {
      svg * {
        opacity: 1;
        stroke: $white;
      }
    }
  }

  &__summary {
    border-radius: 16px;
    background-color: $codGray2;
    padding: 20px;

    svg {
      width: 32px;
      height: 32px;

      @include mobile {
        width: 24px;
        height: 24px;
      }
    }

    .ant-row:first-child {
      margin-top: 0 !important;
    }
  }

  .btn-save {
    &--inactive {
      svg {
        path {
          fill: none;
          stroke: $default;
        }
      }
    }
  }

  .talent-detail__intro__image {
    img {
      border-radius: 24px;
    }
  }

  .talent-detail__intro__covers {
    img {
      border-radius: 8px;
    }
  }
}
