.video-track {
  &__video,
  .video-placeholder {
    height: $videoCallHeight;
    width: 100%;
    border-radius: 16px;
    object-fit: cover;
  }

  &__placeholder {
    background-color: $black2;
    align-items: center;
    justify-content: center;
    display: flex;
  }
}
