.main-header {
  height: 100%;

  .btn-categories {
    background-color: transparent;
    border: none;

    .ant-typography {
      font-weight: 500;
    }

    &:hover,
    &:focus,
    &:active,
    &:visited {
      background-color: transparent;
    }
  }

  &__dropdown-menu {
    box-shadow: 0 0 3px rgba($default, 0.2);
    padding: 16px !important;
    border-radius: 16px !important;

    &__overlay {
      right: 50px !important;
      left: auto !important;
      width: 300px;
    }

    .ant-dropdown-menu-item {
      height: auto;
      min-height: 44px;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0 16px !important;
      margin-top: 8px;
      border-radius: 8px;

      &:first-child {
        margin-top: 0;
      }

      &:hover {
        background-color: rgba($primary, 0.2);
      }

      &.switch-talent {
        background-color: $primary;
        justify-content: center;

        &:hover {
          background-color: $primary;
        }
      }

      span {
        width: 100%;
        height: 44px;
        line-height: 44px;
      }

      a {
        color: $default;
        font-weight: 500;
        display: block;
        width: 100%;
        height: 100%;
      }

      svg {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }
  }

  .dark-mode {
    .avatar-profile__info {
      span {
        color: $white;
      }
    }
  }

  .mobile-header {
    &--left {
      width: 64px;

      @include mobile {
        flex: 1;
        width: auto;
      }
    }

    &--right {
      width: 64px;

      @include mobile {
        flex: 1;
        width: auto;
      }
    }
  }

  .hidden-label {
    .ant-form-item-label {
      position: absolute;
      left: -1000px;
    }

    input,
    .ant-select-selector {
      height: auto !important;
    }
  }
}

.category-menu {
  padding: 16px !important;

  &-mobile {
    .ant-collapse-arrow {
      padding: 0 !important;
    }
  }

  .ant-dropdown-menu-item {
    height: 40px;
    margin-top: 8px;

    img {
      width: 24px;
      filter: brightness(0);
    }

    &:first-child {
      margin-top: 0;
    }

    &:hover {
      border-radius: 8px;
      background-color: rgba($primary, 0.2);
    }
  }
}

.search-dropdown {
  @include mobile {
    width: calc(100% - 48px) !important;
    padding: 0 !important;
  }

  .search-item {
    &--experience {
      .divider {
        display: block;
        background-color: $border;
        height: 1px;
        width: calc(100% - 16px);
      }
    }
  }

  .ant-select-item {
    padding: 8px 24px;
  }

  .ant-empty-description {
    color: $default;
  }
}
