.ant-drawer-dark {
  .ant-drawer-content {
    overflow-x: hidden;
  }
  .ant-drawer-content,
  .ant-drawer-header,
  .ant-drawer-body,
  .ant-layout-sider-dark {
    background-color: $shark !important;
  }

  .ant-drawer-header {
    .ant-drawer-title,
    .ant-drawer-close {
      color: $white !important;
    }

    .ant-drawer-close {
      top: 10px;
    }
  }
}
