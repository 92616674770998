.landing-page-v2-layout {
  min-height: 100vh !important;
  width: 100%;
  overflow-x: hidden;
  background: #1e1e20;
  z-index: 0;
  position: relative;
  font-family: "ABC Repro Variable", "SFProDisplay", sans-serif;

  &__nav-container {
    position: fixed;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;

    .pill-nav {
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }
  }

  &.mobile-menu-visible {
    overflow: hidden;
    height: 100vh;
  }

  &__menu {
    top: 74px !important;
    position: fixed;

    @include mobile {
      position: absolute;
    }
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr min-content 1fr;
    align-items: center;
    width: 100%;
    padding: 26px;

    &__logo {
      height: 36px;
      cursor: pointer;

      @include mobile {
        height: 24px;
      }
    }

    &__right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 16px;

      button {
        user-select: none;
      }

      &__join-waitlist {
        opacity: 1;
        transition: opacity 0.4s ease-in-out;

        &.hide {
          opacity: 0;

          .cta-button {
            cursor: unset;
          }
        }
      }

      &__login {
        font-size: 14px;
        font-weight: 500;
        color: white;
        background: transparent;
        border: none;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        cursor: pointer;
        height: 30px;

        transition: color 0.2s ease-in-out;
        &:hover {
          color: #ff61ff;
        }
      }
    }

    .avatar-profile__text {
      font-size: 16px !important;
      font-weight: 500;
    }

    @include mobile {
      padding: 16px 0;
    }

    &__wrapper {
      position: fixed;
      z-index: 999;
      width: 100%;
      background: transparent;
      transition: background-color 200ms linear;

      &.scrolling {
        background: linear-gradient(180deg, rgba(18, 18, 18, 0.9) 0%, rgba(18, 18, 18, 0) 100%);
      }

      @include mobile {
        background: linear-gradient(180deg, rgba(18, 18, 18, 0.9) 0%, rgba(18, 18, 18, 0) 100%);
      }
    }

    &__container {
      padding: 0 88px;

      @include mobile {
        padding: 0 32px;
      }

      @include mobile {
        padding: 0 16px;
      }
    }
  }

  &__footer-container {
    padding: 8vh 6% 60px 6%;

    .landing-footer {
      &__sections {
        padding: 40px 0;

        :first-child {
          @include mobile {
            grid-column: 1 / 3;
          }
        }

        &__section-content {
          > p {
            margin: 0;

            > a {
              color: white;
              text-decoration: underline;
              text-underline-offset: 4px;
            }
          }
        }

        @include mobile {
          gap: 40px 24px;

          &__section-item {
            gap: 0;
          }

          &__section-title {
            font-size: 20px;
          }

          &__section-content {
            font-size: 14px;
          }
        }
      }
    }
  }

  &__background-gradient {
    position: fixed;
    top: 0;
    bottom: -10vh;
    left: 0;
    right: 0;
    z-index: -1;
    background: linear-gradient(
      340deg,
      rgba(41, 59, 193, 0.7) -7%,
      rgba(94, 49, 169, 0.7) 14%,
      rgba(30, 30, 32, 0.7) 48%
    );
  }
}
