.video-placeholder {
  width: 100%;

  &__container {
    width: 100%;
    border-radius: 8px;
    object-fit: cover;
    height: $videoCallHeight;
    background-color: $black2;
    align-items: center;
    justify-content: center;
    display: flex;
  }
}
.join-placeholder {
  width: 100%;
  &__container {
    width: 100%;
    border-radius: 8px;
    object-fit: cover;
    height: $videoCallHeight;
    background-color: $codGray;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
}
