@import "atropos/scss";

.atropos {
  width: 100%;
  height: 100%;
}

.atropos-rotate {
  // Safari fix layer order
  transform-style: flat;
}

.atropos-inner {
  overflow: visible;
  z-index: 0;
  width: 100%;
  height: 100%;

  .atropos-stacked-item {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
