.marketing-modal {
  padding: 0;

  .modal-header {
    background: #3c8456;
    height: auto;
    padding: 22px 28px;
    padding-bottom: 47px;
    border-radius: 16px 16px 0 0;
    position: relative;
  }

  .modal-header-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255, 0.05);
  }

  .modal-body {
    margin-left: 24px;
    margin-right: 24px;

    .modal-card {
      padding: 10px;
      margin-top: 24px;
      border: 1px solid $whisper;
      border-radius: 12px;
      margin-bottom: 16px;

      .modal-card-col-stack {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 16px;
        max-width: 50%;

        .ant-tag {
          width: fit-content;
        }
      }
    }

    .modal-form {
      margin-top: 16px;
    }
  }

  .text-right {
    text-align: right;
  }
}
