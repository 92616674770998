$breakpoints: (
  "xs-phone": 320px,
  "phone": 480px,
  "tablet": 768px,
  "desktop": 1024px,
  "widescreen": 1200px,
);

// keywords
$media-expressions: (
  "screen": "screen",
  "print": "print",
  "handheld": "handheld",
  "landscape": "(orientation: landscape)",
  "portrait": "(orientation: portrait)",
  "retina2x": "(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)",
  "retina3x": "(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)",
);

@import "../vendors/icomoon";

.talent-faqs,
.talent-tips {
  @import "../vendors/include-media";
  @import "../vendors/normalize";

  // ligatured operators ≥ ≤
  @import "../static-base/functions";
  @import "../static-base/variables";
  @import "../static-base/mixins";
  @import "../static-base/helpers";
  @import "../static-base/reset";
  @import "../static-base/typography";
  @import "../static-base/forms";
  @import "../layouts/common";
  @import "../layouts/header";
  @import "../layouts/footer";
}
