#footer {
  text-align: center;
  padding: rem-calc(35 0);
  border-top: 1px solid #e9e9eb;

  .copyright {
    display: block;
    text-align: center;
    color: rgba(#000, 0.2);
  }
}
