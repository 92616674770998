.send-tip-modal {
  .send-tip-price-button {
    width: 101px;
    height: 48px;
    margin-bottom: 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-content: center !important;

    @include mobile {
      width: 90px;
    }

    .ant-input {
      text-align: center !important;
    }
  }

  .ant-input-number {
    border-radius: 8px;
  }

  .input-custom {
    input {
      text-align: center !important;
    }
  }

  .ant-form-item-label label {
    font-weight: 400 !important;
  }

  .ant-select {
    flex: 1;
  }

  .ant-select-selector {
    height: 60px !important;

    .ant-row-middle {
      margin-top: 5px !important;
    }
  }

  .ant-select-arrow {
    right: 0 !important;
    top: 0 !important;
    height: 60px !important;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
  }

  .ant-btn-primary {
    width: 100% !important;
    margin-top: 12px !important;
  }

  .active-tip-price-btn {
    border-width: 2px !important;
    border-color: $primary !important;
  }

  textarea {
    padding-top: 12px !important;
  }

  .ant-modal-header {
    border-bottom-width: 0 !important;
    padding-bottom: 0 !important;
  }

  .ant-modal-title {
    font-size: 18px !important;
    margin-top: 56px !important;
  }

  .ant-avatar {
    border: 2px solid $white !important;
    position: absolute !important;
    top: -56px !important;
    left: calc(50% - 56px) !important;
  }

  .ant-modal-body {
    padding-top: 0 !important;
  }
}

.send-tip-card__content {
  padding: 16px;

  &__price {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 24px;
  }
}
