// Colors
// Color calculator - http://razorjam.github.io/sasscolourfunctioncalculator/
// Color names - http://name-of-color.com/

$gray: #999;
$light-gray: #f1f1f1;
$black: #000;
$light-black: #020025;
$white: #fff;
$light-blue: #39f;
$blue: #73e5ff;
$dark-blue: #011844;

$base-font-sans-serif: "Gotham", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
$base-font-serif: "Times New Roman", "Times", "Baskerville", "Georgia", serif;

// This is the default html and body font-size for the base rem value.
$rem-base: 16px;

// Body
$base-text-color: $dark-blue;
$base-background-color: $white !default;
$font-size-base: 16px !default;
$line-height-base: 1.2 !default;
$base-font-family: $base-font-sans-serif !default;

$vertical-rhythm: ($line-height-base * 1em);

$base-min-width: 320px;

// Liks
$base-link-color: $dark-blue;

// Buttons
$btn-color: $white !default;
$btn-background: $black !default;
$btn-padding: 0.4em 0.7em !default;
$btn-font-size: inherit !default;

// Forms
$form-element-padding: $btn-padding !default;
$form-element-border-color: $gray !default;
$form-element-focus-border-color: $black !default;
$placeholder-color: $gray !default;

// Headers
$h1-font-size: 30px !default;
$h2-font-size: 27px !default;
$h3-font-size: 24px !default;
$h4-font-size: 21px !default;
$h5-font-size: 17px !default;
$h6-font-size: 15px !default;

$headings-font-family: inherit !default;
$headings-color: inherit !default;

$animation-speed: 0.3s;
