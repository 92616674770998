.call {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 32px;

  @include mobile {
    padding: 0 16px;
  }

  #Dish {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    background-color: $default;
  }

  /* Camera */
  #Dish .Camera {
    position: relative;
    vertical-align: middle;
    align-self: center;
    border-radius: 10px;
    overflow: hidden;
    display: inline-block;
    animation: show 0.4s ease;
  }

  .swiper-button {
    background: linear-gradient(180deg, rgba(20, 20, 20, 0) 0%, #141414 100%);
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 84px;
    height: 40px;
    transform: translate(-50%, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    border-radius: 0 !important;

    &:hover,
    &:focus,
    &:active {
      background: linear-gradient(180deg, rgba(20, 20, 20, 0) 0%, #141414 100%) !important;
    }

    svg path {
      stroke: $white;
    }
  }

  .swiper-button-up {
    svg {
      transform: rotate(-90deg);
    }
  }

  .swiper-button-down {
    svg {
      transform: rotate(90deg);
    }
  }

  &__header {
    &__item {
      background-color: $white;
      border-radius: 4px;
      border-radius: 8px;

      &__timer {
        text-align: center;
      }

      &--divider {
        margin: 0 12px;
        width: 1px;
        height: 24px;
        background-color: $gray6;
      }

      * {
        line-height: 24px;
      }
    }
  }
}

.large-tiles {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  // height: calc(100vh - 152px);

  // @include mobile {
  //   height: calc(100vh - 166px);
  // }

  &__row {
    width: 100%;
    overflow: hidden;
    align-items: center;

    .large-tiles__col--muted {
      width: 50%;
      height: 193px;
      padding: 0 !important;

      .tile {
        width: 100% !important;
        height: 162px !important;
      }
    }

    &--has-portrait {
      .ant-col {
        &:first-child {
          width: calc(69.5%);
        }

        &:last-child {
          width: 30.5%;
        }

        .tile {
          video {
            max-height: none;
            width: 100%;
          }
        }
      }
    }

    &--co-host {
      .tile {
        video {
          width: 100%;
        }
      }
    }
  }
}

.small-tiles {
  display: flex;
  align-items: center;
  max-height: calc(100vh - 194px) !important;
  position: relative;

  .swiper-container {
    height: 100%;
  }

  &--height-auto {
    .swiper-slide {
      height: auto !important;
    }
  }
}
