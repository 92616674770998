.stepper-header {
  height: 100%;

  .ant-steps {
    justify-content: flex-end;

    .ant-steps-item {
      max-width: 300px;
      width: 30%;

      &-title {
        color: $default !important;
      }

      &-icon {
        background-color: rgba($default, 0.2);
        border: none;

        span {
          color: $default;
        }
      }

      &-active,
      &-finish {
        .ant-steps-item-icon {
          background-color: $primary !important;

          span {
            color: $default;
          }
        }
      }

      &:last-child {
        width: 120px;
      }
    }
  }
}
