.live-events {
  padding: 32px;
  background-color: $default !important;
  margin-top: 70px;

  &__title {
    color: $white !important;
    margin-bottom: 16px !important;
  }

  .ant-tabs {
    overflow: visible;

    &-tab {
      color: $white !important;
      margin-right: 60px;
    }

    &-nav {
      margin-bottom: 24px !important;

      &::before {
        border: none !important;
      }
    }
  }
}
