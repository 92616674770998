.terms,
.privacy,
.talent-privacy,
.expert-terms {
  max-width: $container;
  margin: auto;
  padding: 24px 16px;

  &-container {
    height: 100vh;
    overflow: auto;
  }
}

@media (max-width: #{$tablet-width}) {
  .terms,
  .privacy,
  .talent-privacy,
  .expert-terms {
    padding-bottom: 100px;
  }
}
