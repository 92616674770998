.form-payment {
  input {
    font-weight: 400;
    font-size: 16px;
    background-color: transparent !important;
    color: $white;
    border: 1px solid $tundola;
    padding: 14px;
    height: 52px;

    &:hover,
    &:active,
    &:focus {
      border: 1px solid $tundola;
    }

    &::placeholder {
      font-size: 16px;
      font-weight: 400;
      color: rgba($white, 0.5);
    }
  }

  .ant-form-item-explain {
    min-height: 0;
    margin-bottom: 0 !important;
  }

  .form-payment__item {
    .form-payment__item__content {
      border: 1px solid $tundola;
      height: 52px;
      display: flex;
      align-items: center;
      padding: 0 14px;

      iframe {
        position: absolute;
        left: 0;
        padding-left: 16px;
      }

      img {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translate(0, -50%);
      }

      .StripeElement {
        flex: 1;
        height: 52px;
      }
    }

    .ant-form-item-explain {
      min-height: 0;
      margin-bottom: 0 !important;

      * {
        color: $red;
        font-weight: 500;
      }
    }

    label {
      font-size: 16px;
    }
  }

  .ant-col {
    &:first-child {
      .form-payment__item__content {
        border-radius: 8px 0 0 8px;
      }
    }

    &:last-child {
      .form-payment__item__content {
        border-radius: 0 8px 8px 0;
      }
    }

    @include mobile {
      .form-payment__item__content {
        margin-top: 8px;
        border-radius: 8px 0 0 8px;
      }

      &:first-child {
        .form-payment__item__content {
          border-radius: 8px;
        }
      }

      &:last-child {
        .form-payment__item__content {
          border-radius: 0 8px 8px 0;
        }
      }
    }
  }

  .card-icon {
    width: 24px;
    height: 24px;
  }
}

.braintree-placeholder {
  display: none;
}

.braintree-large-button {
  display: none;
}

.braintree-sheet {
  border: none;

  &__header {
    display: none !important;
  }

  &__content {
    padding: 0 !important;
  }
}
