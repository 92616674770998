.bundle-detail {
  .ant-layout-footer {
    .footer-top-area {
      height: 0;

      @include mobile {
        height: 44px;
      }
    }
  }

  .product-card__name {
    @include mobile {
      height: 40px !important;
    }
  }

  .bundle-experience-card {
    .experience-type-tag.large {
      @include mobile {
        font-size: 12px;
        padding: 0 6px;
      }
    }
  }

  &__footer {
    padding: 16px 0;
    background-color: $gray5;
  }

  .experience-detail__time {
    margin-top: 8px !important;

    .text--medium16 {
      font-size: 14px !important;
    }
  }

  .bundle-checkout-successful {
    @include mobile {
      padding-bottom: 32px;
    }
  }

  &__experiences {
    background-color: rgba($white, 0.05);
    border-radius: 16px;

    .experience-detail__divider {
      &:last-child {
        display: none;
      }
    }
  }
}
