.video-call-wait-room {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $default;

  &__container {
    flex: 1;
    max-width: 1200px;
    padding-left: 16px;
    padding-right: 16px;
    &--camera {
      width: 100%;
      margin: auto;
      border-radius: 8px;
      img {
        object-fit: cover;
      }

      @include mobile {
        max-width: 211px;
        max-height: 295px;
      }
      .call-local {
        .local {
          height: 330px !important;
          width: 100%;
          bottom: 0;
          right: 0;
          z-index: 1;
          .tile__name {
            display: none;
          }

          @include mobile {
            height: 295px !important;
          }
        }
      }
      .react-player-wrapper {
        border-radius: 8px;
        height: 330px !important;
        max-width: 544px;

        @include mobile {
          height: 295px !important;
        }
        img {
          border-radius: 8px;
        }
      }
      .video-placeholder {
        border-radius: 8px;

        &__container {
          height: 330px !important;
          max-width: 544px;

          @include mobile {
            height: 295px !important;
          }
        }

        .ant-typography {
          display: none;
        }
      }

      video {
        border-radius: 8px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        transform: scaleX(-1);
      }
    }

    &--actions {
      position: absolute;
      transform: translate(-50%, 0);
      left: 50%;
      bottom: 20px;
      z-index: 999;

      @include mobile {
        left: 53%;
      }
      &--tray {
        position: relative;
      }

      .report-icon {
        position: absolute;
        right: -5px;
        top: -5px;
      }

      .permission-disable {
        cursor: not-allowed;
        pointer-events: none;
        background-color: $white !important;

        .icon {
          opacity: 0.2;
        }

        .report-icon {
          opacity: 1;
        }
      }

      .ant-btn {
        width: 40px !important;
        height: 40px !important;
        background-color: rgba($default, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        animation-duration: 0s !important;
        path {
          stroke: $white !important;
        }
        &:hover {
          opacity: 0.7;
        }

        &:active,
        &:focus {
          background: rgba($default, 0.8);
        }

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .ant-avatar {
    background: $primary;
    border: none;
    font-weight: $medium;
    font-size: $small;
    line-height: 22px;
    color: $default;
  }

  &__devices {
    &--select {
      border-radius: 8px;
      margin-top: 16px;

      .ant-select-selector {
        height: 56px !important;
        padding-left: 16px !important;
        padding-right: 16px !important;
        justify-content: center;
        align-items: center;
      }

      .ant-select-selection-item {
        line-height: 24px !important;
        background-color: $white !important;
      }

      span {
        &.ant-select-arrow,
        &.ant-select-selection-search,
        &.ant-select-selection-item,
        &.video-call-wait-room__devices--select--option {
          font-size: 14px !important;
          font-weight: 500 !important;
          color: $dark;
          height: 54px !important;
        }
      }

      .ant-select-arrow {
        height: auto !important;
        margin: auto !important;
        transform: translate(0, -50%);
        right: 16px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &--select--option {
      display: flex;
      align-items: center;

      > svg {
        margin-right: 10px;
      }
    }
  }

  &__information {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    justify-content: flex-start;

    @include mobile {
      margin-bottom: 24px;
    }
    &--tag {
      border-radius: 4px;
      text-align: center;
    }

    &--suggest {
      padding: 20px;
      border-radius: 8px;
    }
  }
  &__notify {
    max-width: 490px;
  }

  .ant-select-selector {
    border-radius: 8px !important;
  }

  .ant-select-selection-item {
    display: flex !important;
    align-items: center;

    > svg {
      margin-right: 10px;
    }
  }
}
