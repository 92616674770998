.purchasing-option {
  &__card {
    border-radius: 16px;
    border: 1px solid $border;
    height: 100%;
    display: flex;
    flex-direction: column;

    &__header {
      background-color: rgba($default, 0.02);
      padding: 24px;
    }

    &__content {
      padding: 24px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .type {
        color: rgba($default, 0.5);
      }

      .desc-item {
        margin-bottom: 24px;

        .ant-col {
          height: 28px;
          line-height: 28px;

          svg {
            height: 28px;

            circle {
              fill: $violet;
            }
          }
        }
      }

      .ant-btn {
        margin-top: 16px;
        border-color: $violet;
        background-color: $violet;

        &:hover {
          border-color: $violet;
          background-color: $violet;
        }
      }
    }
  }
}
