.all-experiences {
  // .live-event-card__tag {
  //   display: none;
  // }

  .ant-pagination-item-active {
    border-color: $default;

    a {
      color: $default;
    }
  }
}
