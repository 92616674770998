.image-skeleton {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    object-fit: cover;
    //object-position: top;
  }

  &--circle {
    border-radius: 100% !important;
    overflow: hidden;
  }
  &__mobile-edit {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(18, 18, 18, 0.8);
    height: 24px;
    border-end-end-radius: 123px;
    border-end-start-radius: 123px;
  }
}
