.auth-layout {
  position: relative;
  background: $bg-dark;
  min-height: 100vh !important;
  width: 100%;
  overflow-x: hidden;

  &__header {
    display: flex;
    align-items: center;
    padding: 24px 0;

    @include mobile {
      justify-content: center;
      padding: 24px 0;
    }

    img {
      height: 40px;

      @include mobile {
        height: 32px;
      }
    }
  }

  .container {
    max-width: $landing-page-container;
    padding: 0 24px;
    margin: 0 auto;

    @include mobile {
      padding: 0;
    }
  }
}
