.chat-box {
  border: 1px solid $border;
  height: 100%;
  background-color: $white;
  animation: fadeInRight 0.2s;

  .avatar-profile {
    &__text {
      .ant-avatar-string {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  &__content {
    padding: 16px;
    height: calc(100vh - 243px);
    overflow: auto;
  }

  &__item {
    margin-top: 16px;

    .ant-col:last-child {
      max-width: calc(100% - 40px);
    }

    &__sender {
      font-size: 12px;
      color: rgba($default, 0.8);
    }

    &__message {
      padding: 12px;
      border-radius: 16px;
      background-color: $gray;
      word-break: break-word;
    }

    &--right {
      flex-direction: row-reverse !important;

      .ant-col:first-child {
        display: flex;
        align-items: flex-end;
      }

      .chat-box__item__message {
        background-color: rgba($primary, 0.2);
      }
    }

    &--same-previous {
      margin-top: 0;

      .chat-box__item__sender {
        display: none;
      }

      .chat-box__item__avatar {
        visibility: hidden;
      }
    }
  }

  &__header {
    height: 56px;
    border-bottom: 1px solid $border;
    position: relative;
    .ant-typography {
      font-weight: 500;
      font-size: 18px;
    }
    &--close {
      position: absolute !important;
      right: 16px;
      top: 16px;
      padding: 0 !important;
      min-height: auto !important;
    }
  }

  &__empty {
    flex-direction: column !important;

    &__icon {
      background-color: $gray5;
      width: 48px;
      height: 48px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ant-typography {
      color: rgba($default, 0.7);
    }
  }

  .ant-form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    border-top: 1px solid $border;

    &-item {
      padding: 4px 0;
      margin-bottom: 0;
      flex: 1;

      input {
        border: none;
        padding: 0;

        &:disabled,
        &:focus,
        &:active {
          background-color: $white !important;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px $white inset !important;
        }
      }
    }

    button {
      padding-right: 0;
      background-color: $white !important;
      color: rgba($default, 0.5);

      &:hover {
        color: $primary;
      }

      &:disabled {
        color: rgba($default, 0.5);
      }
    }
  }
}
