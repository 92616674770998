@import "./abstracts/variables";
@import "./layouts/layouts";
@import "./base/display";
@import "./base/background";
@import "./base/spacing";
@import "./base/text";
@import "./base/form";
@import "./base/button";
@import "./base/modal";
@import "./base/tooltip";
@import "./base/tabs";
@import "./base/boxshadow";
@import "./base/border";
@import "./base/message";
@import "./base/noti-message";
@import "./static-base/consumer-terms.scss";
@import "./static-base/expert-terms.scss";
@import "./static-base/privacy-policy.scss";
@import "react-loading-skeleton/dist/skeleton.css";

// html {
//   overflow: hidden;
// }

body {
  scroll-behavior: smooth;
  font-family: $fontSFProDisplay !important;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //   position: fixed;
  //   margin: 0;
  //   padding: 0;
  //   border: 0;
  //   outline: 0;
  //   background: 0 0;
  //   -webkit-overflow-scrolling: touch;
  //   overflow-x: auto;
  //   overflow-y: auto;
}

#nprogress .bar {
  background: $primary !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.container {
  max-width: $container;
  width: 100%;
  margin: auto;
  padding: 0 24px;

  @include mobile {
    padding: 0;
  }
  &--full-width {
    width: 100%;
    margin: auto;
  }
}

// https://issuehunt.io/r/ant-design/ant-design/issues/10144
// .ant-row {
//   margin-right: 0 !important;
//   margin-left: 0 !important;
// }

// .ant-col:first-child {
//   padding-left: 0 !important;
// }

// .ant-col:last-child {
//   padding-right: 0 !important;
// }

.ant-row.container {
  margin: auto !important;
}

.ant-col {
  min-width: 0;
}

.ant-btn-link {
  color: #2f80ed;

  path {
    stroke: #2f80ed;
  }
}

.ant-btn-link:hover,
.ant-btn-link:focus {
  color: #020025;

  path {
    stroke: #020025;
  }
}

//.ant-col:first-child {
//  padding-left: 0 !important;
//}
//
//.ant-col:last-child {
//  padding-right: 0 !important;
//}

.slick-list,
.slick-track {
  touch-action: pan-y;
}

.ant-btn-block {
  width: 100% !important;
}

@import "./components/components";
@import "./pages/pages";
@import "./livestreamComponents/livestreamComponents";
@import "./videoCallComponents/videoCallComponents";

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 100px;
  background-color: rgba(#ffffff, 0.4);
  color: rgba(#ffffff, 0.4);
  animation: dotFlashingLighten 1s infinite linear alternate;
  animation-delay: 0.5s;

  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: -24px;
    width: 12px;
    height: 12px;
    border-radius: 100px;
    background-color: rgba(#ffffff, 0.4);
    color: rgba(#ffffff, 0.4);
    animation: dotFlashingLighten 1s infinite alternate;
    animation-delay: 0s;
  }

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: 24px;
    width: 12px;
    height: 12px;
    border-radius: 100px;
    background-color: rgba(#ffffff, 0.4);
    color: rgba(#ffffff, 0.4);
    animation: dotFlashingLighten 1s infinite alternate;
    animation-delay: 1s;
  }

  &.darken {
    background-color: rgba(#121212, 0.4);
    color: rgba(#121212, 0.4);
    animation: dotFlashingDarken 1s infinite linear alternate;
    animation-delay: 0.5s;

    &::before {
      background-color: rgba(#121212, 0.4);
      color: rgba(#121212, 0.4);
      animation: dotFlashingDarken 1s infinite alternate;
      animation-delay: 0s;
    }

    &::after {
      background-color: rgba(#121212, 0.4);
      color: rgba(#121212, 0.4);
      animation: dotFlashingDarken 1s infinite alternate;
      animation-delay: 1s;
    }
  }
}

@keyframes dotFlashingLighten {
  0% {
    background-color: rgba(#ffffff, 0.4);
  }
  50%,
  100% {
    background-color: rgba(#ffffff, 0.2);
  }
}

@keyframes dotFlashingDarken {
  0% {
    background-color: rgba(#121212, 0.4);
  }
  50%,
  100% {
    background-color: rgba(#121212, 0.2);
  }
}

.hide-scrollbar {
  overflow-y: hidden;
  &:hover {
    overflow-y: auto;
  }

  &::-webkit-scrollbar {
    appearance: none !important;
    display: none !important;
  }
}
