.reset-password-form {
  .ant-form-item-explain-error {
    margin-top: 4px !important;
    margin-bottom: 0 !important;
  }

  .ant-form-item-explain {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .ant-form-item:not(:last-child) {
    margin-bottom: 16px !important;
  }

  .ant-form-item-has-error {
    :not(.ant-input-disabled).ant-input-affix-wrapper {
      border: 1px solid $red !important;
      border-right-width: 1px !important;

      &:focus,
      &:hover {
        border: 1px solid $primary !important;
      }
    }
  }

  .ant-input-affix-wrapper {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ant-form-item-label > label {
    color: $default;
    opacity: 0.8;
  }
}
