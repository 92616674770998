@mixin ScrollBehavior {
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  -ms-scroll-snap-type: x mandatory;
  -webkit-scroll-snap-type: x mandatory;
}

.Carousel {
  width: 100%;
  height: 100%;
  &__container {
    overflow: auto;
    width: 100%;
    height: 100%;

    @include ScrollBehavior;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    @include tablet {
      margin-top: 40px;
    }
  }

  &__wrapper {
    display: grid;
    width: 100%;
    grid-auto-flow: column;
    grid-template-columns: auto;
    grid-gap: 24px;

    @include ScrollBehavior;

    @include mobile {
      grid-template-columns: auto;
    }
  }
}

.slider__btn {
  position: absolute;
  left: calc((100vw - 1264px) / 2);
  top: 270px;

  @media (min-width: #{$desktop-width}) and (max-width: 1312px) {
    left: 24px;
    top: 270px;
  }

  @include tablet {
    left: 32px;
    top: 510px;
  }
}
