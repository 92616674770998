.user-layout {
  background: $default !important;

  .fluid-container {
    width: 100%;
    max-width: 1138px;
    margin: auto;
  }

  &__header.ant-layout-header {
    max-width: 1138px;
    position: sticky;
    top: 0;
    z-index: 999;
    padding: 0;
    background-color: $default;
    height: 84px;
    margin: auto;
    width: 100%;

    @include mobile {
      height: 64px;
    }

    .mobile-header--left {
      svg path {
        stroke: $white !important;
      }
    }

    .mobile-header--right {
      @include mobile {
        * {
          display: none;
        }
      }
    }
  }

  &__content {
    min-height: calc(100vh - 84px);
    border-radius: 0;
    background-color: $default !important;

    @include mobile {
      min-height: calc(100vh - 64px);
    }
  }

  &__page-content {
    padding: 32px;
    width: 100%;

    &--no-sidebar {
      border-radius: 16px 16px 0 0 !important;
    }
  }

  .side-bar-wrapper {
    position: relative;
    border-radius: 16px 0 0 0;
    background-color: $dark;

    .ant-typography {
      color: $white;
    }
  }

  .ant-layout-sider {
    position: sticky;
    z-index: 99;
    border-radius: 16px 0 0 0;
    background-color: $shark;

    @include mobile {
      border-radius: 0;
    }
  }

  .ant-layout-content {
    min-height: calc(100vh - 80px);
    background-color: $gray7 !important;
    border-radius: 0 16px 0 0;
    margin: auto;
    width: 100%;

    @include mobile {
      min-height: calc(100vh - 64px);
    }
  }

  .ant-layout-footer {
    padding: 0;
    background: $white;
  }

  &--profile {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }

  &--full {
    .user-layout__content {
      margin-left: 0;
    }
  }
}

@media only screen and (max-height: 767px) {
  .user-layout {
    aside.ant-layout-sider {
      min-height: 100% !important;
      height: auto !important;
      top: 0 !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .user-layout {
    min-height: 100vh;

    &__page-content {
      padding: 16px;
    }

    .side-bar-wrapper {
      border-radius: 0;
    }

    .ant-layout-content {
      border-radius: 0;
    }

    .user-layout__header {
      padding: 0 16px;
    }

    .user-layout__content {
      margin-left: 0;
    }

    .fluid-container,
    .container {
      max-width: 100% !important;
    }
  }
}
