.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 9999;

  &--full {
    width: 100%;
    height: 100%;
    transform: translate(0, 0);
    left: 0;
    top: 0;
    background-color: $white;
  }
}

@media (max-width: #{$tablet-width}) {
  .loading {
    width: 100%;
    left: 0;
    transform: translate(0, 0);
  }
}
