.special-request-section {
  padding: 0 16px 24px 16px;
  border: 1px solid $border;
  margin-top: 54px;

  &__talent {
    margin-top: -54px;
    position: relative;

    img {
      border: 2px solid white;
    }

    @include mobile {
      position: relative;
      top: 0;
    }

    &__icon {
      width: 32px;
      height: 32px;
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: $yellow;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;

      @include mobile {
        width: 24px;
        height: 24px;

        svg {
          width: 18px;
          height: 18px;
        }
      }

      svg {
        path {
          stroke: $white;
        }
      }
    }
  }

  @include mobile {
    border: none;
    margin-top: 0;
    height: auto;
  }

  &__info {
    text-align: center;

    @include mobile {
      position: relative;
      margin-top: 16px;
      bottom: 0;
    }
  }

  &--pending {
    .special-request-section {
      &__talent__icon {
        background-color: $yellow;
      }
    }
  }

  &--accepted,
  &--completed {
    .special-request-section {
      &__talent__icon {
        background-color: $green;
      }
    }
  }

  &--declined {
    .special-request-section {
      &__talent__icon {
        background-color: $red;
      }
    }
  }

  &--support {
    padding: 0;
    border-bottom: 1px solid $border;
    padding-bottom: 16px;

    @include mobile {
      flex-direction: row-reverse !important;
    }

    .special-request-section__talent {
      @include mobile {
        margin-top: 0;
      }
    }

    .special-request-section__info {
      @include mobile {
        text-align: left;
        flex: 1;
      }
    }
  }
}
