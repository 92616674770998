.my-experience-card {
  border: 1px solid $border;
  border-radius: 16px;
  cursor: pointer;

  &.no-border {
    border: none;
    border-radius: 0;
    margin-bottom: 0 !important;

    .my-experience-card__header {
      padding-bottom: 0 !important;
    }
  }

  .border-bottom {
    border-bottom: 1px solid $border;
  }

  .divider {
    display: block;
    background-color: $border;
    height: 1px;
    width: 80px;
  }

  &__date {
    color: rgba($default, 0.7);
  }

  &__header {
    padding-bottom: 24px;
  }

  &__footer {
    border-top: 1px solid $border;
  }

  &__btn-details {
    @include mobile {
      width: 40px;
      padding: 0 !important;
    }
  }

  .my-experience-product {
    .product-card__image {
      border-radius: 8px;
      width: auto;
      border: 1px solid grey5;

      .image-skeleton {
        width: 100% !important;

        > img {
          width: 100%;
        }
      }
    }

    .product-card__name {
      font-size: 16px !important;
      font-weight: $medium !important;

      @include mobile {
        height: 40px;
      }
    }

    .product-card__price {
      font-size: 14px !important;
      font-weight: normal !important;
    }
  }
}
