.talent-info-request {
  border: 1px solid $border;
  border-radius: 16px;

  &__title {
    background-color: rgba($default, 0.02);
    height: 48px;
    border-bottom: 1px solid $border;
  }

  &__content {
    padding: 24px 16px 16px;

    &__item {
      border-bottom: 1px solid rgba($gray, 0.5);

      &.guarantee {
        padding: 16px 0;

        .guarantee__desc {
          font-size: 10px;
          color: rgba($black, 0.6);
        }
      }
    }

    .question-expand {
      padding-top: 16px;

      .ant-collapse {
        border: none;
        background: none;

        &-item {
          border: none;

          .ant-collapse-header {
            color: rgba($black, 0.7);

            svg path {
              fill: $default;
            }
          }
        }
      }
    }
  }
}
