.experience-section {
  border: 1px solid rgba($white, 0.2);
  border-radius: 8px;

  .divider {
    background-color: rgba($white, 0.2);
    height: 1px;
    width: calc(100% - 16px);
  }

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;

    @include mobile {
      font-size: 14px;
    }
  }

  &__name {
    font-weight: 500 !important;
  }

  &__status {
    font-weight: 500;

    &--pending {
      color: $yellow !important;
    }

    &--completed,
    &--accepted {
      color: $green !important;
    }

    &--declined {
      color: $red !important;
    }
  }
}
