.filter-by-category-modal {
  .ant-modal-body {
    padding: 0 0 16px 0;

    .ant-checkbox-group {
      max-height: 500px;
      overflow: auto;
      padding: 0 16px;
    }
  }

  .filter-by-category {
    &__item {
      border-bottom: 1px solid $border;

      &:last-child {
        border-bottom: none;
      }

      .ant-checkbox-checked {
        &::after {
          border-radius: 4px !important;
        }

        .ant-checkbox-inner {
          background-color: $green !important;
        }
      }

      .ant-checkbox-inner {
        width: 20px !important;
        height: 20px !important;

        &::after {
          width: 6px !important;
          height: 10px !important;
        }
      }
    }
  }
}
