// TODO: These styles don't seem to be used anywhere. Delete this file?

.home-landing-page {
  height: 100vh;
  color: #fff;
  background: #232323;
  font-size: 14px;
  min-width: 320px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  a {
    color: #011844;
    -webkit-transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, background 0.3s ease-in-out,
      opacity 0.3s ease-in-out;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, background 0.3s ease-in-out,
      opacity 0.3s ease-in-out;
    text-decoration: underline;
  }

  a:hover,
  a:focus {
    color: #000612;
    outline: none;
    text-decoration: none;
  }

  .container {
    max-width: 1180px;
    width: 90%;
    padding: 0;
    margin: 0 auto;
  }

  .btn {
    -webkit-transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
    transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
    display: inline-block;
    vertical-align: top;
    border-radius: 10px;
    padding: 12px 30px 12px 30px;
    text-align: center;
    color: #020025;
    font-weight: bold;
    border: 0;
    font-size: 14px;
    text-decoration: none;
    position: relative;
    background-color: #73e5ff;
  }

  .btn:hover {
    background-color: #40dcff;
  }

  .beta-btn,
  .beta-btn:hover {
    background: transparent;
    color: #7b7b7b;
    border: 1px solid #7b7b7b;
    pointer-events: none;
  }

  h1 {
    font-weight: bold;
    font-size: 36px;
    margin-bottom: 0;
    color: #fff;
  }

  h2 {
    font-size: 16px;
    line-height: 150%;
    margin: 0;
    font-weight: bold;
    color: #fff;
  }

  .nav {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
    width: 100%;
    background: transparent;
  }

  .wrapper {
    height: 100%;
    max-width: 680px;
    width: 90%;
    margin: 0 auto;
  }

  .mx-width {
    max-width: 500px;
  }
}
