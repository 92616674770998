.experience-type-tag {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  padding: 1px 6px;
  color: $white !important;
  background-color: $red;
  display: inline-block;
  border-radius: 4px;

  @include mobile {
    font-size: 10px;
    line-height: 18px;
    padding: 0 4px;
  }

  &.live_class {
    background-color: $orange;
  }

  &.interactive_live_class {
    background-color: $red;
  }

  &.exclusive_content {
    background-color: $green;
  }

  &.one_to_one {
    background-color: $blueDodger;
  }

  &.course {
    background-color: $lavender;
  }

  &.bundle {
    background-color: $pink;
  }

  &.medium {
    padding: 1px 6px;
    font-size: 14px;
    height: 24px;
    line-height: 22px;

    @include mobile {
      font-size: 12px;
      height: 20px;
      line-height: 18px;
      padding: 1px 6px;
    }
  }

  &.large {
    padding: 2px 8px;
    font-size: 16px;
    height: 28px;
    line-height: 24px;

    @include mobile {
      font-size: 14px;
      height: 22px;
      line-height: 22px;
      padding: 1px 6px;
    }
  }
}
