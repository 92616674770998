.category-detail {
  &__sub-categories {
    @include mobile {
      padding-left: 16px;
    }
  }

  &__exclusive {
    margin-top: -32px;

    @include mobile {
      margin-top: 0;
    }

    .home__section {
      margin-top: 0;
    }

    .section-header {
      margin-bottom: 32px !important;

      @include mobile {
        margin-bottom: 24px;
      }
    }
  }

  .ant-tabs {
    margin-top: 24px;

    &-nav {
      &::before {
        display: none;
      }

      &-list {
        z-index: 999;
      }
    }

    &-ink-bar {
      display: none;
    }

    &-tab-btn {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      padding: 6px 16px;
      border: 1px solid $white;
      font-family: $fontSFProDisplay;
      text-transform: uppercase;

      @include mobile {
        padding: 6px 16px;
        font-size: 14px;
        line-height: 24px;
        height: 36px;
      }
    }

    &-tab {
      padding: 0;
      margin-right: 24px;

      @include mobile {
        margin-right: 8px;
      }

      &:first-child {
        margin-left: 16px;
      }

      &:hover {
        .ant-tabs-tab-btn {
          color: $default !important;
        }
      }

      &-active {
        .ant-tabs-tab-btn {
          border: 1px solid $default;
        }
      }
    }
  }

  &__title {
    max-width: 558px;
    text-align: center;
    margin: auto;
  }

  .swiper-container {
    // padding-left: 24px;
    // padding-right: 24px;
    // margin-left: -24px;

    @include mobile {
      padding-left: 16px;
      padding-right: 16px;
      // margin-left: 0;
    }
  }

  &__banner {
    position: relative;

    .image-skeleton {
      width: 100% !important;

      .ant-skeleton {
        width: 100% !important;

        .ant-skeleton-avatar {
          width: 100% !important;
        }
      }
    }

    &__photo {
      width: 100%;
    }

    &__buttons {
      position: absolute;
      top: 24px;
      left: 50%;
      transform: translate(-50%, -0);
    }

    &__name {
      span {
        display: block;
        color: rgba($white, 0.8);

        &:first-child {
          color: $white;
        }
      }

      &__wrapper {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.1) 100%);
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9;
        display: flex;
        align-items: flex-end;
        padding-bottom: 32px;

        @include mobile {
          padding-bottom: 24px;
        }
      }
    }
  }

  &__tag {
    padding: 24px 0;
    border-bottom: 1px solid $border;

    &__item {
      margin-right: 12px;
      border: 1px solid $border;
      border-radius: 8px;
      padding: 8px 16px;

      &.active {
        background-color: rgba(#73e5ff, 0.4);
      }

      &.separator {
        width: 1px;
        height: 38px;
        background-color: rgba(#c4c4c4, 0.4);
        padding: 0;
        border: none;
      }
    }
  }
}

.category-tag {
  &__container {
    padding-top: 24px;
  }

  &__item {
    border: 1px solid $default;
    padding: 8px 12px;
    cursor: pointer;

    &.active {
      border: 2px solid $primary;
    }
  }

  &__separator {
    width: 1px;
    height: 38px;
    background-color: rgba(#c4c4c4, 0.4);
    margin: 0 12px;
    padding: 0;
    border: none;
  }
}
