.business {
  > * {
    color: #011844;
    background: #fff;
    font-size: 14px;
    line-height: 130%;
    min-width: 320px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
    display: block;
    align-items: flex-start;
  }

  a {
    color: #011844;
    -webkit-transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, background 0.3s ease-in-out,
      opacity 0.3s ease-in-out;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, background 0.3s ease-in-out,
      opacity 0.3s ease-in-out;
    text-decoration: underline;
  }

  a:hover,
  a:focus {
    color: #000612;
    outline: none;
    text-decoration: none;
  }

  .container {
    max-width: 1180px;
    width: 90%;
    padding: 0;
    margin: 0 auto;
  }

  .btn {
    -webkit-transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
    transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
    display: inline-block;
    vertical-align: top;
    border-radius: 10px;
    padding: 8px 30px;
    text-align: center;
    color: #020025;
    font-weight: bold;
    border: 0;
    font-size: 14px;
    text-decoration: none;
    position: relative;
    background-color: #73e5ff;
  }

  .btn:hover {
    background-color: #40dcff;
  }

  h1 {
    font-weight: bold;
    font-size: 36px;
    margin-bottom: 0;
  }

  h2 {
    font-size: 28px;
    line-height: 130%;
    margin: 0;
    font-weight: bold;
    color: #011844;
  }

  h3 {
    font-size: 18px;
    line-height: 130%;
    margin: 0;
    font-weight: 500;
  }

  .color-white {
    color: #fff;
  }

  #footer {
    text-align: center;
    padding: 2.1875rem 0;
    border-top: 1px solid #e9e9eb;
  }

  #footer .copyright {
    display: block;
    text-align: center;
    color: rgba(0, 0, 0, 0.2);
  }

  .nav {
    position: fixed;
    top: 0;
    z-index: 999;
    left: 0;
    right: 0;
    width: 100%;
    background: transparent;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .nav.active {
    background: rgba(255, 255, 255, 0.7);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
  }

  .nav .btn {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .nav.active .btn {
    visibility: visible;
    opacity: 1;
  }

  .logo-holder img {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .header-content h1 {
    max-width: 450px;
    width: 100%;
  }

  .white-logo {
    top: 1px;
    visibility: hidden;
    opacity: 0;
  }

  .nav.active .logo-holder .white-logo {
    visibility: visible;
    opacity: 1;
  }

  .nav.active .logo-holder .black-logo {
    opacity: 0;
  }

  header {
    background-image: url(/static/assets/images/business/header.jpg);
    background-size: cover;
    background-position: center bottom;
    min-height: 630px;
  }

  .mx-width {
    max-width: 300px;
  }

  .icon-group img {
    height: 35px;
    width: 33px;
    margin-bottom: 35px;
  }

  .icon-group {
    min-width: 70px;
  }

  .icon-section {
    margin-left: 120px;
  }

  .section {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  h2.title {
    font-size: 22px;
  }

  .talent {
    width: 19%;
  }

  .experience {
    width: 32%;
  }

  p {
    margin-bottom: 0;
  }

  .talent img,
  .experience img {
    border-radius: 10px;
    -webkit-border-radius: 10px;
    width: 100%;
    height: 280px;
    object-fit: cover;
    object-position: center;
  }

  .experience img {
    height: 200px;
  }

  .closing-icon {
    width: 48%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
    padding: 40px 20px;
    font-weight: 500;
    text-align: center;
  }

  .closing-icon img {
    margin: 0 auto 30px auto;
  }

  .quote {
    min-width: 43%;
    background-image: url(/static/assets/images/business/quote.jpg);
    border-radius: 10px;
    margin-left: 80px;
    -webkit-border-radius: 10px;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center;
  }

  .quote::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }

  .quote h3 {
    position: relative;
    z-index: 2;
    color: #fff;
    max-width: 340px;
    padding-top: 30px;
    padding-left: 30px;
    font-weight: bold;
    line-height: 29px;
  }

  .mx-width-2 {
    max-width: 400px;
  }

  @media (max-width: 770px) {
    .mb-flex-col {
      flex-direction: column;
    }

    h2.mx-width {
      max-width: 100%;
      margin-bottom: 30px;
    }

    .icon-section,
    .talents {
      display: block !important;
      text-align: left;
      margin-left: 0;
    }

    .icon-group,
    .talent {
      display: inline-block;
      width: 49%;
      vertical-align: top;
      margin-bottom: 20px;
    }

    .talent {
      width: 47%;
      margin: 0 1% 30px 1%;
    }

    .closing-icon {
      padding: 30px 20px;
    }

    .experience {
      width: 100%;
      margin-bottom: 30px;
    }

    .mb-no-p {
      padding-top: 0 !important;
    }

    .quote {
      width: 100%;
      margin-top: 50px;
      margin-left: 0;
      padding: 80px 0;
    }

    header.d-flex {
      background-image: url(/static/assets/images/business/header-mobile.jpg);
      display: block !important;
      min-height: 580px;
    }

    .header-content h1 {
      font-size: 28px;
      margin-bottom: 10px !important;
    }

    .header-content {
      padding-top: 100px;
    }

    .experience img {
      height: 180px;
    }

    .talent img {
      height: 230px;
    }
  }
}
