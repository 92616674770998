.ant-menu-inline-collapsed-tooltip {
  display: none;
}

.main-menu {
  border-radius: 16px !important;
  max-height: 440px;
  overflow: auto;
  padding: 0 !important;

  .truncate {
    display: block;
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
  }

  &__overlay {
    z-index: 999999;
    min-width: 252px !important;
  }

  .ant-dropdown-menu-item {
    height: 48px;
    min-height: 48px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 16px !important;
    border-bottom: 1px solid $whisper;

    &:last-child {
      border: none;
    }

    &:hover {
      background-color: rgba($primary, 0.2);
    }

    &.switch-talent {
      background-color: $primary;
      justify-content: center;

      &:hover {
        background-color: $primary;
      }
    }

    span {
      width: 100%;
      height: auto;
      line-height: 48px;
    }

    a {
      color: $default;
      font-size: 16px;
      font-weight: 500;
      display: block;
      width: 100%;
      height: 24px;
      line-height: 24px;
    }

    svg {
      width: 24px;
      height: 24px;
      margin-right: 16px;

      &:not(.no-stroke) {
        * {
          stroke: $bg-dark;
        }
      }
    }
  }

  &--talent {
    .ant-dropdown-menu-item {
      padding: 0 20px !important;
      height: 56px;
      min-height: 56px;

      &.large {
        height: 72px;
        min-height: 72px;
      }

      span {
        height: 24px;
        line-height: 24px;
      }
    }
  }
}

.mobile-main-menu {
  .truncate {
    display: block;
    max-width: 150px;
    white-space: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.ant-layout-sider {
    width: 270px !important;
    min-width: 270px !important;
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    z-index: 9999999;
    background-color: $white;
  }

  &.ant-layout-sider-collapsed {
    width: 0 !important;
    min-width: 0 !important;
  }

  .ant-menu {
    height: 100vh !important;
  }

  .ant-menu-item {
    height: 48px !important;
    line-height: 48px !important;
    padding: 0 16px !important;
    flex-flow: nowrap;
    display: flex;
    &.p__l--40 {
      padding-left: 40px !important;
    }
    &.h--100 {
      height: 100px !important;
    }

    &.active {
      border-radius: 123px;
      background-color: $primary !important;
      color: $default !important;
    }

    &.height--auto {
      height: auto !important;
      line-height: auto !important;

      &:hover,
      &:visited,
      &:active {
        background-color: $white !important;
        color: $default !important;
      }
    }

    .ant-typography {
      color: $default !important;
      white-space: normal;

      &.text--red {
        color: $red !important;
      }
    }

    a {
      color: $default !important;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      display: block;
      width: 100%;
      height: 100%;

      &:hover,
      &:visited,
      &:active {
        color: $default !important;
      }
    }
  }

  .ant-menu-title-content {
    margin-left: 0 !important;
  }

  svg {
    margin-right: 16px;

    &:not(.no-stroke) {
      * {
        stroke: $bg-dark;
      }
    }
  }

  &--talent {
    .ant-menu-item {
      margin: 0 !important;
      padding: 16px 20px !important;
      height: 56px;
      min-height: 56px;

      &:active,
      &:focus {
        background: rgba(37, 127, 252, 0.05);
        border-radius: 60px;
      }

      &.height--auto {
        padding: 0 !important;
      }
    }
  }

  &__header {
    position: absolute;
    top: 24px;
    left: 16px;
    display: block;
    z-index: 9999999;
  }
}
