.Special__offer__modal {
  width: 664px !important;

  @include mobile {
    max-width: calc(100vw - 48px);
    margin: 0 auto;
  }

  .close-icon {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;

    @include mobile {
      top: 8px;
      right: 8px;
    }
  }

  &__copy--btn {
    justify-content: space-between;
    border: 1px solid $whisper;
    border-radius: 8px;

    .copy-text {
      font-size: 14px;
      line-height: 22px;
      color: $blue;
    }
    .ant-typography {
      width: calc(100% - 80px);
    }
  }

  &__btn-wrapper {
    @include mobile {
      flex-direction: column-reverse;
    }

    .ant-btn {
      width: 160px;
      height: 52px;
      border-radius: 123px;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 1px;
      font-weight: 700;

      @include mobile {
        height: 44px;
      }
    }

    .cancel-btn {
      border: 2px solid #e0e0e0;

      &:hover,
      &:focus,
      &:active {
        border-color: #e0e0e0 !important;
      }

      span {
        color: $default;
      }

      @include mobile {
        width: 100%;
      }
    }
    .visit-store-btn {
      background: $default;
      margin-left: 24px;
      border: none;

      span {
        color: $white;
      }
      &:hover,
      &:focus,
      &:active {
        border-color: transparent !important;
      }

      @include mobile {
        width: 100%;
        margin-bottom: 16px;
        margin-left: 0;
      }
    }
  }

  .ant-modal-content {
    overflow: visible;
    border-radius: 16px !important;
    background-color: transparent;
  }

  .ant-modal-body {
    padding: 0;
  }

  &__header {
    background: linear-gradient(var(--ant-success-color), var(--ant-success-color)), var(--ant-primary-color);
    border-bottom: 1px solid rgba($white, 0.1);
    border-radius: 16px 16px 0 0;

    .ant-typography {
      color: var(--ant-info-color) !important;
    }

    .image-skeleton {
      border-radius: 8px !important;
      overflow: hidden;
    }

    &__left {
      position: relative;

      .ant-btn {
        height: 52px !important;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        svg {
          width: 52px;
          height: 52px;
        }

        @include mobile {
          height: 32px !important;

          svg {
            width: 32px;
            height: 32px;
          }
        }
      }
    }
  }

  &__content {
    background-color: $white;
    max-height: calc(100vh - 160px - 160px);
    overflow: auto;
    border-radius: 0 0 16px 16px;

    &__wrapper {
      border-radius: 0 0 16px 16px;
      overflow: hidden;
    }

    @include mobile {
      max-height: calc(100vh - 160px - 200px);
    }
  }
}
