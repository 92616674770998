.review-special-request-modal {
  .ant-modal-body {
    padding: 0;
  }

  &__info {
    max-height: 300px;
    margin-top: 32px;
    overflow: auto;
    padding-right: 16px;
  }

  &__item {
    border-bottom: 1px solid rgba($border, 0.5);

    &--first {
      border-top: 1px solid rgba($border, 0.5);
    }
  }

  &__notice {
    background-color: rgba($yellow, 0.11);
    text-align: center;
    margin-top: 24px;
  }
}

.request-detail-modal {
  .ant-modal-body {
    padding: 16px 24px 24px;
  }
}
