.full-page-modal-layout {
  &__card {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 540px;
    border-radius: 16px !important;

    .ant-card-body {
      padding: 24px !important;
    }

    &::before {
      content: "";
      background-image: url(/static/assets/images/logo-landing-page.svg);
      width: 80px;
      height: 26px;
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -66px);

      @include mobile {
        width: 68px;
        height: 21px;
        background-image: url(/static/assets/images/logo-landing-page-mobile.svg);
        transform: translate(-50%, -45px);
      }
    }

    @include mobile {
      width: 100%;
      min-width: 100%;

      .ant-card-body {
        padding: 20px !important;
      }
    }
  }

  &__wrapper {
    .ant-form-item {
      &-control-input {
        input {
          font-size: $medium1 !important;
          font-weight: $semi-bold !important;
          line-height: 24px !important;
        }
      }

      &-has-success {
        .ant-form-item-control-input-content > input {
          background-color: $gray7 !important;
        }
      }
    }
  }

  &__card-mobile-drawer {
    @include mobile {
      top: 90px;
      bottom: 0;
      left: 0;
      transform: none;
      border-radius: 16px 16px 0 0 !important;
    }
  }
}
