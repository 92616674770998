.booking-summary {
  padding: 24px;
  border: 2px solid $mineShaft;
  border-radius: 16px;

  @include mobile {
    padding: 16px;
  }

  .ant-divider {
    background-color: rgba($white, 0.1);
  }

  &--bundle {
    padding: 0;
    border: none;

    img {
      border-radius: 4px;
    }

    .bundle-experience-card {
      border-radius: 0;
      border: none;
      border-bottom: 1px solid $border;
      padding: 16px 0;

      .experience-detail__time {
        margin-top: 8px !important;
      }

      .text--medium16 {
        font-size: 14px !important;
      }
    }

    .bundle-experience-card img {
      border-radius: 4px;
    }

    .booking-summary__header {
      border-bottom: none;
      padding-bottom: 0;
    }

    .booking-summary__header__wrapper {
      padding: 20px 16px 16px;
      border: 1px solid $border;
      border-radius: 8px 8px 0 0;
    }

    .booking-summary__info__wrapper {
      padding: 16px;
      border: 1px solid $border;
      border-top: none;
      border-radius: 0 0 8px 8px;
    }
  }
}
