.footer {
  a {
    color: $default;
  }

  &__routes {
    padding: 24px 0;

    .ant-col {
      &:nth-child(1),
      &:nth-child(2) {
        display: flex;
        flex-direction: column;

        a {
          //margin-top: 16px;
        }
      }
    }

    img.disabled {
      opacity: 0.5;
      filter: grayscale(100%);
    }

    .btn-become-talent {
      width: 174px;

      @include mobile {
        width: 100%;
        text-align: center;
        padding: 0 !important;
      }
    }
  }

  &__info {
    padding: 16px 0 !important;
    box-shadow: 0 -1px 0 rgba($white, 0.1);
    margin: auto;

    @include mobile {
      padding: 16px !important;

      .ant-col {
        &:first-child {
          padding-bottom: 8px;
          border-bottom: 1px solid rgba($white, 0.1);
        }

        &:last-child {
          padding-top: 16px;
        }
      }
    }
  }
}
