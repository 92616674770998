/* stylelint-disable */

.category-card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 12px;

  &__background-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }

  &__background-gradient {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.9;
    background-image: linear-gradient(to right, red, yellow);
  }

  &__desc {
    white-space: break-spaces;
    word-break: break-word;
    margin-bottom: 0 !important;
  }

  &__icon {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile {
      width: 54px;
      height: 54px;
    }

    &__background {
      opacity: 0.11;
      position: absolute;
      width: 100%;
      height: 100%;
    }

    img {
      width: 48px;
      height: 48px;

      @include mobile {
        width: 32px;
        height: 32px;
      }
      opacity: 0.67;
    }
  }

  &__name {
    width: 100%;
    white-space: pre-wrap;
    padding: 0;
    position: absolute;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    width: 100%;
    height: 100%;
    color: $white !important;
    margin: 0;
    top: 0;
    margin-top: 0 !important;
    display: flex;
    align-items: flex-end;
    padding: 16px;
    border-radius: 12px;

    @include mobile {
      padding: 12px;
    }
  }
}
