/* stylelint-disable */

.home {
  button.ant-btn.ant-btn-lg {
    @include mobile {
      font-size: 14px;
      line-height: 24px;
      padding: 6px 16px;
      height: 36px;
    }
  }

  .swiper-container {
    padding-left: 24px;
    padding-right: 24px;
    margin-left: -24px;
    @include mobile {
      padding-left: 16px;
      padding-right: 16px;
      margin-left: 0px;
    }
  }

  &__container {
    display: flex;
    align-items: center;
    flex-direction: column;

    h3 {
      margin: 40px 0 24px 0;
    }
  }

  &__title {
    max-width: 558px;
    text-align: center;
    margin: auto;
    padding: 0 16px;
  }

  &__intro {
    &--container {
      overflow: hidden;
      background-size: 100% 100%;

      @include mobile {
        background-size: 100% 100%;
      }
    }

    &__feature {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)),
        url("/static/assets/images/home-intro-min.jpg");
      background-size: cover;
      width: auto;
      overflow: auto;
      height: 209px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      > .ant-row {
        flex-wrap: unset;
        margin-left: 16px !important;

        &:first-child {
          .ant-col:nth-child(2) {
            padding-right: 16px !important;
          }
        }
      }

      &__wrapper {
        position: relative;
        overflow: auto;
        img {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    &__content {
      max-width: 490px;
      margin-left: calc(min(calc((50vw - 490px) / 2), 96px));
    }

    img {
      width: 100%;
    }

    svg {
      path {
        stroke: $default;
      }
    }

    &__item {
      height: 44px;
      padding: 0;

      svg {
        width: 24px;
        height: 24px;
      }

      * {
        color: $white !important;
        stroke: $white !important;
      }
    }

    &--join {
      .title {
        color: $white;
      }

      .desc {
        color: rgba($white, 0.7);
      }
    }

    &--step {
      .icon {
        width: 56px;
        height: 56px;
        flex: 0 0 56px;
        border-radius: 50%;
        background-color: $primary;
        color: $black;
      }

      .text {
        color: $white;
      }
    }
  }

  .home__section {
    margin-top: 16px;
  }

  .slick-slide {
    padding: 0 15px;

    @include mobile {
      padding: 0 10px;

      &:first-child {
        padding: 0 5px;
      }
    }
  }

  .home__carousel__wrapper {
    // overflow: hidden;
    white-space: nowrap;
  }

  .home__exclusive {
    margin-top: -32px;

    @include mobile {
      margin-top: 0;
    }

    .home__section {
      margin-top: 0;
    }

    .section-header {
      margin-bottom: 32px !important;

      @include mobile {
        margin-bottom: 24px;
      }
    }
  }

  .home__categories {
    margin-top: 34px;
    @include mobile {
      margin-top: 20px;
    }
    .category-items {
      overflow: auto;
      white-space: nowrap;

      .category-item {
        display: inline-block;
        width: 264px !important;
        margin-right: 30px;

        @include mobile {
          width: 184px !important;
          margin-right: 20px;
        }
      }

      scrollbar-width: none; // Firefox
      -ms-overflow-style: none; // IE 10+
      // Chrome/Safari/Webkit
      &::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
        background: transparent;
      }
    }
  }
  &__one_to_one {
    margin-top: 54px !important;
    @include mobile {
      margin-top: 40px !important;
    }
  }

  .ant-tabs {
    margin-top: 16px;

    &-nav {
      &::before {
        display: none;
      }

      &-list {
        z-index: 999;
      }
    }

    &-ink-bar {
      display: none;
    }

    &-tab-btn {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      padding: 6px 16px;
      border: 1px solid $white;

      @include mobile {
        padding: 6px 16px;
        font-size: 14px;
        line-height: 24px;
        height: 36px;
      }
    }

    &-tab {
      padding: 0;
      margin-right: 24px;

      @include mobile {
        margin-right: 8px;
      }

      &:first-child {
        margin-left: 16px;
      }

      &:hover {
        .ant-tabs-tab-btn {
          color: $default !important;
        }
      }

      &-active {
        .ant-tabs-tab-btn {
          border: 1px solid $default;
          font-weight: 500 !important;
        }
      }
    }
  }
}

.new-gift-alert {
  border-radius: 16px;
  padding: 16px;
  border: 1px solid $border;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

  &__arrow {
    height: 24px;

    svg path {
      stroke: #0085ff;
    }
  }

  @include mobile {
    padding: 16px 16px;
  }

  &__label {
    background-color: $primary;
    padding: 2px 6px;

    &--consumer-gift {
      background-color: $orange2;
      color: $white !important;
    }
  }
}

.home-gift-banner {
  height: 58px;
  position: relative;
  width: 100%;
  background-color: $default;

  @include mobile {
    height: 100px;
    padding: 12px 0;
  }

  &__background {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    background-color: #8fe3fc;
  }

  .btn-give-gift {
    height: 36px !important;
    padding: 0 12px;
  }
}

.not-found {
  min-width: 100vw;
  min-height: 100vh;
  background: white;
  .bg--grey {
    background-color: grey;
  }
}
