.product-modal {
  width: 1110px !important;

  * {
    &::-webkit-scrollbar {
      background: var(--ant-primary-color);
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 16px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba($white, 0.2);
      border-radius: 6px;
    }
  }

  &.darken {
    * {
      &::-webkit-scrollbar-thumb {
        background: rgba($default, 0.2);
      }
    }
  }

  .default-image-wrapper {
    height: 495px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(#ffffff, 0.1);
    svg {
      path {
        fill: rgba(#ffffff, 0.3);
      }
      circle {
        fill: rgba(#ffffff, 0.3);
      }
    }
    &.darken {
      background: rgba(#121212, 0.1);
      svg {
        path {
          fill: rgba(#121212, 0.3);
        }
        circle {
          fill: rgba(#121212, 0.3);
        }
      }
    }
  }

  .ant-typography {
    color: var(--ant-info-color) !important;
  }

  .ant-modal-close {
    top: 16px;
    right: 16px;

    &-x {
      width: 32px;
      height: 32px;
      line-height: 32px;

      svg {
        width: 32px;
        height: 32px;

        path {
          stroke: var(--ant-info-color);
        }
      }
    }
  }

  .ant-modal-content {
    background-color: var(--ant-primary-color);

    .ant-modal-body {
      max-height: calc(100vh - 80px);
      overflow: auto;
      padding: 40px;
    }

    .swiper-container {
      padding-bottom: 40px;

      .swiper-pagination {
        bottom: 0;
        height: 8px;
        line-height: 0px;

        .swiper-pagination-bullet {
          margin: 0 6px;
          opacity: 0.2;
          background-color: $white;

          &.darken {
            background-color: $default;
          }

          &-active {
            opacity: 0.4;
          }
        }
      }
    }

    .ant-divider {
      border-top: 1px solid rgba($white, 0.1);

      &.darken {
        border-top: 1px solid rgba($default, 0.1);
      }
    }

    .ant-select {
      &-selector {
        background: transparent;
        border-color: var(--ant-info-color);
        padding: 0 14px;
        transition: none;
        height: 52px !important;
        align-items: center;

        input {
          height: 52px;
        }
      }

      &:hover,
      &:focus,
      &.ant-select-focused {
        .ant-select-selector {
          border-color: var(--ant-info-color);
          box-shadow: none;
        }
      }

      &-selection-search {
        right: 36px;
      }

      &-selection-item {
        color: var(--ant-info-color);
        font-weight: 600;
        font-size: 16px;
        line-height: 24px !important;
      }

      &-arrow {
        user-select: none;
        transform: translate(0, -50%);
        width: 24px;
        height: 24px;
        right: 12px;

        svg {
          path {
            stroke: var(--ant-info-color);
          }
        }
      }
    }

    .product-amount {
      display: flex;
      align-items: center;
      border-radius: 123px;
      padding: 0 14px;
      height: 52px;
      border: 1px solid var(--ant-info-color);

      .ant-btn {
        height: 16px;

        svg path {
          stroke: var(--ant-info-color);
        }
      }
    }

    .btn-share {
      svg path {
        stroke: var(--ant-info-color);
      }
    }

    .btn-buy-now {
      background: $white !important;

      span {
        color: $default !important;
        line-height: 20px;
      }

      &:disabled {
        background: rgba($white, 0.5) !important;
      }

      &.darken {
        background: $default !important;

        &:disabled {
          background: rgba($default, 0.5) !important;
        }

        span {
          color: $white !important;
        }
      }
    }
  }

  @media screen and (max-width: 1174px) {
    width: 100% !important;
    max-width: 100vw;
    height: 100vh;
    padding: 0 !important;
    border-radius: 0;
    margin: 0;

    .ant-modal-content {
      height: 100%;
      border-radius: 0 !important;

      .ant-modal-body {
        max-height: calc(100vh);
        overflow: auto;
      }
    }
  }

  @media screen and (max-width: 798px) {
    height: 100%;

    .ant-modal-content {
      height: 100vh;
      overflow: auto;

      .ant-modal-body {
        max-height: unset;
        height: auto;
        max-width: 448px;
        margin: auto;
        padding: 32px 0;

        .product-info {
          overflow: hidden;
        }

        .product-title,
        .product-price {
          text-align: center;
        }

        .avatar-profile {
          justify-content: center;

          .ant-col:last-child {
            flex: unset !important;
          }
        }
      }
    }
  }

  @media screen and (max-width: 481px) {
    .ant-modal-content {
      .ant-modal-body {
        padding: 56px 0 32px 0;
        max-width: none;

        .product-info {
          padding: 0 16px;
        }

        .image-skeleton {
          img {
            border-radius: 0 !important;
          }
        }
      }
    }
  }
}

.product-selector {
  min-width: 225px !important;
  width: auto !important;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.18);
  border-radius: 16px;
  padding: 0;
  max-width: 503px;

  .rc-virtual-list-holder {
    max-height: 280px !important;
  }

  .rc-virtual-list-scrollbar-thumb {
    background: rgba($default, 0.2) !important;
  }

  @media screen and (max-width: 1174px) {
    max-width: calc(50% - 50px);
  }

  @media screen and (max-width: 798px) {
    max-width: 448px;
  }

  @media screen and (max-width: 481px) {
    min-width: 191px !important;
    max-width: calc(100vw - 32px);
  }

  .ant-select-item {
    padding: 16px 20px;

    &.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background: rgba(37, 127, 252, 0.05);
    }

    .ant-select-item-option-content {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }

    &.disabled {
      .ant-select-item-option-content {
        color: $default;
        opacity: 0.4;
      }
    }
  }
}
