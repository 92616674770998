.loading-module {
  border-radius: 16px;
  overflow: hidden;
  &__item {
    background: $gray9;
    border-radius: 8px;
    &--photo {
      height: 140px;
      width: 140px;
      .react-loading-skeleton {
        border-radius: 8px 0 0 8px;
      }
    }
  }
}
