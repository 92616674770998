.video-call {
  flex: 1;
  flex-direction: column;
  display: flex;
  &__room-info {
    span {
      display: block;
    }
  }
  &__action-button {
    flex-grow: 1;
    align-items: center;
  }
}
