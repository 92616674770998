.give-gift {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .firework-image {
    height: 191px !important;
  }

  &__title {
    &--mobile {
      text-align: left;
      margin-left: 24px;
    }
  }

  .amount {
    label {
      font-size: 16px;
    }
  }

  .ant-radio-group {
    display: flex;

    .ant-radio-button-wrapper {
      width: 115px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      background: $white !important;
      border: 1px solid $border;
      margin-left: 16px;

      &:first-child {
        margin-left: 0;
      }

      &:hover {
        color: $default !important;
      }

      &::before {
        display: none;
      }
    }
  }

  .float-label {
    input {
      min-height: 72px !important;
      color: rgba($default, 0.7) !important;
      font-weight: 400 !important;
      padding-top: 28px !important;
      padding-bottom: 0;
    }

    .label {
      font-size: 16px !important;
      font-weight: 400 !important;
      top: 24px;
    }

    &.floating {
      .label {
        color: $default !important;
        top: 10px;
        font-weight: 500 !important;
      }
    }
  }

  textarea {
    padding: 16px !important;
  }

  &__form {
    width: 100%;

    &__message {
      .ant-form-item-label {
        padding-bottom: 8px !important;
      }
    }

    &__card {
      flex-basis: 526px !important;
      padding-right: 0 !important;

      &.ant-col:first-child {
        padding-left: 32px !important;

        @include mobile {
          padding-left: 0 !important;
        }
      }

      @include mobile {
        flex-basis: 100% !important;
        padding-left: 0 !important;
      }
    }

    &__info {
      padding-left: 0 !important;

      &.ant-col:last-child {
        padding-right: 32px !important;

        @include mobile {
          padding-right: 0 !important;
        }
      }

      @include mobile {
        padding-right: 0 !important;
      }
    }

    &__amount {
      label {
        font-weight: 500 !important;
      }
    }

    .ant-form-item-label {
      label {
        font-size: 16px !important;
        font-weight: 500 !important;
      }
    }
  }

  &__payment {
    width: 100%;

    .ant-select-item {
      margin-bottom: 4px !important;
    }

    .avatar-profile {
      .ant-col:last-child {
        margin-left: 8px;
      }
    }

    .avatar-profile__info {
      span {
        font-weight: 500 !important;
      }
    }

    span.avatar-profile__profession {
      font-size: 14px !important;
      font-weight: 400 !important;
      color: rgba($default, 0.7) !important;
    }

    &__summary {
      border: 1px solid $border;
      padding: 24px 16px;
      border-radius: 16px;

      .ant-row {
        border-top: 1px solid $border;
      }
    }

    .ant-select {
      width: 100%;

      &-arrow {
        width: 24px;
        height: 24px;
        margin-top: 0;
        transform: translate(0, -50%);
      }

      &-selector {
        height: 62px !important;

        img {
          width: 54px;
          height: 32px;

          &.apple-pay {
            width: 44px;
          }
        }

        .ant-select-selection-item {
          height: 62px !important;
          background: none !important;
          color: $default !important;
          line-height: 62px !important;
        }
      }
    }

    .ant-form-item-label {
      label {
        font-size: 14px !important;
        font-weight: normal !important;
      }
    }
  }

  &__successful {
    text-align: center;
    max-width: 459px;
    margin: auto;
    width: 100%;
    padding: 24px 0;

    &__btn-submit {
      height: 56px !important;
    }

    &__header {
      position: relative;

      img {
        width: 100%;
      }
    }

    &__title {
      display: inline-block;
      color: $green !important;

      &.text--default {
        color: $default !important;
      }
    }

    &__desc {
      display: inline-block;
      color: rgba($default, 0.7) !important;
    }

    &__code {
      background-color: $gray5;
      border-radius: 8px;

      &__text {
        color: rgba($default, 0.8) !important;
        border-right: 1px solid $border;
        width: 289px;
        height: 56px;
        line-height: 56px !important;

        @include mobile {
          width: auto;
          padding: 0 20px;
        }
      }
    }
  }

  button[type="submit"] {
    height: 56px !important;
  }

  .gift-experience__payment__subTitle {
    margin-bottom: 16px;
  }
}
