// @import "../../styles/abstracts/variables";

.lw {
  background-color: black;
  height: 2000px;
  width: 586px;
  position: relative;
}
.loading-card {
  height: 140px;
  width: 586px;
  border-radius: 8px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1);
}

.image {
  height: 140px;
  width: 140px;
  left: 0;
  top: 0;
  position: absolute;
  right: 76.11%;
  background: rgba(255, 255, 255, 0.1);
}

.line1 {
  height: 20px;
  width: 398px;
  border-radius: 8px;
  position: absolute;
  left: 20;

  top: 0;
  background: rgba(255, 255, 255, 0.1);
}

.line2 {
  height: 20px;
  width: 398px;
  border-radius: 8px;
  position: absolute;
  left: 26%;
  right: 0;
  top: 72px;
  background: rgba(255, 255, 255, 0.1);
}
