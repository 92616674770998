.bundle-checkout-successful {
  .product-card__name {
    @include mobile {
      height: 40px !important;
    }
  }

  .none-navigate-buttons {
    .section-header {
      display: none !important;

      @include mobile {
        display: block !important;
      }
    }
  }
}
