.two-factor-modal {
  &.img-top {
    &::before {
      content: "";
      background-image: url(/static/assets/images/logo-landing-page.svg);
      width: 80px;
      height: 26px;
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -66px);

      @include mobile {
        width: 68px;
        height: 21px;
        background-image: url(/static/assets/images/logo-landing-page-mobile.svg);
        transform: translate(-50%, -45px);
      }
    }
  }
  .text {
    &--regular14 {
      line-height: 20px;
    }
    &--semibold16,
    &--regular16 {
      line-height: 22px;
    }
    &--semibold18,
    &--semibold20 {
      line-height: 28px;
    }
  }

  .ant-input {
    line-height: 24px;
  }

  .ant-modal-close {
    top: 24px !important;

    @include mobile {
      top: 16px !important;
    }
  }

  .ant-modal-header {
    padding: 24px 24px 0 24px !important;
    border-bottom: 0 none !important;

    @include mobile {
      padding: 16px !important;
    }
  }

  .ant-modal-body {
    padding: 8px 24px 22px;
    max-height: 70vh;
    overflow: auto;

    @include mobile {
      padding: 8px 16px 24px;
      max-height: calc(100vh - 280px);
    }
  }

  .ant-modal-footer {
    background-color: $gray7;
    border-top: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
  }

  @include mobile {
    left: 0;
    margin: 0 !important;
    max-width: 100vw !important;
    padding: 0 !important;
    bottom: 0;
    top: auto !important;
    position: absolute !important;

    .ant-modal-content {
      border-radius: 16px 16px 0 0 !important;
    }
  }

  button.ant-btn[disabled] span {
    color: $blue !important;
    text-transform: none;
  }
  .ant-form {
    &-item {
      input {
        border-width: 1px !important;
      }
      &-explain {
        margin-top: 4px !important;
        &-error {
          margin-top: 0 !important;
        }
      }
    }
  }
  .ant-form-item-has-error {
    :not(.ant-input-disabled).ant-input,
    :not(.ant-input-disabled).ant-input-affix-wrapper {
      border: 1px solid $red !important;
      border-right-width: 1px !important;

      &:focus,
      &:hover {
        border: 1px solid $primary !important;
      }
    }

    .ant-input-affix-wrapper {
      :not(.ant-input-disabled).ant-input {
        border: unset !important;
        border-right-width: 1px !important;

        &:focus,
        &:hover {
          border: unset !important;
        }
      }
    }
  }
}
