.ant-tabs {
  width: 100%;

  &-tab {
    color: $default;

    &:hover {
      color: $default;
    }

    &-active {
      .ant-tabs-tab-btn {
        color: $default !important;
        font-weight: 500 !important;
        text-shadow: none !important;
      }
    }
  }

  &-nav {
    margin: 0 !important;
  }

  &-ink-bar {
    border-radius: 8px 8px 0 0;
  }
}
