/* stylelint-disable */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.ant-radio {
  &:hover,
  &:active,
  &:focus {
    .ant-radio-inner {
      border-color: $checkedBorder !important;
    }
  }

  &-checked {
    .ant-radio-inner {
      border-color: $checkedBorder !important;
      background: $checked;

      &::after {
        background-color: $white;
      }
    }
    &::after {
      border-color: $checkedBorder !important;
    }
  }
}

.ant-input-lg {
  font-size: 14px !important;
  height: 56px;
}

.ant-input-number {
  width: 100%;
}

// checkbox
.ant-checkbox-group {
  width: 100%;
}

.ant-checkbox {
  top: 0 !important;

  &-inner {
    border-radius: 4px !important;
  }

  &:hover,
  &:active,
  &:focus {
    .ant-checkbox-inner {
      border-color: $checkedBorder !important;
    }
  }

  &-checked {
    .ant-checkbox-inner {
      border: none !important;
      background-color: $green !important;

      &::after {
        width: 6px;
        height: 10px;
        left: 25%;
      }
    }
    &::after {
      border: none !important;
      border-radius: 4px !important;
    }
  }
}

.checkbox-inline > .ant-checkbox {
  // AntD default value to override `top: 0` in class above
  top: 0.2em !important;
}

.ant-form-item-has-success {
  .ant-form-item-label {
    label {
      opacity: 0.8;
    }
  }
}

.ant-form-item {
  font-weight: 600;

  &-label {
    padding-bottom: 8px !important;

    label {
      font-weight: $regular;
      font-size: 14px;
    }
  }

  &-control-input {
    input {
      height: 52px;
      padding-left: 12px;
      padding-right: 12px;
      border-radius: 8px;
      font-weight: 600;
      font-size: 16px;

      &::placeholder {
        font-weight: normal;
        color: rgba($default, 0.5);
      }

      &.input-rounded {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        border: 2px solid $mercury;
        border-radius: 123px;

        &.ant-input-disabled {
          border: 2px solid $mercury !important;
        }

        &.ant-input-password {
          border-radius: 123px !important;
        }

        &:active,
        &:focus,
        &:hover {
          border: none;
        }

        &::placeholder {
          font-weight: 600;
          font-size: 16px;
          color: rgba($default, 0.5);
        }
      }
    }

    textarea {
      padding-left: 16px;
      padding-right: 16px;
      border-radius: 8px;

      &::placeholder {
        font-weight: normal;
        color: rgba($default, 0.5);
      }
    }
    .ant-input-password,
    .ant-picker,
    .ant-select-selector {
      border-radius: 8px !important;
    }
    .ant-input-password {
      &-icon {
        width: 24px;
        height: 24px;
        svg {
          display: none;
        }
      }
      .anticon-eye {
        background: url("/static/assets/icons/eye-hide.svg");
        background-size: auto;
      }
      .anticon-eye-invisible {
        background: url("/static/assets/icons/eye.svg");
        background-size: auto;
      }
    }
    .ant-select {
      &-arrow {
        width: 24px;
        height: 24px;
        height: 100%;
        display: flex;
        top: 0;
        margin-top: 0;
        align-items: center;

        .anticon-down {
          background: url(../../../public/static/assets/icons/sDown.svg);
          width: 24px;
          height: 24px;
          svg {
            display: none;
          }
        }
      }
    }
  }
}

.ant-input-group-addon {
  background-color: $white;
  font-weight: 500;
}

.ant-radio-group {
  width: 100%;

  .ant-radio-button-wrapper {
    height: 40px;
    line-height: 40px;
    margin-left: 24px;
    border: 1px solid $gray3;

    &:hover {
      color: $default;
    }

    &:first-child {
      margin-left: 0;
    }

    &::before {
      display: none;
    }

    &.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]) {
      color: $default;
      border: 1px solid $primary;
    }
  }
}

.ant-form-item-has-error {
  :not(.ant-input-disabled).ant-input,
  :not(.ant-input-disabled).ant-input-affix-wrapper {
    border: 2px solid $red !important;
    border-right-width: 2px !important;

    &:focus,
    &:hover {
      border: 2px solid $primary !important;
    }
  }

  .react-tel-input {
    input.phone-input {
      border: 2px solid $red !important;

      + .phone-button-select {
        border-right: 2px solid $red !important;
      }
    }
  }

  .ant-input-affix-wrapper {
    :not(.ant-input-disabled).ant-input {
      border: unset !important;
      border-right-width: 2px !important;

      &:focus,
      &:hover {
        border: unset !important;
      }
    }
  }
}

.react-tel-input {
  .selected-flag {
    &:hover,
    &:focus,
    &:active {
      background-color: transparent !important;
    }
  }

  .flag-dropdown.open .selected-flag {
    background-color: transparent !important;
  }

  input.phone-input {
    border: 1px solid $mercury !important;

    &:focus,
    &:hover {
      border: 1px solid $primary !important;

      + .phone-button-select {
        border-right: 1px solid $primary !important;
      }
    }
  }
}

input.ant-input {
  &:focus,
  &:hover {
    box-shadow: none !important;
    border: 2px solid $primary !important;
    border-right-width: 2px !important;
  }

  &.ant-input-disabled {
    border: none !important;
    background-color: $gray7;
    color: $default;
  }
}

.ant-input-affix-wrapper {
  .ant-input {
    &:focus,
    &:hover {
      border: none !important;
    }
  }
}

.ant-form-item-explain-error {
  margin-top: 8px !important;
  // margin-bottom: 24px;
}

.range-picker {
  width: 268px;
  padding-left: 44px !important;
  padding-right: 16px !important;

  .ant-picker-active-bar {
    display: none;
  }

  .ant-picker-suffix {
    position: absolute;
    left: 16px;
    margin: 0;
  }
}

.ant-form-item-explain {
  // margin-bottom: 24px !important;
  font-size: 12px !important;
  color: rgba($default, 0.7);
  font-weight: 400;
  margin-top: 8px;
}
