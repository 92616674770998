.experience-purchase-detail {
  padding: 24px 0;

  @include mobile {
    padding-top: 16px;
  }

  .product-card__name {
    @include mobile {
      height: 40px !important;
    }
  }

  &__item {
    @include mobile {
      margin: 0 !important;
      margin-top: 16px !important;

      .ant-col:first-child {
        padding-left: 0 !important;
      }

      .ant-col:last-child {
        text-align: right;
        padding-right: 0 !important;
      }
    }
  }

  &--special-request {
    .field-item {
      @include mobile {
        padding: 14px 0;
        border-bottom: 1px solid rgba($border, 0.5);
      }

      &--none-border {
        border-bottom: none;
      }

      &-wrapper {
        @include mobile {
          padding: 0 16px;
        }

        &--border-top {
          @include mobile {
            border-top: 1px solid rgba($border, 0.5);
          }
        }

        &--border-bottom {
          @include mobile {
            border-bottom: 1px solid rgba($border, 0.5);
          }
        }
      }
    }
  }

  &__rejected {
    background-color: rgba($border, 0.5);
  }

  &__header {
    border-bottom: 1px solid $border;
  }

  &__status {
    font-weight: 500;

    &--pending {
      color: $yellow;
    }

    &--accepted,
    &--completed {
      color: $green;
    }

    &--declined {
      color: $red;
    }
  }

  &__time {
    svg {
      margin-right: 8px;

      g {
        opacity: 1;
      }
    }
  }
}
