.talent-card {
  position: relative;
  cursor: pointer;
  border-radius: 12px;
  filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.1));
  background: $white;
  margin-bottom: 40px;

  @include mobile {
    margin-bottom: 20px;
  }

  &__background-img-container {
    width: 100%;
    overflow: hidden;
  }

  &__background-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  &__name {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    max-height: 20px;
    overflow: hidden;

    @include mobile {
      font-size: 14px;
    }
  }

  &__bio {
    height: 40px;
    max-height: 40px;
    overflow: hidden;
    line-height: 20px !important;
  }

  &__profession {
    color: rgba($color: $default, $alpha: 0.8) !important;
  }
}
