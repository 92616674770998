#wrapper {
  position: relative;
  overflow: hidden;
}

.container {
  max-width: rem-calc(1135);
  padding: rem-calc(0 15);
  margin: 0 auto;
}

.bg-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.banner-section {
  padding: rem-calc(25 0);

  .content-holder {
    min-height: rem-calc(365);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: rem-calc(30);
    border-radius: rem-calc(16);
    text-align: center;
    position: relative;
    overflow: hidden;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(#000, 0.7);
      z-index: 1;
    }

    h1 {
      max-width: rem-calc(666);
      color: $white;
      position: relative;
      z-index: 2;
      margin-top: rem-calc(15);
      line-height: rem-calc(48);

      @include media("<phone") {
        font-size: rem-calc(25);
      }
    }
  }
}

.btn {
  @include animate(background-color color, 0.25s);
  display: inline-block;
  vertical-align: top;
  border-radius: rem-calc(10);
  padding: rem-calc(15 35);
  text-align: center;
  color: $light-black;
  border: 0;
  text-decoration: none;
  position: relative;
  background-color: $blue;

  &:hover {
    background-color: darken($blue, 10%);
  }
}

.steps-section {
  padding: rem-calc(25 0);

  @include media(">=tablet") {
    padding: rem-calc(12 0 100);
  }

  .steps-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: rem-calc(908);
    margin: 0 auto;

    .steps {
      text-align: center;

      @include media("<tablet") {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: rem-calc(40);
      }

      @include media(">=tablet") {
        padding: rem-calc(0 15);
        width: 33.33%;
      }

      &:nth-child(even) {
        @include media(">=tablet") {
          flex-direction: column-reverse;
          display: flex;
          flex-wrap: wrap;
        }

        .image-holder {
          @include media(">=tablet") {
            margin-bottom: 0;
          }
        }

        .text-holder {
          @include media(">=tablet") {
            margin: rem-calc(0 0 28);
          }
        }
      }
    }

    .image-holder {
      width: 100%;
      margin-bottom: rem-calc(30);

      @include media(">=phone") {
        width: rem-calc(200);
      }

      @include media(">=580px") {
        width: rem-calc(300);
      }

      @include media(">=tablet") {
        margin-bottom: rem-calc(36);
        width: 100%;
      }
    }

    .text-holder {
      text-align: center;
      padding: rem-calc(0 20);

      @include media("<tablet") {
        flex: 1;
      }

      @include media(">=tablet") {
        width: 100%;
        padding: rem-calc(0 23);
        margin: rem-calc(0 0 28);
      }

      @include media(">=820px") {
        padding: rem-calc(0 35);
      }

      p {
        line-height: 1.429;
        margin-bottom: rem-calc(24);

        @include media(">=desktop") {
          margin-bottom: rem-calc(34);
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.heading {
  text-align: center;
  color: $dark-blue;
  max-width: rem-calc(900);
  margin: 0 auto 37px;

  @include media(">=tablet") {
    margin: 0 auto 73px;
  }

  .sub-heading {
    display: block;
    font-size: rem-calc(16);
  }

  h1 {
    color: $dark-blue;
    letter-spacing: 1.028;
  }

  &.heading-v2 {
    margin: 0 auto 29px;

    h1 {
      margin-bottom: rem-calc(15);

      @include media(">=tablet") {
        margin-bottom: rem-calc(25);
      }
    }
  }
}

.calender-section {
  padding: rem-calc(63 0 0);
  background-color: #f1f1f1;

  .calender-holder {
    text-align: center;
  }
}

.social-section {
  padding: rem-calc(57 0);

  .heading {
    p {
      line-height: 1.357;
    }

    h1 {
      margin-bottom: rem-calc(14) !important;
    }
  }

  .btn-holder,
  .link {
    display: block;
    text-align: center;
  }

  .link {
    font-size: rem-calc(12);
  }

  .btn-holder {
    margin-bottom: rem-calc(16);
  }
}

.social-post-holder {
  background-color: $white;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.09);
  border-radius: rem-calc(10);
  max-width: rem-calc(800);
  margin: 0 auto 36px;
  padding: rem-calc(24 27);

  @include media(">=desktop") {
    margin-top: rem-calc(47);
  }

  .post-holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: rem-calc(0 -15);

    .post {
      padding: rem-calc(0 15);
      margin-bottom: rem-calc(30);

      @include media(">=phone") {
        width: 50%;
      }

      @include media(">=580px") {
        width: 33.33%;
        margin-bottom: 0;
      }
    }
  }
}

.gifts-section {
  padding: rem-calc(40 0);
  background-color: #f1f1f1;

  @include media(">=tablet") {
    padding: rem-calc(60 0 90);
  }

  .heading {
    margin: 0 auto 35px !important;

    h1 {
      margin-bottom: rem-calc(27);
    }
  }

  .image-holder {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.09);
    border-radius: rem-calc(10);
    border: 7px solid $white;
    overflow: hidden;
    max-width: rem-calc(651);
    margin: 0 auto;

    @include media(">=tablet") {
      border: 15px solid $white;
    }
  }
}

.faq-section {
  padding: rem-calc(69 0 44);

  .heading {
    margin: 0 auto 50px;
  }

  .btn-holder {
    text-align: center;
    margin-top: rem-calc(46);
  }
}

.faq-holder {
  max-width: rem-calc(780);
  margin: 0 auto;
}

.accordion {
  @extend %listreset;

  > li {
    margin-bottom: rem-calc(24);

    &.active {
      a {
        &::before {
          transform: rotate(180deg) translateY(50%);
        }
      }
    }

    a {
      display: block;
      padding: rem-calc(18 60 20 30);
      position: relative;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.09);
      border-radius: rem-calc(10);
      background-color: $white;
      font-size: rem-calc(16);

      &:hover {
        background-color: darken($white, 1.2%);
      }

      @include media(">=tablet") {
        font-size: rem-calc(22);
        padding: rem-calc(23 60 28 47);
      }

      &:before {
        content: "\e900";
        font-family: "icomoon"; /* stylelint-disable-line */
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: rem-calc(27);
        @include animate(transform);
        font-size: rem-calc(13);
      }
    }
  }

  .slide {
    padding: rem-calc(25 30 10);
    font-size: rem-calc(16);
  }

  a {
    text-decoration: none;
  }
}
