.infinite-talent-marquee {
  z-index: 0;
  padding: 16px 0;

  &__talent {
    width: 15vh;
    max-width: 160px;

    a {
      transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
      &:hover {
        transform: scale(1.1);
        box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
      }
    }
  }

  --gap: 32px;

  display: flex;
  user-select: none;
  gap: var(--gap);
  position: relative;

  &__group {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: var(--gap);
    will-change: transform;
    animation: scroll var(--duration) linear infinite;
  }

  &--reverse {
    .infinite-talent-marquee__group {
      animation-direction: reverse;
    }
  }

  &:hover {
    .infinite-talent-marquee__group {
      animation-play-state: paused;
    }
  }

  &--mobile {
    .infinite-talent-marquee__talent {
      width: 20vh;
      max-width: 180px;
    }
  }

  @keyframes scroll {
    0% {
      transform: translateX(calc(50%));
    }

    100% {
      transform: translateX(calc(-50% - var(--gap)));
    }
  }
}
