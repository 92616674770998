.rating-modal {
  .send-tip-price-button {
    width: 101px;
    height: 48px;
    margin-bottom: 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-content: center !important;

    @include mobile {
      width: 90px;
    }

    .ant-input {
      text-align: center !important;
    }
  }

  .ant-input-number {
    border-radius: 8px;
  }

  .input-custom {
    input {
      text-align: center !important;
    }
  }

  .active-tip-price-btn {
    border-width: 2px !important;
    border-color: $primary !important;
  }
}
